import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState, Extra } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { AccessState } from "./@types/accessSlice";

/**
 * Initial State of Access State
 *
 * On Load, status will be 'new' to indicate that
 * there hasn't been any attempts to retrieve a new
 * token.
 */
const initialState: AccessState = {
  status: "new",
};

/**
 * Retrieve Auth0 Access Token
 *
 * Utilizes getAccessTokenSilently() underneath the hood
 * after the provider has successfully initialized Auth0
 * and the related Context
 *
 * @returns Auth0 Access Token
 */
export const getAccessToken = createAsyncThunk<
  string,
  void,
  { state: RootState; extra: Extra }
>(
  "access/getTokenSilently",
  async (_, { getState, rejectWithValue }) => {
    const { access: { getAccessTokenSilently, status, token: existingToken } } = getState();

    try {
      // console.log("[slice][access] access/getTokenSilently", access);

      if (!getAccessTokenSilently) {
        return rejectWithValue(
          new Error("Auth0 not initialized with access state")
        );
      }      
      
      let token = '';
      switch(status) {
        case "ready":
          token = existingToken
          break;

        default:
          token = await getAccessTokenSilently()
          break;
      }

      return token;
    } catch (e) {
      if ((e as any).error === "login_required") {
        // console.log("[slice][access] error: login required", e);
        // await access.loginWithRedirect();
      }
      if ((e as any).error === "consent_required") {
        console.log("[slice][access] error: consent required", e);
        // await access.loginWithRedirect();
      }

      return rejectWithValue(e);
    }
  },
  {
    // TODO: add condition later - seems to cause issues enabling it
    // condition: (_, { getState }) => getState().access.status !== 'loading'
  }
);

const accessSlices = createSlice({
  name: "access",
  initialState,
  reducers: {
    setTokenManagement(
      state,
      { payload: { getAccessTokenSilently, loginWithRedirect } }
    ) {
      console.info("[slice][access] init");
      state.getAccessTokenSilently = getAccessTokenSilently;
      state.loginWithRedirect = loginWithRedirect;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessToken.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      getAccessToken.rejected,
      (state, { payload }: PayloadAction<unknown>) => {
        // console.error("[slice][access] error", payload);
        state.status = "error";
        state.error = payload;
        state.lastUpdate = new Date();
      }
    );
    builder.addCase(
      getAccessToken.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        // console.log("[slice][access] ready", payload);
        state.status = "ready";
        state.token = payload;
        state.lastUpdate = new Date();
      }
    );
  },
});

export const { setTokenManagement } = accessSlices.actions;
export default accessSlices.reducer;
