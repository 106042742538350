enum EventErrorType {
  UNKNOWN_CAMPAIGN_ID = 'UNKNOWN_CAMPAIGN_ID',
  UNKNOWN_SPONSORSHIP_ID = 'UNKNOWN_SPONSORSHIP_ID',
  REGISTRATION_CLOSED = 'REGISTRATION_CLOSED',
}

export class EventError extends Error {
  static Type = EventErrorType

  constructor(eventError: EventErrorType) {
      super(eventError);

      // Set the prototype explicitly.
      Object.setPrototypeOf(this, EventError.prototype);
  }
}