const initialState = {
  view: 'transactions',
  transactions: [],
  totalTransactionPages: 1,
  totalTransactions: 0,
  transactionPage: 1,
  transactionPerPage: 20,
  otherTransactions: [],
  subscriptions: [],
  refundResult: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TRANSACTIONS_REPORT': {
      return {
        ...state,
        transactions: action.payload,
      }
    }
    case 'SET_OTHER_TRANSACTIONS_REPORT': {
      return {
        ...state,
        otherTransactions: action.payload,
      }
    }
    case 'SET_TOTAL_TRANSACTION_PAGES_REPORT': {
      return {
        ...state,
        totalTransactionPages: action.payload,
      }
    }
    case 'SET_TOTAL_TRANSACTIONS_REPORT': {
      return {
        ...state,
        totalTransactions: action.payload,
      }
    }
    case 'SET_TRANSACTION_PAGE_REPORT': {
      return {
        ...state,
        transactionPage: action.payload,
      }
    }
    case 'SET_TRANSACTION_PER_PAGE_REPORT': {
      return {
        ...state,
        transactionPerPage: action.payload,
      }
    }
    case 'SET_SUBSCRIPTIONS_REPORT': {
      return {
        ...state,
        subscriptions: action.payload,
      }
    }
    case 'SET_VIEW_REPORT': {
      return {
        ...state,
        view: action.payload,
      }
    }
    case 'SET_REFUND_RESULT_REPORT': {
      return {
        ...state,
        refundResult: action.payload,
      }
    }
    case 'RESET_REPORT': {
      return initialState
    }
  }
  return state
}