import _ from "lodash";
import axiosServer from "modules/axiosServer";
import { updateAttendeesUrl } from "modules/urls";
import { getEditedAttendees } from "modules/utils";
import { transformErrorIntoObject, attendeeValidationSchemaDict } from "modules/validation";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRecaptchaToken, setRecaptchaUsed } from "./recaptchaSlice";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Extra, RootState } from "src/store";
import { defaultRequiredFlags } from "./flags";

import type {
  AttendeesState,
  AttendeeCampaignState,
} from "./@types/attendeesSlice";

const initialState: AttendeesState = {
  attendees: [],
  pendingChanges: [],
  newAttendees: [],
  attendeeCampaigns: [],
  dataStatus: null,
  attendeeIdWithError: null,
  dataMessage: null,
  limit: 10,
  page: 1,
  count: 0,
  additionalConfigs: {},
  ...defaultRequiredFlags,
}

export const validateAttendees = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("attendees/validateAttendees", async (addAttendees, { getState, dispatch, rejectWithValue }) => {
  let idWithError, pageWithError
  try {
    const { attendees: { pendingChanges, newAttendees, attendees, limit, additionalConfigs } } = getState();
    const { validationSchema = 'attendee' } = additionalConfigs
    const editedAttendees = addAttendees ? newAttendees : getEditedAttendees(pendingChanges, attendees)
    if (!editedAttendees.length) {
      return rejectWithValue({ message: ['No changes detected'] });
    }
    const attendeeIds = pendingChanges.map(a => a.id)
    for (var index in editedAttendees) {
      idWithError = editedAttendees[index].id
      pageWithError = Math.floor(attendeeIds.indexOf(idWithError) / limit) + 1
      const {id, contact, ...attendee} = editedAttendees[index]
      await attendeeValidationSchemaDict[validationSchema].validate(attendee);
    }
  } catch (e) {
    console.error(e)
    dispatch(setAttendeeIdWithError(idWithError))
    dispatch(setPagination({ page: pageWithError }))
    return rejectWithValue(e);
  }
});

export const searchAttendees = createAsyncThunk<
  { data: any },
  any,
  { state: RootState; extra: Extra }
>(
  "attendees/searchAttendees",
  async (params, { dispatch, getState, rejectWithValue, extra }) => {
    const { attendees, access, recaptcha } = getState();
    const cid: string = params.cid
    const page: number = params.page
    const limit: number = params.limit || attendees.limit || null
    const currentAttendees = attendees.attendees
    const currentPendingChanges = attendees.pendingChanges
    const currentCount = attendees.count
    const currentAdditionalConfigs = attendees.additionalConfigs
    const targetIndex = { min: (page - 1) * limit, max: currentCount ? Math.min(page * limit, currentCount) : page * limit}
    const currentFetched = currentPendingChanges.slice(targetIndex.min, targetIndex.max)
    if (
      (currentFetched.length == targetIndex.max - targetIndex.min) &&
      !currentFetched.find(v => !v) && !params.force
    ) {
      // result has been fetched
      return dispatch(setPagination({ page }))
    }

    try {
      const recaptchaMode = recaptcha.mode
      let token = recaptchaMode === 'v2' ? recaptcha.v2Token : recaptcha.token;
      if (!token || recaptcha.status !== "idle") {
        await dispatch(getRecaptchaToken());
        token = getState().recaptcha[recaptchaMode === 'v2' ? 'v2Token' : 'token'];
      }

      const headers: { [key: string]: string | undefined } = {
        recaptcharesponsetoken: token,
      };
      if (access.token) {
        headers.Authorization = `Bearer ${access.token}`;
      }
      // debugger;
      const { data: { attendees: newAttendees, count, ...additionalConfigs } }: any = await axiosServer.get(
        updateAttendeesUrl,
        { 
          params: { cid, limit, page },
          headers
        }
      );

      let nextAttendees: Array<any> = Array.from(currentAttendees)
      let nextPendingChanges: Array<any> = Array.from(currentPendingChanges)
      for (var i = 0; i < newAttendees.length; i++) {
        nextAttendees[(page - 1) * limit + i] = newAttendees[i]
        nextPendingChanges[(page - 1) * limit + i] = newAttendees[i]
      }

      dispatch(setAttendees({
        attendees: nextAttendees,
        pendingChanges: nextPendingChanges,
      }));
      dispatch(setPagination({ page, limit, count }));
      if (additionalConfigs) {
        dispatch(setAdditionalConfigs(additionalConfigs))
      }
      setTimeout(() => {
        dispatch(setDataMessage(null));
      }, 2000);
      await dispatch(setRecaptchaUsed());
      return attendees;
    } catch (e) {
      console.error(e);
      await dispatch(setRecaptchaUsed());
      return rejectWithValue(e);
    }
  }
);

export const submitAttendeeChanges = createAsyncThunk<
  { data: any },
  any,
  { state: RootState; extra: Extra }
>(
  "attendees/submitAttendeeChanges",
  async (params, { dispatch, getState, rejectWithValue, extra }) => {
    const { addAttendees = false, accountId = '', cid = '' } = params
    const { attendees: { pendingChanges, newAttendees, attendees, extraForms }, access, recaptcha } = getState();
    const editedAttendees = addAttendees ? newAttendees : getEditedAttendees(pendingChanges, attendees)

    try {
      const recaptchaMode = recaptcha.mode
      let token = recaptchaMode === 'v2' ? recaptcha.v2Token : recaptcha.token;
      if (!token || recaptcha.status !== "idle") {
        await dispatch(getRecaptchaToken());
        token = getState().recaptcha[recaptchaMode === 'v2' ? 'v2Token' : 'token'];
      }

      const headers: { [key: string]: string | undefined } = {
        recaptcharesponsetoken: token,
      };
      if (access.token) {
        headers.Authorization = `Bearer ${access.token}`;
      }
      // debugger;
      const res: any = await axiosServer.post(
        updateAttendeesUrl,
        { attendees: editedAttendees, extraForms },
        { 
          params: addAttendees ? { insert: true, account_id: accountId, cid } : { cid },
          headers,
        }
      );

      dispatch(setAttendees({
        attendees: pendingChanges,
        pendingChanges,
      }))
      dispatch(setDataMessage(`Attendees are ${addAttendees ? 'added' : 'updated'} successfully`))
      setTimeout(() => {
        dispatch(setDataMessage(null))
      }, 2000)
      await dispatch(setRecaptchaUsed());
      if (addAttendees) {
        dispatch(searchAttendees({ cid, page: 1}))
        dispatch(removeAttendee([0, true]))
      }
      return res;
    } catch (e) {
      console.error(e);
      await dispatch(setRecaptchaUsed());
      return rejectWithValue(e);
    }
  }
);

const attendeesSlices = createSlice({
  name: 'attendees',
  initialState,
  reducers: {
    setPagination(
      state,
      {
        payload: { page, limit, count }
      }: PayloadAction<{ page: any, limit?: any, count?: any}>
    ) {
      if (page) {
        state.page = page
      }
      if (limit) {
        state.limit = limit
      }
      if (count !== null) {
        state.count = count
      }
    },
    editAttendee(
      state,
      {
        payload: [index, { name, value, data = null }, addAttendees = false],
      }: PayloadAction<[number, { name: string; value: any, data: any }, addAttendees: boolean]>
    ) {
      const targetField = addAttendees ? 'newAttendees' : 'pendingChanges';
      const targetAttendee = state[targetField][index] || null;

      state[targetField] = [
        ...state[targetField].slice(0, index),
        data || {
          ...targetAttendee,
          [name]: value,
        },
        ...state[targetField].slice(index + 1),
      ];
    },
    setAttendees(
      state,
      {
        payload: { attendees, pendingChanges },
      }: PayloadAction<{ attendees: Array<any>, pendingChanges: Array<any> }>
    ) {
      state.attendees = attendees;
      state.pendingChanges = pendingChanges;
    },
    removeAttendee(
      state,
      {
        payload: [removeIndex, reset = false],
      }: PayloadAction<[ number, boolean ]>
    ) {
      if (reset) {
        state.newAttendees = []
      }
      else {
        state.newAttendees = [
          ...state.newAttendees.slice(0, removeIndex),
          ...state.newAttendees.slice(removeIndex + 1),
        ]
      }
    },
    setAttendeeCampaigns(
      state,
      {
        payload: attendeeCampaigns,
      }: PayloadAction<Array<AttendeeCampaignState>>
    ) {
      state.attendeeCampaigns = attendeeCampaigns;
    },
    setAttendeeIdWithError(
      state,
      {
        payload: id,
      }: PayloadAction<string>
    ) {
      state.attendeeIdWithError = id;
    },
    setDataMessage(
      state,
      {
        payload: message,
      }: PayloadAction<string | null> 
    ) {
      state.dataMessage = message
    },
    setAdditionalConfigs(
      state,
      {
        payload: additionalConfigs,
      }: PayloadAction<object | null> 
    ) {
      state.additionalConfigs = additionalConfigs
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchAttendees.pending, (state) => {
      state.dataStatus = 'pending';
      state.attendeeIdWithError = null;
    });
    builder.addCase(searchAttendees.rejected, (state, { payload }: PayloadAction<any>) => {
      console.error('searchAttendees.rejected', payload)
      const responseError = payload?.response?.data || ''
      state.__valid = false
      state.__errors = [{ message: [responseError] }]
      state.dataStatus = null
    });
    builder.addCase(searchAttendees.fulfilled, (state) => {
      state.dataStatus = null
    });
    builder.addCase(submitAttendeeChanges.pending, (state) => {
      state.dataStatus = 'pending';
      state.attendeeIdWithError = null;
    });
    builder.addCase(submitAttendeeChanges.rejected, (state, { payload }: PayloadAction<any>) => {
      console.error('submitAttendeeChanges.rejected', payload)
      const responseError = payload?.response?.data || ''
      state.__valid = false
      state.__errors = [{ message: [responseError] }]
      state.dataStatus = null
    });
    builder.addCase(submitAttendeeChanges.fulfilled, (state) => {
      state.dataStatus = null
    });
    builder.addCase(validateAttendees.rejected, (state, { payload }: any) => {
      console.error('validateAttendees.rejected', payload)
      if (Array.isArray(payload.message)) {
        state.__errors = [payload];
      }
      else {
        state.__errors = transformErrorIntoObject(payload);
      }
      state.__valid = false;
      console.log(
        "[slices][attendees] validateAttendee results",
        state.__errors
      );
    });
    builder.addCase(validateAttendees.fulfilled, (state) => {
      state.attendeeIdWithError = null
      state.__errors = [];
      state.__valid = true;
    });
  },
})

export const { 
  editAttendee,
  setAttendees, 
  removeAttendee,
  setAttendeeCampaigns, 
  setAttendeeIdWithError, 
  setDataMessage,
  setPagination,
  setAdditionalConfigs,
} = attendeesSlices.actions;
export default attendeesSlices.reducer;