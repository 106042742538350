import type { Site } from "src/types/sites";
import { AppError } from "src/errors";

enum EnabledEnvironments {
  DEV = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

enum EnabledSites {
  VV = 'donate.veritysvillage.com',
  STS = 'donate.savethestorks.com',
  FEW = 'donate.foreverywoman.org',
  EMA = 'donate.everymothersadvocate.org'
}

const useLocal = import.meta.env.DEV || location.host.includes('localhost')

let siteDomains = [
  /** 
   * Production Domains
   */
  {
    domain: 'donate.veritysvillage.com',
    site: EnabledSites.VV,
    environment: EnabledEnvironments.PRODUCTION
  },
  {
    domain: 'donate.savethestorks.com',
    site: EnabledSites.STS,
    environment: EnabledEnvironments.PRODUCTION
  },
  {
    domain: 'donate.foreverywoman.org',
    site: EnabledSites.FEW,
    environment: EnabledEnvironments.PRODUCTION
  },
  {
    domain: 'donate.everymothersadvocate.org',
    site: EnabledSites.EMA,
    environment: EnabledEnvironments.PRODUCTION
  },

  /** 
   * Staging Domains
   */
  {
    domain: 'verity.staging.sukow.org',
    site: EnabledSites.VV,
    environment: EnabledEnvironments.STAGING
  },
  {
    domain: 'storks.staging.sukow.org',
    site: EnabledSites.STS,
    environment: EnabledEnvironments.STAGING
  },
  {
    domain: 'few.donate.staging.sukow.org',
    site: EnabledSites.FEW,
    environment: EnabledEnvironments.STAGING
  },
  {
    domain: 'donate.ema.staging.sukow.org',
    site: EnabledSites.EMA,
    environment: EnabledEnvironments.STAGING
  },

  /** 
   * TODO: Dev Domains configuration
   */
  // {
  //   domain: `verity.localhost.sukow.org:${import.meta.env.VITE_PORT}`,
  //   site: EnabledSites.VV,
  //   environment: EnabledEnvironments.DEV
  // },
  // {
  //   domain: `storks.localhost.sukow.org:${import.meta.env.VITE_PORT}`,
  //   site: EnabledSites.STS,
  //   environment: EnabledEnvironments.DEV
  // },
  // {
  //   domain: `few.donate.localhost.sukow.org:${import.meta.env.VITE_PORT}`,
  //   site: EnabledSites.FEW,
  //   environment: EnabledEnvironments.DEV
  // },
]

/** 
 * Local Domain or Vite Preview
 */
if (useLocal) {
  console.log(import.meta.env)
  const viteHost = import.meta.env.VITE_HOST
  siteDomains.push({
    domain: viteHost,
    site: viteHost as EnabledSites,
    environment: EnabledEnvironments.DEV
  })
}

/**
 * Shared Config for Services on Site
 */
const shared = {
  localStorageSuffix: '_state_key',
  services: {
    anet: {
      loginId: '42fXyc5tRxJ',
      clientKey: '9z2evfw74J9vkYdpB2Cv4vLq9Wv2dNjr9m6ehjJB6ZARpyzdWEjvq7EAhKWXSL8S',
    },
    googleRecaptcha: '6LcOCckaAAAAAH9u4DWdZ8Wdxz64zy_3oTDpysnV',
    googleRecaptcha_v2: '6LeG9HMcAAAAAHGUlZ4t60TIgyk3FuDe3p1MsgLc', // sk 6LeG9HMcAAAAABeLcUpdAOcNhsoZtxoNrWbueL-a
  },
  sandbox: {
    anet: {
      loginId: '4Q9p4tzQ568',
      clientKey: '9c8NFEkuvgj3yw2eS3WYseWu895P6NRrEfGcnCTdxDb7g3AL3L562VRHusmcLamZ',
    },
    googleRecaptcha: '6LcOCckaAAAAAH9u4DWdZ8Wdxz64zy_3oTDpysnV',
    googleRecaptcha_v2: '6LeG9HMcAAAAAHGUlZ4t60TIgyk3FuDe3p1MsgLc',
                  // '6LeG9HMcAAAAAHGUlZ4t60TIgyk3FuDe3p1MsgLc' // site key v2
                  // '6LeG9HMcAAAAABeLcUpdAOcNhsoZtxoNrWbueL' // secret v2
  }
}

/**
 * Full Config for Services and Host for Site
 */
export const config : Record<string, Site> = {
  /** 
   * For Every Women Site Config
   */
  [EnabledSites.FEW]: {
    title: 'For Every Women - Donation & Payment',
    gtmId: '',
    ...shared,
    site: 'FEW',
    prefix: 'few',
    host: {
      server: 'https://donate.foreverywoman.org',
      home: 'https://donate.foreverywoman.org',
      cookie: '.foreverywoman.org',
      whitelist: 'donate.savethestorks.com,donate.veritysvillage.com,storks.staging.sukow.org,verity.staging.sukow.org'.split(','),
      receipts: 'https://donate.foreverywoman.org',
    },
    services: {
      auth0: {
        client: import.meta.env.MODE === 'production' ? 'FRACTuriUp7sjpKeJ251OOtIOzd2femh' : 'S4mndsOVvwoSnl6VP2ITddT4ZegxRbTm',
        domain: 'few.us.auth0.com',
      },
      stripe: {
        pk: 'pk_live_51LFT0RGagZwBlmlKCoFYUeZxCLKLxCCYY1oErBsFVVRBXVYNQ7hDDSQz5FVl5UMdRJPYxTtTIR1Wy8ww9YDHbrij00i4GtPxhz',
      },
      ...shared.services
    },
    sandbox: {
      auth0: {
        client: 'piQb0qaL4qhYkpRynKzblF58lXgiJFmn',
        domain: 'few.us.auth0.com',
      },
      stripe: {
        pk: 'pk_test_51LFT0RGagZwBlmlKnOD6bbXdn1S1hxIKPLzoJQlQtcdGhyZ1ynh9rHfhkRBAWiVldeKq9ZiuZYiF6aLVGS4YJDqX00jTtpLYQ0',
      },
      ...shared.sandbox
    }
  },

  /** 
   * Verity's Village Site Config
   */
  [EnabledSites.VV]: {
    title: 'Verity\'s Village - Donation & Payment',
    gtmId: 'GTM-NRVNGVR',
    ...shared,
    site: 'VV',
    prefix: 'vv',
    host: {
      server: 'https://donate.veritysvillage.com',
      home: 'https://donate.veritysvillage.com',
      cookie: '.veritysvillage.com',
      whitelist: 'donate.savethestorks.com,donate.veritysvillage.com,storks.staging.sukow.org,verity.staging.sukow.org'.split(','),
      receipts: 'https://donate.veritysvillage.com',
    },
    services: {
      auth0: {
        client: import.meta.env.MODE === 'production' ? 'n3TwPqciErgVURX4hfw6WfMSS5XT4Rx0' : 'sfM4T7CHxTztnsdKri5lwhSkI68vwZPJ',
        domain: 'savethestorks.us.auth0.com',
      },
      stripe: {
        pk: 'pk_live_51J35QVDCYyqzHmllj23UELMwJsmihplmb6jOAsuTmEk8Aeu2k1l4O4tKRr2WwvvHiJ1WMltEB2rnXYxJIT0OhCXz00DbtMllrH',
      },
      ...shared.services
    },
    sandbox: {
      auth0: {
        client: 'Q6UeilX9FEEJLFETxH1hE0iL9vGp6Wwg',
        domain: 'savethestorks.us.auth0.com',
      },
      stripe: {
        pk: 'pk_test_51J35QVDCYyqzHmllwc5rIm80c5p4ZBYfQheIfXEksmUcMuZHvCiMG5ijT5HOohBitM4HcVl9CMiCuKZbZwFShzGV00pRUsgN7e',
      },
      ...shared.sandbox
    }
  },

  /** 
   * Save The Storks Site Config
   */
  [EnabledSites.STS]: {
    title: 'Save the Storks - Donation & Payment',
    gtmId: 'GTM-W4HFDGS',
    ...shared,
    site: 'STS',
    prefix: 'sts',
    host: {
      server: 'https://donate.savethestorks.com',
      home: 'https://donate.savethestorks.com',
      cookie: '.savethestorks.com',
      whitelist: 'donate.savethestorks.com,donate.veritysvillage.com,storks.staging.sukow.org,verity.staging.sukow.org'.split(','),
      receipts: 'https://donate.savethestorks.com',
    },
    services: {
      auth0: {
        client: import.meta.env.MODE === 'production' ? 'A2JhBbySEEpvOBR8j2v8VA23wwCW1b1Z' : 'ovzzB0svO09wYAO70V8JpdEY7kPWyb2O',
        domain: 'savethestorks.us.auth0.com',
      },
      stripe: {
        pk: 'pk_live_KJeQy2Rovzk4kdI6PGkJ8B0e',
      },
      ...shared.services
    },
    sandbox: {
      auth0: {
        client: 'p1rVqu67drxHWsf6cj2s5oPCe03Rm26r', //'p1rVqu67drxHWsf6cj2s5oPCe03Rm26r',
        domain: 'savethestorks.us.auth0.com',
      },
      stripe: {
        pk: 'pk_test_BK12wxfhXsjKScJjRqp4nTXH',
      },
      ...shared.sandbox
    }
  },

  /** 
   * Save The Storks Site Config
   */
  [EnabledSites.EMA]: {
    title: 'Every Mother&#039;s Advocate - Donation & Payment',
    gtmId: 'GTM-TR24R89',
    ...shared,
    site: 'EMA',
    prefix: 'ema',
    host: {
      server: 'https://donate.everymothersadvocate.org',
      home: 'https://donate.everymothersadvocate.org',
      cookie: '.savethestorks.com',
      whitelist: 'donate.everymothersadvocate.org,donate.staging.ema.sukow.org'.split(','),
      receipts: 'https://donate.everymothersadvocate.org',
    },
    services: {
      auth0: {
        client: import.meta.env.MODE === 'production' ? '' : '',
        domain: '',
      },
      stripe: {
        pk: '',
      },
      ...shared.services
    },
    sandbox: {
      auth0: {
        // need to update
        client: 'p1rVqu67drxHWsf6cj2s5oPCe03Rm26r', //'p1rVqu67drxHWsf6cj2s5oPCe03Rm26r',
        domain: 'everymothersadvocate.us.auth0.com',
      },
      stripe: {
        pk: 'pk_test_9nd6zm9PsUXJmNwMdVRnN1Rb',
      },
      ...shared.sandbox
    }
  },
}

/**
 * Web Worker instance using ComLink for RPCs
 */
// export const workerInstance = new ComlinkWorker<typeof import("./worker")>(
//   new URL("./worker", import.meta.url)
// );

/** 
 * Use Config [default export]
 * 
 * @returns Site configuration
 */
export const useConfig = () : Site => {
  let site: EnabledSites

  const sites = siteDomains.filter(site => site.environment == import.meta.env.MODE)
  if (sites.length < 1) {
    throw new Error(AppError.UNABLE_TO_RESOLVE_SITE)
  }

  console.log(sites, import.meta.env.MODE, location.host)
  if (useLocal && sites.length > 0) {
    // site = sites[0].site
    site = EnabledSites.STS
  } else {
    const resolvedSite = sites.find(site => site.domain === location.host)
    console.log(resolvedSite)
    if (!resolvedSite) {
      throw new Error(AppError.UNABLE_TO_RESOLVE_SITE)
    }

    site = resolvedSite.site
  }

  console.log(config[site])
  return config[site]
}
export default useConfig