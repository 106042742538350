const initialState = {
  modalOpen: false,
  primaryCommunicationChannel: null,
  secondaryCommunicationChannel: null,
  enjoyReceiving: [],
  openEmailFrequency: null,
  reasonToSupport: null,
  agreeToReceive: [],
  birthdate: {
    year: null,
    month: null,
    date: null,
  },
  gender: null,
  whetherOwnBusiness: null,
  maritalStatus: null,
  numberOfKids: null,
  hasExperienceOfAdoption: null,
  representedMinistry: null,
  withUnplannedPregnancyExperience: null,
  dataStatus: null, // loading etc
  // contact: {
  //   firstName: '',
  //   lastName: '',
  //   streetAddress: '',
  //   company: '',
  //   city: '',
  //   state: '',
  //   zip: '',
  //   country: '',
  //   phone: '',
  //   email: '',
  // },
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case 'SET_CONTACT_DATA_PREFERENCES': {
    //   return {
    //     ...state,
    //     contact: {
    //       ...state.contact,
    //       ...action.payload,
    //     }
    //   }
    // }
    case "SET_FORM_DATA_PREFERENCES": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "SET_DATA_STATUS_PREFERENCES": {
      return {
        ...state,
        dataStatus: action.payload,
      };
    }
    case "SET_ERROR_PREFERENCES": {
      return {
        ...state,
        error: action.payload,
      };
    }
    case "SET_MODAL_OPEN_PREFERENCES": {
      return {
        ...state,
        modalOpen: action.payload,
      };
    }
    case "RESET_PREFERENCES": {
      return {
        ...initialState,
        ...action.payload,
      };
    }
  }
  return state;
};
