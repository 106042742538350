import React from 'react'
import { Row, Col } from 'react-bootstrap'

const contents = [
  {
    img: 'amy.png',
    text: '"When I saw his little heartbeat, I remember laying there just crying, and at that point, thinking I can\'t do this, I cannot have an abortion."',
    name: 'Amy',
    role: 'Mom',
  },
  {
    img: 'marcus.png',
    text: '"In a world that\'s so easy to be known for what you\'re against, Save the Storks has given us a better way. We can be known for what we\'re for, not what we\'re against! We can proclaim the solution and offer real support to abortion-vulnerable women who need to know that they\'re not alone."',
    name: 'Pastor Marcus Mecum',
    role: 'Donor'
  },
  {
    img: 'david.png',
    text: '"The work that Save the Storks does on a daily basis is truly transformative and life-saving... Their meaningful partnerships have empowered many women to choose life for their babies. When a woman board a Stork Bus, she is cared for with compassion and love... Save the Storks is unique in their mission, and they truly approach the pro-life mission with excellence and kindness in all they do."',
    name: 'David Fonseca',
    role: 'Donor',
  },
]
const campaignContents = {
  '701Pd0000045MliIAE': [
    {
      img: 'jon.jpg',
      text: (
        <div>
          {`Purchasing a Mobile Medical Unit is a significant undertaking... After thoughtful deliberation, we chose to partner with Save the Storks to bring mobile ministry to Bright Hope of Allentown, Pennsylvania.`}
          <br/><br/>
          {`The journey has been nothing short of fantastic! Our Mobile Clinic is out in the community every week, and in just over a year, we have served dozens of clients and patients. Save the Storks has been an invaluable resource, supporting us every step of the way.`}
          <br/><br/>
          {`Thanks to their partnership, we have ministered to multiple women who were about to enter the doors of an abortion clinic but instead chose life for their child aboard our Mobile Clinic.`}
        </div>
      ),
      name: 'Jon Merwarth',
      role: 'CEO, Bright Hope Women\'s Health Center'
    },
    {
      img: 'debbie.jpg',
      text: (
        <div>
          {`The Mobile Medical Clinic is the most powerful tool to showing an abortion minded woman truth, which halts the abortion and then we become effective to walk alongside the woman to show her that she can be a bother through finding solutions, education, encouragement and empowering her.`}
          <br/><br/>
          {`Having a presence on the road has also afforded us the opportunity to be available to women in victimizing situations, such as sex trafficking. Mobile Medical Clinic and the support, training, conferences and cutting-edge solutions from Save the Storks is the pinnacle point of true transformation for a woman to change her life.`}
          <br/><br/>
          {`Save the Storks helps us reaching the women today with new ideas, what women really are looking for and truly helping us to be a shining light in a very dark and evil arena.`}
        </div>
      ),
      name: 'Debbie Biskey',
      role: 'Executive Director, Options for Her',
    },
  ]
}
const campaignStyles = {
  '701Pd0000045MliIAE': `@media only screen and (min-width: 1057px) {
    .app-form-body {
      margin-bottom: 70px;
    }
  }`
}

const Testimonials = ({site = 'STS', cid = ''}) => {
  switch (site) {
    case 'STS':
      return (
        <div id="testimonials" name="testimonials" className='mb3'>
          {
            <style>{campaignStyles[cid] || ''}</style>
          }
          {/* <div id="gift-banner-p1">
            <img src="https://donate.savethestorks.com/images/tshirt.jpeg" style={{ maxWidth: '100%'}} />
            <div>*Free t-shirt for a first-time donor with a monthly gift of $10 a month or a one-time gift of $50. While supplies last.</div>
          </div> */}
          <div id="gift-banner-p1" className='center'>
            <img src="https://donate.savethestorks.com/images/donatesticker.png" style={{ maxWidth: 200, margin: 'auto' }} />
            <div className='center'>Free sticker with a gift of any amount!</div>
          </div>
          <Row>
            {
              (campaignContents[cid] || contents).map((c, index) => {
                const image = (
                  <div className='testimonial-image'>
                    <img src={`https://donate.savethestorks.com/images/testimonials/${c.img}`}/>
                  </div>
                )
                return (
                  <Col xs={12} key={index}>
                    <div className='full-width mt1 mb2'><img className='full-width' src="https://donate.savethestorks.com/images/testimonials/sep.png"/></div>
                    <div className='flex pt2'>
                      { index % 2 ? null : image }
                      <div className='inline-block testimonial-text mb2'>
                        { c.text }
                        <br /><br/>
                        <div className='darkblue'>{c.name}</div>
                        <i className='storks-blue'>{c.role}</i>
                      </div>
                      { index % 2 ? image : null }
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </div>
      )
    case 'FEW':
      return (
        <div id="testimonials" name="testimonials" className='pt3' style={{ backgroundColor: '#F4E7F0', color: '#976B40' }}>
          <p>
            { `We want women and families to have access to the care they need for pregnancy and beyond. We are building and strengthening relationships nationwide. No matter where you are in the nation, your donation will go directly toward supporting those in your community to get health care, pregnancy resources, and more.`}
            <br/>
            <b>{`Make your tax-deductible gift today so we can continue connecting, educating, and empowering people everywhere.`}</b>
          </p>
        </div>
      )
    default:
      return null
  }
} 

export default Testimonials