import _ from "lodash";
import { MIN_DONATION, MIN_DONATION_PER_ATTENDEE } from "modules/constants";
import { optionSchema, transformErrorIntoObject } from "modules/validation";

export const inputDonationAmount = (donationAmount) => {
  return (dispatch) => {
    if (isNaN(Number(donationAmount))) return;
    if (!donationAmount) {
      dispatch(setValidationError({ donationAmount: true }));
      dispatch(setDonationAmount(null));
      return;
    }
    dispatch(
      setDonationAmount(
        donationAmount.match(/\.\d\d[\d]+/)
          ? _.round(Number(donationAmount), 2)
          : Number(donationAmount)
      )
    );
    dispatch(setValidationError({ donationAmount: false }));
  };
};

export const setInitialAmountType = ({ amount, type, onlyShowTypes }) => {
  const payload = {};
  if (!isNaN(amount)) payload.amount = Number(amount);
  if (["yearly", "monthly", "one-time"].includes(type)) payload.type = type;
  const validTypes = ["yearly", "monthly", "one-time"].filter((t) =>
    onlyShowTypes.includes(t)
  );
  if (validTypes.length) payload.onlyShowTypes = validTypes;
  if (validTypes.length && !validTypes.includes(payload.type))
    payload.type = validTypes[0];
  return {
    type: "SET_INITIAL_AMOUNT_TYPE_PAYMENT",
    payload,
  };
};

export const setDonationType = (donationType) => {
  return {
    type: "SET_DONATION_TYPE_OPTIONS",
    payload: donationType,
  };
};

export const setDonationAmount = (donationAmount) => {
  return {
    type: "SET_DONATION_AMOUNT_OPTIONS",
    payload: donationAmount,
  };
};

export const setValidationError = (error) => {
  return {
    type: "SET_VALIDATION_ERROR_OPTIONS",
    payload: error,
  };
};

export const setMonthlyOtherAmount = (flag) => {
  return {
    type: "SET_MONTHLY_OTHER_AMOUNT_OPTIONS",
    payload: flag,
  };
};

export const setPaymentDate = (date) => {
  return {
    type: "SET_PAYMENT_DATE_OPTIONS",
    payload: date,
  };
};

export const setPaymentMonth = (month) => {
  return {
    type: "SET_PAYMENT_MONTH_OPTIONS",
    payload: month,
  };
};

export const setPlans = (
  monthlyAmountQuery,
  yearlyAmountQuery,
  oneTimeAmountQuery
) => {
  let payload = {};
  if (monthlyAmountQuery) {
    const monthlyAmounts = monthlyAmountQuery
      .split(",")
      .map((v) => Number(v))
      .filter((v) => v);
    if (monthlyAmounts.length) {
      payload["monthly"] = [...monthlyAmounts, null];
    }
  }
  if (yearlyAmountQuery) {
    const yearlyAmounts = yearlyAmountQuery
      .split(",")
      .map((v) => Number(v))
      .filter((v) => v);
    if (yearlyAmounts.length) {
      payload["yearly"] = [...yearlyAmounts, null];
    }
  }
  if (oneTimeAmountQuery) {
    const oneTimeAmounts = oneTimeAmountQuery
      .split(",")
      .map((v) => Number(v))
      .filter((v) => v);
    if (oneTimeAmounts.length) {
      payload["one-time"] = [...oneTimeAmounts, null];
    }
  }
  return {
    type: "SET_PLANS_OPTIONS",
    payload,
  };
};

export const injectPlan = (amount, term) => {
  return {
    type: "INJECT_PLAN",
    payload: { amount, term },
  };
};

export const checkOptionValidity = (site, skip = false) => {
  return async (dispatch, getState) => {
    const {
      account: {
        contact: { email },
      },
      options: { donationType, monthlyOtherAmount, donationAmount },
      charityRun,
      eventRegistration,
    } = getState();

    if (skip) return;

    try {
      let minDonationAmount;
      if (charityRun.ready) {
        minDonationAmount = charityRun.attendees.length * MIN_DONATION_PER_ATTENDEE;
      }
      else if (!isNaN(eventRegistration?.campaignParams?.minDonationAmount)) {
        minDonationAmount = eventRegistration?.campaignParams?.minDonationAmount;
      }
      else {
        minDonationAmount = MIN_DONATION;
      }
      if (!!minDonationAmount || email.includes(`+${site.toLowerCase()}test`)) {
        minDonationAmount = 1;
      }

      await optionSchema(minDonationAmount).validate(
        {
          donationAmount,
          donationType,
          monthlyOtherAmount,
        },
        { abortEarly: false }
      );

      await dispatch({
        type: "SET_OPTIONS_VALID",
        payload: true,
      });
    } catch (e) {
      await dispatch({
        type: "SET_OPTIONS_ERROR",
        payload: transformErrorIntoObject(e),
      });
    }
  };
};
