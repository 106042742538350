import moment from 'moment-timezone'

const initialState = {
  valid: false,
  errors: [],
  donationType: 'monthly',
  donationAmount: null,
  donationProduct: null,
  donationPlan: null,
  paymentDate: (new Date()).getDate(),
  paymentMonth: (new Date()).getMonth(),
  validationError: {},
  monthlyOtherAmount: false,
  plans: {
    'yearly': [500, 750, 1000, 1500, null],
    'monthly': [35, 50, 70, 105, null],
    'one-time': [100, 250, 500, 1000, null],
  },
  onlyShowTypes: [],
}

const adjustPaymentDate = (paymentDate, paymentMonth) => {
  if (moment().month(paymentMonth).date(paymentDate).month() !== paymentMonth) {
    return moment().month(paymentMonth + 1).date(1).subtract(1, 'day').date()
  }
  else return paymentDate
}

export default (state = initialState, action) => {
  switch(action.type) {
    case 'SET_OPTIONS_VALID': {
      return {
        ...state,
        valid: action.payload,
        errors: [],
      }
    }
    case 'SET_OPTIONS_ERROR': {
      return {
        ...state,
        errors: action.payload,
        valid: false,
      }
    }
    case 'SET_INITIAL_AMOUNT_TYPE_PAYMENT': {
      return {
        ...state,
        donationAmount: action.payload.amount || state.donationAmount,
        donationType: action.payload.type || state.donationType,
        onlyShowTypes: action.payload.onlyShowTypes || state.onlyShowTypes,
      }
    }
    case 'SET_DONATION_TYPE_OPTIONS': {
      return {
        ...state,
        donationType: action.payload,
      }
    }
    case 'SET_DONATION_AMOUNT_OPTIONS': {
      return {
        ...state,
        donationAmount: action.payload,
      }
    }
    case 'SET_PAYMENT_DATE_OPTIONS': {
      return {
        ...state,
        paymentDate: action.payload,
      }
    }
    case 'SET_PAYMENT_MONTH_OPTIONS': {
      return {
        ...state,
        paymentDate: adjustPaymentDate(state.paymentDate, action.payload),
        paymentMonth: action.payload,
      }
    }
    case 'SET_VALIDATION_ERROR_OPTIONS': {
      return {
        ...state,
        validationError: {
          ...state.validationError,
          ...action.payload,
        }
      }
    }
    case 'SET_MONTHLY_OTHER_AMOUNT_OPTIONS': {
      return {
        ...state,
        monthlyOtherAmount: action.payload,
      }
    }
    case 'SET_PLANS_OPTIONS': {
      return {
        ...state,
        plans: {
          ...state.plans,
          ...action.payload,
        }
      }
    }
    case 'INJECT_PLAN': {
      // sort and inject
      const injected = [
        ...[action.payload.amount, ...state.plans[action.payload.term].filter(a => a)].sort((a, b) => a - b),
        null,
      ]

      return {
        ...state,
        plans: {
          ...state.plans,
          [action.payload.term]: injected
        }
      }
    }
    case 'RESET_OPTIONS': {
      return {
        ...initialState,
        donationType: state.donationType,
        donationAmount: state.donationAmount,
        monthlyOtherAmount: !state.plans[state.donationType].includes(state.donationAmount),
        plans: state.plans, // retain plans
        ...action.payload,
      }
    }
  }
  return state
}