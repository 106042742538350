const cookieDomain = location.host !== `localhost:${location.port}`
    ? `.${location.host.split('.').slice(-2).join('.')}`
    : 'localhost'

export const setCookie = (
    cname,
    cvalue,
    extime = 0,
    cdomain = cookieDomain
) => {
    let d = new Date()
    d.setTime(d.getTime() + extime);
    let expires = "expires="+ d.toUTCString();
    let domain = "domain=" + cdomain;
    document.cookie = cname + "=" + cvalue + ";" + domain + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    let name;

    if (Array.isArray(cname)) {
        let cvalue = cname.map(() => '');
        name = cname.map(cn => cn + '=');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            name.forEach((n, index) => {
                if (c.indexOf(n) === 0) {
                    cvalue[index] = c.substring(n.length, c.length);
                }
            })
        }
        return cvalue
    }
    else {
        name = cname + "=";
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
