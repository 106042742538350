import React, { useEffect } from 'react';
import { Button, ButtonToolbar, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    addAttendee, removeAttendee, updateAttendee, updateConfirmed
} from 'slices/charityRunSlice';

import type { FunctionComponent } from 'react'
import type { CharityRunContact } from 'slices/@types/charityRunSlice'
import type { RootState, AppDispatch } from 'src/store'
import _ from 'lodash'

/**
 * Attendees for Charity Run
 * 
 * Supports up to (n) amount of attendee for a charity run donation. 
 * Each Attendee is required to enter in a Name, Email and Shirt Size.
 * Phone number is optional. Each attendee is setup as a household in 
 * Salesforce and de-duped if they already exist. 
 * 
 * @returns JSX Element
 */
export const Attendees : FunctionComponent<{}> = () => {
  const dispatch = useDispatch<any>()
  const {
    attendees, 
    confirmed,
    __errors,
    displayValidationError,
    campaignId,
  } = useSelector(({ charityRun, payment }: RootState) => ({...charityRun, displayValidationError: payment.displayValidationError }))
  const { campaigns } = useSelector(({ root }:  RootState) => root)
  const campaign = campaigns.find(c => c.id === campaignId)
  const campaignFields = _.get(campaign, ['options', 'event', 'campaignParams', 'fields'], [])

  /**
   * Field Invalid handler for 
   *  <Form.Control>
   *  errorClassHandle()
   * 
   * @param index - Index of Attendee
   * @param field - Field Name
   * @returns true if value is invalid
   */
  const fieldInvalid = (index, field) => 
    __errors.some(e => e.field === `attendees[${index}].${field}`)

  /**
   * Error Class handler for adding error class to the column
   * 
   * @param index - Index of Attendee
   * @param field - Field Name
   * @returns error class to add styling to field 
   */
  const errorClassHandle = (index, field) => 
    displayValidationError && 
    fieldInvalid(index, field)
      ? 'has-error' 
      : ''

  /**
   * Retrieve relative Error Message from validation attempt
   * 
   * @param index - Index of Attendee
   * @param field - Field Name
   * @returns error message to display to user
   */
  const errorMessage = (index, field) => 
    __errors.find(e => e.field === `attendees[${index}].${field}`)?.message[0]

  /**
   * Reset Error from OnChange event
   * 
   * @param event - OnChangeEvent Listener
   */
  const resetError = (event) => {
    event.currentTarget.classList.remove('is-invalid')
    event.currentTarget.parentElement?.parentElement?.classList.remove('has-error')
  }

  /**
   * On Change handler for Form.Control
   * 
   * @param event - OnChangeEvent Listener
   * @param index - Index of Attendee
   */
  const onChangeHandle = (event, index) => {
    // TODO: validate field on change instead of resetting error
    resetError(event)
    dispatch(updateAttendee([index, event.target]))
  }

  /**
   * Defined Shirt Sizes for Form.Select
   */
  const shirtSizes: { label: string, value: string }[] = [
    // { label: 'X-Small', value: 'xs' },
    { label: 'Small', value: 's' },
    { label: 'Medium', value: 'm' },
    { label: 'Large', value: 'l' },
    { label: 'X-Large', value: 'xl' },
    { label: 'XX-Large', value: 'xxl' },
    { label: 'XXX-Large', value: 'xxxl' },
  ]

  /** 
   * On Mount add one blank Attendee to start
   */
  useEffect(() => {
    let dispatched;
    if (!attendees.length) {
      dispatched = dispatch(addAttendee())
    }

    return () => {
      if (dispatched) {
        dispatched.abort()
      }
    }
  }, [])

  return (
    <div className="attendee-list">
      {attendees.map((attendee: CharityRunContact, index: number) => {
        const rowTitle = index === 0 ? 'Main Registrant' : `Attendee ${index + 1}`

        return (
          <div key={index}>
            <Row>
              <Col xs={12}>
                {rowTitle}
              </Col>
            </Row>
            <Row xs={1} sm={2} md={3}>
              {
                campaignFields.map((campaignField, campaignFieldIndex) => {
                  let fieldInput
                  switch(campaignField.type) {
                    case 'select':
                      fieldInput = (
                        <Form.Group as={Col} className={errorClassHandle(index, campaignField.name)} key={campaignFieldIndex}>
                          <FloatingLabel label={campaignField.placeholder}>
                            <Form.Select title={campaignField.placeholder} 
                              name={campaignField.name}
                              onChange={(event) => onChangeHandle(event, index)}
                              isInvalid={fieldInvalid(index, campaignField.name)}
                            >
                              {campaignField.options.map((option, optionIndex) => (
                                <option
                                  key={`${option.value}${optionIndex}`}
                                  value={option.value}
                                >
                                { option.label }
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errorMessage(index, campaignField.name)}</Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      )
                      break
                    case 'tel':
                      fieldInput = (
                        <Form.Group as={Col} className={errorClassHandle(index, campaignField.name)} key={campaignFieldIndex}>
                          <FloatingLabel label={campaignField.placeholder}>
                            <Form.Control
                              name={campaignField.name}
                              placeholder={campaignField.placeholder}
                              type='tel'
                              pattern={campaignField.pattern}
                              value={attendee[campaignField.name] || ''}
                              onChange={(event) => onChangeHandle(event, index)}
                              isInvalid={fieldInvalid(index, campaignField.name)}
                            />
                            <Form.Control.Feedback type="invalid">{errorMessage(index, campaignField.name)}</Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      )
                      break
                    default:
                      fieldInput = (
                        <Form.Group as={Col} className={errorClassHandle(index, campaignField.name)} key={campaignFieldIndex}>
                          <FloatingLabel label={campaignField.placeholder}>
                            <Form.Control
                              name={campaignField.name}
                              placeholder={campaignField.placeholder}
                              type='text'
                              value={attendee[campaignField.name]}
                              onChange={(event) => onChangeHandle(event, index)}
                              isInvalid={fieldInvalid(index, campaignField.name)}
                            />
                            <Form.Control.Feedback type="invalid">{errorMessage(index, campaignField.name)}</Form.Control.Feedback>
                          </FloatingLabel>
                        </Form.Group>
                      )
                  }
                  return fieldInput
                })
              }
              <Col style={{textAlign: 'right'}}>
                <Button 
                  variant="outline-secondary"
                  size="lg"
                  onClick={() => dispatch(removeAttendee(index))}
                >
                  <span className="fa fa-trash" />
                </Button>
              </Col>
            </Row>
            
          </div>
        )
      })}

      <ButtonToolbar>
        <Button 
          variant='primary'
          className="add-another v-center"
          onClick={() => dispatch(addAttendee())}
        >+</Button>
        <Button
          variant='link'
          className="add-another"
          onClick={() => dispatch(addAttendee())}
        >Add another attendee</Button>
      </ButtonToolbar>

      <Form.Check 
        id="confirm-registrant-shipping"
        className="pre-field flex-children"
      >
        <Form.Check.Input
          type='checkbox'
          defaultChecked={confirmed}
          onChange={event => dispatch(updateConfirmed(event.target.checked))}
        />
        <Form.Check.Label>
          I confirm that the t-shirts will be shipped to the main registrant's address.
        </Form.Check.Label>
      </Form.Check>
    </div>
  )
}

export default Attendees