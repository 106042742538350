export const retrieveAccountUrl = '/account'

export const retrieveRolesUrl = '/account/roles'

export const createStripeSetupIntentUrl = '/account/stripe_setup_intent'

export const fetchConnectionTokenUrl = '/stripe/connection_token'

export const fetchStripeTerminalConfigUrl = '/stripe/terminal_config'

export const fetchStripeTerminalsUrl = '/stripe/terminals'

export const auditAccountUrl = '/account/audit'

export const deletePaymentProfileUrl = '/account/payment_profile'

// export const getPlansUrl = '/donation/plans'

export const donationUrls = {
  ['one-time']: '/donation/charge',
  ['monthly']: '/donation/subscribe',
  ['yearly']: '/donation/subscribe',
}

export const updatePaymentMethodUrl = '/donation/payment_method'

export const sourceUrl = '/source'

export const cancelDonationUrl = '/donation/cancel'

export const pauseDonationUrl = '/donation/pause'

export const refundDonationUrl = '/donation/refund'

export const getTransactionsUrl = '/transaction'

export const getReceiptUrl = '/transaction/receipt'

export const getCampaignsUrl = '/campaign'

export const getUtmVideosUrl = '/campaign/utm_videos'

export const getStatusReasonsUrl = '/status_reason'

export const preferencesUrl = '/preferences'

export const plaidCreateLinkTokenUrl = '/plaid/create_link_token'

export const plaidGetStripeTokenUrl = '/plaid/get_stripe_token'

export const adminAttendeesUrl = '/admin/attendees'

export const adminAttendeesCreatedUrl = '/admin/attendees_created'

export const adminUsersUrl = '/admin/users'

export const adminContactsOfAccountUrl = 'admin/salesforce_contacts'

export const adminMimicUrl = '/admin/mimic'

export const adminUnmimicUrl = '/admin/unmimic'

export const adminMergeAccountsUrl = '/admin/merge_accounts'

export const adminMergeContactsUrl = '/admin/merge_contacts'

export const updateMailChimpUrl = '/email/mailchimp'

export const charityRunHouseholdUrl = '/account/charity_run'

export const updateAttendeesUrl = '/account/attendees'
