import axiosServer from "modules/axiosServer";
import { downloadAttachment } from "modules/utils";
import {
  cancelDonationUrl,
  pauseDonationUrl,
  getTransactionsUrl,
  getReceiptUrl,
  refundDonationUrl,
} from "modules/urls";
import { setLoading } from "./paymentActions";
import {
  retrieveCustomer,
  setRootSubscription,
  setRootSubscriptionYearly,
} from "../slices/rootSlice";
import _ from "lodash";
import { sendGtmPixel } from "modules/gtm";
import { getCookie } from "modules/cookie";
import moment from "moment-timezone";

export const setTransactions = (transactions) => {
  return {
    type: "SET_TRANSACTIONS_REPORT",
    payload: transactions,
  };
};

export const setOtherTransactions = (transactions) => {
  return {
    type: "SET_OTHER_TRANSACTIONS_REPORT",
    payload: transactions,
  };
};

export const setSubscriptions = (subscriptions) => {
  return {
    type: "SET_SUBSCRIPTIONS_REPORT",
    payload: subscriptions,
  };
};

export const setView = (view) => {
  return {
    type: "SET_VIEW_REPORT",
    payload: view,
  };
};

export const setTotalTransactionPages = (pages) => {
  return {
    type: "SET_TOTAL_TRANSACTION_PAGES_REPORT",
    payload: pages,
  };
};

export const setTotalTransactions = (count) => {
  return {
    type: "SET_TOTAL_TRANSACTIONS_REPORT",
    payload: count,
  };
};

export const setTransactionPage = (page) => {
  return {
    type: "SET_TRANSACTION_PAGE_REPORT",
    payload: page,
  };
};

export const setTransactionPerPage = (perPage) => {
  return {
    type: "SET_TRANSACTION_PER_PAGE_REPORT",
    payload: perPage,
  };
};

export const setRefundResult = (data) => {
  return {
    type: "SET_REFUND_RESULT_REPORT",
    payload: data,
  };
};

export const getTransactions = (
  getAccessTokenSilently,
  { perPage = "", page = "", starting_after = "" }
) => {
  return async (dispatch, getState) => {
    const { customerProfileId, profileAuth0 } = getState().root;
    const { sub: auth0_uid = "" } = profileAuth0 || {};
    const {
      transactions: currentTransactions,
      totalTransactions,
      transactionPerPage: currentPerPage,
      transactionPage: currentPage,
      totalTransactionPages,
    } = getState().report;
    const { loading } = getState().payment;
    if (loading) return;
    if (page && !perPage) {
      const nextPageTransactions = currentTransactions.slice(
        (page - 1) * currentPerPage,
        page * currentPerPage
      );
      if (nextPageTransactions.filter((v) => v).length) {
        // Data have been fetched
        dispatch(setTransactionPage(page));
        return;
      }
    }
    dispatch(setLoading(true));
    try {
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const {
        data: { transactions, hasMoreTransactions },
      } = await axiosServer.get(getTransactionsUrl, {
        params: { page, perPage, auth0_uid, customerProfileId, starting_after },
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      });
      let nextTransactions, nextPage;
      if (perPage) {
        nextTransactions = transactions;
        dispatch(
          setTotalTransactionPages(Math.ceil(totalTransactions / perPage))
        );
        dispatch(setTransactionPerPage(perPage));
        nextPage = 1
      } else if (page) {
        nextTransactions = [...currentTransactions]; // avoid mutation error
        transactions.forEach((transaction, index) => {
          nextTransactions[(page - 1) * currentPerPage + index] = transaction;
        });
        nextPage = page
      }
      dispatch(setTransactions(nextTransactions));
      if (hasMoreTransactions) {
        // For stripe
        dispatch(
          setTotalTransactionPages(
            perPage
              ? 2
              : totalTransactionPages + (page === totalTransactionPages ? 1 : 0)
          )
        );
        dispatch(
          setTotalTransactions(
            perPage ? perPage * 2 : totalTransactions + currentPerPage
          )
        );
      }
      dispatch(setTransactionPage(nextPage));
    } catch (error) {
      console.error(error);
    }
    dispatch(setLoading(false));
  };
};

export const cancelDonation = (
  getAccessTokenSilently,
  { subscription, reason }
) => {
  return async (dispatch, getState) => {
    const {
      accountId,
      profileAuth0,
      subscription: rootSubscription,
      subscriptionYearly: rootSubscriptionYearly,
    } = getState().root;
    const {
      contact: { email, firstName, lastName, phone, address },
    } = getState().account;
    const customerAddress = {
      email,
      firstName,
      lastName,
      phone,
      ...address,
    };

    const { subscriptions } = getState().report;
    const { loading } = getState().payment;
    if (loading) return;
    // const paymentIds = subscription.authorize_net_payment_profile_id__c || `${subscription.stripe_customer_id__c}-${subscription.stripe_payment_source_id__c}`
    // const [customerProfileId, customerPaymentProfileId] = paymentIds.split('-')
    // const subscriptionId = subscription.authorize_net_subscription_id__c || subscription.stripe_subscription_id__c
    // const amount = subscription.npe03__amount__c
    const {
      id: subscriptionId,
      customerProfileId,
      customerPaymentProfileId,
      amount,
      installmentPeriod,
    } = subscription;
    const guestId = getCookie("guest_id");
    dispatch(setLoading(true));
    sendGtmPixel({
      event: "donate_submit",
      email: profileAuth0 ? profileAuth0.email : email,
      id: accountId || guestId || "N/A",
      amount,
      subscription: subscriptionId,
      type: "cancel",
    });
    try {
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      await axiosServer.post(
        cancelDonationUrl,
        {
          profileAuth0,
          accountId,
          customerProfileId,
          customerPaymentProfileId, // needed?
          subscriptionId,
          reason,
          customerAddress,
        },
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
        }
      );
      console.log("cancelled");
      sendGtmPixel({
        event: "donate_cancel",
        email: profileAuth0 ? profileAuth0.email : email,
        id: accountId || guestId || "N/A",
        amount,
        subscription: subscriptionId,
      });
      if (installmentPeriod === "Yearly") {
        const nextRootSubscriptionYearly =
          rootSubscriptionYearly &&
          (rootSubscriptionYearly.authorize_net_subscription_id__c ||
            rootSubscriptionYearly.stripe_subscription_id__c) === subscriptionId
            ? subscriptions
                .filter(
                  (s) =>
                    (s.authorize_net_subscription_id__c ||
                      s.stripe_subscription_id__c) !== subscriptionId
                )
                .find(
                  (s) =>
                    s.npe03__open_ended_status__c === "Open" &&
                    s.npe03__installment_period__c === "Yearly"
                )
            : rootSubscriptionYearly;
        dispatch(setRootSubscriptionYearly(nextRootSubscriptionYearly || null));
      } else if (installmentPeriod === "Monthly") {
        const nextRootSubscription =
          rootSubscription &&
          (rootSubscription.authorize_net_subscription_id__c ||
            rootSubscription.stripe_subscription_id__c) === subscriptionId
            ? subscriptions
                .filter(
                  (s) =>
                    (s.authorize_net_subscription_id__c ||
                      s.stripe_subscription_id__c) !== subscriptionId
                )
                .find(
                  (s) =>
                    s.npe03__open_ended_status__c === "Open" &&
                    s.npe03__installment_period__c === "Monthly"
                )
            : rootSubscription;
        dispatch(setRootSubscription(nextRootSubscription || null));
      }
      dispatch(
        setSubscriptions(
          subscriptions.map((s) =>
            (s.authorize_net_subscription_id__c ||
              s.stripe_subscription_id__c) === subscriptionId
              ? {
                  ...s,
                  npe03__open_ended_status__c: "Closed",
                }
              : s
          )
        )
      );
      dispatch(retrieveCustomer()).then(() => dispatch(setLoading(false)));
    } catch (error) {
      console.error(error);
      const errorMessage = _.get(error, ["response", "data", "error"], error);
      sendGtmPixel({
        event: "donate_fail",
        email: profileAuth0 ? profileAuth0.email : email,
        id: accountId || guestId || "N/A",
        message: errorMessage,
        amount,
        subscription: subscriptionId,
        type: "cancel",
      });
      dispatch(setLoading(false));
    }
  };
};

export const pauseDonation = (
  getAccessTokenSilently,
  { subscription, months }
) => {
  return async (dispatch, getState) => {
    const {
      accountId,
      profileAuth0,
      customerProfileId: defaultCustomerProfileId,
    } = getState().root;
    const { inspiration } = getState().contact;
    const {
      contact: { firstName, lastName, phone, email, address },
    } = getState().account;
    const customerAddress = {
      firstName,
      lastName,
      phone,
      email,
      ...address,
    };
    const { loading } = getState().payment;
    if (loading) return;
    // const {
    //   npe03__amount__c: amount,
    //   npe03__date_established__c: startDate,
    // } = subscription
    // const subscriptionId = subscription.authorize_net_subscription_id__c || subscription.stripe_subscription_id__c || null
    // const paymentIds = subscription.authorize_net_payment_profile_id__c || `${subscription.stripe_customer_id__c || defaultCustomerProfileId}-${subscription.stripe_payment_source_id__c}`
    // const subscriptionPaymentProfileId = paymentIds || null
    // const [customerProfileId, customerPaymentProfileId] = paymentIds.split('-')
    const {
      id: subscriptionId,
      customerProfileId,
      customerPaymentProfileId,
      subscriptionPaymentProfileId,
      amount,
      startDate,
      npe03__installment_period__c: installmentPeriod,
    } = subscription;
    const interval = installmentPeriod == "Yearly" ? "year" : "month";
    const guestId = getCookie("guest_id");
    dispatch(setLoading(true));
    sendGtmPixel({
      event: "donate_submit",
      email: profileAuth0 ? profileAuth0.email : email,
      id: accountId || guestId || "N/A",
      amount,
      subscription: subscriptionId,
      months,
      type: "pause",
    });
    try {
      if (!customerPaymentProfileId) {
        throw "Missing payment profile";
      }
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const {
        data: { nextPaymentDate },
      } = await axiosServer.post(
        pauseDonationUrl,
        {
          profileAuth0,
          accountId,
          customerProfileId,
          customerPaymentProfileId,
          subscriptionId,
          subscriptionPaymentProfileId,
          amount,
          startDate,
          months,
          interval,
          inspiration,
          customerAddress,
        },
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
        }
      );
      console.log("paused", nextPaymentDate);
      sendGtmPixel({
        event: "donate_pause",
        email: profileAuth0 ? profileAuth0.email : email,
        id: accountId || guestId || "N/A",
        amount,
        subscription: subscriptionId,
        months,
      });
      dispatch(retrieveCustomer()).then(() => {
        const currentSubscription =
          getState().root[
            interval === "year" ? "subscriptionYearly" : "subscription"
          ];
        const nextPaymentDateString =
          typeof nextPaymentDate === "string"
            ? nextPaymentDate
            : moment(new Date(nextPaymentDate)).format("YYYY-MM-DD");
        if (
          currentSubscription &&
          (currentSubscription.authorize_net_subscription_id__c ||
            currentSubscription.stripe_subscription_id__c) === subscriptionId
        ) {
          const subWithDate = {
            ...subscription,
            npe03__next_payment_date__c: nextPaymentDateString,
          };
          dispatch(
            interval === "year"
              ? setRootSubscription(subWithDate)
              : setRootSubscriptionYearly(subWithDate)
          );
        }
        const currentSubscriptions = getState().report.subscriptions;
        if (currentSubscriptions.length) {
          dispatch(
            setSubscriptions(
              currentSubscriptions.map((s) => {
                if (
                  s.stripe_subscription_id__c === subscriptionId ||
                  s.authorize_net_subscription_id__c === subscriptionId
                ) {
                  return {
                    ...s,
                    npe03__next_payment_date__c: nextPaymentDateString,
                  };
                } else {
                  return s;
                }
              })
            )
          );
        }
        dispatch(setLoading(false));
      });
    } catch (error) {
      console.error(error);
      const errorMessage = _.get(error, ["response", "data", "error"], error);
      sendGtmPixel({
        event: "donate_fail",
        email: profileAuth0 ? profileAuth0.email : email,
        id: accountId || guestId || "N/A",
        message: errorMessage,
        amount,
        subscription: subscriptionId,
        months,
        type: "pause",
      });
      dispatch(setLoading(false));
    }
  };
};

export const getReceipt = (
  getAccessTokenSilently,
  { id: transactionId, paymentGateway, receiptUrl },
  toReceiveUrl,
) => {
  return async (dispatch, getState) => {
    if (receiptUrl) {
      return window.open(receiptUrl, "_blank");
    }
    const { accountId, customerProfileId, profileAuth0 } = getState().root;
    dispatch(setLoading(true));
    try {
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const response = await axiosServer.get(
        `${getReceiptUrl}/${transactionId}`,
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
          params: { accountId, gateway: paymentGateway },
          ...(toReceiveUrl ? null : { responseType: "blob" }),
        }
      );
      if (toReceiveUrl) {
        window.open(response.data.receiptUrl, '_blank')
      }
      else {
        downloadAttachment(
          response.data,
          `Save-the-Storks-Receipt-${transactionId}.pdf`
        );
      }
    } catch (error) {
      console.error(error);
    }
    dispatch(setLoading(false));
  };
};

export const refundDonation = (getAccessTokenSilently, config) => {
  return async (dispatch, getState) => {
    const { profileAuth0 } = getState().root;
    const { loading } = getState().payment;
    if (loading) return;
    try {
      dispatch(setLoading(true));
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const headers = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      const { data: refundId } = await axiosServer.post(
        refundDonationUrl,
        config,
        { headers }
      );
      dispatch(setLoading(false));
      dispatch(
        setRefundResult({
          status: "success",
          refundId,
          paymentGateway: config.paymentGateway,
          amount: config.amount,
        })
      );
    } catch (error) {
      console.error(error);
      let errorMessage = _.get(
        error,
        ["response", "data", "error"],
        _.get(error, ["response", "data"], error)
      );
      if (!errorMessage.code && !errorMessage.message) {
        errorMessage = {
          code: _.get(errorMessage, ["raw", "type"], "").toUpperCase(),
          message: _.get(errorMessage, ["raw", "message"], ""),
        };
      }
      dispatch(setLoading(false));
      dispatch(
        setRefundResult({
          status: "failed",
          error: errorMessage,
        })
      );
    }
  };
};
