import axiosServer from 'modules/axiosServer'
import { 
  adminAttendeesUrl, 
  adminAttendeesCreatedUrl, 
  adminUsersUrl, 
  adminContactsOfAccountUrl, 
  adminMimicUrl, 
  adminUnmimicUrl,
  adminMergeAccountsUrl, 
  adminMergeContactsUrl 
} from 'modules/urls'
import { attendeeSchema, attendeeValidationSchemaDict, transformErrorIntoObject } from "modules/validation"
import { getEditedAttendees } from "modules/utils";
import _ from 'lodash'

export const setSearch = query => {
  return {
    type: 'SET_SEARCH_ADMIN',
    payload: query,
  }
}

export const setSearchBy = query => {
  return {
    type: 'SET_SEARCH_BY_ADMIN',
    payload: query,
  }
}

export const setSearchGateway = gateway => {
  return {
    type: 'SET_SEARCH_GATEWAY_ADMIN',
    payload: gateway,
  }
}

export const setDataStatus = status => {
  return {
    type: 'SET_DATA_STATUS_ADMIN',
    payload: status,
  }
}

export const setSearchStatus = status => {
  return {
    type: 'SET_SEARCH_STATUS_ADMIN',
    payload: status,
  }
}

export const setMimicParams = params => {
  return {
    type: 'SET_MIMIC_PARAMS_ADMIN',
    payload: params,
  }
}

export const selectDedupAccounts = accountIds => {
  return {
    type: 'SELECT_DEDUP_ACCOUNTS_ADMIN',
    payload: accountIds,
  }
}

export const selectAccountMergingFields = accountMergingFields => {
  return {
    type: 'SELECT_ACCOUNT_MERGING_FIELDS_ADMIN',
    payload: accountMergingFields,
  }
}

export const selectContactMergingSets = contactMergingSets => {
  return {
    type: 'SELECT_CONTACT_MERGING_SETS_ADMIN',
    payload: contactMergingSets,
  }
}

export const selectDedupContacts = contactIds => {
  return {
    type: 'SELECT_DEDUP_CONTACTS_ADMIN',
    payload: contactIds,
  }
}

export const setMergableContactFields = mergableContactFields => {
  return {
    type: 'SET_MERGEABLE_CONTACT_FIELD_ADMIN',
    payload: mergableContactFields,
  }
}

export const setRetainDuplicates = flag => {
  return {
    type: 'SET_RETAIN_DUPLICATES_ADMIN',
    payload: flag,
  }
}

export const setRequestError = requestError => {
  return {
    type: 'SET_REQUEST_ERROR_ADMIN',
    payload: requestError,
  }
}

export const mergeAccounts = (getAccessTokenSilently, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setDataStatus('loading'))
      dispatch(setRequestError(null))
      const { profileAuth0 } = getState().root
      const { accountMergingFields, searchResult, retainDuplicates } = getState().admin
      const { Salesforce: salesforceResults } = searchResult || {}
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      const { data: { contacts, mergableContactFields } } = await axiosServer.post(
        adminMergeAccountsUrl,
        { accountMergingFields },
        {
          params: retainDuplicates ? { retain_duplicates: 'true' } : {},
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        },
      )
      dispatch(setDataStatus(null))
      dispatch({
        type: 'SET_SEARCH_RESULT_ADMIN',
        payload: {
          ...searchResult,
          Salesforce: {
            ...salesforceResults,
            contacts,
            mergableContactFields,
          }
        },
      })
      const contactMergingSets = [{[contacts[0].id]: mergableContactFields}]
      dispatch(selectContactMergingSets(contactMergingSets))
      dispatch(selectDedupContacts([[contacts[0].id]]))
      if (callback) {
        callback()
      }
    }
    catch(err) {
      console.error('Error while merging accounts', err)
      let error = _.get(
        err,
        ["response", "data", "error"],
        _.get(err, ["response", "data"], err)
      )
      dispatch(setDataStatus(null))
      dispatch(setRequestError(error))
    }
  }
}

export const setAttendees = attendees => {
  return {
    type: 'SET_ATTENDEES_ADMIN',
    payload: attendees,
  }
}

export const setAttendeePagination = pagination => {
  return {
    type: 'SET_ATTENDEE_PAGINATION_ADMIN',
    payload: pagination,
  }
}

export const setPendingAttendeeChanges = pendingAttendeeChanges => {
  return {
    type: 'SET_PENDING_ATTENDEE_CHANGES_ADMIN',
    payload: pendingAttendeeChanges,
  }
}

export const editAttendee = data => {
  return {
    type: 'EDIT_ATTENDEE_ADMIN',
    payload: data,
  }
}

export const removeAttendee = data => {
  return {
    type: 'REMOVE_ATTENDEE_ADMIN',
    payload: data,
  }
}

export const validateAttendees = (addAttendees) => {
  return async (dispatch, getState) => {
    let idWithError, pageWithError
    try {
      const { 
        admin: { 
          pendingAttendeeChanges: pendingChanges, 
          newAttendees,
          attendees,
          attendeePagination: { limit },
          attendeeAdditionalConfigs: { validationSchema }
        } 
      } = getState();
      const editedAttendees = addAttendees ? newAttendees : getEditedAttendees(pendingChanges, attendees)
      if (!editedAttendees.length) {
        dispatch({
          type: 'SET_ATTENDEES_VALID_ADMIN',
          payload: false,
        })
        return dispatch({
          type: 'SET_ATTENDEE_ERRORS_ADMIN',
          payload: [{ message: ['No changes detected'] }],
        })
      }
      const attendeeIds = pendingChanges.map(a => a.id)
      for (var index in editedAttendees) {
        idWithError = editedAttendees[index].id
        pageWithError = Math.floor(attendeeIds.indexOf(idWithError) / limit) + 1
        const {id, contact, ...attendee} = editedAttendees[index]
        await attendeeValidationSchemaDict[validationSchema].validate(attendee)
      }
      dispatch({
        type: 'SET_ATTENDEES_VALID_ADMIN',
        payload: true,
      })
    } catch (e) {
      console.error(e)
      dispatch({
        type: 'SET_ATTENDEES_VALID_ADMIN',
        payload: false,
      })
      dispatch({
        type: 'SET_ATTENDEE_ID_WITH_ERROR_ADMIN',
        payload: idWithError,
      })
      console.log(pageWithError)
      dispatch(setAttendeePagination({ page: pageWithError }))
      dispatch({
        type: 'SET_ATTENDEE_ERRORS_ADMIN',
        payload: transformErrorIntoObject(e),
      })
    }
  }
}

export const mergeContacts = (getAccessTokenSilently, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setDataStatus('loading'))
      dispatch(setRequestError(null))
      const { profileAuth0 } = getState().root
      const { contactMergingSets, searchResult, retainDuplicates } = getState().admin
      const { Salesforce: salesforceResults } = searchResult || {}
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      await axiosServer.post(
        adminMergeContactsUrl,
        { contactMergingSets },
        {
          params: retainDuplicates ? { retain_duplicates: 'true' } : {},
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        },
      )
      dispatch(setDataStatus(null))
      dispatch({
        type: 'SET_SEARCH_RESULT_ADMIN',
        payload: {
          ...searchResult,
          Salesforce: {
            ...salesforceResults,
            contacts: [],
            accounts: [],
          }
        },
      })
      if (callback) {
        callback()
      }
    }
    catch(err) {
      console.error('Error while merging contacts', err)
      let error = _.get(
        err,
        ["response", "data", "error"],
        _.get(err, ["response", "data"], err)
      )
      dispatch(setDataStatus(null))
      dispatch(setRequestError(error))
    }
  }
}

export const getAttendeesCreated = (getAccessTokenSilently, { cid, accountId }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchStatus('loading'))
      const { profileAuth0 } = getState().root
      const { admin: { attendeeAdditionalConfigs: additionalConfigs } } = getState();
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      const { data: { created: attendeeCreated } } = await axiosServer.get(
        adminAttendeesCreatedUrl,
        {
          params: {
            cid, 
            account_id: accountId,
          },
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        }
      )
      const nextAdditionalConfigs = _.cloneDeep(additionalConfigs)
      _.set(nextAdditionalConfigs, ['ticketCount', accountId, 'created'], attendeeCreated)
      dispatch({
        type: 'SET_ATTENDEE_ADDITIONAL_CONFIGS_ADMIN',
        payload: nextAdditionalConfigs,
      })
      dispatch(setSearchStatus(null))
    }
    catch(error) {
      console.error('Error while searching attendees', error)
      dispatch(setSearchStatus(null))
    }
  }
}

export const searchAttendees = (getAccessTokenSilently, { cid, customCid = false, page, forExport = false, newSearch = false }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchStatus('loading'))
      const { profileAuth0 } = getState().root
      const { admin: { pendingAttendeeChanges: pendingChanges, attendees, attendeePagination: { limit, count: currentCount } } } = getState();
      const targetIndex = { min: (page - 1) * limit, max: currentCount ? Math.min(page * limit, currentCount) : page * limit}
      const currentFetched = pendingChanges.slice(targetIndex.min, targetIndex.max)
      if (
        (currentFetched.length == targetIndex.max - targetIndex.min) &&
        !currentFetched.find(v => !v) && 
        !newSearch
      ) {
        // result has been fetched
        dispatch(setSearchStatus(null))
        return dispatch(setAttendeePagination({ page }))
      }
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      const { 
        data: { attendees: newAttendees, count, customCampaign, ...additionalConfigs } 
      } = await axiosServer.get(
        adminAttendeesUrl,
        {
          params: {
            cid, 
            page, 
            limit: limit || getState().admin.attendeeLimit, 
            custom_cid: customCid ? 'true' : '',
            for_export: forExport ? 'true' : '',
          },
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        }
      )
      let nextAttendees = Array.from(newSearch ? [] : attendees)
      let nextPendingChanges = Array.from(newSearch ? [] : pendingChanges)
      if (forExport) {
        nextAttendees = newAttendees
        nextPendingChanges = newAttendees
      }
      else {
        for (var i = 0; i < newAttendees.length; i++) {
          nextAttendees[(page - 1) * limit + i] = newAttendees[i]
          nextPendingChanges[(page - 1) * limit + i] = newAttendees[i]
        }
      }
      if (customCampaign) {
        dispatch({
          type: 'SET_ATTENDEE_CUSTOM_CAMPAIGN_ADMIN',
          payload: customCampaign,
        })
      }
      if (additionalConfigs) {
        dispatch({
          type: 'SET_ATTENDEE_ADDITIONAL_CONFIGS_ADMIN',
          payload: additionalConfigs,
        })
      }
      dispatch(setAttendees(nextAttendees))
      dispatch(setPendingAttendeeChanges(nextPendingChanges))
      dispatch(setAttendeePagination({ page, limit, count }))
      dispatch(setSearchStatus(null))
    }
    catch(error) {
      console.error('Error while searching attendees', error)
      dispatch(setSearchStatus(null))
    }
  }
}

export const updateAttendees = (getAccessTokenSilently, { addAttendees = false, accountId = '', cid = '' }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setSearchStatus('loading'))
      const { profileAuth0 } = getState().root
      const { admin: { pendingAttendeeChanges: pendingChanges, newAttendees, attendees } } = getState()
      const editedAttendees = addAttendees ? newAttendees : getEditedAttendees(pendingChanges, attendees)
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      await axiosServer.post(
        adminAttendeesUrl,
        { attendees: editedAttendees },
        {
          params: addAttendees ? { insert: true, account_id: accountId, cid } : {},
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        }
      )
      dispatch(setAttendees(pendingAttendeeChanges))
      dispatch(setSearchStatus(null))
      dispatch({
        type: 'SET_ATTENDEES_VALID_ADMIN',
        payload: true,
      })
      dispatch({
        type: 'SET_ATTENDEE_ID_WITH_ERROR_ADMIN',
        payload: null,
      })
      dispatch({
        type: 'SET_ATTENDEE_DATA_MESSAGE_ADMIN',
        payload: 'Attendees are updated successfully',
      })
      setTimeout(() => {
        dispatch({
          type: 'SET_ATTENDEE_DATA_MESSAGE_ADMIN',
          payload: null,
        })
      }, 3000)
      dispatch({
        type: 'SET_ATTENDEE_ERRORS_ADMIN',
        payload: [],
      })
    }
    catch(error) {
      const responseError = error?.response?.data || ''
      dispatch({
        type: 'SET_ATTENDEES_VALID_ADMIN',
        payload: false,
      })
      dispatch({
        type: 'SET_ATTENDEE_ERRORS_ADMIN',
        payload: [{ message: [responseError] }],
      })
      console.error('Error while updating attendees', error)
      dispatch(setSearchStatus(null))
    }
  }
}

export const searchAccounts = (getAccessTokenSilently, query) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setDataStatus('loading'))
      dispatch(setRequestError(null))
      const { profileAuth0 } = getState().root
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      dispatch(setMimicParams({
        auth0Uid: '',
        sfdcAccountId: '',
        sfdcContactId: '',
      }))
      const { data: result } = await axiosServer.get(
        adminUsersUrl,
        {
          params: query,
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        }
      )
      dispatch({
        type: 'SET_SEARCH_RESULT_ADMIN',
        payload: result,
      })
      dispatch(setDataStatus(null))
    }
    catch(err) {
      console.error('Error while searching accounts', err)
      let error = _.get(
        err,
        ["response", "data", "error"],
        _.get(err, ["response", "data"], err)
      )
      dispatch(setDataStatus(null))
      dispatch(setRequestError(error))
    }
  }
}

export const searchSfdcContactOfAccount = (getAccessTokenSilently, sfdcAccountId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setDataStatus('loadingContacts'))
      dispatch(setRequestError(null))
      const { profileAuth0 } = getState().root
      const { searchResult: { Salesforce: salesforceResults } = {} } = getState().admin
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      const { data: contacts } = await axiosServer.get(
        `${adminContactsOfAccountUrl}/${sfdcAccountId}`,
        { headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {} }
      )
      dispatch({
        type: 'SET_SEARCH_RESULT_ADMIN',
        payload: {
          Salesforce: {
            ...salesforceResults,
            contacts,
          }
        },
      })
      dispatch(setDataStatus(null))
    }
    catch(err) {
      console.error('Error while searching accounts', err)
      let error = _.get(
        err,
        ["response", "data", "error"],
        _.get(err, ["response", "data"], err)
      )
      dispatch(setDataStatus(null))
      dispatch(setRequestError(error))
    }
  }
}

export const mimicAccount = (getAccessTokenSilently, {
  auth0Uid, 
  sfdcAccountId, 
  sfdcContactId,
} = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setDataStatus('loading'))
      const { profileAuth0 } = getState().root
      const { searchGateway, mimicParams } = getState().admin
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      const params = {
        auth0_uid: auth0Uid || mimicParams.auth0Uid, 
        account_id: sfdcAccountId || mimicParams.sfdcAccountId, 
        contact_id: sfdcContactId || mimicParams.sfdcContactId, 
        mimic_gateway: searchGateway,
      }
      await axiosServer.get(
        adminMimicUrl,
        {
          params,
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        }
      )
      location.href = '/'
    }
    catch(error) {
      console.error('Error while mimicking accounts', error)
      dispatch(setDataStatus(null))
    }
  }
}

export const unmimicAccount = getAccessTokenSilently => {
  return async (dispatch, getState) => {
    try {
      dispatch(setDataStatus('loading'))
      const { profileAuth0 } = getState().root
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null
      await axiosServer.get(
        adminUnmimicUrl,
        {
          headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
        }
      )
      location.href = '/'
    }
    catch(error) {
      console.error('Error while unmimicking accounts', error)
      dispatch(setDataStatus(null))
    }
  }
}