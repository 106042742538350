import axiosServer from "./axiosServer";

const eventEndpoint = `_e`

/**
 * Event Keys
 */
export enum Events {
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  PAGE_VIEW = 'page_view',
  DONATE_SUCCESS = 'donate_success',
  DONATE_ERROR = 'donate_error',
}

/**
 * Request Query to Event Key Mapping
 */
export const eventQueryKeys: Array<{key:string, query:string}> = [
  {
    key: 'm',
    query: 'meta'
  },
  {
    key: 'l',
    query: 'level'
  },
  {
    key: 'e',
    query: 'event'
  },
  {
    key: 'i',
    query: 'info'
  }
]

export type EmitEventRequest = {
  level: 'info' | 'debug' | 'http' | 'verbose' | 'error'
  info?: any;
  meta?: any;
}
export const emitEvent = async (event: Events, data: EmitEventRequest = { level: 'info' }) : Promise<void> => {
  const { level, ...setData } = data

  const search = new URLSearchParams()
  search.set('e', event)
  search.set('l', level)
  Object.keys(setData).forEach(key => {
    search.set(eventQueryKeys.find(eventKey => eventKey.query === key)?.key || 'i', typeof setData[key] === 'string' ? setData[key] : JSON.stringify(setData[key]))
  })

  await axiosServer.get(
    `${eventEndpoint}?${search.toString()}`
  );
}

emitEvent.events = Events