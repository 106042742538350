// @ts-nocheck

import _ from "lodash";
import qs from "query-string";

import { getCookie } from "./cookie";
import { campaignIgnoreSites } from "./siteContents";
import { anetToken, recaptchaToken } from "./token";
import { attendeeSchema, charityRunSchema } from "./validation";
import store from "src/store";
import { MIN_DONATION, MIN_DONATION_PER_ATTENDEE } from "modules/constants";

export const reformatApiAddress = (address, paymentGateway) => {
  if (paymentGateway === "Authorize.NET") {
    return {
      firstName: (address.firstName || "").trim(),
      lastName: (address.lastName || "").trim(),
      streetAddress: (address.address || "").trim(),
      company: (address.company || "").trim(),
      city: (address.city || "").trim(),
      state: (address.state || "").trim(),
      zip: (address.zip || "").trim(),
      country: (address.country || "").trim(),
      phone: (address.phoneNumber || "").trim(),
    };
  } else {
    let { firstName, lastName } = address;
    if (!firstName || !lastName) {
      lastName = ((address.name || "").split(" ").slice(-1)[0] || "").trim();
      firstName = (address.name || "").replace(lastName, "").trim().trim();
    }
    return {
      firstName: (firstName || "").trim(),
      lastName: (lastName || "").trim(),
      streetAddress: (address.line1 || "").trim(),
      company: (address.company || "").trim(),
      city: (address.city || "").trim(),
      state: (address.state || "").trim(),
      zip: (address.postal_code || "").trim(),
      country: (address.country || "").trim(),
      phone: (address.phone || "").trim(),
    };
  }
};

export const getUtmsFromUrl = () => {
  const parsed = qs.parse(location.search);
  return ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"]
    .filter((utm) => parsed[utm])
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: parsed[cur],
      }),
      {}
    );
};

export const utmSbjsMapping = {
  utm_source: "src",
  utm_medium: "mdm",
  utm_campaign: "cmp",
  utm_content: "cnt",
  utm_term: "trm",
};
export const getUtmsFromCookie = () => {
  return [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
  ].reduce((acc, cur) => {
    const val = getCookie(cur);
    return val
      ? {
          ...acc,
          [utmSbjsMapping[cur]]: val,
        }
      : acc;
  }, {});
};

export const dates = Array.apply(0, new Array(31)).map((v, i) => i + 1);
export const dateTitles = dates.map((d) => {
  switch (d % 10) {
    case 1:
      if (d !== 11) return `${d}st`;
      else break;
    case 2:
      if (d !== 12) return `${d}nd`;
      else break;
    case 3:
      if (d !== 13) return `${d}rd`;
  }
  return `${d}th`;
});

export const monthTitles = [
  "January",
  "Feburary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const daysOfMonths = monthTitles.map((m, i) =>
  [3, 5, 8, 10].includes(i) ? 30 : i == 1 ? 28 : 31
);

export const getPaymentSummary = (store) => {
  const { options, payment, contact } = store;
  const { donationType, donationAmount, paymentDate } = options;
  let {
    type,
    bank,
    card,
    billingAddress,
    billingAddressSameAsContact,
    paymentProfiles,
    selectedPaymentProfileId,
    gateway: paymentGateway,
  } = payment;
  const { address: contactAddress } = contact;
  const {
    firstName,
    lastName,
    streetAddress,
    city,
    state,
    zip,
    country,
    phone,
    company,
  } = billingAddressSameAsContact ? contactAddress : billingAddress;
  const selectedPaymentProfile = paymentProfiles.find(
    (p) => p.customerPaymentProfileId === selectedPaymentProfileId
  );
  if (selectedPaymentProfile) {
    type = selectedPaymentProfile.payment.creditCard ? "card" : "bank";
  }
  let cardDetails;
  if (paymentGateway === "Authorize.NET") {
    cardDetails =
      type === "card"
        ? {
            cardNumber: _.get(
              selectedPaymentProfile,
              ["payment", "creditCard", "cardNumber"],
              `XXXX ${card.cardNumber.slice(card.cardNumber.length - 4)}`
            ),
            expirationDate: _.get(
              selectedPaymentProfile,
              ["payment", "creditCard", "expirationDate"],
              `${card.month}/${card.year}`
            ),
            cardType: _.get(
              selectedPaymentProfile,
              ["payment", "creditCard", "cardType"],
              null
            ),
          }
        : {
            accountNumber: _.get(
              selectedPaymentProfile,
              ["payment", "bankAccount", "accountNumber"],
              `XXXX ${bank.accountNumber.slice(bank.accountNumber.length - 4)}`
            ),
            accountType: _.get(
              selectedPaymentProfile,
              ["payment", "bankAccount", "accountType"],
              bank.accountType
            ),
          };
  } else {
    type === "card"
      ? {
          cardNumber: `XXXX ${selectedPaymentProfile.last4}`,
          expirationDate: `${("0" + selectedPaymentProfile.exp_month).slice(
            -2
          )}/${selectedPaymentProfile.exp_year}`,
          cardType: selectedPaymentProfile.brand,
        }
      : {
          accountNumber: `XXXX ${selectedPaymentProfile.last4}`,
          accountType: selectedPaymentProfile.account_holder_type,
        };
  }
  return {
    donationType,
    donationAmount: donationAmount
      ? `$${Number(donationAmount).toFixed(2)}`
      : "none",
    paymentDate:
      donationType === "monthly"
        ? `${dateTitles[paymentDate - 1]} of every month`
        : donationType === "yearly"
        ? `${dateTitles[paymentDate - 1]} of every year`
        : null,
    type,
    ...(type === "card"
      ? {
          cardNumber: _.get(
            selectedPaymentProfile,
            ["payment", "creditCard", "cardNumber"],
            `XXXX ${card.cardNumber.slice(card.cardNumber.length - 4)}`
          ),
          expirationDate: _.get(
            selectedPaymentProfile,
            ["payment", "creditCard", "expirationDate"],
            `${card.month}/${card.year}`
          ),
          cardType: _.get(
            selectedPaymentProfile,
            ["payment", "creditCard", "cardType"],
            null
          ),
        }
      : {
          accountNumber: _.get(
            selectedPaymentProfile,
            ["payment", "bankAccount", "accountNumber"],
            `XXXX ${bank.accountNumber.slice(bank.accountNumber.length - 4)}`
          ),
          accountType: _.get(
            selectedPaymentProfile,
            ["payment", "bankAccount", "accountType"],
            bank.accountType
          ),
        }),
    billingAddress: `${firstName} ${lastName}, ${
      company ? company + ", " : ""
    }${streetAddress}, ${city}, ${state} ${zip}, ${country} (Tel: ${phone})`,
  };
};

export const checkValidForPayment = (store, site) => {
  const {
    confirmedMonthly,
    type,
    card,
    bank,
    bankStripe,
    useSaved,
    billingAddress: { 
      company: billingCompany, 
      address: address_1, 
      __valid: __valid_1,
      __pristine: __pristine_1,
      __errors: __errors_1,
      ...billingAddress 
    },
    billingAddressSameAsContact,
    gateway: paymentGateway,
    paymentProfileEditMode,
    selectedPaymentProfileId,
  } = store.payment;
  const { validationError, company, ...contactState } = {
    ...store.account.contact,
    ...store.account.contact.address,
    ...(paymentProfileEditMode
      ? null
      : {
          ...(campaignIgnoreSites.includes(site)
            ? null
            : { inspiration: store.contact.inspiration }),
          email: store.account.contact.email,
        }),
  };
  const {
    address,
    type: contactType,
    __valid,
    __pristine,
    __errors,
    ...contact
  } = contactState;
  // console.log("using contact", contact);
  const billingAddressToUse = billingAddressSameAsContact
    ? contact
    : billingAddress;
  const { donationType, monthlyOtherAmount, donationAmount } = store.options;
  const { campaignParams } = store.eventRegistration;
  const charityRun = store.charityRun;
  let donationAmountValid = true, minDonationAmount = 5;
  if (!paymentProfileEditMode) {
    if (!isNaN(campaignParams?.minDonationAmount)) {
      minDonationAmount = campaignParams?.minDonationAmount;
      donationAmountValid = donationAmount >= minDonationAmount;
    }
    else if (charityRun.ready) {
      minDonationAmount = charityRun.attendees.length * MIN_DONATION_PER_ATTENDEE;
      donationAmountValid = donationAmount >= minDonationAmount;
    }
    else if (
      !donationAmount ||
      (donationAmount < MIN_DONATION &&
        !contact.email.includes(`+${site.toLowerCase()}test`))
    ) {
      donationAmountValid = false;
    }
  }
  if (!donationAmountValid) {
    return {
      validForPayment: false,
      validationErrorMessage:
        `Please check and re-enter the donation amount (minimum $${minDonationAmount})`,
    };
  }

  const contactCompleted =
    paymentProfileEditMode && !billingAddressSameAsContact
      ? true
      : Object.values(contact).reduce((acc, cur) => acc && !!cur, true);
  let emailValid =
    paymentProfileEditMode ||
    !!contact.email.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/);
  if (paymentGateway === "Stripe") {
    delete billingAddressToUse.phone; // Stripe billing address doesn't require phone
    emailValid = billingAddressSameAsContact ? emailValid : true;
  }
  let invalidBillingAddressField = ''
  const billingAddressCompleted = Object.keys(billingAddressToUse).reduce(
    (acc, cur) => {
      if (!invalidBillingAddressField && !billingAddressToUse[cur]) {
        invalidBillingAddressField = cur
      }
      return acc && !!billingAddressToUse[cur]
    },
    true
  );
  let paymentCompleted;
  if (!paymentProfileEditMode) {
    if (paymentGateway === "Authorize.NET" || type === "bank_ach") {
      paymentCompleted = !!Object.values(
        ["bank", "bank_ach"].includes(type) ? bank : card
      ).reduce((acc, cur) => acc && cur, true);
    } else if (type === "bank_ach_stripe") {
      // paymentCompleted = !!Object.values(bankStripe).reduce((acc, cur) => acc && cur, true)
      paymentCompleted = true; // use contact name and email instead
    } else {
      paymentCompleted = ["applePay", "googlePay", "bank"].includes(type)
        ? true
        : !!card.fullName;
    }
  } else {
    paymentCompleted = true;
  }

  const invalidFields = {
    email: !emailValid,
    contact: !contactCompleted,
    billingAddress: !billingAddressCompleted,
    payment: !paymentCompleted,
  };
  let validForPayment: any = false;
  if (useSaved) validForPayment = contactCompleted;
  else
    validForPayment =
      (paymentProfileEditMode || type) &&
      (paymentCompleted ||
        (paymentProfileEditMode && selectedPaymentProfileId)) &&
      contactCompleted &&
      billingAddressCompleted;
  validForPayment =
    validForPayment &&
    (donationType !== "monthly" || !monthlyOtherAmount || confirmedMonthly) &&
    (!!store.contact.inspiration ||
      campaignIgnoreSites.includes(site) ||
      paymentProfileEditMode) &&
    emailValid;

  const validity = Object.keys(invalidFields).reduce((prev, curr) => prev && !curr)
  if (!validity) {
    console.debug("[modules][utils] checkValidForPayment:validity", invalidFields);
  }

  let validationErrorMessage =
    Object.keys(invalidFields).reduce(
      (acc, cur) =>
        (invalidFields[cur] && validationErrorMessageDict[cur]) || acc,
      null
    ) ||
    "Please complete the missing required fields above and then try again.";

  if (store.charityRun.ready && validForPayment) {
    // validate that payment is of minimum
    const minPerAttendee = 35;
    const minRequired = minPerAttendee * store.charityRun.attendees.length;

    validForPayment = store.options.donationAmount >= minRequired;
    if (!validForPayment) {
      validationErrorMessage = `The minimum required donation amount is $${minRequired}`;
    } else {
      validForPayment = !store.charityRun.__errors.length;
      if (!validForPayment) {
        validationErrorMessage = store.charityRun.__errors[0].message[0];
      }
    }
  }

  return { validForPayment, validationErrorMessage, invalidBillingAddressField };
};

const validationErrorMessageDict = {
  // email: "Please enter a valid email.", // commented for now as would be misleading for layout 769
};

export const downloadAttachment = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const calculateProcessingFee = (
  amount,
  selectedPaymentProfile,
  paymentType
) => {
  let feeAmount = _.round(amount * 0.022 + 0.3, 2);
  if (selectedPaymentProfile) {
    if (
      selectedPaymentProfile.object === "card" ||
      _.get(selectedPaymentProfile, ["payment", "creditCard"])
    ) {
      if (
        selectedPaymentProfile.brand === "American Express" ||
        false // TODO: fix-> _.get(selectedPaymentProfile, ['payment', 'creditCard', 'cardType'] == 'AmericanExpress')
      ) {
        feeAmount = _.round(amount * 0.035, 2);
      }
    }
    if (
      selectedPaymentProfile.object === "bank_account" ||
      _.get(selectedPaymentProfile, ["payment", "bankAccount"])
    ) {
      feeAmount = Math.min(5, _.round(amount * 0.008, 2));
    }
  } else if (paymentType === "bank") {
    feeAmount = Math.min(5, _.round(amount * 0.008, 2));
  }
  return feeAmount;
};

export const constructRedirectUri = (uriParams, path) => {
  const redirectUri = window.location.origin + path;
  const params = qs.stringify(uriParams);
  return redirectUri + (params ? `?${params}` : "");
};

export const parseSubscription = (
  subscription,
  crm,
  defaultCustomerProfileId
) => {
  if (!subscription) return {};
  if (crm === "salesforce") {
    const paymentIds =
      subscription.authorize_net_payment_profile_id__c ||
      `${subscription.stripe_customer_id__c || defaultCustomerProfileId}-${
        subscription.stripe_payment_source_id__c
      }`;
    const subscriptionPaymentProfileId = paymentIds || null;
    const [customerProfileId, customerPaymentProfileId] = paymentIds.split("-");
    return {
      id:
        subscription.stripe_subscription_id__c ||
        subscription.authorize_net_subscription_id__c ||
        subscription.id,
      customerProfileId,
      customerPaymentProfileId,
      subscriptionPaymentProfileId,
      amount: subscription.npe03__amount__c,
      startDate: subscription.npe03__date_established__c,
      nextPaymentDate: subscription.npe03__next_payment_date__c
        ? new Date(subscription.npe03__next_payment_date__c).toLocaleDateString(
            "en-US",
            { timeZone: "utc" }
          )
        : "N/A",
      status: subscription.npe03__open_ended_status__c,
      installmentPeriod: subscription.npe03__installment_period__c,
      actions:
        subscription.npe03__open_ended_status__c === "Open" &&
        ((subscription.authorize_net_subscription_id__c &&
          subscription.authorize_net_payment_profile_id__c) ||
          (subscription.stripe_subscription_id__c &&
            subscription.stripe_payment_source_id__c))
          ? ["manage"]
          : [],
    };
  } else {
    // Default to stripe
    return {
      id: subscription.id,
      customerProfileId: subscription.customer,
      customerPaymentProfileId: subscription.default_source,
      subscriptionPaymentProfileId: `${subscription.customer}-${subscription.default_source}`,
      amount: _.get(subscription, ["plan", "amount"], 0) / 100,
      startDate: new Date(subscription.start_date * 1000).toLocaleDateString(
        "en-US"
      ),
      nextPaymentDate: new Date(
        subscription.current_period_end * 1000
      ).toLocaleDateString("en-US"),
      status:
        subscription.status[0].toUpperCase() + subscription.status.slice(1),
      actions: ["active", "trialing"].includes(subscription.status)
        ? ["manage"]
        : [],
    };
  }
};

const statusTextMapping = {
  "Closed Won": "Paid",
  PreAuthorization: "Pending",
};

export const parsePayment = (payment, customer, crm) => {
  if (!payment) return {};
  if (crm === "salesforce") {
    return {
      id:
        payment.id ||
        payment.authorize_net_transid__c ||
        payment.stripe_charge_id__c,
      gatewayPaymentId:
        payment.authorize_net_transid__c || payment.stripe_charge_id__c,
      paymentGateway: payment.authorize_net_transid__c
        ? "Authorize.NET"
        : "Stripe",
      amount: payment.amount,
      payerName: payment.contact_name__c,
      paymentMethod: payment.gd_payment_method__c,
      paymentLast4: payment.gd_donation_last_4__c || "N/A",
      status:
        payment.amount < 0
          ? "Refunded"
          : statusTextMapping[payment.stagename] || payment.stagename,
      refunded: payment.stagename === "Refunded",
      refunds: (payment.refunds || []).map((r) => ({
        amount: r.amount,
        uid: r.authorize_net_transid__c || r.stripe_charge_id__c || r.id,
        refundDate: r.createddate,
      })),
      paymentDate: new Date(
        payment.gd_terminal_name__c === "SalesForce Manual Entry"
          ? payment.closedate
          : payment.createddate
      ).toLocaleDateString(),
      actions:
        ["Closed Won", "Refunded"].includes(payment.stagename) &&
        payment.transaction_type__c !== "Refund"
          ? ["getReceipt"]
          : [],
      adminActions:
        !payment.fully_refunded &&
        (payment.authorize_net_transid__c || payment.stripe_charge_id__c) &&
        payment.transaction_type__c !== "Refund"
          ? ["refund"]
          : [],
    };
  } else {
    // Default to stripe
    const paymentMethodObject =
      _.get(payment, ["source", "object"]) ||
      _.get(payment, ["payment_method_details", "type"]) ||
      "card";
    const isCard = paymentMethodObject === "card";
    const refunds = _.get(payment, ["refunds", "data"], []);
    const output = {
      id: payment.id,
      gatewayPaymentId: payment.id,
      paymentGateway: "Stripe",
      amount: _.round(_.get(payment, "amount", 0) / 100, 2),
      payerName:
        _.get(payment, ["source", "name"]) ||
        _.get(payment, ["billing_details", "name"]) ||
        _.get(customer, "name") ||
        "N/A",
      paymentMethod: isCard
        ? "Credit Card " +
          (_.get(payment, ["source", "type"]) ||
            _.get(payment, ["payment_method_details", "card", "brand"]) ||
            "")
        : "Bank Account",
      paymentLast4:
        _.get(payment, ["source", "last4"]) ||
        _.get(payment, [
          "payment_method_details",
          _.get(payment, ["payment_method_details", "type"]),
          "last4",
        ]) ||
        "N/A",
      status: payment.status ? payment.status[0].toUpperCase() + payment.status.slice(1) : 'N/A',
      refunded: refunds && refunds.length,
      refunds: refunds.map((r) => ({
        amount: _.round(_.get(r, "amount", 0) / 100, 2),
        uid: r.id,
        refundDate: new Date(r.created * 1000).toLocaleDateString("en-US"),
      })),
      paymentDate: new Date(payment.created * 1000).toLocaleDateString("en-US"),
      receiptUrl: payment.receipt_url,
      actions: ["getReceipt"],
      adminActions: payment.fully_refunded ? [] : ["refund"],
    };
    output.paymentMethod =
      output.paymentMethod[0].toUpperCase() + output.paymentMethod.slice(1);
    return output;
  }
};
/**
 * Create Resource Id
 *
 * @returns string based id
 */
export const createResourceId = (): string => {
  const resourceIdRaw = new Uint8Array(12);
  window.crypto.getRandomValues(resourceIdRaw);
  return Array.from(resourceIdRaw, (v) => v.toString(16).padStart(2, "0")).join(
    ""
  );
};
/**
 * Execute reCaptcha when ready
 *
 * @returns Response Token from Google ReCaptcha
 */
export const reCaptchaReady = async (siteKey: string) =>
  new Promise<string>((resolve, reject) => {
    const { mode = 'v3', v2Token } = store.getState().recaptcha
    if (mode === 'v3') {
      window.grecaptcha.ready(async () => {
        try {
          const recaptchaResponseToken = await window.grecaptcha.execute(
            siteKey,
            { action: "submit" }
          );
          resolve(recaptchaResponseToken);
        } catch (e) {
          reject(e);
        }
      });
    }
    else {
      // v2
      if (v2Token) {
        resolve(v2Token)
      }
      else {
        const recaptchaWrapper = document.getElementById('recaptcha-wrapper')
        if (recaptchaWrapper) {
          recaptchaWrapper.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        }
        reject({
          code: 'RECAPTCHA WARNING',
          message: 'Please finish the validation before continuing',
        })
      }
    }
  });

/**
 * Third Party Scripts that need to be loaded on DOM
 */
export enum LoadScript {
  ANET = "ANET",
  RECAPTCHA = "RECAPTCHA",
  PLAID = "PLAID",
}
export enum LoadScriptResult {
  DUPLICATE = "DUPLICATE",
  LOADED = "LOADED",
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
  UNKNOWN = "UNKNOWN",
}

export const loadScript = async (
  scriptType: LoadScript,
  params?: any
): Promise<LoadScriptResult | string> => {
  return new Promise((resolve, reject) => {
    // console.log("[util] load script", scriptType);
    const script = document.createElement("script");

    switch (scriptType) {
      case LoadScript.ANET:
        if (window.ajsLoaded) {
          return resolve(LoadScriptResult.DUPLICATE);
        }
        script.src = anetToken.script_url;
        window.ajsLoaded = true;
        break;

      case LoadScript.RECAPTCHA:
        if (window.grecaptcha) {
          return resolve(LoadScriptResult.DUPLICATE);
        }
        script.src = `${recaptchaToken.script_url}?render=${params}`;
        break;

      case LoadScript.PLAID:
        if (window.Plaid) {
          return resolve(LoadScriptResult.DUPLICATE);
        }
        script.src = `https://cdn.plaid.com/link/v2/stable/link-initialize.js`;
        break;

      default:
        reject(new Error(LoadScriptResult.ERROR));
    }

    script.onload = () => resolve(LoadScriptResult.LOADED);
    document.head.appendChild(script);
  });
};

export const checkCampaignOption = (
  campaignOptions,
  layoutConfig,
  donationAmount,
  donationType
) => {
  let result = null
  for (var config of [campaignOptions, layoutConfig?.giftOptions]) {
    if (!config) continue;
    if (
      config.minAmounts &&
      config.minAmounts[donationType] &&
      config.minAmounts[donationType] > donationAmount
    ) {
      continue;
    } else if (config.event) {
      continue;
    } else if (!config.options) {
      continue;
    } else {
      result = { title: config.title, option: config.options[0] };
    }
  }
  return result
};

export const checkRecaptchaFromError = (
  errorMessage,
  setModeAction,
  setTokenValidatedAction,
  dispatch,
  getState
) => {
  if (errorMessage?.code === 'RECAPTCHA WARNING') {
    if (getState().recaptcha.mode === 'v3') {
      dispatch(setModeAction('v2'))
    }
    else {
      setTokenValidatedAction(false)
    }
  }
}

export const getEditedAttendees = (pendingChanges, attendees) => {
  return pendingChanges.filter((attendee, index) => {
    return Object.keys(attendee).reduce((acc, cur) => {
      return acc || (attendee[cur] !== attendees[index][cur])
    }, false)
  })
}

export const saveAsCSV = (
  dataSets = [],
  filename,
  formatterSets = [],
  exportKeySets = [],
  exportTitleSets = [],
  dataSetNames = []
) => {
  let results = [], headers

  for (var dataIndex in dataSets) {
    let data = dataSets[dataIndex],
      formatters = formatterSets[dataIndex] || {},
      dataSetName = dataSetNames[dataIndex]
    let fields = (exportKeySets[dataIndex] || Object.keys(data[0])).filter((v) => v),
      fieldValue = ''
    let titles = exportTitleSets[dataIndex] || {}
    results[dataIndex] = ''
    if (!data.length) {
      continue
    }
    // Process data.
    data.forEach((el) => {
      fields.forEach((field, index) => {
        fieldValue = ((formatters[field] ? formatters[field](el[field], el.currency, el) : el[field]) || 'N/A')
          .toString()
          .replace(/"/g, '""')

        results[dataIndex] += '"' + fieldValue + '"'

        if (index < fields.length - 1) {
          results[dataIndex] += ','
        }
      })
      results[dataIndex] += '\n'
    })

    // Set headers.
    headers = fields.map((field) => titles[field] || field).join(',')

    // Ready data for reading.
    results[dataIndex] = (dataSetName ? `${dataSetName}\n` : '') + headers + '\n' + results[dataIndex]
  }

  let result = results.filter((v) => v).join('\n\n')
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'

  let blob = new Blob([result], { type: 'text/csv;charset=UTF-8' }),
    url = window.URL.createObjectURL(blob)

  a.href = url
  a.download = filename + '_' + (Date.now() ? Date.now().toString() : '') + '.csv'
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}
