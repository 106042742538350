import { each, groupBy, keys } from "lodash";
import {
  array,
  bool,
  mixed,
  number,
  object,
  string,
  ValidationError,
} from "yup";

import { campaignIgnoreSites } from "./siteContents";

// import type { ValidationError } from 'yup'

/** RegEx for phone number validation */
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

/**
 * Attendee Schema in Charity Run Store
 */
export const eventRegistrationSchema = object({
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string().email("Email is not valid").required("Email is required"),
  foodAllergies: string().required("Food Allergies are required"),
  phone: string().matches(phoneRegExp, "Phone number is not valid").nullable(),
  foodOnly: bool().required(),
});


/**
 * Attendee Schema in Charity Run Store
 */
export const attendeeSchema = object({
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string().email("Email is not valid").required("Email is required"),
  shirtSize: mixed()
    .oneOf(["s", "m", "l", "xl", "xxl", "xxxl"] as const)
    .required("Please choose a shirt size"),
  phone: string().matches(phoneRegExp, "Phone number is not valid").nullable(),
});

export const attendeeValidationSchemaDict = {
  eventRegistrationSchema,
  attendeeSchema,
}

/**
 * Charity Run Store validation
 */
export const charityRunSchema = object({
  ready: bool().required(),
  confirmed: bool().when("ready", {
    is: true,
    then: (schema) => schema.required(),
  }),
  attendees: array().when("ready", {
    is: true,
    then: (schema) => schema.of(attendeeSchema),
    otherwise: (schema) => schema.nullable().optional(),
  }),
});

/**
 * Contact Information / Address Schema in Contact Store
 */
export const addressSchema = object({
  company: string().nullable().optional(),
  streetAddress: string().required("Street Address is required"),
  city: string().required("City is required"),
  state: string().required("State is required"),
  country: string().required("Country is required"),
  zip: string().required("Zip is required"),
});

/**
 * Contact Store validation
 */
export const contactSchema = object({
  paymentProfileEditMode: bool().nullable(),
  editMode: bool().nullable(),
  site: string().nullable(),
  firstName: string().required("First Name is required"),
  lastName: string().required("Last Name is required"),
  email: string().email("Email is not valid").required("Email is required"),
  phone: string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  // .nullable()
  // .optional(),
  address: addressSchema,
  inspiration: string().when(["site", "editMode", "paymentProfileEditMode"], {
    // whether this field should be ignored or not depending on site
    is: (site, editMode, paymentProfileEditMode) => site && !editMode && !paymentProfileEditMode && !campaignIgnoreSites.includes(site),
    then: (schema) => schema.required("Please provide what inspired you"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
});

/**
 * Payment Terminal validation
 */
export const contactTerminalSchema = object({
  firstName: string().optional(),
  lastName: string().optional(),
  email: string().email("Email is not valid").required("Email is required")
});

/**
 * Option Store validation
 *
 * @param min - the minimum required donation
 * @returns YUP validation object
 */
export const optionSchema = (min = 5) => {
  return object({
    monthlyOtherAmount: bool(),
    donationType: string()
      .oneOf(["monthly", "one-time", "yearly"] as const)
      .required("Please choose a donation frequency"),
    donationAmount: number()
      .required(`Please enter a donation amount (minimum $${min})`)
      .positive()
      .min(min, `The minimum required donation amount is $${min}`),
  });
};

/**
 * Pay with Bank Account (ACH) Schema in Payment Store
 */
export const bankSchema = object({
  routingNumber: string().required("Routing Number is required"),
  accountNumber: string().required("Account Number is required"),
  nameOnAccount: string().required("Name on Account is required"),
  accountType: string().defined(),
  country: string().defined(),
  currency: string().defined(),
});

/**
 * Pay with Credit/Debit Card Schema in Payment Store
 */
export const cardSchema = object({
  fullName: string().required("Name on Card is required"),
});

/**
 * Payment Store validation
 */
export const paymentSchema = object({  
  // not required / not used
  gateway: string().defined(),

  // required
  paymentProfileEditMode: bool().defined(),
  useSaved: bool().defined(),

  // type is used to determine define requirements for:
  // - card
  // - bank
  type: string().when("paymentProfileEditMode", {
    is: true,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) =>
      schema
        .required("Payment type is required")
        .oneOf([
          "bank",
          "bank_ach",
          "bank_ach_stripe",
          "applePay",
          "googlePay",
          "card",
        ]),
  }),

  // when a payment profile is available and selected
  selectedPaymentProfileId: string().when("useSaved", {
    is: true,
    then: (schema) => schema.required("Payment Profile not selected"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),

  billingAddressSameAsContact: bool().defined(),
  billingAddress: object().when("billingAddressSameAsContact", {
    is: false,
    then: () =>
      object().when("selectedPaymentProfileId", {
        is: (selectedPaymentProfileId) => {
          return !selectedPaymentProfileId;
        },
        then: () => addressSchema,
      }),
  }),

  card: object().when("type", {
    is: "card",
    then: () =>
      object().when("selectedPaymentProfileId", {
        is: (selectedPaymentProfileId) => {
          return !selectedPaymentProfileId;
        },
        then: () => cardSchema,
      }),
  }),
  bank: object().when("type", {
    is: "bank_ach",
    then: () =>
      object().when("selectedPaymentProfileId", {
        is: (selectedPaymentProfileId) => {
          return !selectedPaymentProfileId;
        },
        then: () => bankSchema,
      }),
  }),
});

/**
 * Find Input Element with a matching field name from the end
 * 
 * @param fieldName - Field name to use for document query
 * @param multiple - whether to search for multiple fields or only one
 * @returns the matching element(s)
 */
const findElementEndingWith = (fieldName: string, multiple: boolean = false) => {
  let element

  // field name exceptions
  if (fieldName === 'inspiration') return document.querySelector('#inspiration-dropdown')

  // all else
  if (multiple) {
    element = document.querySelectorAll(`input[name$='${fieldName}']`);
    if (!element.length) element = document.querySelectorAll(`select[name$='${fieldName}']`);
    if (!element.length) element = document.querySelectorAll(`textarea[name$='${fieldName}']`);
  } else {
    element = document.querySelector(`input[name$='${fieldName}']`);
    if (!element) element = document.querySelector(`select[name$='${fieldName}']`);
    if (!element) element = document.querySelector(`textarea[name$='${fieldName}']`);
  }

  return element
}

/**
 * Scroll DOM to Error Field
 *
 * @param errors ValidationError error object
 * @param isArray whether the input is in an array of fields with similar names
 */
export const scrollToError = (errors) => {
  const isArray = errors[0].field.includes("[");

  if (isArray) {
    let [index, field] = errors[0].field.split(".");
    index = index.match(/\[(\d?)\]/)[0].replaceAll(/[\[\]']+/g, "");
    index = parseInt(index);

    const elements = findElementEndingWith(field, true)
    if (elements.length) {
      elements[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else {
      console.error(`Unable to find field(s) ${field}`);
    }
  } else {
    let { field } = errors[0];
    const element = findElementEndingWith(field)

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else {
      console.error(`Unable to find field ${field}`);
    }
  }
};

/**
 * Transform Error Into Object
 *
 * @description Transform the yup error into a useable validation object
 * @param {ValidationError} errors Yup validation errors
 * @returns {Record<string, string>} Validation errors
 */
export const transformErrorIntoObject = (error: unknown) => {
  const errors: any[] = [];
  let fields: string[] = [];

  if (error instanceof ValidationError) {
    const fieldErrors = groupBy(error.inner, "path");
    fields = keys(fieldErrors);

    each(fields, async (field) => {
      const errorList: any[] = [];

      each(fieldErrors[field], (fieldError) => {
        errorList.push(fieldError.errors[0]);
      });

      errors.push({
        field,
        message: errorList,
      });
    });

    if (!errors.length && error.errors.length && error.path) {
      errors.push({
        field: error.path,
        message: error.errors,
      })
    }
  } else {
    console.error(
      "Unknown Error at validation.transformErrorIntoObject",
      error
    );
  }

  return errors;
};
