export const sendGtmPixel = (data, callback) => {
  if (location.hostname === 'localhost') {
    return
  }
  if (window.google_tag_manager) {
    dataLayer.push(data)
    if (callback) {
      callback()
    }
  }
  else {
    const script = document.getElementById('gtm-script')
    if (script) {
      script.onload = () => {
        dataLayer.push(data)
        if (callback) {
          callback()
        }
      }
    }
  }
}

export const sendPageView = (page, email = 'N/A') => {
  sendGtmPixel({
    event: 'page_view',
    page,
    email,
  })
}
