import { DefaultStateFlags } from "./defaultFlags";

export enum EventRegistrationType {
  /** 
   * Regular signup with full Donation options
   */
  REGULAR = 'REGULAR',
  /** 
   * One time donation with a single fixed pricing set
   * 
   * @example `VV's Charity Run event, ONE_TIME_FIXED with price=35`
   */
  ONE_TIME_FIXED = 'ONE_TIME_FIXED',
  /**
   * One time donation with a single fixed pricing and applicable tiered discount 
   */
  ONE_TIME_FIXED_TIERED_DISCOUNT = 'ONE_TIME_FIXED_TIERED_DISCOUNT',
  /** 
   * Sponsorship event
   */
  SPONSORSHIP = 'SPONSORSHIP',
}

export interface EventRegistrationAttendee {
  firstName: string;
  lastName: string;
  email: string;
}

export interface EventRegistrationAttendeeExtras {
  phone?: string;
  notes?: string;

  // additional fields
  [key:string]: any
    // - VV Charity Run, field=shirtSize
    // - STS Golf for Life, field=foodAllergies  
}

export enum EventAmountTerms {
  ONE_TIME = "one-time",
  MONTHLY = "monthly",
  ANNUALLY = "annually",
  CUSTOM = "custom",
}

export type EventAmountOptions = {
  term: EventAmountTerms,
  onlyShowTerm?: EventAmountTerms | Array<EventAmountTerms>,
  amount: number,
  exceptionAmount?: number,
  discount?: 
  {
    type: 'fixed-volume'
    amount: number,
    volumeOf: number,
  } | {
    // not implemented yet
    type: 'percentage',
    amount: number,
  }
}

export type EventFields = {
  /**
   * Required form fields
   */
  required: Array<string>
  /**
   * Optional form fields
   */
  optional?: Array<string>
  /**
   * Hidden fields that derive its value
   * from other form fields
   */
  hidden?: Array<string>
  /**
   * Exception fields that identify the user
   * to fall into the exception category for
   * this type of campaign
   */
  exception?: Array<string>
}

/**
 * Event Registration State
 */
export type EventRegistrationState = {
  /**
   * Event Campaign ID - required for all events
   * @param campaignId
   */
  campaignId?: string;
  /**
   * Event is ether expired or fully booked
   * @param expired
   */
  expired?: boolean;
  /**
   * Event Sponsorship Package Index number
   * @param sponsorshipPackageIndex
   */
  sponsorshipPackageIndex?: number;
  /**
   * Sponsorship Parameters linked to Campaign
   */
  sponsorshipParams?: {
    /**
       * Exceptions Apply to this Event Sponsorship Package
       * @param sponsorshipParams.exceptionsApply
       */
    exceptionsApply?: boolean,
    /**
     * Discounts Apply to an individual Event Sponsorship Package
     * @param sponsorshipParams.discountsApply
     */
    discountsApply?: boolean,
    /**
     * Discounts Apply to All including Event Sponsorship Package
     * Exceptions
     * @param sponsorshipParams.discountsApplyToAll
     */
    discountsApplyToAll?: boolean,
    /**
     * Sponsorship Packages
     */
    packages: Array<{
      /**
       * Name or Identifier of Sponsorship Package
       * @param sponsorshipParams.packages[].id
       */
      id: string,
      /**
       * Max Total Registrants in an Event Sponsorship Package
       * @param sponsorshipParams.packages[].maxTotalRegistrants
       * 
       * The number of total registration irrespective
       * of exceptions
       */
      maxTotalRegistrants?: number,
      /**
       * Sponsorship Amount Options
       * @param sponsorshipParams.packages[].options
       */
      options?: EventAmountOptions
    }>,
    /**
     * Sponsorship Event Fields Config
     * @param sponsorshipParams.fields
     */
    fields: EventFields
  }
  /**
   * Campaign Parameters set by Campaign ID 
   * @param campaignParams
   */
  campaignParams?: {
    /** 
     * Indicates whether each registrant would have
     * includes contents
     * @param campaignParams.hasIncludesText
     */
    hasIncludesText?: boolean,
    /** 
     * Includes Text per registrant
    */
    includes?: {
      /** 
       * Default string to show for what is included
       */
      default: string,
      /**
       * To show what is included in an exception 
       * registrant
       */
      exception?: string,
    }
    hasAdditionalInstructions?: boolean,
    additionalInstructions?: [string],
    /**
     * Indicates whether this campaign has sponsorship
     * packages available
     * @param campaignParams.hasSponsorshipPackages
     */
    hasSponsorshipPackages?: boolean,
    /**
     * Max Registrants in an Event
     * @param campaignParams.maxRegistrantsExcludingException
     * 
     * The number of max registrants in an event 
     * not including any registrants that can be 
     * considered exceptions
     */
    maxRegistrantsExcludingException?: number,
    /**
     * Max Total Registrants in an Event
     * @param campaignParams.maxTotalRegistrants
     * 
     * The number of total registration irrespective
     * of exceptions
     */
    maxTotalRegistrants?: number,
    /**
     * Exceptions Apply to this event
     * @param campaignParams.exceptionsApply
     */
    exceptionsApply?: boolean,
    /**
     * Discounts Apply to Regular Event Registrations
     * @param campaignParams.discountsApply
     */
    discountsApply?: boolean,
    /**
     * Discounts Apply to All including Event Registration
     * Exceptions
     * @param campaignParams.discountsApplyToAll
     */
    discountsApplyToAll?: boolean,
    /**
     * Minimum donation amount
     * Exceptions
     * @param campaignParams.minDonationAmount
     */
    minDonationAmount?: number,
    /**
     * Form fields for event registration
     * @param campaignParams.fields
     */
    fields: EventFields
  }
  checkout?: {
    subtotalAmount: number,
    totalAmount?: number,
    totalDiscountAmount: number,
    totalExceptionSubtotalAmount: number,
    totalExceptionCount: number,
    lineCount: number,
  }
  /**
   * Event Content
   */
  content?: {
    title: string,
    description: string,
    startDate: Date,
    endDate: Date, 
    location: string,
    logo: string,
    logoWidth: string,
  }
  /**
   * Type of Event
   * @param type
   */
  type: EventRegistrationType | Array<EventRegistrationType>;
  /**
   * List of Attendees for Event
   * @param attendees
   */
  attendees: Array<EventRegistrationAttendee & EventRegistrationAttendeeExtras>;
} & 
  /** 
   * Require all default flags (errors, valid, pristine)
   * to be initialized with state
   * @param __errors
   * @param __pristine
   * @param __valid
   */
  Required<DefaultStateFlags> & (
  {
    /**
     * Event is ready for new registrations
     * @param ready
     */
    ready: boolean,
    /**
     * Pricing Options available for event
     * @param options
     */
    options: EventAmountOptions
  } |
  {
    /**
     * Event is ready for new registrations
     * @param ready
     */
    ready?: boolean,
    /**
     * Pricing Options available for event
     * @param options
     */
    options?: EventAmountOptions
  }
);