import { configureStore } from "@reduxjs/toolkit";

import reducer from './reducers'
import middleware from './middleware'
import type { Extra } from "./middleware";

let enhancers : any = []
if (import.meta.env.DEV) {
  enhancers.push(
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({
      features: {
        pause: false, // start/pause recording of dispatched actions
        lock: false, // lock/unlock dispatching actions and side effects
        persist: false, // persist states on page reloading
        export: false, // export history of actions in a file
        import: "custom", // import history of actions from a file
        jump: false, // jump back and forth (time traveling)
        skip: false, // skip (cancel) actions
        reorder: true, // drag and drop actions in the history list
        dispatch: true, // dispatch custom actions or action creators
        test: true, // generate tests for the selected actions
      },
    })
  )
}

let store
try {
  store = configureStore({
    middleware,
    enhancers,
    devTools: false,
    reducer,
  })
} catch (e) {
  console.info('[store] enhancers disabled due to incompatibility')
  store = configureStore({
    middleware,    
    devTools: false,
    reducer,
  })
}

// Store related types
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type {
  Extra
}

export default store
