import React from 'react'
import { FloatingLabel } from 'react-bootstrap'

const InputWrapperByShowTitle = (props) => {
  const { showInputTitles, title, optional, showFloatingLabel = true } = props
  return (
    <>
      { 
        showInputTitles ? (
          <label className={`${optional ? '' : 'required'} dark-blue mb1`}>
            {title}
            { optional ? <span style={{ fontSize: '0.75rem', color: '#888' }}>&nbsp;(optional)</span> : null}
          </label>
        ) : null 
      }
      {
        (showInputTitles || !showFloatingLabel) ? props.children : (
          <FloatingLabel label={showInputTitles ? '' : title}>
            { props.children }
          </FloatingLabel>
        )
      }          
    </>
  )
}

export default InputWrapperByShowTitle