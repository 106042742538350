import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'

export const NavLinkContainer = ({to, onClick, type = '', children}) => (
  <LinkContainer to={to} onClick={() => onClick()}>
    <div className={`nav-link-container ${type}`}>
      {children}
    </div>
  </LinkContainer>
)

export default NavLinkContainer