enum AppError {
  UNABLE_TO_RESOLVE_SITE = 'Unable to resolve site',
}

namespace AppError {
  export const exception = (e: AppError) : never => {
    throw new Error(e)
  }
}

export { AppError }