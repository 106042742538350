import React, { useState, Children } from 'react'

import {
  siteGeneralBackgroundImage,
  siteGeneralBackgroundImageStyle,
  siteLinkUrl,
  siteLogoStyles,
  sitePhone,
  siteSuccessBackgroundImage,
  sitesWithPhoneNav,
  sitesWithTrapezoid,
} from 'modules/siteContents'

export const MainImage = (props) => {
  const [utmCampaignImageLoaded, setUtmCampaignImageLoaded] = useState(false)
  const { 
    imageTemplate,
    site, 
    utmCampaign,
    children
  } = props  

  switch(imageTemplate) {
    case 'success': 
      return (
        <div 
          className="background" 
          style={{ backgroundImage: `url("${siteSuccessBackgroundImage[site]}")`}}
        />
      )
    
    case 'admin-terminal':
      return null

    case 'events':
      const eventImageFunction = siteGeneralBackgroundImage[site]
      const eventImageDefault = eventImageFunction && eventImageFunction()
      const eventImageUtm = eventImageFunction && eventImageFunction(utmCampaign)

      return (
        <div style={siteGeneralBackgroundImageStyle[site] || { width: '100%', position: 'relative' }}>
          <img src={eventImageUtm} style={utmCampaignImageLoaded ? { width: '100%' } : { display: 'none' }} onLoad={() => setUtmCampaignImageLoaded(true)}/> 
          <img src={eventImageDefault} style={utmCampaignImageLoaded ? { display: 'none' } : { width: '100%' }}/> 
          <div className="right flex v-center xs-invisible" style={{ overflow: 'hidden', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent' }}>
            <div style={{ width: '70%' }}/>
              { Children.map(children, child => child) }
          </div>
        </div>
      )

    default: 
      const mainImageFunction = siteGeneralBackgroundImage[site]
      const mainImageDefault = mainImageFunction && mainImageFunction()
      const mainImageUtm = mainImageFunction && mainImageFunction(utmCampaign)
      
      return (
        <div style={siteGeneralBackgroundImageStyle[site] || { width: '100%', position: 'relative' }}>
          <img src={mainImageUtm} style={utmCampaignImageLoaded ? { width: '100%' } : { display: 'none' }} onLoad={() => setUtmCampaignImageLoaded(true)}/> 
          <img src={mainImageDefault} style={utmCampaignImageLoaded ? { display: 'none' } : { width: '100%' }}/> 
          <div className="right flex v-center xs-invisible" style={{ overflow: 'hidden', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent' }}>
            <div style={{ width: '70%' }}/>
              {
                sitesWithTrapezoid.includes(site) && !utmCampaignImageLoaded && 
                (
                  <div className="trapezoid-box flex v-center center" style={{ width: '30%', height: 140 }}>
                    <div style={{ width: '100%' }}>
                      <div className="darkblue" style={{ fontFamily: 'Gotham-Bold, Arial, sans-serif', fontSize: 20, lineHeight: 1, color: 'darkblue' }}>
                        Your Generous Donation
                      </div>
                      <div className="storkblue" style={{ fontFamily: 'AustinRegular, Arial, sans-serif', fontSize: 40, color: '#40bfdd' }}>
                        Saves Lives
                      </div>
                    </div>
                  </div>
                )
              }
          </div>
        </div>
      )

  }
}