import {
  array,
  bool,
  mixed,
  number,
  object,
  string,
  ValidationError,
} from "yup";

import { EventAmountTerms, EventRegistrationState } from "./@types/eventRegistrationSlice";
import { useParams } from "./eventRegistrationSlice/utils"

const minDonationAmount = 5
const errorMessages = {
  campaignId: 'Campaign ID is required',
  firstName: 'First Name is required',
  lastName: 'Last Name is required',
  email: [
    'Email is not valid',
    'Email is required',
  ],
  amount: [
    `Please enter a donation amount greater than $${minDonationAmount}`,
    'Amount is required'
  ],
  foodAllergies: 'Please specific whether you have food allergies',
  phone: 'Phone is required',
}


export const validateEventRegistration = <T>(method: string, eventRegistration: T) => {
  const params = useParams(eventRegistration)
  const campaignSetReadySchema = {
    campaignId: string().required(errorMessages.campaignId),
    options: object({
      amount: number().min(minDonationAmount, errorMessages.amount[0]).required(errorMessages.amount[1]),
      term: string().oneOf(Object.values(EventAmountTerms))
    })
  }

  let attendeeSchema = {
    // email: string().email(errorMessages.email[0]).required(errorMessages.email[1]),
  }

  if (method.startsWith('eventRegistration')) {
    const state = eventRegistration as EventRegistrationState
    params?.fields.required.forEach((key, keyIndex) => {
        if (typeof attendeeSchema[key] === 'undefined') {
          if (key === 'email') {
            attendeeSchema[key] = string().email(errorMessages.email[0]).required(errorMessages.email[1])
          } else {
            attendeeSchema[key] = string().required(errorMessages[key] || null)
          }
        }
      })

      params?.fields.optional?.forEach((key, keyIndex) => {
        if (typeof attendeeSchema[key] === 'undefined') {
          attendeeSchema[key] = string().optional()
        }
      })
  }

  switch(method) {
    case 'eventRegistration/setReady':
      return object(campaignSetReadySchema).validate(eventRegistration, {
        abortEarly: false,
      })

    default: 
    case 'eventRegistration/validateEventRegistrationState':
      return object({
        ready: bool().required(),
        attendees: array().when("ready", {
          is: true,
          then: (schema) => schema.of(object(attendeeSchema)),
          otherwise: (schema) => schema.nullable().optional(),
        }),
      }).validate(eventRegistration, {
        abortEarly: false,
      })
    
  }
}
