export const preferenceOptions = [
  {
    key: 'primaryCommunicationChannel',
    title: 'Primary communication channel:',
    options: ['Email', 'Facebook', 'Instagram', 'LinkedIn', 'Mail', 'Website', 'Text Messages', 'Phone']
  },
  {
    key: 'secondaryCommunicationChannel',
    title: 'Secondary communication channel:',
    options: ['Email', 'Facebook', 'Instagram', 'LinkedIn', 'Mail', 'Website', 'Text Messages', 'Phone']
  },
  {
    key: 'enjoyReceiving',
    title: 'Enjoy receiving: (please mark all)',
    type: 'multiple',
    optionIndexOfAll: 0,
    options: [
      'All Content',
      'Financials',
      'Stories of Lives Changed',
      'News',
      'PRC and Bus Info',
      'Updates',
      'Up-coming Events/Webinars',
    ]
  },
  {
    key: 'openEmailFrequency',
    title: 'I open email:',
    options: ['Every Email', '> 80%', '> 50%', '> 25%', 'Very Few', 'None']
  },
  {
    key: 'reasonToSupport',
    title: 'Reason you choose to support us:',
    type: 'text',
  },
  {
    key: 'agreeToReceive',
    title: 'I agree to receive:',
    type: 'multiple',
    options: ['Email', 'Text Messages', 'Newsletter']
  },
  {
    key: 'birthdate',
    title: 'Birthdate:',
    type: 'date',
  },
  {
    key: 'gender',
    title: 'Gender:',
    options: ['Male', 'Female']
  },
  {
    key: 'whetherOwnBusiness',
    title: 'Do you own a business:',
    options: ['Yes', 'No'],
  },
  {
    key: 'maritalStatus',
    title: 'Marital status:',
    options: ['Married', 'Single']
  },
  {
    key: 'numberOfKids',
    title: 'Number of Kids:',
    options: ['0', '1', '2', '3', '4', '5+']
  },
  {
    key: 'hasExperienceOfAdoption',
    title: 'Do you have a personal experience with adoption?',
    options: ['Yes', 'No']
  },
  {
    key: 'representedMinistry',
    title: 'Do you represent a ministry?',
    allowToSpecify: 'Other',
    options: [
      'No',
      'Church',
      'Missionary',
      'Non-profit',
      'Pro-Life Ministry',
      'Pregnancy Center',
      'Other',
    ]
  }
]

export const mapPreferencesToGtmData = formData => {
  let result = {}
  for (var preferenceOption of preferenceOptions) {
    const optionValue = formData[preferenceOption.key]
    if (!optionValue) {
      continue
    }
    switch (preferenceOption.type) {
      case 'multiple':
        result[preferenceOption.key] = optionValue.join(';')
        break
      case 'date':
        result[preferenceOption.key] = `${optionValue.year}-${optionValue.month}-${optionValue.date}`
        break
      default:
        result[preferenceOption.key] = optionValue
    }
  }
  return result
}
