// @ts-nocheck

import React from 'react';
import { Form } from 'react-bootstrap';
import GiftBanner from 'sharedComponents/GiftBanner';
import Navbar from 'sharedComponents/Navbar';
import Payment from 'sharedComponents/Payment'; // moved from ./sections to sharedComponents
import Spinner from 'sharedComponents/Spinner';
import Testimonials from 'sharedComponents/Testimonials';

import EventRegistration from './sections/EventRegistration'
import CharityRun from './sections/CharityRun';
import Contact from './sections/Contact';
import Options from './sections/Options';
import PaymentProfiles from './sections/PaymentProfiles';

import type { DonateProps, DonateState } from './Donate';
/**
 * Donate Now Page
 * 
 * Used as default redirect from /
 * Renders and rewrite to /donate?amount=50&term=monthly on load.
 * 
 */
class DonateDefault extends React.Component<DonateProps, DonateState> {

  render() {
    const {
      campaignId, 
      customer, 
      enableEvents,
      enableEventRegistrations,
      enableCharityRun,
      getAccessTokenSilently, 
      guestMode, 
      initialUrlParams, 
      isAdminTerminal,
      loading, 
      loadingCustomer, 
      site, 
    } = this.props

    return (
      <div 
        className={[
          'app-form-body',
          guestMode ? ' guest-mode' : '',
          enableEvents ? ' event-registration-container' : ''
        ].join('')} 
        style={{ position: 'relative' }}
        >

        {(!enableEvents || enableEvents && enableEventRegistrations) && (
        <div className={`form-container donate`}>
          {loading && 
            <Spinner mask/> 
          }
          <Navbar />
          {enableCharityRun && (
            <div className={`pt2 charity-run`}>
              <Form noValidate>
                <CharityRun />
              </Form>
            </div>
          )}
          {enableEvents && (
            <div className={`${guestMode ? '' : 'pt2'} event-registration`}>
            <Form noValidate>
              <EventRegistration />
            </Form>
          </div>
          )}
          <Form>  
            <Options 
              {...this.props}
              guestMode={guestMode} 
              isAdminTerminal={isAdminTerminal}
            />
            <Contact
              {...this.props}
              guestMode={guestMode} 
              editMode={true} 
            />
            {customer ? 
              <PaymentProfiles 
                {...this.props}
                getAccessTokenSilently={getAccessTokenSilently} 
              /> 
              : null 
            }
            <Payment 
              {...this.props}
              getAccessTokenSilently={getAccessTokenSilently} 
              withTitle={!customer && !loadingCustomer} 
              guestMode={guestMode} 
              // editMode={true}
            />
          </Form>
        </div>
        )}
        <div id="save-the-storks-gift" />
        {guestMode && (
          <>
            {!isAdminTerminal && 
              <GiftBanner 
                cid={campaignId || initialUrlParams.cid} 
              />
            }
            <Testimonials site={site} cid={campaignId || initialUrlParams.cid} />
          </>
        )}
      </div>
    )
  }
}

export default DonateDefault
