import React, { useState, useContext, createContext, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { Spinner } from 'react-bootstrap'

import { initialStripeContext, StripeContext } from './context'
import { tokenEventHandler } from './eventHandlers'
import { SitesContext } from '../SitesProvider'

import type { 
  PaymentRequestTokenEvent, 
} from '@stripe/stripe-js'

/** 
 * Stripe Provider with React Context API
 */
export const StripeProvider = (props) => {
  const [stripeContext, setStripeContext] = useState<StripeContext>(initialStripeContext)
  const {
    services,
    sandbox
  } = useContext(SitesContext)

  const [stripeInstance] = useState(() => loadStripe(import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging' ? services.stripe.pk : sandbox.stripe.pk))
  // const [stripeInstance] = useState(() => loadStripe(import.meta.env.MODE === 'production' ? services.stripe.pk : sandbox.stripe.pk))
  // const [stripeInstance] = useState(() => loadStripe(sandbox.stripe.pk))


  stripeContext.updatePaymentRequest = (paymentRequestOptions, event) => {
    console.log('button clicked', event)
    setStripeContext({
      ...stripeContext,
      checkoutStarted: true,
    })

    stripeContext.paymentRequest?.update({
      total: paymentRequestOptions.total
    })
  }

  stripeContext.savePaymentRequestEvent = (eventType, eventResponse) => {
    console.debug('[provider][stripe] ctx.savePaymentRequestEvent', eventType, eventResponse)

    if (eventType === 'token') {
       setStripeContext({
        ...stripeContext,
        paymentRequestEvents: {
          ...stripeContext.paymentRequestEvents,
          token: eventResponse as PaymentRequestTokenEvent,
        }
      })
    }
  }

  /**
   * Initialize Payment Request for Stripe based payments
   * 
   * @param updateRequest 
   * @returns payment request handler
   */
  stripeContext.initPaymentRequest = ({ 
    paymentRequestOptions,
    onPaymentRequestSuccess,
    onPaymentRequestCancel,
    onCanMakePayment,
  }) => {
    let { stripe, paymentRequest, checkoutStarted } = stripeContext
    if (!stripe || !paymentRequestOptions) return

    try {
      // update or create payment request object
      if (!paymentRequest) {
        console.debug('[provider][stripe] creating stripe payment request object')
        paymentRequest = stripe.paymentRequest(paymentRequestOptions)

        /** 
         * Token Event
         * 
         * On successful Apple / Google Pay returns a token event is created
         * 
         * @param event.token - The token created 
         * @param event.token.card - The CreditCard associated with Merchant
         */
        paymentRequest.on('token', (event: PaymentRequestTokenEvent) => {
          console.debug('[provider][stripe] event token', event)
          tokenEventHandler(event)
          .then(() => {
            stripeContext.savePaymentRequestEvent!('token', event)
            return Promise.resolve(onPaymentRequestSuccess!(event))
          })
          .then(() => {
            // IMPORTANT: has to be called within 30s
            // potentially other resolutions we can send here
            // -- fail | invalid_(field) | complete
            event.complete('success')
          })
          .catch(e => {
            console.log(stripeContext)
            console.error('[provider][stripe]',e)
            event.complete('fail')
          })
        })

        /**
         * Cancel Event
         * 
         * Whenever a user exits out of a Payment Request workflow involving
         * Apple or Google Pay
         */
        paymentRequest.on('cancel', () => {
          if (onPaymentRequestCancel) Promise.resolve(onPaymentRequestCancel())
        })
      } 

      // determine eligibility
      paymentRequest
        .canMakePayment()
        .then(canMakePayment => {

          // update context
          setStripeContext({
            ...stripeContext,
            paymentRequest,
            paymentRequestOptions,
            canMakePayment,
            onPaymentRequestSuccess,
            onPaymentRequestCancel,
          })

          if (typeof onCanMakePayment === 'function') {
            onCanMakePayment(canMakePayment)
          }
        })
        .catch(e => {
          console.error('[provider][stripe] failed to determine canMakePayment', e)
        })
    
      return paymentRequest
    } catch(e) {
      console.error('[provider][stripe] error',e)
    }
  }


  /** 
   * On event - Stripe loaded
   * update stripe context
   */
  useEffect(() => {
    const load = async () => {
      const stripe = await stripeInstance
      if (stripe) {
        setStripeContext((prevState) => {
          // if stripe was loaded
          if (!prevState.stripe && stripe) {
            console.debug('[provider][stripe] loading', prevState, stripe)

            return {
              loading: false,
              checkoutStarted: false,
              stripe
            }
          }

          // do nothing
          else {  
            return prevState
          }
        })
      }
    }
    load()
  }, [loadStripe])


  return (
    <StripeContext.Provider value={stripeContext}>
      <StripeContext.Consumer>
        {(ctx: StripeContext) => (
          <>
            {ctx.loading && <Spinner />}
            {ctx.stripe && (
              <Elements stripe={ctx.stripe}>
                <ElementsConsumer>
                  {({stripe, elements}) => 
                    React.cloneElement(props.children, {
                      // element consumers
                      stripe,
                      elements,

                      // everything else
                      ...props
                    })
                  }
                </ElementsConsumer>
              </Elements>
            )}
          </>
        )}
      </StripeContext.Consumer>
    </StripeContext.Provider>
  )
}

export default StripeProvider
export * from './context'