import { Button, Col, Container, Dropdown, Nav, NavDropdown, NavItem, Navbar, Row } from 'react-bootstrap'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  siteGeneralBackgroundImage,
  siteGeneralBackgroundImageStyle,
  siteLinkUrl,
  siteLogoStyles,
  sitePhone,
  siteSuccessBackgroundImage,
  sitesWithPhoneNav,
  sitesWithTrapezoid,
} from 'modules/siteContents'

import { baseUrl as baseServerUrl } from 'modules/axiosServer'
import { constructRedirectUri } from 'modules/utils'
import qs from 'query-string'
import { useAuth0 } from "@auth0/auth0-react"

import { HeaderNavigationDropDown } from './HeaderComponents/HeaderNavigationDropdown'
import { HeaderFEW } from './HeaderComponents/HeaderFEW'
import { HeaderOPH } from './HeaderComponents/HeaderOPH'
import { MainImage } from './HeaderComponents/MainImage';
import { EventBox } from './HeaderComponents/EventBox';

export const Header = (props) => {
    const { successInfo, site, isAdminTerminal, utmCampaign, layoutConfig } = props
    if (layoutConfig?.header === false) {
      return null
    }
    // const [utmCampaignImageLoaded, setUtmCampaignImageLoaded] = useState(false)
    // const { isAuthenticated, user } = useAuth0()
    // const redirectUri = constructRedirectUri(qs.parse(search), location.pathname)
    // const { loginWithRedirect } = useAuth0()

    const [enableEvents, campaignId] = useSelector(({eventRegistration}) => [eventRegistration.ready && !!eventRegistration.campaignId, eventRegistration.campaignId])

    let imageTemplate = 'default'
    if (successInfo) imageTemplate = 'success'
    else if (isAdminTerminal) imageTemplate = 'admin-terminal'
    else if (campaignId && enableEvents) {
      imageTemplate = 'events'
    }


    if (site === 'FEW') {
      return <HeaderFEW {...props} />
    } else if (site === 'OPH'){
      return <HeaderOPH site={site} />
    }

    return (
      <div id="header">
        <Navbar collapseOnSelect style={{ marginBottom: 0 }}>
          <Container fluid>
            <div className="navbar-header inline-flex">
              <div className="flex v-center" style={{ height: 86 }}>
                <Navbar.Brand>
                  <a href={siteLinkUrl[site] || null}>
                    <img 
                    src={`${baseServerUrl}/assets/images/main-logo${site === 'EMA' ? '.svg' : '.png'}`}
                    style={siteLogoStyles[site]} />
                  </a>
                </Navbar.Brand>
                <Nav style={{ flex: 1}} className="d-block d-sm-none mr2 right">
                {
                  sitesWithPhoneNav.includes(site) ? (
                    <a href={`tel:${sitePhone[site]}`}>
                      <b className="darkblue" style={{ fontSize: 12 }}>
                        <span>DONATE BY PHONE</span>
                        <br />
                        <span>{sitePhone[site]}</span>
                      </b>
                    </a>
                  ) : <div>&nbsp;</div>
                }
                </Nav>
                <Navbar.Toggle />
              </div>
            </div>
          <Navbar.Collapse>
            <Nav className="flex v-center login-nav nav navbar-nav ml-auto mb-2 mb-lg-0">
              {
                sitesWithPhoneNav.includes(site) && (
                  <a href="tel:1-866-639-0479" className="d-none d-sm-block flex-1">
                    <b className="darkblue" style={{ fontSize: 12 }}>
                      <span>DONATE BY PHONE</span>
                      <br />
                      <span>{sitePhone[site]}</span>
                    </b>
                  </a>
                )
              }
              <HeaderNavigationDropDown {...props} />
            </Nav>
          </Navbar.Collapse>
          </Container>
        </Navbar>

        <MainImage 
          imageTemplate={imageTemplate}
          site={site}
          utmCampaign={utmCampaign}
          >
          {
            enableEvents && <EventBox campaignId={campaignId} />
          }
        </MainImage>
      </div>
    )
}

export default Header