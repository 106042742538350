import React from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { Dropdown } from 'react-bootstrap'
import { emitEvent } from 'modules/events'

export const LogoutButton = ({ saveAppState, redirectUri }) => {
  const { logout, user } = useAuth0()
  return (
    <Dropdown.Item
    eventKey={'logout'}
      onClick={async () => {
        await emitEvent(emitEvent.events.LOGOUT, { level: 'info', info: { user } })
        return logout({ logoutParams: { returnTo: redirectUri }})
      }}
    >
      Logout
    </Dropdown.Item>
  )
}
