import React from 'react';
import { Button, Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setReady, updateExtraForms } from 'slices/charityRunSlice';

import Attendees from './Attendees';

import type { FunctionComponent } from 'react'
import type { RootState } from 'src/store'
import type { CharityRunState } from 'slices/@types'

export const CharityRun: FunctionComponent<{}> = () => {
  const {
    ready,
    extraForms,
  }: CharityRunState = useSelector(({ charityRun }: RootState) => charityRun)
  const dispatch = useDispatch<any>()
  const { raceType, formingOwnTeam, runningInHornorOf, additionalInformation } = extraForms

  return (
    <section className='run'>
      {!ready && (
        <>
          <Button
            variant='secondary'
            className="btn-option pull-right v-center get-started"
            onClick={() => dispatch(setReady(true))}
          >
            Get Started
          </Button>
        </>
      )}
      <h3>Join our Charity Run</h3>
      <div className="flex v-center sub-heading">Donate and receive a race packet with your selected t-shirt sizes</div>
      {ready && (<Attendees />)}
      {
        ready && (
          <Row>
            <Col xs={12} className='flex mt1'>
              <Form.Group className='flex'>
                <Form.Check 
                  id="running-in-honor-of"
                  className="pre-field flex-children"
                  style={{ width: 240 }}
                >
                  <Form.Check.Input
                    type='checkbox'
                    defaultChecked={runningInHornorOf.checked}
                    onChange={() => dispatch(updateExtraForms(['runningInHornorOf', {
                      checked: !runningInHornorOf.checked,
                      name: runningInHornorOf.name,
                    }]))}
                  />
                  <Form.Check.Label>
                    We are running in honor of someone.
                  </Form.Check.Label>
                </Form.Check>
                {
                  runningInHornorOf.checked && (
                    <FloatingLabel 
                      label={'Please specify'} 
                      className='ml2' 
                      style={{ maxWidth: 240 }}
                    >
                      <Form.Control
                        name={runningInHornorOf.name}
                        placeholder={'Please specify'}
                        type='text'
                        value={runningInHornorOf.name}
                        onChange={event => dispatch(updateExtraForms(['runningInHornorOf', {
                          checked: runningInHornorOf.checked,
                          name: event.target.value,
                        }]))}
                      />
                    </FloatingLabel>
                  )
                }
              </Form.Group>
              <Form.Group>
                <FloatingLabel label={'Race Type'} className='ml2'>
                  <Form.Select 
                    title={'Race Type'} 
                    style={{ maxWidth: 240 }}
                    name={'raceType'}
                    className='inline-block'
                    onChange={(event) => {
                      dispatch(updateExtraForms(['raceType', event.target.value]))
                    }}
                  >
                    {['In Person', 'Virtual'].map((option) => (
                      <option
                        key={`${option}`}
                        value={option}
                      >
                      { option }
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col xs={12} className='flex'>
              <Form.Group className='flex'>
                <Form.Check 
                  id="forming-own-team"
                  className="pre-field flex-children"
                  style={{ width: 240 }}
                >
                  <Form.Check.Input
                    type='checkbox'
                    defaultChecked={formingOwnTeam.checked}
                    onChange={() => dispatch(updateExtraForms([
                      'formingOwnTeam', 
                      {
                        checked: !formingOwnTeam.checked,
                        name: formingOwnTeam.name,
                      }
                    ]))}
                  />
                  <Form.Check.Label>
                    We are forming our own team.
                  </Form.Check.Label>
                </Form.Check>
                {
                  formingOwnTeam.checked && (
                    <FloatingLabel label={'Please specify'} className='ml2' style={{ maxWidth: 240 }}>
                      <Form.Control
                        name={formingOwnTeam.name}
                        placeholder={'Please specify'}
                        type='text'
                        value={formingOwnTeam.name}
                        onChange={event => dispatch(updateExtraForms(['formingOwnTeam', {
                          checked: formingOwnTeam.checked,
                          name: event.target.value,
                        }]))}
                      />
                    </FloatingLabel>
                  )
                }
              </Form.Group>
            </Col>
            
            <Col xs={12}>
              <Form.Group className='mt1'>
                <FloatingLabel label={'Additional Information'}>
                  <Form.Control
                    as="textarea" 
                    style={{ height: 'auto' }}
                    rows={3}
                    name={'additionalInformation'}
                    placeholder={'Additional Information'}
                    type='text'
                    value={additionalInformation}
                    onChange={event => dispatch(updateExtraForms([
                      'additionalInformation',
                      event.target.value,
                    ]))}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        )
      }
    </section>
  )
}
export default CharityRun