import React, { createContext, useState, useMemo, useCallback, useEffect } from "react"
import type { FunctionComponent, ReactNode, PropsWithChildren } from 'react'

import SiteConfig from "src/sw/site.config";
import type { Site } from "src/types/sites";

export const SitesContext = createContext<Site>(SiteConfig());

// TODO: use WebWorker for SiteConfig() with encryption
export const SitesProvider = ({ children }) => {
  return (
    <SitesContext.Provider value={SiteConfig()}>
      {children}
    </SitesContext.Provider>
  )
}

export default SitesProvider