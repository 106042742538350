import { preferencesUrl } from "modules/urls";
import axiosServer from "modules/axiosServer";
import { sendGtmPixel } from "modules/gtm";
import { mapPreferencesToGtmData } from "sharedComponents/PreferencesModal/contents";
import { checkRecaptchaFromError } from "modules/utils";
import { setRecaptchaMode, setRecaptchaV2TokenValidated } from "../slices/recaptchaSlice";
import _ from "lodash";

export const setContactData = (data) => {
  return {
    type: "SET_CONTACT_DATA_PREFERENCES",
    payload: data,
  };
};

export const setFormData = (data) => {
  return {
    type: "SET_FORM_DATA_PREFERENCES",
    payload: data,
  };
};

export const setDataStatus = (status) => {
  return {
    type: "SET_DATA_STATUS_PREFERENCES",
    payload: status,
  };
};

export const setError = (error) => {
  return {
    type: "SET_ERROR_PREFERENCES",
    payload: error,
  };
};

export const setModalOpen = (flag) => {
  return {
    type: "SET_MODAL_OPEN_PREFERENCES",
    payload: flag,
  };
};

export const resetPreferences = (data) => {
  return {
    type: "RESET_PREFERENCES",
    payload: data,
  };
};

export const getPreferences = (getAccessTokenSilently, callback) => {
  return async (dispatch, getState) => {
    const { profileAuth0 } = getState().root;
    try {
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      dispatch({
        type: "SET_DATA_STATUS_PREFERENCES",
        payload: "loading",
      });
      const {
        data: { contact, ...preferences },
      } = await axiosServer.get(preferencesUrl, {
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      });
      dispatch({
        type: "SET_FORM_DATA_PREFERENCES",
        payload: preferences,
      });
      dispatch({
        type: "SET_CONTACT_DATA_PREFERENCES",
        payload: contact,
      });
      dispatch({
        type: "SET_DATA_STATUS_PREFERENCES",
        payload: null,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "SET_DATA_STATUS_PREFERENCES",
        payload: "error",
      });
    }
  };
};

export const savePreferences = (
  formData,
  contactData,
  getAccessTokenSilently,
  recaptchaResponseToken,
  callback
) => {
  return async (dispatch, getState) => {
    const { profileAuth0, contactId, recaptchaWhitelistKey } = getState().root;
    const { mode: recaptchaMode } = getState().recaptcha;
    let {
      contact,
    } = getState().account;
    contact = {
      ...contactData,
      ...contact
    }
    const contactEmail = contact.email
    try {
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      dispatch({
        type: "SET_DATA_STATUS_PREFERENCES",
        payload: "loading",
      });
      await axiosServer.post(
        preferencesUrl,
        { ...formData, contact:{...contact, ...contact.address}, contactId, recaptchaResponseToken, recaptchaMode },
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
          params: { key: recaptchaWhitelistKey },
        }
      );
      sendGtmPixel({
        event: "profile_save",
        email: (profileAuth0 || {}).email || contactEmail,
        ...mapPreferencesToGtmData(formData),
      });
      dispatch({
        type: "SET_FORM_DATA_PREFERENCES",
        payload: formData,
      });
      dispatch({
        type: "SET_DATA_STATUS_PREFERENCES",
        payload: null,
      });
      if (callback) {
        callback();
      }
      if (recaptchaMode === 'v2') {
        dispatch(setRecaptchaV2TokenValidated(true))
      }
    } catch (error) {
      console.error(error);
      const errorMessage = _.get(
        error,
        ["response", "data", "error"],
        _.get(error, ["response", "data"], error)
      );
      checkRecaptchaFromError(errorMessage, setRecaptchaMode, setRecaptchaV2TokenValidated, dispatch, getState) // If recaptcha v3 fails, switch to v2 mode
      dispatch({
        type: "SET_DATA_STATUS_PREFERENCES",
        payload: "error",
      });
      dispatch({
        type: "SET_ERROR_PREFERENCES",
        payload: errorMessage,
      });
    }
  };
};
