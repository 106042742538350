import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventAmountTerms } from 'slices/@types/eventRegistrationSlice';

import OptionsDefault from './Options'
import CustomOptions from './CustomOptions'

import type { RootState, AppDispatch } from 'src/store'

export default (props) => {
  const { enableEvents, enableEventRegistrations } = props
  const { 
    options
  } = useSelector((state:RootState) => state.eventRegistration)

  if (enableEvents && enableEventRegistrations && options.term === EventAmountTerms.CUSTOM) {
    return (
      <CustomOptions
        {...props}
      />
    )
  } else if (enableEvents && !enableEventRegistrations) {
    // registration not enabled
    return  <></>
  } else {
    return (
      <OptionsDefault
        {...props}
      />
    )
  }
}