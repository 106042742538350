
import { getAccessToken } from "src/slices/accessSlice";

const protectedSlices = [
  'root/account'
]

const requiresAuthentication = (route) => 
  protectedSlices.findIndex(sliceRoute => route.startsWith(sliceRoute)) !== -1

const refreshAccessMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    // only account actions require access token
    if (requiresAuthentication(action.type.toString())) {
      // if (false) {
      const { token } = getState().access

      if (!token) {
        console.log('[middleware][access] getAccessToken() then ->', action)
        return dispatch(getAccessToken())
          .then(() => {
            return next(action)
          })
      } else {
        return next(action);
      }
    } else {
      return next(action);
    }
    
    
  }
}

export default refreshAccessMiddleware;