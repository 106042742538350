// @ts-nocheck
import React from 'react'
// import { withRouter } from 'react-router-dom'
import { Tabs, Tab, Nav, NavItem } from 'react-bootstrap'
import { connect } from 'react-redux'
import Spinner from 'sharedComponents/Spinner'
import { withRouter } from '../routes/WithRouter'

const viewTextDict = {
  donate: 'Donate',
  subscriptions: 'Subscriptions',
  payments: 'Payment History',
  preferences: 'Account', 
  payment_methods: 'Payment Methods',
  attendees: 'Event Attendees',
}
const viewKeys = Object.keys(viewTextDict)
// const viewKeysNoGateway = ['donate', 'payments', 'preferences']

@connect(store => ({
  customer: store.root.customer,
  loadingCustomer: store.root.loadingCustomer,
  profileAuth0: store.root.profileAuth0,
  donationType: store.options.donationType,
  donationAmount: store.options.donationAmount,
  crm: store.root.crm,
  hasEventRegistrations: store.root.hasEventRegistrations,
}))

class Navbar extends React.Component {

  pushToRoute = (viewKey, router, donationType, donationAmount) => {
    if (viewKey === 'donate') {
      router.navigate(`/donate?term=${donationType}&amount=${donationAmount}`)
    }
    else {
      router.navigate(`/${viewKey}`)
    }
  }

  render() {
    const route = location.pathname.replace(/\/$/, '').replace(/^\//, '')
    if (!['donate', 'subscriptions', 'payments', 'preferences', 'payment_methods', 'attendees'].includes(route)) {
      return null
    }
    const { 
      profileAuth0, 
      customer, 
      router, 
      donationType, 
      donationAmount, 
      loadingCustomer, 
      crm,
      hasEventRegistrations,
    } = this.props

    if (!profileAuth0) return null

    let viewKeysToUse = viewKeys
    if (!customer) {
      viewKeysToUse = viewKeysToUse.filter(k => !['subscriptions'].includes(k))
    }
    if (crm !== 'salesforce') {
      viewKeysToUse = viewKeysToUse.filter(k => k !== 'preferences')
    }
    if (!customer || !hasEventRegistrations) {
      viewKeysToUse = viewKeysToUse.filter(k => k !== 'attendees')
    }
    const currentViewIndex = viewKeysToUse.indexOf(route)

    return (
      <Tabs
        id="navbar-tabs"
        activeKey={currentViewIndex} 
        onSelect={(viewKey) => {
          this.pushToRoute(viewKeysToUse[viewKey], router, donationType, donationAmount)
        }}
        justify
      >
        {/* <Tabs variant="tabs" className="responsive-tab-headers"> */}
          { loadingCustomer ? <Spinner mask={+true} hideicon={+true} /> : null }
          {
            viewKeysToUse.map((viewKey, index) => (
              <Tab 
                className={"hidden-xxs"} 
                eventKey={index} 
                key={viewKey}
                title={viewTextDict[viewKey]}
              >
                {/* {viewTextDict[viewKey]} */}
              </Tab>
            ))
          }
          {/* <Tab 
            className={currentViewIndex === 0 ? 'hide' : `prev-tab block-xxs d-none`} 
            eventKey={currentViewIndex - 1}
            onClick={() => this.pushToRoute(viewKeysToUse[currentViewIndex - 1], router, donationType, donationAmount)}
            title={''}
          >
            <span className="fa fa-lg fa-caret-left" />
          </Tab>
          <Tab
            className={`block-xxs d-none`} 
            eventKey={currentViewIndex} 
            title={viewTextDict[route]}
            active
          >
            {(viewTextDict[route] || '').replace(/ /g, "\u00a0")}
          </Tab>
          <Tab 
            className={currentViewIndex === (viewKeysToUse.length - 1) ? 'hide' : `next-tab block-xxs d-none`} 
            eventKey={currentViewIndex + 1}
            onClick={() => this.pushToRoute(viewKeysToUse[currentViewIndex + 1], router, donationType, donationAmount)}
            title={''}
          >
            <span className="fa fa-lg fa-caret-right" />
          </Tab> */}
        {/* </Tabs> */}
      </Tabs>
    )
  }
}

export default withRouter(Navbar)