import _ from "lodash";
import axiosServer from "modules/axiosServer";
import { preferencesUrl } from "modules/urls";
import { contactSchema, transformErrorIntoObject, contactTerminalSchema } from "modules/validation";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAccessToken } from "./accessSlice";
import { defaultFlags, setFlags } from "./flags";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Extra, RootState } from "src/store";

import type {
  AccountState,
  AccountStateContact,
  AccountStatePreferenceOption,
} from "./@types/accountSlice";

const initialState: AccountState = {
  type: "web",
  preferences: {},
  contact: {
    type: "default",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    address: {
      company: "",
      streetAddress: "",
      country: "",
      state: "",
      zip: "",
      city: "",
    },
    ...defaultFlags,
  },
  billingContact: {
    type: "billing",
    sameAsDefaultContact: true,
    ...defaultFlags,
  },
  shippingContact: {
    type: "shipping",
    sameAsDefaultContact: true,
    ...defaultFlags,
  },
};

const setValueWithAddress = <T extends AccountStateContact>(
  useContact: T,
  payload: Partial<AccountStateContact>
) => {
  let contact = useContact;
  Object.keys(payload).forEach((key) => {
    if (key === "address" && payload.address) {
      const addressKeys = Object.keys(payload.address);
      let address = contact.address || {};
      addressKeys.forEach((addressKey) => {
        if (payload.address) {
          address[addressKey] = payload.address[addressKey];
        }
      });

      contact.address = address;
    } else {
      contact[key] = payload[key];
    }
  });

  return contact;
};

interface InitAccountPreferencesRequest {
  site: string;
  options: Array<AccountStatePreferenceOption>;
}
interface InitAccountPreferencesResponse {
  preferences: Record<string, any>;
  preferenceOptions: Array<AccountStatePreferenceOption>;
}
export const initAccountPreferences = createAsyncThunk<
  InitAccountPreferencesResponse | null,
  InitAccountPreferencesRequest,
  { state: RootState; extra: Extra }
>(
  "account/initAccountPreferences",
  async ({ site, options }, { dispatch, rejectWithValue, getState }) => {
    try {
      if (site !== "STS") return null;
      dispatch(getAccessToken());
      const {
        access: { token },
      } = getState();

      const {
        data: { contact: _contact, ...preferences },
      } = await axiosServer.get(preferencesUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const preferenceOptions = Object.keys(preferences).map((key) => {
        return options.find(({ key: optionKey }) => optionKey == key);
      });

      return {
        preferences,
        preferenceOptions,
      } as InitAccountPreferencesResponse;
    } catch (e) {
      console.error("[slices][account] initAccountPreferences", e);
      return rejectWithValue(e);
    }
  }
);

export const checkContactValidity = createAsyncThunk<
  void,
  string,
  { state: RootState; extra: Extra }
>(
  "account/checkContactValidity",
  async (site, { getState, rejectWithValue }) => {
    try {
      const {
        payment: { paymentProfileEditMode },
        contact: { inspiration },
        account: { contact },
      } = getState();

      await contactSchema.validate({
        ...contact,
        inspiration,
        site,
        paymentProfileEditMode,
      }, {
        abortEarly: false,
      });
    } catch (e) {
      console.error("[slices][account][exception]", e);
      return rejectWithValue(e);
    }
  }
);

export const checkContactTerminalValidity = createAsyncThunk<
  void,
  void,
  { state: RootState; extra: Extra }
>(
  "account/checkContactTerminalValidity",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        account: { contact },
      } = getState();

      await contactTerminalSchema.validate(contact, {
        abortEarly: false,
      });
    } catch (e) {
      console.error("[slices][account][exception]", e);
      return rejectWithValue(e);
    }
  }
);

export const checkBillingContactValidity = createAsyncThunk<
  void,
  void,
  { state: RootState; extra: Extra }
>(
  "account/checkBillingContactValidity",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        account: { billingContact, contact },
      } = getState();

      await contactSchema.validate(
        billingContact.sameAsDefaultContact ? contact : billingContact
      );
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const checkShippingContactValidity = createAsyncThunk<
  void,
  void,
  { state: RootState; extra: Extra }
>(
  "account/checkShippingContactValidity",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        account: { shippingContact, contact },
      } = getState();

      await contactSchema.validate(
        shippingContact.sameAsDefaultContact ? contact : shippingContact
      );
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    disableContactEditMode(state) {
      state.contact.editMode = false
    },
    enableContactEditMode(state) {
      state.contact.editMode = true
    },
    setAccountType(state, { payload }: PayloadAction<"web" | "terminal">) {
      state.type = payload;
    },
    setContactEmail(state, { payload }: PayloadAction<string>) {
      state.contact.email = payload;
    },
    setBillingAddressSameAsContact(state, { payload }: PayloadAction<boolean>) {
      state.billingContact.sameAsDefaultContact = payload;
    },
    setBillingContactData(
      state,
      { payload }: PayloadAction<Partial<AccountStateContact>>
    ) {
      state.billingContact = setValueWithAddress(state.billingContact, payload);
    },
    setShippingAddressSameAsContact(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.shippingContact.sameAsDefaultContact = payload;
    },
    setShippingContactData(
      state,
      { payload }: PayloadAction<Partial<AccountStateContact>>
    ) {
      state.shippingContact = setValueWithAddress(
        state.shippingContact,
        payload
      );
    },
    setContactData(
      state,
      { payload }: PayloadAction<Partial<AccountStateContact>>
    ) {
      state.contact = setValueWithAddress(state.contact, payload);
    },
    setCrmAccountId(state, { payload }: PayloadAction<string>) {
      state.crmAccountId = payload;
    },
    updatePreference(
      state,
      { payload }: PayloadAction<{ key: string; value: string }>
    ) {
      state.preferences[payload.key] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initAccountPreferences.fulfilled, (state, { payload }) => {
      if (payload) {
        const { preferences, preferenceOptions } = payload;
        state.preferenceOptions = preferenceOptions;
        state.preferences = preferences;
      }
    });

    builder.addCase(checkContactValidity.pending, (state) => {
      state.contact.__pristine = false;
    });
    builder.addCase(checkContactValidity.rejected, (state, { payload }) => {
      state.contact.__errors = transformErrorIntoObject(payload);
      state.contact.__valid = false;
      console.error(
        "[slices][account] checkContactValidity.rejected",
        state.contact.__errors
      );
    });
    builder.addCase(checkContactValidity.fulfilled, (state) => {
      state.contact.__errors = [];
      state.contact.__valid = true;
    });

    builder.addCase(checkBillingContactValidity.pending, (state) => {
      state.billingContact.__pristine = false;
    });
    builder.addCase(
      checkBillingContactValidity.rejected,
      (state, { payload }) => {
        state.billingContact.__errors = transformErrorIntoObject(payload);
        state.billingContact.__valid = false;
        console.error(
          "[slices][account] checkBillingContactValidity.rejected",
          state.billingContact.__errors
        );
      }
    );
    builder.addCase(checkBillingContactValidity.fulfilled, (state) => {
      state.billingContact.__errors = [];
      state.billingContact.__valid = true;
    });

    builder.addCase(checkShippingContactValidity.pending, (state) => {
      state.shippingContact.__pristine = false;
    });
    builder.addCase(
      checkShippingContactValidity.rejected,
      (state, { payload }) => {
        state.shippingContact.__errors = transformErrorIntoObject(payload);
        state.shippingContact.__valid = false;
        console.error(
          "[slices][account] checkShippingContactValidity.rejected",
          state.shippingContact.__errors
        );
      }
    );
    builder.addCase(checkShippingContactValidity.fulfilled, (state) => {
      state.shippingContact.__errors = [];
      state.shippingContact.__valid = true;
    });

    builder.addCase(checkContactTerminalValidity.pending, setFlags.pending)
    builder.addCase(checkContactTerminalValidity.rejected, setFlags.rejected)
    builder.addCase(checkContactTerminalValidity.fulfilled, setFlags.fulfilled)
  },
});

export const {
  disableContactEditMode,
  enableContactEditMode,
  setAccountType,
  setBillingAddressSameAsContact,
  setBillingContactData,
  setContactData,
  setContactEmail,
  setCrmAccountId,
  setShippingAddressSameAsContact,
  setShippingContactData,
  updatePreference,
} = accountSlice.actions;
export default accountSlice.reducer;
