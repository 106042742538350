const initialState = {
  gateway: 'Stripe',
  ajsLoaded: false,
  stripeLoaded: false,
  plaidLoaded: false,
  type: 'card',
  bank: {
    routingNumber: '',
    accountNumber: '',
    nameOnAccount: '',
    accountType: 'Individual',
    country: 'US',
    currency: 'USD',
  },
  bankStripe: {
    accountName: '',
    accountEmail: '',
  },
  card: {
    cardNumber: '',
    month: '',
    year: '',
    cardCode: '',
    fullName: '',
  },
  selectedSource: null,
  valid: false,
  errors: [],
  validationError: {},
  confirmedMonthly: true,
  loading: false,
  useSaved: false,
  successInfo: null,
  paymentProfiles: [],
  selectedPaymentProfileId: null,
  billingAddress: {
    firstName: '',
    lastName: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
  },
  billingAddressSameAsContact: true,
  asDefaultProfile: true,
  requestError: null,
  displayValidationError: false,
  includeProcessingFee: true,
  paymentProfileEditMode: false,
  paymentMethodUpdateMessage: null,
  stripeTerminalConfig: {},
  stripeTerminals: [],
  stripeTerminalLocations: [],
  stripeTerminal: null, // selected terminal
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAYMENT_VALID': {
      return {
        ...state,
        valid: action.payload,
        errors: [],
      }
    }
    case 'SET_PAYMENT_ERROR': {
      return {
        ...state,
        errors: action.payload,
        valid: false,
      }
    }
    case 'SET_GATEWAY_PAYMENT': {
      return {
        ...state,
        gateway: action.payload || 'Stripe',
      }
    }
    case 'SET_TYPE_PAYMENT': {
      return {
        ...state,
        type: action.payload,
      }
    }
    case 'SET_BANK_TRANSFER_PAYMENT': {
      return {
        ...state,
        bankTransfer: {
          ...state.bankTransfer,
          ...action.payload,
        }
      }
    }
    case 'SET_VALIDATION_ERROR_PAYMENT': {
      return {
        ...state,
        validationError: {
          ...state.validationError,
          ...action.payload,
        }
      }
    }
    case 'SET_USE_SAVED_PAYMENT': {
      return {
        ...state,
        useSaved: action.payload,
      }
    }
    case 'SELECT_SOURCE_PAYMENT': {
      return {
        ...state,
        selectedSource: action.payload,
      }
    }
    case 'SET_LOADING_PAYMENT': {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case 'SET_CONFIRMED_MONTHLY_PAYMENT': {
      return {
        ...state,
        confirmedMonthly: action.payload,
      }
    }
    case 'SET_PAYMENT_PROFILES_PAYMENT': {
      return {
        ...state,
        paymentProfiles: action.payload,
      }
    }
    case 'SET_BILLING_ADDRESS_PAYMENT': {
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          ...action.payload,
        }
      }
    }
    case 'ENTER_CARD_PAYMENT': {
      return {
        ...state,
        card: {
          ...state.card,
          ...action.payload,
        }
      }
    }
    case 'ENTER_BANK_PAYMENT': {
      return {
        ...state,
        bank: {
          ...state.bank,
          ...action.payload,
        }
      }
    }
    case 'ENTER_BANK_STRIPE_PAYMENT': {
      return {
        ...state,
        bankStripe: {
          ...state.bankStripe,
          ...action.payload,
        }
      }
    }
    case 'SET_BILLING_ADDRESS_SAME_AS_CONTACT_PAYMENT': {
      return {
        ...state,
        billingAddressSameAsContact: action.payload,
      }
    }
    case 'SELECT_PAYMENT_PROFILE_PAYMENT': {
      return {
        ...state,
        selectedPaymentProfileId: action.payload,
      }
    }
    // case 'RESET_PAYMENT': {
    //   return {
    //     ...state,
    //     selectedSource: null,
    //     validationError: {},
    //     useSaved: false,
    //   }
    // }
    case 'DONATION_SUCCESS_PAYMENT': {
      return {
        ...state,
        successInfo: action.payload,
      }
    }
    case 'SET_AJS_LOADED_PAYMENT': {
      return {
        ...state,
        ajsLoaded: action.payload,
      }
    }
    case 'SET_STRIPE_LOADED_PAYMENT': {
      return {
        ...state,
        stripeLoaded: action.payload,
      }
    }
    case 'SET_PLAID_LOADED_PAYMENT': {
      return {
        ...state,
        plaidLoaded: action.payload,
      }
    }
    case 'SET_AS_DEFAULT_PROFILE_PAYMENT': {
      return {
        ...state,
        asDefaultProfile: action.payload,
      }
    }
    case 'SET_REQUEST_ERROR_PAYMENT': {
      return {
        ...state,
        requestError: action.payload,
      }
    }
    case 'DISPLAY_VALIDATION_ERROR_PAYMENT': {
      return {
        ...state,
        displayValidationError: action.payload,
      }
    }
    case 'SET_INCLUDE_PROCESSING_FEE_PAYMENT': {
      return {
        ...state,
        includeProcessingFee: action.payload,
      }
    }
    case 'SET_PAYMENT_PROFILE_EDIT_MODE_PAYMENT': {
      return {
        ...state,
        paymentProfileEditMode: action.payload,
      }
    }
    case 'SET_PAYMENT_METHOD_UPDATE_MESSAGE_PAYMENT': {
      return {
        ...state,
        paymentMethodUpdateMessage: action.payload,
      }
    }
    case 'SET_STRIPE_TERMINAL_CONFIG_PAYMENT': {
      return {
        ...state,
        stripeTerminalConfig: action.payload,
      }
    }
    case 'SET_STRIPE_TERMINALS_PAYMENT': {
      return {
        ...state,
        stripeTerminals: action.payload,
      }
    }
    case 'SET_STRIPE_TERMINAL_LOCATIONS_PAYMENT': {
      return {
        ...state,
        stripeTerminalLocations: action.payload,
      }
    }
    case 'SET_STRIPE_TERMINAL_PAYMENT': {
      return {
        ...state,
        stripeTerminal: action.payload,
      }
    }
    case 'RESET_PAYMENT': {
      const { gateway, ajsLoaded, stripeLoaded, plaidLoaded, selectedPaymentProfileId, includeProcessingFee, ...initialInfo } = initialState
      return {
        ...state,
        ...initialInfo,
        bank: {
          ...initialInfo.bank,
          accountType: state.gateway === 'Authorize.NET' ? 'Checking' : 'Individual',
        },
        ...action.payload,
      }
    }
  }
  return state
}