// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import { setSystemMessage } from 'slices/rootSlice'
import { Alert } from 'react-bootstrap'

@connect(store => ({
  message: store.root.systemMessage,
}), { setSystemMessage })

export default class SystemMessage extends React.Component {

  clearMessage = () => {
    setTimeout(() => this.props.setSystemMessage(''), 3000)
  }

  componentDidUpdate() {
    if (this.props.message) {
      this.clearMessage()
    }
  }

  componentDidMount() {
    if (this.props.message) {
      this.clearMessage()
    }
  }

  render() {
    const { message, style = 'success', setSystemMessage } = this.props
    if (message) {
      return (
        <div className="system-message">
          <Alert
            variant={style}
            onDismiss={() => setSystemMessage('')}
          >
            { message }
          </Alert>
        </div>
      )
    }
    return null
  }
}