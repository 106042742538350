// @ts-nocheck

import React from 'react';
import { Button } from 'react-bootstrap';
import Payment from 'sharedComponents/Payment';
import { methodNames } from 'sharedComponents/Payment/PaymentMethodMenu';
import Spinner from 'sharedComponents/Spinner';
import FundDisclaimer from 'sharedComponents/FundDisclaimer';
import Contact from './sections/Contact';
import Options from './sections/Options';
import { sendGtmPixel } from "modules/gtm";

const steps = [
  'options', 
  'contact', 
  'contact_2',
  'payment_details',
]
import type { DonateProps, DonateState } from './Donate';
/**
 * Donate Now Page
 * 
 * Used as default redirect from /
 * Renders and rewrite to /donate?amount=50&term=monthly on load.
 * 
 */
class DonateBySteps extends React.Component<DonateProps, DonateState> {

  state = {
    step: 'options',
    validated: false,
  }

  validateWithinComponent = () => {
    const { paymentType, donationAmount } = this.props
    const { step } = this.state
    switch (step) {
      case 'options':
        return donationAmount >= 5
      case 'payment_method':
        return !!paymentType
    }
    return true
  }

  validateAndGoNext = (result) => {
    const { errors = [] } = result
    const { step } = this.state
    let validated = true, nextStep = step
    switch (step) {
      case 'payment_details':
        nextStep = 'payment_details'
        validated = !errors.find(e => e.field === 'card.fullName')
        break
      case 'contact':
        nextStep = 'contact_2'
        validated = !errors.find(e => ['inspiration', 'firstName', 'lastName', 'email', 'phone'].includes(e.field))
        break
      case 'contact_2':
        nextStep = 'payment_details'
        validated = !errors.find(e => [
          'address.streetAddress', 'address.city', 'address.state', 'address.zip', 'address.country'
        ].includes(e.field))
    }
    if (validated) {
      this.props.setDisplayValidationError(false)
      this.setState({ 
        validated: false, 
        step: nextStep, 
        forceCheckValidity: false, 
      })
    }
    else {
      this.props.setDisplayValidationError(true)
      this.setState({ 
        validated, 
        forceCheckValidity: false, 
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<DonateProps>, prevState: Readonly<DonateState>, snapshot?: any): void {
    if (this.props.paymentRequestError && !prevProps.paymentRequestError) {
      this.setState({ step: 'payment_details' })
    }
    if (this.state.step !== prevState.step) {
      sendGtmPixel({
        event: 'donate_by_step',
        step: this.state.step,
        prev_step: prevState.step,
      })
      if (steps.indexOf(this.state.step) > steps.indexOf(prevState.step)) {
        this.props.setDisplayValidationError(false)
      }
    }
  }

  componentDidMount(): void {
    sendGtmPixel({
      event: 'donate_by_step',
      step: 'options',
    })
    sessionStorage.setItem('layout', 'by_step')
  }

  render() {
    const {
      customer, 
      getAccessTokenSilently, 
      guestMode, 
      isAdminTerminal,
      loadingCustomer, 
      site, 
      layoutConfig,
      paymentType,
      loading,
    } = this.props
    const { step, forceCheckValidity, validated } = this.state
    const validatedWithinComponent = this.validateWithinComponent()
    
    return (
      <div className='py2 px2 guest-mode' style={{ maxWidth: 540, margin: 'auto'}}>
        { loading && <Spinner mask/> }
        <style>{`#app-root-STS,html { background: white }`}</style>
        {
          step === 'options' ? null : (
            <div>
              <Button 
                variant='light'
                size='lg'
                className='bg-white border-white darkblue'
                style={{ paddingLeft: 0 }}
                onClick={() => {
                  this.setState({ step: steps[steps.indexOf(step) - 1] })}
                }
              >
                <span className='fa fa-arrow-left'/>
              </Button>
              <Button 
                variant='light' 
                size='lg'
                className='bg-white border-white float-right darkblue'
                style={{ paddingRight: 0 }}
                onClick={() => this.setState({ step: 'options' })}
              >
                <span className='fa fa-times-circle-o'/>
              </Button>
            </div>
          )
        }
        <div style={step === 'options' ? null : { display: 'none' }}>
          <Options 
            {...this.props}
            guestMode={true} 
            isAdminTerminal={isAdminTerminal}
            layoutConfig={layoutConfig}
          />
        </div> 
        {/* payment method step is currently skipped and default to CC */}
        {/* <div style={step === 'payment_method' ? null : { display: 'none' }}>
          <PaymentMethodMenu 
            {...this.props}
            layoutConfig={layoutConfig}
            canMakePayment={canMakePayment}
          />
        </div>  */}
        <div style={['contact', 'contact_2'].includes(step) ? null : { display: 'none' }}>
          <Contact
            {...this.props}
            title={step === 'contact_2' ? this.props.layoutConfig.contact.title2 : null}
            guestMode={guestMode} 
            editMode={true} 
            visibleInputs={step === 'contact' ? ['firstName', 'lastName', 'phone', 'email', 'company'] : ['streetAddress', 'country', 'state', 'city', 'zip']}
            campaignMenuVisible={step === 'contact'}
          />
        </div>
        <div style={['payment_details'].includes(step) ? null : { display: 'none' }}>
          <Payment 
            {...this.props}
            getAccessTokenSilently={getAccessTokenSilently} 
            withTitle={!customer && !loadingCustomer} 
            titleText={methodNames[paymentType]}
            guestMode={guestMode} 
            // showFormOnly={step === 'payment_details'}
            showFormOnly={false}
            toastVisible={false}
            updateStripeCanMakePayment={canMakePayment => this.setState({ canMakePayment })}
            sslVisible={true}
            forceCheckValidity={forceCheckValidity}
            checkValidityCallback={(res) => {
              this.validateAndGoNext(res)
            }}
          />
        </div>
        {
          step === 'payment_details' ? null : (
            <>
              <br />
              <Button
                variant='primary'
                className='btn-donate mx-auto full-width'
                disabled={!validatedWithinComponent}
                onClick={() => {
                  if (!validated && [
                    // 'payment_details', 
                    'contact',
                    'contact_2',
                  ].includes(step)) {
                    this.setState({ forceCheckValidity: true })
                  }
                  else if (validatedWithinComponent) {
                    this.setState({ step: steps[steps.indexOf(step) + 1] })
                    this.props.setDisplayValidationError(false)
                  }
                }}
              >
                { step === 'options' ? (layoutConfig.startButtonText || 'Give') : 'Continue' }
              </Button>
              <br /><br />
              <legend className="pre-field" style={{ fontSize: 12 }}>
                <span className="fa fa-lock"/>&nbsp;
                SSL encrypted and secure
              </legend>
            </>
          )
        }
        <FundDisclaimer site={site} guestMode={true}/>
      </div>
    )
  }
}

export default DonateBySteps
