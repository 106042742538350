let anetToken, recaptchaToken

/** 
 * Non Environment based Tokens
 */
recaptchaToken = {
  script_url: 'https://www.google.com/recaptcha/api.js',
}

/** 
 * Prod Tokens
 */
if (import.meta.env.PROD) {
  anetToken = {
    script_url: 'https://js.authorize.net/v1/Accept.js',
    ui_script_url: 'https://js.authorize.net/v3/AcceptUI.js'
  }
} else {
  /** 
   * Development/Sandbox Tokens
   */
  anetToken = {
    script_url: 'https://jstest.authorize.net/v1/Accept.js',
    ui_script_url: 'https://jstest.authorize.net/v3/AcceptUI.js'
  }
}

export {
  anetToken,
  recaptchaToken,
}

