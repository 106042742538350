import account from "../slices/accountSlice";
import charityRun from "../slices/charityRunSlice";
import attendees from "src/slices/attendeesSlice";
import recaptcha from "../slices/recaptchaSlice";
import access from "../slices/accessSlice";
import root from "../slices/rootSlice";
import eventRegistration from "../slices/eventRegistrationSlice"

import admin from "./adminReducers";
import contact from "./contactReducers";
import options from "./optionsReducers";
import payment from "./paymentReducers";
import preferences from "./preferencesReducers";
import report from "./reportReducers";

export default {
  root,
  account,
  charityRun,
  attendees,
  access,
  eventRegistration,
  options,
  contact,
  payment,
  recaptcha,
  report,
  preferences,
  admin,
};
