import type { DefaultStateFlags } from "./@types/defaultFlags";
import {
  transformErrorIntoObject,
} from "modules/validation";

export const defaultFlags: DefaultStateFlags = {
  /**
   * Object is Pristine - Has not been touched yet
   */
  __pristine: true,
  /**
   * Object has ether failed validation or hasn't been attempted yet
   */
  __valid: false,
  /**
   * Any Validation errors
   */
  __errors: [],
};

export const defaultRequiredFlags: Required<DefaultStateFlags> =
  defaultFlags as Required<DefaultStateFlags>;

const errorHandler = (state, { payload }) => {
  state.__errors = transformErrorIntoObject(payload)
  state.__valid = false;
  console.log(
    "[slices][charityRun] checkCharityRunValidity results",
    state.__errors
  )
}

const pristineHandler = (state) => {
  state.__pristine = false;
}

const validHandler = (state) => {
  state.__errors = [];
  state.__valid = true;
}

export const setFlags = {
  pending: pristineHandler,
  rejected: errorHandler,
  fulfilled: validHandler
}