import React from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { Dropdown } from 'react-bootstrap'

export const SignUpButton = ({ saveAppState, email, redirectUri }) => {
  const { loginWithRedirect } = useAuth0()
  const options = {
    authorizationParams: {
      screen_hint: "signup",
      login_hint: email || undefined,
      redirect_uri: redirectUri,
    },
  }
  return (
    <Dropdown.Item
      eventKey={'signup'}
      onClick={async () => {
        saveAppState()
        await loginWithRedirect(options)
      }}
    >
      Sign Up
    </Dropdown.Item>
  )
}