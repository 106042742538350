// @ts-nocheck

import {
    removePaymentProfile, selectPaymentProfile, setAsDefaultProfile, setBillingAddress,
    setBillingAddressSameAsContact, setLoading, setRequestError, setUseSaved
} from 'actions/paymentActions';
import _ from 'lodash';
import { reformatApiAddress } from 'modules/utils';
import React from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import Address from 'sharedComponents/Address';

import type { RootState } from 'src/store'
import type { AppRouteProps, InitialUrlParams } from 'src/types/app'

@connect((store: RootState, props: AppRouteProps) => ({
  customer: store.root.customer,
  recaptchaLoaded: store.root.recaptchaLoaded,
  showDetails: store.root.showDetails,
  contactAddress: store.account.contact, //store.contact.address,
  billingAddress: store.payment.billingAddress,
  paymentProfiles: store.payment.paymentProfiles,
  selectedPaymentProfileId: store.payment.selectedPaymentProfileId,
  useSaved: store.payment.useSaved,
  billingAddressSameAsContact: store.payment.billingAddressSameAsContact,
  asDefaultProfile: store.payment.asDefaultProfile,
  subscription: store.options.donationType === 'monthly' ? store.root.subscription : (
    store.options.donationType === 'yearly' ? store.root.subscriptionYearly : null
  ),
  defaultSource: (store.root.customer || {}).default_source,
  displayValidationError: store.payment.displayValidationError,
  paymentGateway: store.payment.gateway,
  paymentType: store.payment.type,
  donationType: store.options.donationType,
  services: props.services,
}), {
  selectPaymentProfile,
  setLoading,
  setRequestError,
  setUseSaved,
  setBillingAddress,
  setBillingAddressSameAsContact,
  setAsDefaultProfile,
  removePaymentProfile,
})

export default class PaymentProfiles extends React.Component {

  setNextPaymentProfileIdBySubscription = () => {
    const { subscription, selectPaymentProfile, setUseSaved, paymentProfiles } = this.props
    const usedPaymentProfileId = (subscription || {}).paymentGateway === 'Authorize.NET' ? (
      _.get(subscription, ['profile', 'paymentProfile', 'customerPaymentProfileId'])
    ) : (
      _.get(subscription, ['payment', 'id'])
    )
    const nextPaymentProfileId = usedPaymentProfileId || (
      subscription ? paymentProfiles.find(p => subscription.paymentGateway === p.gateway) : (
        paymentProfiles[0].customerPaymentProfileId || paymentProfiles[0].id
      )
    )
    if (nextPaymentProfileId) {
      selectPaymentProfile(nextPaymentProfileId)
      setUseSaved(true)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      contactAddress, paymentProfiles, selectedPaymentProfileId, useSaved, billingAddressSameAsContact, subscription,
      selectPaymentProfile, setUseSaved, setBillingAddress, setBillingAddressSameAsContact, donationType, paymentGateway, paymentType,
    } = this.props
    const flattenedContactAddress = {
      ...contactAddress,
      ...contactAddress.address,
    }
    if (!prevProps.paymentProfiles.length && paymentProfiles.length) {
      this.setNextPaymentProfileIdBySubscription()
    }
    if (!prevProps.useSaved && useSaved) {
      // Switch to use saved: if selected new payment method, need to reset
      // check pp id with subscription
      if (donationType === 'one-time') {
        if (!selectedPaymentProfileId) {
          selectPaymentProfile(paymentProfiles[0].customerPaymentProfileId || paymentProfiles[0].id)
        }
      }
      else {
        this.setNextPaymentProfileIdBySubscription()
      }
    }
    if (prevProps.selectedPaymentProfileId !== selectedPaymentProfileId) {
      if (!selectedPaymentProfileId) {
        setBillingAddress(billingAddressSameAsContact ? contactAddress : null)
      }
      else {
        const selectedPaymentProfile = paymentProfiles.find(p => (p.customerPaymentProfileId || p.id) === selectedPaymentProfileId) || paymentProfiles[0]
        if (selectedPaymentProfile) {
          const nextBillingAddress = reformatApiAddress(selectedPaymentProfile.billTo || {
            name: (selectedPaymentProfile.name || '').trim(),
            line1: selectedPaymentProfile.address_line1 || '',
            city: selectedPaymentProfile.address_city || '',
            state: selectedPaymentProfile.address_state || '',
            postal_code: selectedPaymentProfile.address_zip || '',
            country: selectedPaymentProfile.address_country || '',
            ...(_.pick(selectedPaymentProfile.metadata, ['company', 'phone'])),
          }, selectedPaymentProfile.gateway)
          setBillingAddress(nextBillingAddress)
          const sameAsContact = Object.keys(nextBillingAddress).reduce((acc, cur) => (
            acc && (nextBillingAddress[cur] === flattenedContactAddress[cur])
          ), true)
          setBillingAddressSameAsContact(sameAsContact)
        }
      }
    }
    if (!prevProps.billingAddressSameAsContact && billingAddressSameAsContact) {
      setBillingAddress(flattenedContactAddress)
    }
    // if (!isNaN(selectedPaymentProfileId) && paymentGateway === 'Stripe' && (
    //   prevProps.donationType === 'one-time' && 
    //   donationType === 'monthly'
    // ) || (
    //   // Auto reset when a stripe card is selected for ACH
    //   prevProps.paymentType !== 'bank_ach' && paymentType === 'bank_ach'
    // )) {
    //   selectPaymentProfile(null)
    //   setUseSaved(false)
    // }
    if (prevProps.donationType === 'one-time' && donationType !== 'one-time' && subscription) {
      // one time switched to recurring
      const nextSelectedPaymentProfileId = subscription.paymentGateway === 'Authorize.NET' ? (
        subscription.authorize_net_payment_profile_id__c.split('-')[1]
      ) : subscription.stripe_payment_source_id__c
      const nextSelectedPaymentProfile = paymentProfiles.find(p => (p.customerPaymentProfileId || p.id) === nextSelectedPaymentProfileId) || (
        paymentProfiles.find(p => p.gateway === subscription.paymentGateway)
      )
      if (nextSelectedPaymentProfile) {
        selectPaymentProfile(nextSelectedPaymentProfile.customerPaymentProfileId || nextSelectedPaymentProfile.id)
      }
      else {
        selectPaymentProfile(null)
        setUseSaved(false)
      }
    }
    if (
      prevProps.donationType !== 'one-time' &&
      this.props.donationType === 'one-time' &&
      this.props.paymentProfiles.length &&
      !this.props.selectedPaymentProfileId
    ) {
      const nextPaymentProfile = this.props.paymentProfiles[0]
      const nextPaymentProfileId = nextPaymentProfile.id || nextPaymentProfile.customerPaymentProfileId
      console.log('this.props.selectPaymentProfile(nextPaymentProfileId)', this.props.paymentProfiles)
      this.props.selectPaymentProfile(nextPaymentProfileId)
    }
    if (!this.props.useSaved && prevProps.useSaved) {
      this.props.selectPaymentProfile(null)
    }
  }

  renderPaymentRow = (paymentProfile, numPaymentProfiles) => {
    const { 
      selectedPaymentProfileId, 
      selectPaymentProfile, 
      defaultSource,
    } = this.props
    let isCard, summary, card
    if (paymentProfile.gateway === 'Stripe') {
      const accountType = _.get(paymentProfile, ['metadata', 'account_type'], '')
      isCard = paymentProfile.object === 'card' || paymentProfile.card
      card = _.get(paymentProfile, 'card', paymentProfile)
      summary = isCard ? (
        `Credit card (${card.brand}) XXXX${card.last4} EXP. ${card.exp_month}/${card.exp_year}`
      ) : `Bank account${accountType ? (' (' + accountType + ')') : ''} XXXX${paymentProfile.last4}, holder ${paymentProfile.account_holder_name || 'N/A'}`
      return (
        <Row className="sm-gutters" key={paymentProfile.id}>
          <Col xs={12}>
            <div className="flex">
              <Form.Check type="radio"
                inline
                checked={selectedPaymentProfileId === paymentProfile.id}
                onChange={() => {return}}
                onClick={() => selectPaymentProfile(paymentProfile.id)}
                readOnly
              />
              &nbsp;
              {
                isCard ? (
                  <span>
                    <span>{paymentProfile.brand}</span>
                    &nbsp;
                    <span>{paymentProfile.last4}</span>
                    &nbsp;
                    <span>{`Exp. ${paymentProfile.exp_month}/${paymentProfile.exp_year}`}</span>
                  </span>
                ) : (
                  <span>
                    <span>{`Bank Account${accountType ? (' (' + accountType + ')') : ''}`}</span>
                    &nbsp;
                    <span>{`XXXX${paymentProfile.last4}`}</span>
                    &nbsp;
                    <span>{`(Holder: ${paymentProfile.account_holder_name || 'N/A'})`}</span>
                  </span>
                )
              }
              {
                (defaultSource && defaultSource === paymentProfile.id) ? (
                  <span>&nbsp;(Default)</span>
                ) : null
              }
            </div>
          </Col>
        </Row>
      )
    }
    else {
      isCard = !!paymentProfile.payment.creditCard
      const expiry = isCard ? `EXP. ${paymentProfile.payment.creditCard.expirationDate.slice(5, 7)}/${paymentProfile.payment.creditCard.expirationDate.slice(0, 4)}` : ''
      summary = isCard ? (
        `Credit card (${paymentProfile.payment.creditCard.cardType}) ${paymentProfile.payment.creditCard.cardNumber} ${expiry}`
      ) : `Bank account (${paymentProfile.payment.bankAccount.accountType}) ${paymentProfile.payment.bankAccount.accountNumber}, holder ${paymentProfile.payment.bankAccount.nameOnAccount || 'N/A'}`
      return (
        <Row className="sm-gutters" key={paymentProfile.customerPaymentProfileId}>
          <Col xs={12}>
            <div className="flex">
              <Form.Check type="radio"
                inline
                checked={selectedPaymentProfileId === paymentProfile.customerPaymentProfileId}
                onChange={() => {return}}
                onClick={() => selectPaymentProfile(paymentProfile.customerPaymentProfileId)}
                readOnly
              />
              &nbsp;
              {
                paymentProfile.payment.creditCard ? (
                  <span>
                    <span>{paymentProfile.payment.creditCard.cardType}</span>
                    &nbsp;
                    <span>{paymentProfile.payment.creditCard.cardNumber}</span>
                    &nbsp;
                    <span>{expiry}</span>
                  </span>
                ) : (
                  <span>
                    <span>{`Bank Account (${paymentProfile.payment.bankAccount.accountType})`}</span>
                    &nbsp;
                    <span>{paymentProfile.payment.bankAccount.accountNumber}</span>
                    &nbsp;
                    <span>{paymentProfile.payment.bankAccount.nameOnAccount}</span>
                  </span>
                )
              }
              {
                paymentProfile.defaultPaymentProfile ? (
                  <span>&nbsp;(Default)</span>
                ) : null
              }
            </div>
          </Col>
        </Row>
      )
    }
  }

  renderConfirmDeleteModal = (summary, customerProfileId, customerPaymentProfileId, paymentGateway) => {
    const { removePaymentProfile, getAccessTokenSilently, setLoading, setRequestError, site, services } = this.props
    return (
      <Modal show={true} className={`modal-${site}`}>
        <Modal.Header>Delete Payment Method</Modal.Header>
        <Modal.Body>
          <p><b>{`Please confirm to delete the following payment method:`}</b></p>
          <p>{summary}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="left">
            <Button 
              onClick={() => this.setState({ confirmDeleteModalOpenFor: false })}
            >
              Cancel
            </Button>
            <Button 
              className="btn-donate float-right"
              onClick={async() => {
                try {
                  this.setState({ confirmDeleteModalOpenFor: false })
                  setLoading(true)
                  const recaptchaResponseToken = await window.grecaptcha.execute(services.googleRecaptcha, { action: 'submit' })
                  removePaymentProfile(getAccessTokenSilently, { 
                    customerProfileId,
                    customerPaymentProfileId, 
                    paymentGateway,
                    recaptchaResponseToken,
                  })
                }
                catch(error) {
                  console.error(error)
                  const errorMessage = _.get(error, ['response', 'data', 'error'], _.get(error, ['response', 'data'], error))
                  setLoading(false)
                  setRequestError(errorMessage)
                }
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  componentDidMount() {
    const { paymentProfiles, subscription, selectPaymentProfile, setUseSaved } = this.props
    if (paymentProfiles.length) {
      const usedPaymentProfileId = (subscription || {}).paymentGateway === 'Authorize.NET' ? (
        _.get(subscription, ['profile', 'paymentProfile', 'customerPaymentProfileId'])
      ) : (
        _.get(subscription, ['payment', 'id'])
      )
      const nextPaymentProfile = (
        paymentProfiles.find(p => (p.customerPaymentProfileId || p.id) === usedPaymentProfileId) || (
          subscription ? paymentProfiles.find(p => p.gateway === subscription.paymentGateway) : paymentProfiles[0]
      )) || {}
      const nextPaymentProfileId = nextPaymentProfile.id || nextPaymentProfile.customerPaymentProfileId
      if (nextPaymentProfileId) {
        selectPaymentProfile(nextPaymentProfileId)
        setUseSaved(true)
      }
    }
  }

  render() {
    const {
      showDetails,
      paymentProfiles,
      useSaved,
      selectPaymentProfile,
      setUseSaved,
      customer,
      paymentGateway,
      donationType,
      subscription,
      guestMode,
      layoutConfig,
      billingAddressSameAsContact,
      setBillingAddressSameAsContact,
      billingAddress,
      paymentErrors,
      invalidBillingAddressField,
      visibleInputs,
      displayValidationError,
      setBillingAddress,
    } = this.props
    // Sample of bank account data:
    // bankAccount: {accountType: "checking", routingNumber: "XXXX2610", accountNumber: "XXXX6789",…}
    // accountNumber: "XXXX6789"
    // accountType: "checking"
    // bankName: ""
    // echeckType: "WEB"
    // nameOnAccount: "Ken Xu"
    // routingNumber: "XXXX2610"

    if (!showDetails) {
      return null
    }
    const effectivePaymentProfiles = paymentProfiles.filter(p => 
      (p.payment || (p.gateway === 'Stripe')) &&
      (
        donationType === 'one-time' || !subscription || (
          subscription && (subscription.paymentGateway === p.gateway)
        )
      )
    )
    return (
      <div style={{ paddingBottom: 0 }}>
        <legend>Payment Information</legend>
        {
          effectivePaymentProfiles.length ? (
            <Form.Group>
              <ButtonGroup>
                <Button
                  className={`btn-option ${useSaved ? 'selected' : ''}`}
                  onClick={() => setUseSaved(true)}
                >
                  Use Saved Profiles
                </Button>
                <Button
                  className={`btn-option ${!useSaved ? 'selected' : ''}`}
                  onClick={() => {
                    setUseSaved(false)
                    selectPaymentProfile(null)
                  }}
                >
                  New
                </Button>
              </ButtonGroup>
            </Form.Group>
          ) : null
        }
        <Form.Group>
        {
          useSaved ? (
            effectivePaymentProfiles.length ? (
              effectivePaymentProfiles.map(paymentProfile => this.renderPaymentRow(paymentProfile, effectivePaymentProfiles.length))
            ) : <Form.Group>{'No available payment methods'}</Form.Group>
          ) : null
        }
        </Form.Group>
        {
          customer ? (
            <Form.Group>
              <LinkContainer to="/payment_methods"><a>{'Manage payment methods'}</a></LinkContainer>
            </Form.Group>
          ) : null
        }
        {
          (!guestMode && (layoutConfig?.contact?.billingAddressCheckbox?.visible !== false)) ? (
            <div className='mt1'>
              <Form.Check 
                id="billing-contact"
                className="pre-field flex-children"
                style={{marginTop: '0px'}}
              >
                <Form.Check.Input
                  type='checkbox'
                  checked={billingAddressSameAsContact} 
                  onChange={() => setBillingAddressSameAsContact(!billingAddressSameAsContact)}
                />
                <Form.Check.Label>
                  Billing address matches my Contact Address
                </Form.Check.Label>
              </Form.Check>
              {
                billingAddressSameAsContact ? null : (
                  <>
                  <h5>Billing Address</h5>
                  <Address
                    contactErrors={paymentErrors}
                    contact={{ ...billingAddress, address: billingAddress }}
                    invalidBillingAddressField={invalidBillingAddressField}
                    // address={billingAddress}
                    enterInfo={(field, value) => {
                      setBillingAddress({ [field]: value })
                    }}
                    id="billing-address-edit"
                    displayValidationError={displayValidationError}
                    layoutConfig={layoutConfig}
                    visibleInputs={visibleInputs}
                  />
                  </>
                )
              }
            </div>
          ) : null
        }
      </div>
    )
  }
}