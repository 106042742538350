import React from 'react'
import { Container, Nav, Navbar, Row, Col, } from 'react-bootstrap'
import {
  siteLogoStyles,
} from 'modules/siteContents'

import { HeaderNavigationDropDown } from './HeaderNavigationDropdown'

export const HeaderOPH = (props) => {
  const { site } = props

  return (
    <div id="header">
      <Navbar collapseOnSelect style={{ marginBottom: 0 }}>
        <Container className='oph-header'>
          <div className="navbar-header inline-flex">
            <div className="flex v-center" style={{ height: 86 }}>
              <Navbar.Brand>
                <a href={'https://optionspartners.org/'}>
                  <img 
                  src={`https://optionspartners.org/wp-content/uploads/2023/08/Logo-Presentation.png`}
                  style={siteLogoStyles[site]} />
                </a>
              </Navbar.Brand>
              <Navbar.Toggle />
            </div>
          </div>
        <Navbar.Collapse>
          <Nav className="flex v-center login-nav nav navbar-nav navbar-right">
            <HeaderNavigationDropDown 
              {...props}
            />
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="oph-header header-bg">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <div className='align-middle'>
                <h1>Donate</h1>
                <p>Your financial gift saves lives and brings hope to women and families who are facing an unplanned pregnancy.</p>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <img 
                className='hero-image'
                src={`https://optionspartners.org/wp-content/uploads/2023/02/shutterstock_1904745892-scaled-1.webp`} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}