import React from 'react'
import qs from 'query-string'
import { Dropdown, NavDropdown } from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react"

import { constructRedirectUri } from 'modules/utils'

import { NavLinkContainer } from './NavLinkContainer'
import { LogoutButton } from './LogoutButton'
import { LoginButton } from './LoginButton'
import { SignUpButton } from './SignupButton'

type HeaderNavigationDropDownProps = {
  isAdmin: boolean,
  saveAppState: any,
  search: any,
  email: string,
}

export const HeaderNavigationDropDown = ({ isAdmin, saveAppState, search, email }: HeaderNavigationDropDownProps) => {
  const { isAuthenticated, user } = useAuth0()
  const {
    nickname,
    name
  } = user || { nickname: [''], name: '' }
  const redirectUri = constructRedirectUri(qs.parse(search), location.pathname)

  return (
    <NavDropdown
      id="login-menu"
      className={'avatar-box'}
      align={'end'}
      title={
        <>
        <span className="avatar-box-text">
          {
            user ? (
              <span>{(nickname ? nickname[0] : '').toUpperCase()}</span>
            ) : (
              <span className="fa fa-user helpme-icon-user" style={{width: '100%'}} />
            )
          }
        </span>
        <span className="caret"></span>
        </>
      }
    >
      { isAuthenticated ? <Dropdown.Item><span style={{ cursor: 'default'}}>{`Logged in as: ${(name || '').toUpperCase()}`}</span></Dropdown.Item> : null }
      {
        (isAuthenticated && isAdmin) ? (
          <Dropdown.Item>
            <NavLinkContainer to="/admin" onClick={() => { return }}>
              Admin Zone
            </NavLinkContainer>
          </Dropdown.Item>
        ) : null
      }
      { 
        isAuthenticated ? 
        <LogoutButton saveAppState={saveAppState} redirectUri={redirectUri} /> : 
        <LoginButton saveAppState={saveAppState} email={email} redirectUri={redirectUri} /> 
      }
      { isAuthenticated ? null : <SignUpButton saveAppState={saveAppState} email={email} redirectUri={redirectUri} /> }
    </NavDropdown>
  )
}