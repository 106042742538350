import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate, 
  useParams,
} from "react-router-dom";

import type { Location, NavigateFunction, NavigateOptions, Params } from 'react-router-dom'

type WithRouterStandardizeRouterParams = {
  term?: string, 
  amount?: number, 
  options?: NavigateOptions
}

/**
 * HOC Component for appending React-Router-DOM props
 * 
 * @param Component - Component to utilize Router props
 * @returns Derived component
 */
export const withRouter = (Component) => 
    ((props) => {
      let location = useLocation()
      let navigate = useNavigate()
      let params = useParams()
      let [search, setSearch] = useState<WithRouterStandardizeRouterParams>({})

      /**
       * Standardize Donate Route
       * 
       * There are multiple ways to get to the /donate page. This function
       * ensures that the route is always standardized with respect to the
       * URL Params. Term and Amount will always be defined 
       */
      const standardizeDonateRoute = (term: string, amount: number, options : NavigateOptions = {}) => {
        setSearch({
          term,
          amount,
          options,
        })
      }

      // useEffect(() => {
      //   console.info('location changed', location)
      // }, [location])

      useEffect(() => {
        const urlQuery = new URLSearchParams(location.search);
        if (search.amount) {
          urlQuery.set('amount', search.amount.toString())
        } 
        if (search.term) {
          urlQuery.set('term', search.term)
        }

        if (location.search.replace(/^\?/, '') !== urlQuery.toString()) {
          // console.log('withRouter navigate to donate/', urlQuery.toString())
          navigate(`/donate?${urlQuery.toString()}`, search.options)
        }
      }, [search])

      // Scroll to Top on page changes
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);

      return (
        <Component
          {...props}
          router={{ location, navigate, params, standardizeDonateRoute }}
        />
      )
  })

export type WithRouterProps = {
  router: {
    location: Location,
    navigate: NavigateFunction,
    params: Readonly<Params<string>>
  }
}
