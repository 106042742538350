const initialState = {
  email: "",
  name: "",
  // address: {
  //   firstName: '',
  //   lastName: '',
  //   streetAddress: '',
  //   company: '',
  //   city: '',
  //   state: '',
  //   zip: '',
  //   country: '',
  //   phone: '',
  // },
  // addressId: null,
  inspiration: "",
  message: "",
  campaignId: "",
  campaignCategories: [],
  valid: false,
  errors: [],
  selectedCampaignOption: null,
  validationError: {},
};

export default (currentState = initialState, action) => {
  switch (action.type) {
    case "SET_CONTACT_VALID": {
      return {
        ...currentState,
        valid: action.payload,
        errors: [],
      };
    }
    case "SET_CONTACT_ERROR": {
      return {
        ...currentState,
        errors: action.payload,
        valid: false,
      };
    }
    case "SET_DATA_CONTACT": {
      return {
        ...currentState,
        [action.payload.field]: action.payload.val,
      };
    }
    // case 'SYNC_CUSTOMER_CONTACT': {
    //   const {
    //     address,
    //     email = '',
    //   } = action.payload
    //   return {
    //     ...currentState,
    //     email,
    //     address: {
    //       ...currentState.address,
    //       ...address,
    //     }
    //   }
    // }
    // case 'SET_ADDRESS_ID_CONTACT': {
    //   return {
    //     ...currentState,
    //     addressId: action.payload,
    //   }
    // }
    case "SET_INSPIRATION_CONTACT": {
      return {
        ...currentState,
        inspiration: action.payload,
      };
    }
    case "SET_VALIDATION_ERROR_CONTACT": {
      return {
        ...currentState,
        validationError: {
          ...currentState.validationError,
          ...action.payload,
        },
      };
    }
    case "RESET_CONTACT": {
      const {
        validationError,
        inspiration,
        message,
        campaignId,
        selectedCampaignOption,
        ...initialInfo
      } = initialState;
      return {
        ...currentState,
        ...initialInfo,
        ...action.payload,
      };
    }
  }
  return currentState;
};
