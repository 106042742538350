import React, { useState, useEffect } from 'react'
import { Row, Col, Form, DropdownButton, Dropdown, FloatingLabel } from 'react-bootstrap'
import countryList from 'modules/countryList'
import usStates from 'modules/usStates'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import InputWrapperByShowTitle from 'sharedComponents/InputWrapperByShowTitle'

export const Address = (props) => {
    // // Deprecated props
    // const { address = {}, validationError = {}, guestMode } = props
    //
    const { id, hasPhone = true, hasEmail, contact = {}, enterInfo, displayValidationError, contactErrors, layoutConfig, allowAutoCreatingEmail, invalidBillingAddressField } = props
    const errors = contactErrors || []
    const {
      firstName,
      lastName,
      phone,
      email,
      address: {
        streetAddress,
        company,
        city,
        state,
        zip,
        country
      } = {}
    } = contact
    const { visibleInputs = [
      'firstName', 'lastName', 'phone', 'email', 'company', 'streetAddress', 'country', 'state', 'city', 'zip'
    ] } = props
    const { showInputTitles = false } = layoutConfig?.contact || {}
    const inputSize = layoutConfig?.contact?.inputSize || null

    let countryListFiltered = countryList.filter(c => c.name.toLowerCase().startsWith((country || '').toLowerCase()))

    if (!countryListFiltered.length) {
      countryListFiltered.push({ code: null })
    }


    const contactColumns = hasPhone ? 3 : 2
    
    const countriesThatHaveStates = [
      "Australia",
      "Austria",
      "Brazil",
      "Germany",
      "India",
      "Malaysia",
      "Mexico",
      "Micronesia, Federated States of",
      "Myanmar",
      "New Zealand",
      "Nigeria",
      "Palau",
      "Sudan",
      "United States",
    ]
    const countriesThatHaveZipCode = [
      "United States",
      "Philippines"
    ]

    /**
     * Field Prefix for shared usage and distinct naming
     */
    const fieldPrefix = id.includes('contact') ? 'contact' : 'billingAddress'

    /**
     * Field Invalid handler for 
     *  <Form.Control>
     *  errorClassHandle()
     * 
     * @param field - Field Name
     * @returns true if value is invalid
     */
    const fieldInvalid = (field) => 
      displayValidationError && (
        errors.some(e => e.field.endsWith(field)) || (
          id.startsWith('billing-address') && (field === invalidBillingAddressField)
        )
      )

    /**
     * Error Class handler for adding error class to the column
     * 
     * @param field - Field Name
     * @returns error class to add styling to field 
     */
    const errorClassHandle = (field) => 
      (displayValidationError && 
      fieldInvalid(field))
        ? 'has-error' 
        : ''

    /**
     * Retrieve relative Error Message from validation attempt
     * 
     * @param index - Index of Attendee
     * @param field - Field Name
     * @returns error message to display to user
     */
    const errorMessage = (field) => 
      id.startsWith('billing-address') && (field === invalidBillingAddressField) ? 'Please fill in this field' : (
        errors.find(e => e.field.endsWith(field))?.message[0]
      )

    /**
     * Reset Error from OnChange event
     * 
     * @param event - OnChangeEvent Listener
     */
    const resetError = (event) => {
      event.currentTarget.classList.remove('is-invalid')
      event.currentTarget.parentElement?.classList.remove('has-error')
      event.currentTarget.parentElement?.parentElement?.classList.remove('has-error')
    }

    /**
     * On Change handler for Form.Control
     * 
     * @param event - OnChangeEvent Listener
     */
    const onChangeHandle = (event) => {
      const fieldName = event.target.name.split('.').pop();
      const fieldValue = event.target.value;

      // TODO: validate field on change instead of resetting error
      resetError(event)
      enterInfo(fieldName, fieldValue)
    }

    const [emailDisabled, setEmailDisabled] = useState(false)
    useEffect(() => {
      if (emailDisabled && !email) {
        const emailName = (firstName && lastName) ? `${firstName.toLowerCase()}-${lastName.toLowerCase()}-storks` :
          `visitor-${(new Date()).getTime()}-storks`
        enterInfo('email', `${emailName}@noemail.savethestorks.com`)
      }
    }, [emailDisabled])

    // useEffect(() => {
    //   if (id.startsWith('contact')) {
    //     // contact address
    //     setFormField(contact)
    //   } else {
    //     // billing address
    //     setFormField({
          // firstName: address.firstName,
          // lastName: address.lastName,
          // phone: address.phone,
          // email: address.email,
          // address: {
          //   streetAddress: address.streetAddress,
          //   company: address.company,
          //   city: address.city,
          //   state: address.state,
          //   zip: address.zip,
          //   country: address.country,
          // }
    //     })
    //   }
      
    // }, [id, contact])

    const emailInput = (
      <Col 
        sm={layoutConfig?.contact?.email?.order === 3 ? 12 : 8} 
        className={[errorClassHandle('email'), 'mb-3', visibleInputs.includes('email') ? '' : 'hidden'].join(' ')}
      >
        <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Email">
          <Form.Control
            maxLength={255}
            type="text"
            name={`${fieldPrefix}.email`}
            value={email}
            onChange={e => onChangeHandle(e)}
            placeholder="Email"
            isInvalid={fieldInvalid('email')}
            size={inputSize}
            disabled={emailDisabled}
          />
          <Form.Control.Feedback type="invalid">{errorMessage('email')}</Form.Control.Feedback>
        </InputWrapperByShowTitle>
      </Col>
    )
    const phoneInput = (
      <Col 
        sm={layoutConfig?.contact?.phone?.sm || null} 
        className={[errorClassHandle('phone'), 'mb-3', visibleInputs.includes('phone') ? '' : 'hidden'].join(' ')}
      >
        <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Phone">
          <Form.Control
            maxLength={25}
            type="text"
            name={`${fieldPrefix}.phone`}
            value={phone}
            onChange={e => onChangeHandle(e)}
            placeholder="Phone"
            isInvalid={fieldInvalid('phone')}
            size={inputSize}
          />
          <Form.Control.Feedback type="invalid">{errorMessage('phone')}</Form.Control.Feedback>
        </InputWrapperByShowTitle>
      </Col>
    )

    return (
      <div>
        <Form.Group>
          {
            allowAutoCreatingEmail ? (
              <Row>
                <Col xs={12}>
                  <Form.Check
                    label='Auto create fake email'
                    onClick={() => setEmailDisabled(!emailDisabled)}
                  />
                </Col>
              </Row>
            ) : null
          }
          <Row xs={1} sm={2} md={contactColumns}>
            <Col 
              xs={layoutConfig?.contact?.firstName?.xs || (hasEmail ? 4 : null)} 
              sm={layoutConfig?.contact?.firstName?.sm || (hasEmail ? 4 : null)} 
              className={[errorClassHandle('firstName'), 'mb-3', visibleInputs.includes('firstName') ? '' : 'hidden'].join(' ')}
            >
              <InputWrapperByShowTitle showInputTitles={showInputTitles} title="First Name">
                <Form.Control
                  maxLength={50}
                  type="text"
                  name={`${fieldPrefix}.firstName`}
                  value={firstName}
                  onChange={e => onChangeHandle(e)}
                  placeholder={"First Name"}
                  isInvalid={fieldInvalid('firstName')}
                  size={inputSize}
                />
                <Form.Control.Feedback type="invalid">{errorMessage('firstName')}</Form.Control.Feedback>
              </InputWrapperByShowTitle>
            </Col>
            <Col 
              xs={layoutConfig?.contact?.lastName?.xs || (hasEmail ? 4 : null)} 
              sm={layoutConfig?.contact?.lastName?.sm || (hasEmail ? 4 : null)} 
              className={[errorClassHandle('lastName'), 'mb-3', visibleInputs.includes('lastName') ? '' : 'hidden'].join(' ')}
            >
              <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Last Name">
                <Form.Control
                  maxLength={50}
                  type="text"
                  name={`${fieldPrefix}.lastName`}
                  value={lastName}
                  onChange={e => onChangeHandle(e)}
                  placeholder="Last Name"
                  isInvalid={fieldInvalid('lastName')}
                  size={inputSize}
                />
                <Form.Control.Feedback type="invalid">{errorMessage('lastName')}</Form.Control.Feedback>
              </InputWrapperByShowTitle>
            </Col>
            { hasEmail && (layoutConfig?.contact?.email?.order === 3) ? emailInput : null }
            { hasPhone && (layoutConfig?.contact?.email?.order !== 3) ? phoneInput : null }
          </Row>
          <Row sm={1} md={hasEmail ? 2 : 1}>
            { hasEmail && (layoutConfig?.contact?.email?.order !== 3) ? emailInput : null }
            { hasPhone && (layoutConfig?.contact?.email?.order === 3) ? phoneInput : null }
            <Col 
              xs={layoutConfig?.contact?.company?.xs || (hasEmail ? 4 : null)} 
              sm={layoutConfig?.contact?.company?.sm || (hasEmail ? 4 : null)} 
              className={`mb-3 ${visibleInputs.includes('company') ? '' : 'hidden'}`}
            >
              <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Company" optional={true}>
                <Form.Control
                  maxLength={50}
                  type="text"
                  name={`${fieldPrefix !== 'contact' ? fieldPrefix : `${fieldPrefix}.address`}.company`}
                  value={company}
                  onChange={e => onChangeHandle(e)}
                  placeholder="Company"
                  isInvalid={fieldInvalid('company')}
                  size={inputSize}
                />
                <Form.Control.Feedback type="invalid">{errorMessage('company')}</Form.Control.Feedback>
              </InputWrapperByShowTitle>
            </Col>
          </Row>
          {hasEmail}

          <Row>
            <Col className={[errorClassHandle('streetAddress'), 'mb-3', visibleInputs.includes('streetAddress') ? '' : 'hidden'].join(' ')}>
            <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Street Address">
              <Form.Control
                maxLength={60}
                type="text"
                name={`${fieldPrefix !== 'contact' ? fieldPrefix : `${fieldPrefix}.address`}.streetAddress`}
                value={streetAddress}
                onChange={e => onChangeHandle(e)}
                placeholder="Street Address"
                isInvalid={fieldInvalid('streetAddress')}
                size={inputSize}
              />
              <Form.Control.Feedback type="invalid">{errorMessage('streetAddress')}</Form.Control.Feedback>
              </InputWrapperByShowTitle>
            </Col>
          </Row>

          <Row xs={1} sm={2} md={4}>
            <Col 
              xs={layoutConfig?.contact?.country?.xs || 12} 
              sm={layoutConfig?.contact?.country?.sm || 6} 
              md={layoutConfig?.contact?.country?.md || 4} 
              className={[errorClassHandle('country'), 'mb-3', visibleInputs.includes('state') ? '' : 'hidden'].join(' ')}
            >
              <div className="full-width-dropdown">
                <InputWrapperByShowTitle showInputTitles={showInputTitles} showFloatingLabel={false} title="Country">
                  <Dropdown
                    className={showInputTitles ? "" : "form-dropdown-floating-control"}
                    tabIndex={0}
                    id={`${id}-country-dropdown`}
                  >
                    <DropdownToggle 
                      className={showInputTitles ? "" : "form-floating"}
                      style={showInputTitles ? { paddingTop: 0, paddingBottom: 0, paddingLeft: 0, width: '100%' } : null}
                    >
                      <input 
                        id={`${id}-in-dropdown-input`}
                        className={`in-dropdown-input form-control${fieldInvalid('country') ? ' is-invalid' : ''} ${showInputTitles ? 'form-control-lg' : ''}`}
                        style={showInputTitles ? { paddingLeft: '0.75rem' } : null}
                        name={`${fieldPrefix !== 'contact' ? fieldPrefix : `${fieldPrefix}.address`}.country`}
                        value={country}
                        onChange={e => onChangeHandle(e)}
                        placeholder='Enter or Select'
                        onKeyDown={e => {
                          if (e.key == 'Enter') {
                            const container = document.getElementById(`${id}-country-dropdown`)
                            container.click()
                            e.currentTarget.blur()
                          }
                        }}
                        onKeyUp={e => {
                          if (e.key == ' ') {
                            e.preventDefault()
                          }
                        }}
                        onClick={() => {
                          const container = document.getElementById(`${id}-country-dropdown`)
                          if (!container.parentNode.classList.contains('open')) {
                            container.click()
                          }
                        }}
                      />
                      {
                        showInputTitles ? null : (
                          <label htmlFor={`${id}-in-dropdown-input`}>Country <span>(enter or select)</span></label>
                        )
                      }
                    </DropdownToggle>
                    <DropdownMenu>
                    {
                      countryListFiltered.map(c => (
                        <Dropdown.Item
                          key={c.code}
                          onClick={(e) => {
                            enterInfo('country', c.name.trimStart())

                            const el = document.querySelector(`#${id}-in-dropdown-input`)
                            el.classList.remove('is-invalid')
                            el.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove('has-error')
                            document.querySelector('.invalid-country-feedback').style.display = 'none'
                          }}
                          active={+( c.name === country )}
                          disabled={!c.code}
                        >
                          { c.name || 'No matched countries' }
                        </Dropdown.Item>
                      ))
                    }
                    </DropdownMenu>
                  </Dropdown>
                </InputWrapperByShowTitle>
              </div>
              <div className="invalid-feedback invalid-country-feedback" style={{display: (displayValidationError && fieldInvalid('country')) ? 'block' : 'none'}}>{errorMessage('country')}</div>
            </Col>
            <Col 
              xs={layoutConfig?.contact?.state?.xs || 12} 
              sm={layoutConfig?.contact?.state?.sm || 6} 
              md={layoutConfig?.contact?.state?.md || 4} 
              className={[errorClassHandle('state'), 'mb-3', visibleInputs.includes('state') ? '' : 'hidden'].join(' ')}
            >
              {
                country === 'United States' ? (
                  <div className="full-width-dropdown">
                    <InputWrapperByShowTitle showInputTitles={showInputTitles} title="State">
                      <Form.Select
                        id={`${id}-state-dropdown`}
                        name={`${fieldPrefix !== 'contact' ? fieldPrefix : `${fieldPrefix}.address`}.state`}
                        value={state}
                        onChange={e => onChangeHandle(e)}
                        isInvalid={fieldInvalid('state')}
                        size={inputSize}
                      >
                        {Object.keys(usStates).map(st => (
                          <option
                            key={st}
                            value={st}
                          >
                          { usStates[st] }
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errorMessage('state')}</Form.Control.Feedback>
                    </InputWrapperByShowTitle>
                  </div>
                ) : (
                  <InputWrapperByShowTitle 
                    showInputTitles={showInputTitles} 
                    title={countriesThatHaveStates.includes(country) ? 'State/Region' : 'Province/Region'}
                  >
                    <Form.Control
                      maxLength={40}
                      type="text"
                      name={`${fieldPrefix !== 'contact' ? fieldPrefix : `${fieldPrefix}.address`}.state`}
                      value={state}
                      onChange={e => onChangeHandle(e)}
                      placeholder="State/Province/Region"
                      isInvalid={fieldInvalid('state')}
                      size={inputSize}
                    />
                    <Form.Control.Feedback type="invalid">{errorMessage('state')}</Form.Control.Feedback>
                  </InputWrapperByShowTitle>
                )
              }
            </Col>
            <Col 
              xs={layoutConfig?.contact?.city?.xs || 12} 
              sm={layoutConfig?.contact?.city?.sm || 6} 
              md={layoutConfig?.contact?.city?.md || 2} 
              className={[errorClassHandle('city'), 'mb-3', visibleInputs.includes('city') ? '' : 'hidden'].join(' ')}
            >
              <InputWrapperByShowTitle showInputTitles={showInputTitles} title="City">
                <Form.Control
                  maxLength={40}
                  type="text"
                  name={`${fieldPrefix !== 'contact' ? fieldPrefix : `${fieldPrefix}.address`}.city`}
                  value={city}
                  onChange={e => onChangeHandle(e)}
                  isInvalid={fieldInvalid('city')}
                  placeholder="City"
                  size={inputSize}
                />
                <Form.Control.Feedback type="invalid">{errorMessage('city')}</Form.Control.Feedback>
              </InputWrapperByShowTitle>
            </Col>
            <Col 
              xs={layoutConfig?.contact?.zip?.xs || 12} 
              sm={layoutConfig?.contact?.zip?.sm || 6} 
              md={layoutConfig?.contact?.zip?.md || 2} 
              className={[errorClassHandle('zip'), 'mb-3', visibleInputs.includes('zip') ? '' : 'hidden'].join(' ')}
            >
              <InputWrapperByShowTitle showInputTitles={showInputTitles} title={countriesThatHaveZipCode.includes(country) ? 'Zip Code' : 'Postal Code'}>
                <Form.Control
                  maxLength={20}
                  type="text"
                  name={`${fieldPrefix !== 'contact' ? fieldPrefix : `${fieldPrefix}.address`}.zip`}
                  value={zip}
                  onChange={e => onChangeHandle(e)}
                  isInvalid={fieldInvalid('zip')}
                  placeholder="ZIP/Postal Code"
                  size={inputSize}
                />
                <Form.Control.Feedback type="invalid">{errorMessage('zip')}</Form.Control.Feedback>
              </InputWrapperByShowTitle>
            </Col>
          </Row>
          
        </Form.Group>
      </div>
    )
  // }
}

export default Address