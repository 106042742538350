import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import type { RootState } from 'src/store'
import moment from 'moment-timezone';

type EventBoxProps = {
  campaignId: string
}

export const EventBox = ({ campaignId }: EventBoxProps) => {
  // State: root
  const {
    campaigns
  } = useSelector((state:RootState) => state.root)
  const campaign = campaigns.find(({id}) => campaignId === id)
  const eventContent = campaign?.options?.event?.content
  const [content, setContent] = useState(eventContent)

  const headlineWrapperStyle = { width: '40%', height: 140 }
  const headlineStyle1 = { fontFamily: 'Gotham-Bold, Arial, sans-serif', fontSize: 18, lineHeight: 1, color: 'darkblue' }
  const headlineStyle2 = { fontFamily: 'AustinRegular, Arial, sans-serif', fontSize: 40, color: '#40bfdd' }

  return (
    <div className="event-content-outer trapezoid-box flex v-center left" style={headlineWrapperStyle}>
      <div className="event-content" style={{ width: '100%' }}>
        <div className="event-logo">
          <img src={content.logo} width={content.logoWidth} /> 
        </div>
        <div className="event-content-wrapper">
          <div className="darkblue content-title" style={headlineStyle1}>
            {content.title}
          </div>
          <div className="content-details">
            <p>
              <strong>Date:</strong> {(moment(content.startDate)).format('ddd MMM D YYYY')}<br />
              <strong>Location:</strong> {content.location}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}