import React from 'react'

const campaignGiftImage = {
  '7014o000001VMm5AAG': 'freeshirt_savethestorks.jpg'
}
const campaignGiftText = {
  '7014o000001VMm5AAG': 'Get a free shirt with a monthly gift of $35+'
}

const GiftBanner = ({ cid }) => (
  <div id="gift-banner" name="gift-banner" style={{ display: 'none' }}>
    <div id="gift-banner-p1">
      <h4>{'Free sticker with a gift of any amount!'}</h4>
      <img src={`https://donate.savethestorks.com/images/donatesticker.png`} style={{ maxWidth: '100%' }}/>
    </div>
    <div id="gift-banner-p2">
      <h4>Look what your giving can do!</h4>
      <div className="left item-text">
        <p>YOU are helping create a different story ... one of hope and empowerment for every woman facing an unplanned pregnancy. Your giving reaches women grappling with fear and uncertainty and saves babies’ lives through compassion, education, and holistic care. Thank you.</p>
        <p>Here’s some of what your giving helps accomplish through Stork Buses, state-of-the-art mobile medical clinics:</p>
        <div className="flex v-top">
          <img src="https://donate.savethestorks.com/images/bus_icon.png" className="gift-banner-icon" />
          <div>
            SAVED LIVES
            <br />
            4 out of 5 women who board a Stork Bus choose life for their child.
            <sup>2</sup>
          </div>
        </div>
        <hr />
        <div className="flex v-top">
          <img src="https://donate.savethestorks.com/images/women_icon.png" className="gift-banner-icon"/>
          <div>
            EXCELLENT CARE & COMPASSIONATE SUPPORT
            <br />
            Comprehensive support including pregnancy tests, ultrasounds, STI testing, and ongoing services for moms and babies — all offered at no cost.
          </div>
        </div>
        <hr />
        <div className="flex v-top">
          <img src="https://donate.savethestorks.com/images/speaker_icon.png" className="gift-banner-icon"/>
          <div>
            A CULTURE THAT VALUES LIFE
            <br />
            As the impact of local pregnancy resource centers gets accelerated, the culture around us changes.
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default GiftBanner