import { logger } from "redux-logger"
import refreshAccessMiddleware from './refreshAccessMiddleware'
import siteConfig from "src/sw/site.config"

const extraArgument = {
  site: () => siteConfig()
}

export type Extra = typeof extraArgument
export default (getDefaultMiddleware) => {
  let middlewareChain = getDefaultMiddleware({
      thunk: {
        extraArgument,
      },
      serializableCheck: false 
    }).concat(refreshAccessMiddleware)

  if (import.meta.env.DEV && 
    import.meta.env.VITE_LOG_LEVEL === 'info' && 
    import.meta.env.VITE_REDUX_LOGS === 'on') {
    
    middlewareChain = middlewareChain.concat(logger)
  }

  return middlewareChain
}
