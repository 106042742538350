import React from "react"
import { Button } from "react-bootstrap"

export default ({ year, receiptUrl, receiptHost, layoutConfig }) => layoutConfig?.receiptBanner?.visible === false ? null : (
  <div className="system-message">
    <div className="alert alert-info flex v-center">
      <div className="flex-1">Download your Year-End tax receipt.</div>
      <Button 
        variant="primary"
        onClick={() => window.open(`${receiptHost}/year_receipt/${receiptUrl}`, '_blank').focus()}
        target="_blank"
      >
        Download Now
      </Button>
    </div>
  </div>
)