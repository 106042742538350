import React, { createContext } from 'react'
import type { 
  CanMakePaymentResult, 
  PaymentRequest, 
  PaymentRequestOptions, 
  PaymentRequestPaymentMethodEvent,
  PaymentRequestSourceEvent, 
  PaymentRequestTokenEvent, 
  StripePaymentRequestButtonElementClickEvent,
  Stripe, 
} from '@stripe/stripe-js'


export type StripeContext = {
  //////
  // public to all components
  /////
  loading: boolean,
  checkoutStarted: boolean,
  stripe?: Stripe,
  paymentRequest?: PaymentRequest,

  // https://stripe.com/docs/js/payment_request/create#stripe_payment_request-options
  paymentRequestOptions?: PaymentRequestOptions,
  canMakePayment?: CanMakePaymentResult | null,

  initPaymentRequest?: (update: {
    paymentRequestOptions:  PaymentRequestOptions,
    onPaymentRequestSuccess?: StripeContext['onPaymentRequestSuccess'],
    onPaymentRequestCancel?: StripeContext['onPaymentRequestCancel'],
    onCanMakePayment?: (canMakePayment) => {},
  }) => void,
  updatePaymentRequest?: (options: PaymentRequestOptions, event: StripePaymentRequestButtonElementClickEvent) => any,

  /////
  // only for context internal usage
  /////

  // callbacks
  onPaymentRequestSuccess?: (res: PaymentRequestTokenEvent | PaymentRequestPaymentMethodEvent | PaymentRequestSourceEvent) => {},
  onPaymentRequestCancel?: () => {},
  savePaymentRequestEvent?: (eventType: string, eventResponse: PaymentRequestTokenEvent | PaymentRequestPaymentMethodEvent | PaymentRequestPaymentMethodEvent) => void,

  // payment request event responses
  paymentRequestEvents?: {
    token?: PaymentRequestTokenEvent,
    source?: PaymentRequestSourceEvent,
    paymentMethod?: PaymentRequestPaymentMethodEvent,
  },
}


export const initialStripeContext : StripeContext = {
  loading: true,
  checkoutStarted: false,
}


export const StripeContext = createContext<StripeContext>(initialStripeContext);