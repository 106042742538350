import {
  setInitialAmountType,
  setPlans,
} from "actions/optionsActions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateTotals, useParams, isSponsorship, useMaxRegistrantLimit } from "./utils"
import { defaultRequiredFlags, setFlags } from "../flags";
import { EventRegistrationType } from "../@types/eventRegistrationSlice"
import { validateEventRegistration } from "../validation"

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState, Extra } from "src/store";
import type {
  EventRegistrationAttendee, 
  EventRegistrationAttendeeExtras,
  EventAmountOptions,
  EventRegistrationState,
} from "../@types/eventRegistrationSlice"
import { EventError } from './errors'

/** 
 * Event Registration Initial State and Workflow
 * 
 * Workflow: Event registrations have an incoming Campaign ID
 * by the query param `cid`. This then identifies what type of
 * campaign parameters to set. Once the correct params are set
 * (EventAmountOptions), the state slice would have ready=true,
 * for users to continue with the workflow. 
 * 
 * @example
 *    // VV's Charity Run when ready
 *    eventRegistration: {
 *      campaignId: '7013t0000026segAAA',
 *      type: 'ONE_TIME_FIXED', // enum:EventRegistrationType
 *      options: {  // type:EventAmountOptions
 *        term: 'one-time',
 *        onlyShowTerm: 'one-time',
 *        amount: 35,
 *      },
 *      ready: true,
 *      attendees: [],
 *  
 *      // state flags
 *      __pristine: true,
 *      __errors: [],
 *      __valid: false
 *    }
 */
const initialState: EventRegistrationState = {
  type: EventRegistrationType.REGULAR,
  attendees: [],
  ...defaultRequiredFlags,
};

/** 
 * Set Event as Expired
 * 
 * Only applied when running through setReady function
 * and campaign is ether expired/old or is fully booked.
 */
export const setExpired =  createAsyncThunk<void,void,{ state: RootState }>("eventRegistration/setExpired", async () => {})


/**
 * Set Ready [workflow.onLoad]
 * 
 * Initialize price options for event.
 * 
 * @param packageIndex - the package index number from sponsorship param packages
 * @return the package index number
 */
export const setReady = createAsyncThunk<
  number,
  number,
  { state: RootState; extra: Extra }
>("eventRegistration/setReady", async (packageIndex = -1, { dispatch, getState, rejectWithValue }) => {
  try {
    const eventRegistration : EventRegistrationState = getState().eventRegistration
    await validateEventRegistration('eventRegistration/setReady', eventRegistration)

    const campaign = getState().root.campaigns.find(({id}) => id === eventRegistration.campaignId) || {}
    if (campaign.fullyBooked) {
      throw new EventError(EventError.Type.REGISTRATION_CLOSED)
    }

    const sponsorship = isSponsorship(eventRegistration)
    if (sponsorship && packageIndex < 0) throw new EventError(EventError.Type.UNKNOWN_SPONSORSHIP_ID)

    let {
      amount = 0,
      term,
      onlyShowTerm,
    } = (sponsorship ? eventRegistration.sponsorshipParams?.packages[packageIndex].options : (eventRegistration.options)) || {} 

    await dispatch(setPlans(undefined, undefined, amount.toString()));
    await dispatch(
      setInitialAmountType({
        amount: amount.toString(),
        type: term,
        onlyShowTypes: Array.isArray(onlyShowTerm) ? onlyShowTerm : [onlyShowTerm],
      })
    );

    // finally add one attendee
    if (!eventRegistration.attendees.length)
      await dispatch(addAttendee())

    return packageIndex
  } catch(e) {
    if (e instanceof EventError) {
      switch(e.message) {
        case EventError.Type.REGISTRATION_CLOSED: 
          console.info('[donate] event registration closed') 
          dispatch(setExpired())
          return packageIndex
        default:
      } 
    }
    return rejectWithValue(e)
  }
});

/** 
 * Add Attendee to attendee list [workflow.onLoad-2|workflow.onClick]
 */
export const addAttendee = createAsyncThunk<
  void,
  void,
  { state: RootState; extra: Extra }
>("eventRegistration/addAttendee", async (_, { getState, dispatch }) => {
  const eventRegistration = getState().eventRegistration;
  const {
    attendees,
    // campaignParams,    
    options: {
      amount,
      term,
      onlyShowTerm,
    }
  } = eventRegistration

  const sponsorship = isSponsorship(eventRegistration)
  const maxRegistrants = useMaxRegistrantLimit(eventRegistration) || 0

  if (attendees.length > 0 &&
    (attendees.length < maxRegistrants || maxRegistrants === 0) && !sponsorship) {
    const computedAmount = ((attendees.length + 1) * amount).toFixed(2)

    // add another attendee with fixed pricing to start
    dispatch(setPlans(undefined, undefined, computedAmount));
    dispatch(
      setInitialAmountType({
        amount: computedAmount,
        type: term,
        onlyShowTypes: Array.isArray(onlyShowTerm) ? onlyShowTerm : [onlyShowTerm],
      })
    );
  }
});

/** 
 * Remove Attendee from attendee list [workflow.onClick]
 * 
 * @param removeIndex - the attendee index number to remove
 * @returns the removed index number
 */
export const removeAttendee = createAsyncThunk<
  number,
  number,
  { state: RootState; extra: Extra }
>("eventRegistration/removeAttendee", async (removeIndex, { getState, dispatch }) => {
  const {
    eventRegistration: {
      attendees,
      options: {
        amount,
        term,
        onlyShowTerm,
      }
    },
  } = getState();

  if (attendees.length !== 1) {
    const computedAmount =  ((attendees.length - 1) * amount).toFixed()

    dispatch(setPlans(undefined, undefined, computedAmount));
    dispatch(
      setInitialAmountType({
        amount: computedAmount,
        type: term,
        onlyShowTypes: Array.isArray(onlyShowTerm) ? onlyShowTerm : [onlyShowTerm],
      })
    );
  }

  return removeIndex;
});

/** 
 * Validate Event Registration State [workflow.onSubmit]
 */
export const validateEventRegistrationState = createAsyncThunk<
  void,
  void,
  { state: RootState; extra: Extra }
>("eventRegistration/validateEventRegistrationState", async (_, { getState, dispatch, rejectWithValue }) => {
  const {
    eventRegistration,
  } = getState();

  try {
    await validateEventRegistration('eventRegistration/validateEventRegistrationState', eventRegistration)

  } catch(e) {
    return rejectWithValue(e)
  }
})


/**
 * Event Registration Slice Setup
 */
const eventRegistrationSlices = createSlice({
  name: "eventRegistration",
  initialState,
  reducers: {
    /** 
     * Set Campaign ID and related details
     * 
     * @param {string} campaignId - Campaign ID
     */
    setCampaignId(state, { payload: { cid, campaigns } }: PayloadAction<{ cid: string; campaigns: any }>) {
      let term: EventAmountOptions['term']

      let campaign = campaigns.find(c => (c.id === cid))
      if (campaign) {
        if (campaign.options?.event) {
          let eventOptions = campaign.options.event
          term = eventOptions.term;
          state.campaignId = cid;
          state.type = EventRegistrationType[eventOptions.type];
          state.campaignParams = eventOptions.campaignParams;
          state.options = eventOptions.options;
          state.sponsorshipParams = eventOptions.sponsorshipParams;
        }
        else {
          console.info('[donate] no event options', cid);
        }
      }
      else {
        throw new EventError(EventError.Type.UNKNOWN_CAMPAIGN_ID)
      }
    },
    /**
     * Set Campaign workflow as Sponsorship type
     * 
     * Adds to existing event campaign type as sponsorship
     */
    setSponsorship(state) {
      if (Array.isArray(state.type) && !state.type.includes(EventRegistrationType.SPONSORSHIP)) {
        state.type.push(EventRegistrationType.SPONSORSHIP)
      } 
      else if (!Array.isArray(state.type) && state.type !== EventRegistrationType.SPONSORSHIP) {
        state.type = [state.type, EventRegistrationType.SPONSORSHIP]
      }
    },
    /**
     * Update Attendee in Attendee List
     * and then update totals for checkout
     * 
     * @param EventTarget - name,value pair of event target for updating attendee 
     */
    updateAttendee(
      state,
      {
        payload: [index, target],
      }: PayloadAction<[number, { name: string; value: any }]>
    ) {
      state.attendees[index][target.name] = target.value;
      if (!isSponsorship(state)) {
        updateTotals(state)
      }
    },
    /**
     * Check checkout totals
     */
    updateCheckout(state) {
      updateTotals(state)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setExpired.fulfilled, (state) => { state.expired = true })
    builder.addCase(setReady.rejected, (state) => { state.ready = false })
    builder.addCase(setReady.fulfilled, (state, { payload }: PayloadAction<number>) => {
      if (payload > -1) state.sponsorshipPackageIndex = payload
      state.ready = true 
    })

    builder.addCase(addAttendee.fulfilled, (state) => {
      let attendee: Partial<EventRegistrationAttendee & EventRegistrationAttendeeExtras> = {}
      const { fields } = useParams(state)
      const maxRegistrants = useMaxRegistrantLimit(state) || 0
      fields?.required.forEach(field => {
        attendee[field] = ''
      })
      fields?.optional?.forEach(field => {
        attendee[field] = ''
      })
      fields?.hidden?.forEach(field => {
        attendee[field] = ''
      })
      
      if (state.attendees.length < maxRegistrants || maxRegistrants === 0) {
        state.attendees.push(attendee as EventRegistrationAttendee & EventRegistrationAttendeeExtras)
      }
      if (!isSponsorship(state)) {
        updateTotals(state)
      }
    })
    builder.addCase(removeAttendee.fulfilled, (state, { payload }: PayloadAction<number>) => {
      state.attendees.splice(payload, 1);
      if (!isSponsorship(state)) {
        updateTotals(state)
      }
    });
    
    builder.addCase(validateEventRegistrationState.pending, setFlags.pending)
    builder.addCase(validateEventRegistrationState.rejected, setFlags.rejected)
    builder.addCase(validateEventRegistrationState.fulfilled, setFlags.fulfilled)
  },
});

export const {
  setCampaignId,
  setSponsorship,
  updateAttendee,
  updateCheckout,
} = eventRegistrationSlices.actions;
export default eventRegistrationSlices.reducer;
