// @ts-nocheck
// cSpell:ignore Recaptcha, grecaptcha, autohide, hideicon //

import {
    setTypePayment, updatePaymentMethod
} from 'actions/paymentActions';
import _ from 'lodash';
import {
    calculateProcessingFee
} from 'modules/utils';
import React from 'react';
import { Button, ButtonGroup, Col, FloatingLabel, Form, Modal, Row, Table, Toast, ToastContainer } from 'react-bootstrap';
import { connect } from 'react-redux';
import Spinner from 'sharedComponents/Spinner';
import { withRouter } from 'src/routes/WithRouter';


import { StripeProvider, StripeContext } from '../../providers/StripeProvider';

import type { ConnectedProps } from 'react-redux'
import type { RootState } from 'src/store'
import type { AppRouteProps } from 'src/types/app'
import type { Stripe } from '@stripe/stripe-js'
import { donationTypeNames } from 'modules/siteContents';

export const methodNames = {
  card: 'Credit Card',
  bank: 'Bank Account',
  bank_ach: 'Checking or Savings',
  bank_ach_stripe: 'Bank Account',
  applePay: 'Apple Pay',
  googlePay: 'Google Pay',
}
const paymentMethodImageUrls = {
  card: 'card_icon.svg',
  bank: 'bank_icon.svg',
  bank_ach: 'bank_icon.svg',
  bank_ach_stripe: 'bank_icon.svg',
  applePay: 'apple_pay_icon.svg',
  googlePay: 'google-pay-mark.png',
}
const plaidSupportedSites = [] // Plaid is disabled now
const anetSupportedSites = ['STS']

const mapState = (store: RootState, ownProps: AppRouteProps) => {
  const { services, sandbox } = ownProps
  if (!services || !sandbox) console.error('[shared][payment] service error: not available')
  const selectedPaymentProfile = (store.payment.useSaved && store.payment.selectedPaymentProfileId) ? (
    store.payment.paymentProfiles.find(p => (p.id || p.customerPaymentProfileId) === store.payment.selectedPaymentProfileId) || null
  ) : null
  const processingFee = calculateProcessingFee(store.options.donationAmount, selectedPaymentProfile, store.payment.type)

  return {    
    type: store.payment.type,
    services,
    sandbox,
  }
}

const actionCreators = {
  setTypePayment,
}

const connector = connect(mapState, actionCreators)
type PaymentProps = ConnectedProps<typeof connector> & AppRouteProps & { stripe: Stripe }
interface PaymentState {}

class PaymentMethodMenu extends React.Component<PaymentProps, PaymentState> {
  static contextType = StripeContext;
  state: PaymentState 

  render() {
    const {
      type,
      setTypePayment,
      site,
      services,
      callback,
      layoutConfig,
      donationType,
      donationAmount,
      canMakePayment: appleGoogleSupported = { applePay: false, googlePay: false, link: false },
    } = this.props

    if (!services) return <Spinner />

    let paymentMethodKeys = [
      'card', 'bank_ach_stripe', 
      ...(anetSupportedSites.includes(site) ? ['bank_ach'] : []),
      ...(appleGoogleSupported ? ['applePay', 'googlePay'].filter(v => appleGoogleSupported[v]) : [])
    ]

    const paymentMethodMenuEntries = paymentMethodKeys ? paymentMethodKeys.map(t => (
      <Col
        xs={6}
        key={t}
        className="payment-method-entry"
      >
        <div className="flex v-center full-width">
          <Button 
            className={`flex-1 v-center center ${t === type ? 'btn-donate' : ''}`}
            onClick={() => {
              if (t !== type) {
                setTypePayment(t)
                if (callback) {
                  callback()
                }
              }
            }}
          >
            <img src={`https://donate.savethestorks.com/images/${paymentMethodImageUrls[t]}`} className="payment-method-icon"/>
            <span className="payment-method-text">
              {methodNames[t]}
            </span>
          </Button>
        </div>
      </Col>
    )) : null

    return (
      <Row className="payment">
        <Col xs={12}>
          <h3 className='donate-headline' style={layoutConfig?.paymentMethodMenu?.titleStyle || null}>
            { layoutConfig?.paymentMethodMenu?.title || 'Choose Payment Method'}
          </h3>
        </Col>
        { paymentMethodMenuEntries }
        <Col xs={12} className='center mt3'>
          <div className="mb2" style={{ fontSize: 16, letterSpacing: '.03em' }}>
            {`${donationTypeNames[donationType]} Gift Amount: ${ Number(donationAmount) ? (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(donationAmount)) : 'N/A'}`}
          </div>
        </Col>
      </Row>
    )
  }
}

export default connector(withRouter((props) => {
  return (
    <PaymentMethodMenu {...props} />
  )
}))