import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import content from '../EventRegistration/content'
import { Badge, Button, Form, Col } from 'react-bootstrap'
import { calculateProcessingFee } from 'modules/utils'
import { donationTypeNames } from 'modules/siteContents'
import {
  setDonationType,
  setDonationAmount,
} from 'actions/optionsActions'

import { setIncludeProcessingFee } from 'actions/paymentActions'
import { EventRegistrationType } from 'slices/@types/eventRegistrationSlice'
import { useParams, isSponsorship } from 'slices/eventRegistrationSlice/utils'

import type { RootState } from 'src/store'

export default () => {
  const dispatch = useDispatch<any>()

  // State: eventRegistration
  const {
    campaignId,
    sponsorship,
    params: {
      exceptionsApply,
      discountsApply,
      discountsApplyToAll,
    },
    checkout,
    type: eventRegistrationType,
  } = useSelector(({eventRegistration}:RootState) => ({
    ...eventRegistration,
    params: useParams(eventRegistration),
    sponsorship: isSponsorship(eventRegistration)
  }))
  // State: options
  const {
    donationType,
  } = useSelector(({ options }:RootState) => options)
  // State: payment
  const {
    includeProcessingFee,
    type: paymentType,
  } = useSelector(({ payment }: RootState) => payment)
  // State: root
  const {
    campaigns
  } = useSelector((state:RootState) => state.root)
  const campaign = campaigns.find(({id}) => campaignId === id)
  const campaignContent = campaign?.options?.event?.content

  // On Load
  useEffect(() => {
    if (donationType !== 'one-time') {
      dispatch(setDonationType('one-time'));
    }
  }, [])

  // UI Vars
  const processingFee = calculateProcessingFee(checkout.totalAmount, null, paymentType)
  const roundedUp = (Number(checkout.totalAmount) + Number(processingFee)).toFixed(2)

  // update final donation amount (commented due to twice adding fee amount)
  // useEffect(() => {
  //   dispatch(setDonationAmount(includeProcessingFee ? (checkout.totalAmount + processingFee).toFixed(2) : checkout.totalAmount))
  // }, [checkout.totalAmount, includeProcessingFee])

  const renderButtons = () => {
    const eventType = Array.isArray(eventRegistrationType) ? eventRegistrationType[0] : eventRegistrationType

    switch(eventType) {
      case EventRegistrationType.ONE_TIME_FIXED_TIERED_DISCOUNT:
        const numberOfAttendees = checkout.lineCount
        const finalDonationAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
          includeProcessingFee ? (checkout.totalAmount + processingFee).toFixed(2) : checkout.totalAmount,
        )

        return (
          <div className="options-custom">
          <Form.Group id="donate-type-container">
            <div className="flex pb-3 options-custom-donate-type">
              <Button className={`flex-1 btn-option selected`}>
                {donationTypeNames[donationType]} Donation of {finalDonationAmount}
              </Button>
            </div>
          </Form.Group>
          
          <Form.Group className={`flex flex-wrap options-custom-donate-amount`}>
            {!sponsorship && (
              <Col>
                <Button className={`full-width btn-option ${numberOfAttendees > 0 ? 'selected' : ''}`}>
                  {(discountsApply || discountsApplyToAll) && (
                    checkout.totalDiscountAmount > 0 ? (
                      <>
                      <s>${checkout.subtotalAmount}</s> ${checkout.subtotalAmount - checkout.totalDiscountAmount} &nbsp;
                      </>
                    ): `$${checkout.subtotalAmount} `
                  )}
                  {/* ${checkout.subtotalAmount}&nbsp;&nbsp; */}
                  <Badge bg="dark">{numberOfAttendees} Registrant{numberOfAttendees > 1 ? 's' : ''}</Badge>
                </Button>
              </Col>
            )}
            {exceptionsApply && (
              <Col>
                <Button className={`full-width btn-option ${checkout.totalExceptionCount > 0 ? 'selected' : ''}`}>
                  ${checkout.totalExceptionSubtotalAmount}&nbsp;&nbsp;
                  <Badge bg="dark">{checkout.totalExceptionCount} {campaignContent?.exceptionLabel || ''}{checkout.totalExceptionCount > 1 ? 's' : ''}</Badge>
                </Button>
              </Col>
            )}
            <Col style={{fontSize: '0.9em', paddingLeft: '20px', paddingTop: '17px'}}>
              {/* Amount per Contact: ${amount}<br />
              {exceptionsApply && (
                <>
                  Amount per {campaignContent.exceptionLabel}: ${checkout.totalExceptionAmount || 0}<br />
                </>
              )} */}
              {(discountsApply || discountsApplyToAll) && (
                <>
                  Discounts Applied: ${checkout.totalDiscountAmount.toFixed(2)}<br />
                </>
              )}
            </Col>
          </Form.Group>
          </div>
        )
      default:
        return (<></>)
    }
  }

  return (
    <section className='options'>
      <div>
        <h3 className='donate-headline'>Your Donation</h3>
        {campaignContent && (
          <p style={{whiteSpace: "pre-wrap"}}>
            {campaignContent.description}
          </p>
        )}
      </div>

      {eventRegistrationType && renderButtons()}
      
      <div>
        <Form.Check
          id="round-up"
          className="pre-field flex-children"
        >
          <Form.Check.Input
            type='checkbox'
            defaultChecked={includeProcessingFee} 
            onChange={() => dispatch(setIncludeProcessingFee(!includeProcessingFee))}
          />
          <Form.Check.Label>
            <div className="flex v-center">
              {`Cover processing fees by rounding up my donation to $${roundedUp}`}
            </div>
          </Form.Check.Label>
        </Form.Check>
      </div>
    </section>
  )
}