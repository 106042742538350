type EventFields = {
  name: string,
  placeholder: string,
  saveTo?: string,
  exception?: boolean,
  required?: boolean,
  prefix?: string,
} & (
  {
    type: 'select',
    allowToSpecify?: string,
    allowToSpecifyLabel?: string,
    allowToSpecifyOption?: string,
    selected?: string,
    options: Array<{label: string, value: string, disabled?: boolean}>
  } | 
  { 
    type: 'tel' | 'text', 
    pattern?: string
  } | 
  {
    type: 'checkbox'
  }
)

export const fields : Array<EventFields> = [
  {
    name: 'firstName',
    placeholder: 'First Name',
    type: 'text',
  },
  {
    name: 'lastName',
    placeholder: 'Last Name',
    type: 'text',
  },
  {
    name: 'shirtSize',
    placeholder: 'Shirt Size',
    type: 'select',
    selected: 'xs',
    options: [
      // { label: 'X-Small', value: 'xs' },
      { label: 'Small', value: 's' },
      { label: 'Medium', value: 'm' },
      { label: 'Large', value: 'l' },
      { label: 'X-Large', value: 'xl' },
      { label: 'XX-Large', value: 'xxl' },
      { label: 'XXX-Large', value: 'xxxl' },
    ]
  },
  {
    name: 'foodAllergies',
    placeholder: 'Do you have any food allergies?',
    type: 'select',
    allowToSpecify: 'other',
    allowToSpecifyLabel: 'Please specify',
    allowToSpecifyOption: 'yes',
    selected: '',
    options: [
      { label: '', value: '', disabled: true },
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },
  {
    name: 'email',
    placeholder: 'Email',
    type: 'text',
  },
  {
    name: 'phone',
    placeholder: 'Phone Number',
    type: 'tel',
    pattern: '[0-9]{3}-[0-9]{2}-[0-9]{3}',
  },
  {
    name: 'foodOnly',
    placeholder: 'Food Only',
    type: 'checkbox',
    exception: true,
  }
]

type UseFieldsParams = {
  fields: {
    required: Array<string>, 
    prefix?: string,
    optional?: Array<string>,
    hidden?: Array<string>,
  },
  flags: {
    __pristine?: boolean,
    __errors?: Array<any>,
  }
}

export const useFields = (options: UseFieldsParams) => {
  const prefix = options.fields.prefix || 'attendees'
  const displayErrors = options.flags.__pristine || true
  const errors = options.flags.__errors || []

  const mergedFields = [
    // all required fields
    ...options.fields.required
      .map(requiredField => {
        let field = fields.find(field => field.name === requiredField)
        
        if (field) {
          field.required = true
          field.prefix = prefix
        }

        return field
      }),
    // all optional fields
    ...(options.fields.optional || [])
      .map(optionalFields => {
        let field = fields.find(field => field.name === optionalFields)

        if (field) {
          field.prefix = prefix
        }

        return field
      })
  ]

  const invalidField = (index, field) => errors.some(e => e.field === `${prefix}[${index}].${field}`)
  const errorClass = (index, field) => displayErrors && invalidField(index, field) ? 'has-error' : ''
  const errorMessage = (index, field) => errors.find(e => e.field === `${prefix}[${index}].${field}`)?.message[0]
  const resetError = (event) => {
    event.currentTarget.classList.remove('is-invalid')
    event.currentTarget.parentElement?.parentElement?.classList.remove('has-error')
  }

  return {
    fields: mergedFields,
    handles: {
      errorClass,
      errorMessage,
      invalidField,
      resetError,
    }
  }
}