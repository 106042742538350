import React, { useEffect, useState, createRef, useRef } from 'react';
import { Button, ButtonToolbar, Col, FloatingLabel, Form, Row, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFields } from './fields'
import { isFulfilled } from '@reduxjs/toolkit'

import { 
  updateAttendee,
  removeAttendee,
  addAttendee,
  setReady, 
  setExpired,
} from 'slices/eventRegistrationSlice';
import { useParams, useMaxRegistrantLimit, isSponsorship } from 'slices/eventRegistrationSlice/utils';
import { 
  getCampaigns
} from 'slices/rootSlice'

import type { FunctionComponent, ElementType } from 'react'
import type { EventRegistrationState } from 'slices/@types/eventRegistrationSlice'
// import type { CharityRunContact } from 'slices/@types/charityRunSlice'
import type { RootState, AppDispatch } from 'src/store'

/** 
 * Event Registration Form
 */
export const RegistrationForm : FunctionComponent<{}> = () => {
  const dispatch = useDispatch<any>()
  const selectRefs = useRef<Array<string>>([])
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    show: boolean,
    label?: string,
    index?: number,
  }>({show: false})

  // const eventRegistration = useSelector((state: RootState) => state.eventRegistration) 
  // const [maxRegistrants, setMaxRegistrants] = useState(0)

  const {
    campaignId,
    expired,
    params,
    maxRegistrants,
    // type,
    // options,
    campaignParams,
    attendees, 
    sponsorshipPackageIndex,
    sponsorship,
    __pristine,
    __errors,
  }: EventRegistrationState & { params: any, maxRegistrants: number, sponsorship: boolean } = useSelector((state: RootState) => ({
    ...state.eventRegistration,
    params: useParams(state.eventRegistration),
    maxRegistrants: useMaxRegistrantLimit(state.eventRegistration, state.eventRegistration.sponsorshipPackageIndex),
    sponsorship: isSponsorship(state.eventRegistration)
  }))

  const {
    displayValidationError
  } = useSelector((state:RootState) => state.payment)

  const {
    campaigns
  } = useSelector((state:RootState) => state.root)
  const campaign = campaigns.find(({id}) => campaignId === id)
  const eventContent = campaign?.options?.event?.content

  const {
    fields,
  } = params || {fields:{required:[]}}

  const {
    fields: formFields, 
    handles: { 
      errorClass,
      errorMessage,
      invalidField,
      resetError,
    }} = useFields({
    fields,
    flags: {
      __errors,
      __pristine: __pristine && displayValidationError,
    }
  })

  // useEffect(() => {
  //   setMaxRegistrants(useMaxRegistrantLimit(eventRegistration, eventRegistration.sponsorshipPackageIndex) as number)
  // }, [eventRegistration.sponsorshipPackageIndex])
  
  /**
   * On Change handler for Form.Control
   * 
   * @param event - OnChangeEvent Listener
   * @param index - Index of Attendee
   */
  const onChangeHandle = (event: React.ChangeEvent<any>, index: number) => {
    // TODO: validate field on change instead of resetting error
    resetError(event)
    dispatch(updateAttendee([index, event.target]))
  }

  /**
   * On Change handler for Form.Check
   * 
   * @param event - OnChangeEvent Listener
   * @param index - Index of Attendee
   */
  const onChangeCheckHandler = (event: React.ChangeEvent<any>, index: number) => {
    resetError(event)
    dispatch(updateAttendee([index, {name: event.target.name, value: event.target.checked ? true : false }]))
  }

  // Field Configuration for Attendee
  // - split fields into chunks of 3, so we can display
  //   3 fields per row MD size screens and larger
  const rowFieldMax = 3
  const rowFields = formFields.reduce((all:any,one:any,i) => {
    const ch = Math.floor(i/rowFieldMax); 
    all[ch] = [].concat((all[ch]||[]),one); 
    return all
  }, [])

  // For Delete Attendee Confirmation Modal
  // - Only confirm if a value has been entered into any of the fields,
  //   otherwise delete the attendee automatically
  const handleModalClose = () => setDeleteConfirmation({ show: false })
  const handleModalShow = (label: string, index: number) => {
    const attendee = attendees[index]
    let showDelete = false
    Object.keys(attendee).forEach(field => {
      if (!showDelete && attendee[field] !== '') {
        showDelete = true
      }
    })

    if (showDelete) {
      setDeleteConfirmation({
        show: true,
        label,
        index,
      })
    } else {
      dispatch(removeAttendee(index))
    }
  };

  // 
  // Only render if event has expired or is full
  if (isFulfilled(setExpired) && expired) {
    return (
      <section className={`campaign campaign-event campaign-${campaign.id} campaign-status-${campaign.status.toLowerCase().replace(' ','-')}`}>
        <h3>Registrations for <span className="accent-header">{campaign.public_name__c}</span> have closed</h3>
        {eventContent?.eventUrl && (
          <Button 
            variant='primary'
            className="v-center"
            onClick={() => window.location.href = eventContent?.eventUrl}
          >Go Back to Event Page</Button>
        )}
      </section>
    )
  }

  // 
  // Only render after:
  // - Campaigns have been retrieved from the API
  // - Campaign ID has been set and the ready flag 
  //   is set to true
  return isFulfilled(getCampaigns, setReady) && campaign && (
    <section className={`campaign campaign-event campaign-${campaign.id} campaign-status-${campaign.status.toLowerCase().replace(' ','-')}`}>
      <h3>{sponsorship ? `Sponsor` : `Join`} <span className="accent-header">{campaign.public_name__c}</span></h3>
      <div className="flex v-center sub-heading">{campaign.description}</div>
      <div className="event-registration-form">

      <Modal show={deleteConfirmation.show} onHide={handleModalClose}>
        <Modal.Body>Are you sure you want to remove <strong>{deleteConfirmation.label}</strong>?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={handleModalClose}>
            No
          </Button>
          <Button variant="primary" onClick={() => deleteConfirmation.index && dispatch(removeAttendee(deleteConfirmation.index)) && handleModalClose()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

        {attendees.map((attendee, index: number) => {
          const rowTitle = index === 0 ? '' : `Attendee ${index + 1}`

          return (
            <div key={`attendee-${index}`}>
              <Row>
                <Col xs={12}>
                  {rowTitle}&nbsp;&nbsp;
                  
                  {index !== 0 && (
                    <Button 
                    variant="link"
                    size="sm"
                    onClick={() => handleModalShow(rowTitle, index)}
                  >
                    <span className="fa fa-trash" />
                  </Button>
                  )}
                </Col>
         
              </Row>

              {rowFields.map((row, rowIndex) => (
                <Row key={`attendee-${index}-row-${rowIndex}`} xs={1} sm={2} md={3}>
                  {row.map((field, fieldIndex) => {
                    /**
                     * Set Attendee as exception by field
                     * 
                     * @param event - On Change Event
                     * @returns true regardless of process for chaining
                     */
                    const fieldExceptionHandler = (event) => {
                      if (field.exception && event.target.checked !== undefined) {
                        dispatch(updateAttendee([index, {name: 'exception', value: event.target.checked}]))
                      } else if (field.exception && event.target.value !== undefined) {
                        dispatch(updateAttendee([index, {name: 'exception', value: event.target.value}]))
                      }
                      return true
                    }

                    /** 
                     * Return field based on field.type
                     */
                    switch(field.type) {
                      case 'tel':
                      case 'text': 
                        return (
                          <Form.Group key={`attendee-${index}${rowIndex}${fieldIndex}`} as={Col} className={errorClass(index, field.name)}>
                            <FloatingLabel label={field.placeholder}>
                              <Form.Control
                                name={field.name}
                                placeholder={field.placeholder}
                                type={field.type}
                                value={attendee[field.name]}
                                onChange={(event) => fieldExceptionHandler(event) && onChangeHandle(event, index)}
                                isInvalid={invalidField(index, field.name)}
                                pattern={field.pattern ? field.pattern : null}
                              />
                              <Form.Control.Feedback type="invalid">{errorMessage(index, field.name)}</Form.Control.Feedback>
                            </FloatingLabel>
                          </Form.Group>
                        )
                      
                      case 'select': 
                        return (
                          <Form.Group key={`attendee-${index}${rowIndex}${fieldIndex}`} as={Col} className={errorClass(index, field.name)}>
                            <FloatingLabel label={field.placeholder}>
                              <Form.Select title={field.placeholder}
                                  name={field.name}
                                  onChange={(event) => {
                                    selectRefs.current[index] = event.target.value
                                    fieldExceptionHandler(event)
                                    onChangeHandle(event, index)
                                  }}
                                  isInvalid={invalidField(index, field.name)}
                                  defaultValue={attendee[field.name] || field.selected}
                                >
                                {field.options.map((option, optionIndex) => (
                                  <option
                                    key={`${option.value}${optionIndex}`}
                                    value={option.value}
                                    disabled={option.disabled}
                                  >
                                  { option.label }
                                  </option>
                                ))}
                              </Form.Select>
                              {field.allowToSpecify && field.allowToSpecifyOption === selectRefs.current[index] && (
                                <FloatingLabel label={field.allowToSpecifyLabel}>
                                  <Form.Control
                                    name={field.allowToSpecify}
                                    type={'text'}
                                    value={attendee[field.allowToSpecify]}
                                    onChange={(event) => fieldExceptionHandler(event) && onChangeHandle(event, index)}
                                    isInvalid={invalidField(index, field.allowToSpecify)}
                                  />
                                  <Form.Control.Feedback type="invalid">{errorMessage(index, field.allowToSpecify)}</Form.Control.Feedback>
                                </FloatingLabel>
                              )}
                              <Form.Control.Feedback type="invalid">{errorMessage(index, field.name)}</Form.Control.Feedback>
                            </FloatingLabel>
                          </Form.Group>
                        )
                      
                      case 'checkbox':
                        return (
                          <Form.Group key={`attendee-${index}${rowIndex}${fieldIndex}`} as={Col} className={errorClass(index, field.name)}>
                            <Form.Check
                              id={`attendee-${index}${rowIndex}${fieldIndex}-checkbox-${field.name}`}
                              className="pre-field flex-children"
                            >
                              <Form.Check.Input
                                type='checkbox'
                                name={field.name}
                                defaultChecked={!!attendee[field.name]}
                                onChange={(event) => fieldExceptionHandler(event) && onChangeCheckHandler(event, index)}
                              />
                              <Form.Check.Label>
                                  {field.placeholder}
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Control.Feedback type="invalid">{errorMessage(index, field.name)}</Form.Control.Feedback>
                          </Form.Group>
                        )
                      
                      default: 
                        return (<></>)
                    }
                  })}
                </Row>
              ))}
              {campaignParams?.hasIncludesText && (
                <p style={{width: '95%', color: '#212529'}}>
                  <strong>Includes:</strong>&nbsp;
                  {! attendee.exception && campaignParams.includes?.default}
                  { attendee.exception && campaignParams.includes?.exception}
                </p>
              )}
              {campaignParams?.hasAdditionalInstructions && (
                (campaignParams?.additionalInstructions || []).map((i, index) => (
                  <p key={index} style={{width: '95%', color: '#212529'}}>{i}</p>
                ))
              )}
            </div>
          )
        })}
        {(maxRegistrants < 0 || attendees.length < maxRegistrants) && (
          <ButtonToolbar>
            <Button 
              variant='primary'
              className="add-another v-center"
              onClick={() => dispatch(addAttendee())}
            >+</Button>
            <Button
              variant='link'
              className="add-another"
              onClick={() => dispatch(addAttendee())}
            >Add another attendee</Button>
          </ButtonToolbar>
        )}
      
      </div>
    </section>
  )
}

export default RegistrationForm