import React from 'react'
import { Button } from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react"

import type { RedirectLoginOptions, AppState } from "@auth0/auth0-react"

const LoginButton = ({ variant, size, saveAppState, text, email, disabled, className, nextModal }) => {
  const { loginWithRedirect } = useAuth0()

  const options: RedirectLoginOptions<AppState> = {
    authorizationParams: {
      screen_hint: "login",
      login_hint: email || undefined,
    },
    appState: {
      returnTo: window.location.pathname
    },
  }

  return (
    <Button
      variant={variant}
      size={size}
      className={className}
      disabled={disabled}
      onClick={async () => {
        saveAppState(nextModal)
        await loginWithRedirect(options)
      }}
    >
      { text }
    </Button>
  )
}

export default LoginButton