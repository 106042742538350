import React from 'react'
import { Col, Dropdown, FormGroup, Row } from 'react-bootstrap';

const GiftMenu = ({ giftOptions, selectedCampaignOption, onClick, inline = false }) => (
  <FormGroup>
    <Row className="sm-gutters">
      <Col xs={12}>
        <span>{ giftOptions.title }&nbsp;</span>
        <Dropdown className={inline ? 'inline-block' : 'mt1'}>
          <Dropdown.Toggle>
            { selectedCampaignOption.option }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              giftOptions.options.map(o => (
                <Dropdown.Item 
                  key={o}
                  className='mr2'
                  active={!!selectedCampaignOption && (selectedCampaignOption.option === o)}
                  onClick={() => onClick(o)}
                >
                  {o}
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  </FormGroup>
)

export default GiftMenu