import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux"
import { BrowserRouter } from 'react-router-dom'
import { Spinner } from 'react-bootstrap';

import App from './App'
import { AppProvider } from './providers/AppProvider';
import { AccessTokenProvider } from './providers/AccessTokenProvider';
import { SitesProvider, SitesContext } from './providers/SitesProvider'
import store from './store'

import './assets/styles/main.css'

/**
 * Main App Container
 * 
 * Instead of hardcoded within index.html, creating the outlining
 * div element here provides granular control of the element as well 
 * as when it is rendered on page. With the upgrade to React +18, 
 * createRoot() is the preferred way to bootstrap a react app.
 */
const container = document.createElement('div')
document.body.prepend(container)
document.body.classList.add('site')

/** 
 * Development Mode additions
 * 
 */
if (import.meta.env.MODE !== 'production') {
  /**
   * Test Run Method
   * 
   * Loads test script from Browser into DOM, utilizing FakerJs 
   * to auto populate fields for testing purposes. Stripe fields
   * are not supported as they use an iframe. 
   * 
   * @usage
   * ```
   * __testRun();
   * ```
   */
  const testRun = () => {
    const src = document.createElement('script')
    src.src = '/assets/scripts/charity-run.test.js'
    document.head.append(src);
  }
  window.__testRun = testRun
}

/** 
 * @see {@link https://react.dev/reference/react-dom/client/createRoot}
 */
createRoot(container)
  .render(
    <Provider store={store}>
      <BrowserRouter>
        <SitesProvider>

          <SitesContext.Consumer>
            {(site) => {
              if (!site) {
                console.error('Unable to resolve site config')
                return <Spinner />
              }
              
              // set 'site' specific classes and ids after site has 
              // been identified
              window.site = site.site.toLowerCase()
              document.body.classList.add(site.site)
              container.setAttribute('id', `app-root-${site.site}`)
              const title = document.getElementById('website-title')
              if (title) {
                title.innerHTML = site.title
              }


              if (import.meta.env.DEV) {
                console.warn('NOTE: Use __testRun() to autofill fields for testing.')
              }

              // render site related app components
              return (
                <AppProvider services={site.services} sandbox={site.sandbox}>
                  <AccessTokenProvider>

                    <App {...site} />
                    
                  </AccessTokenProvider>
                </AppProvider>
              )
            }}
          </SitesContext.Consumer>

        </SitesProvider> 
      </BrowserRouter>
    </Provider>
  )
