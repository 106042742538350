import axios from 'axios'

export const baseUrl = location.host.startsWith('localhost')
  ? 'http://localhost:8082/server'
  : `${location.protocol}//${location.host}/server`

// export const baseUrl = `${location.protocol}//${location.host}/server`

export default axios.create({
  baseURL: baseUrl,
  withCredentials: true
})