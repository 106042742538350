// @ts-nocheck

import { setData } from 'actions/contactActions';
import { setInitialAmountType } from 'actions/optionsActions';
import { sendPageView } from 'modules/gtm';
import queryString from 'query-string';
import React from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import GiftBanner from 'sharedComponents/GiftBanner';
import Navbar from 'sharedComponents/Navbar';
import Payment from 'sharedComponents/Payment'; // moved from ./sections to sharedComponents
import Spinner from 'sharedComponents/Spinner';
import Testimonials from 'sharedComponents/Testimonials';
import { setContactData } from 'slices/accountSlice';
import { 
  setCampaignId as setCharityRunCampaignId, 
  updateAttendee as updateCharityRunAttendee, 
  updateConfirmed as updateCharityConfirmed,
  setReady as setCharityRunReady,
} from 'slices/charityRunSlice';
import { setCampaignId, setReady, setSponsorship, updateCheckout} from 'slices/eventRegistrationSlice'
import { getSponsorshipPackageIndex } from 'slices/eventRegistrationSlice/utils'
import { setDisplayValidationError } from 'actions/paymentActions';

import EventRegistration from './sections/EventRegistration'
import CharityRun from './sections/CharityRun';
import Contact from './sections/Contact';
import Options from './sections/Options';
import PaymentProfiles from './sections/PaymentProfiles';
import DonateDefault from './DonateDefault';
import DonateBySteps from './DonateBySteps';

import type { ConnectedProps } from 'react-redux'
import type { RootState } from 'src/store'
import type { AppRouteProps, InitialUrlParams } from 'src/types/app'

import { checkValidForPayment } from 'modules/utils'

const mapState = (store: RootState, props: AppRouteProps) => ({
  loading: store.payment.loading,
  loadingCustomer: store.payment.loadingCustomer,
  paymentType: store.payment.type,
  isAdminTerminal: !!store.root.userRoles.find((r: {name:string}) => r.name === 'Admin Terminal'),
  profileAuth0: store.root.profileAuth0,
  accountId: store.root.accountId,
  customer: store.root.customer,
  campaignId: store.contact.campaignId,
  campaigns: store.root.campaigns,
  eventCampaignId: store.eventRegistration.campaignId,
  eventRegistration: store.eventRegistration,
  enableEvents: store.eventRegistration.ready,
  enableEventRegistrations: !store.eventRegistration.expired,
  enableCharityRun: store.charityRun.ready,

  // contactErrors: store.contact.errors,
  contactErrors: store.account.contact.__errors,
  paymentErrors: store.payment.errors,
  optionsErrors: store.options.errors,
  paymentRequestError: store.payment.requestError,
  ...checkValidForPayment(store, props.site),

  initialUrlParams: ((store, props) : InitialUrlParams => {
    let { site } = props
    let {
      cid: campaignId,
      amount,
      term,
      show_tax_status,
      show,
      sponsorship: sponsorshipId,
      layout,
    } = queryString.parse(location.search)
    let enableEvents = store.eventRegistration.ready
    let enableSponsorship = store.eventRegistration.ready
    let enableCharityRun = store.charityRun.ready
    // let sponsorshipIndex = -1
    

    // if not from url, from state
    // campaignId = campaignId || store.contact.campaignId

    if (!store.options.term && !term && !store.options.amount && !amount) {
      amount = '35'
      term = 'monthly'
    } 
    // if set in state
    else if (!term && !amount && store.options.term && store.options.amount) {
      term = store.options.term
      amount = store.options.amount
    }

    const initialTerm =  term || store.options.plans.donationType || 'monthly'
    const initialAmount = amount && amount !== 'other' ? Number(amount) : 'other'

    const isMonthly = initialTerm === 'monthly'
    const isOther = initialAmount === 'other'
    const showTaxStatus = show_tax_status === '1'

    // VV campaigns (legacy event registration system)
    // - replace with event registration system
    if (
      !enableCharityRun && site === 'VV' && 
      campaignId && 
      _.get(store.root.campaigns.find(c => c.id === campaignId), ['options', 'event', 'eventType']) === 'CHARITY_RUN'
    ) {
      // console.log('[donate] enable charity run', campaignId)
      enableCharityRun = true
    }

    // STS campaigns
    else if (!enableEvents && site === 'STS' && campaignId) {
      // console.log('[donate] enable event registration', campaignId)
      enableEvents = true
    }


    // Sponsorships
    if (!enableSponsorship && sponsorshipId) {
      // console.log('incoming sponsorship package name', sponsorship, store.eventRegistration)
      // sponsorshipIndex = getSponsorshipPackageIndex(sponsorshipId, store.eventRegistration)
      // console.log('[donate] enable sponsorship', sponsorship)
      enableSponsorship = true
    }

  
    return {
      cid: campaignId || store.contact.campaignId,
      amount: initialAmount,
      term: initialTerm,
      show,
      isMonthly,
      isOther,
      enableCharityRun,
      enableEvents,
      enableSponsorship,
      sponsorshipId,
      // sponsorshipIndex,
      showTaxStatus,
      layout,
    }
  })(store, props)
})
const connector = connect(mapState, { 
  setCharityRunCampaignId,
  updateCharityRunAttendee,
  updateCharityConfirmed,
  setCharityRunReady,
  setReady, 
  setCampaignId, 
  setSponsorship,
  setContactData, 
  updateCheckout,
  setData, 
  setInitialAmountType,
  setDisplayValidationError
})
export type DonateProps = ConnectedProps<typeof connector> & AppRouteProps;
export interface DonateState { }

/**
 * Donate Now Page
 * 
 * Used as default redirect from /
 * Renders and rewrite to /donate?amount=50&term=monthly on load.
 * 
 */
class Donate extends React.Component<DonateProps, DonateState> {
  componentDidUpdate(prevProps: Readonly<DonateProps>, prevState: Readonly<DonateState>, snapshot?: any): void {
    const {
      initialUrlParams,
      enableEvents,
      setSponsorship,
      eventCampaignId,
      eventRegistration,
      updateCheckout,
      setReady,
    } = this.props

    if (!prevProps.eventCampaignId && eventCampaignId) {
      console.info('[donate] campaign ID is set', eventCampaignId)
    }

    if (!prevProps.enableEvents && enableEvents) {
      console.info('[donate] events workflow is set')

      if (initialUrlParams.enableSponsorship && initialUrlParams.sponsorshipId) {
        console.info('[donate] enabling sponsorship workflow, package:', initialUrlParams.sponsorshipId)
        setSponsorship()
        setReady((getSponsorshipPackageIndex(initialUrlParams.sponsorshipId, eventRegistration)))
          .then(updateCheckout)
        
      }
    }
  }

  componentDidMount() {
    console.log('[donate] componentDidMount')
    const {
      setCharityRunCampaignId,
      updateCharityRunAttendee,
      updateCharityConfirmed,
      setCharityRunReady,
      initialUrlParams,
      profileAuth0,
      setCampaignId, 
      setReady,
      enableCharityRun,
      enableEvents,
      campaigns,
    } = this.props
    sendPageView('/donate', profileAuth0?.email)

    try {
      // Enable Charity Run for VV
      // - TODO: replace with Event Registration System
      if (!enableCharityRun && initialUrlParams.enableCharityRun) {
        console.log('[donate] enabling charity run workflow')
        setCharityRunCampaignId(initialUrlParams.cid)
        setCharityRunReady(true).unwrap()
      }
  
      // Enable Event Registration System WITHOUT Sponsorship
      else if (!enableEvents && initialUrlParams.enableEvents) {
        console.log('[donate] enabling event registration workflow', initialUrlParams.cid)
        setCampaignId({ cid: initialUrlParams.cid, campaigns })
        setReady().unwrap()
      }

    } catch(e){
      //
      // Campaign Exceptions (silent)
      // - Campaign doesn't exist in EventRegistration setup
      // |--> slices/eventRegistrationSlices.ts : setCampaignId( :string )
      //
      console.error(e)
    }
  }

  render() {
    const {
      campaignId, 
      customer, 
      enableEvents,
      enableEventRegistrations,
      enableCharityRun,
      getAccessTokenSilently, 
      guestMode, 
      initialUrlParams, 
      isAdminTerminal,
      loading, 
      loadingCustomer, 
      site, 
      layoutConfig
    } = this.props

    if (layoutConfig) {
      if (layoutConfig.flowType === 'steps') {
        return <DonateBySteps {...this.props} layoutConfig={layoutConfig} />
      }
    }
    return <DonateDefault {...this.props} />

    return (
      <div 
        className={[
          'app-form-body',
          guestMode ? ' guest-mode' : '',
          enableEvents ? ' event-registration-container' : ''
        ].join('')} 
        style={{ position: 'relative' }}
        >
        {enableCharityRun && (
          <div className={`form-container ${guestMode ? '' : 'pt2'} charity-run`}>
            <Form noValidate>
              <CharityRun />
            </Form>
          </div>
        )}
        {enableEvents && (
          <div className={`form-container ${guestMode ? '' : 'pt2'} event-registration`}>
          <Form noValidate>
            <EventRegistration />
          </Form>
        </div>
        )}

        {(!enableEvents || enableEvents && enableEventRegistrations) && (
        <div className={`form-container donate`}>
          {loading && 
            <Spinner mask/> 
          }
          <Navbar />
          <Form>  
            <Options 
              {...this.props}
              guestMode={guestMode} 
              isAdminTerminal={isAdminTerminal}
            />
            <Contact
              {...this.props}
              guestMode={guestMode} 
              editMode={true} 
            />
            {customer ? 
              <PaymentProfiles 
                {...this.props}
                getAccessTokenSilently={getAccessTokenSilently} 
              /> 
              : null 
            }
            <Payment 
              {...this.props}
              getAccessTokenSilently={getAccessTokenSilently} 
              withTitle={!customer && !loadingCustomer} 
              guestMode={guestMode} 
              // editMode={true}
            />
          </Form>
        </div>
        )}
        <div id="save-the-storks-gift" />
        {guestMode && (
          <>
            {!isAdminTerminal && 
              <GiftBanner 
                cid={campaignId || initialUrlParams.cid} 
              />
            }
            <Testimonials site={site} cid={campaignId || initialUrlParams.cid} />
          </>
        )}
      </div>
    )
  }
}

export default connector(Donate)
