import React from 'react'
import LoginButton from './LoginButton'
import { siteDefaultErrMsg } from 'modules/siteContents'

const RequestError = ({ error, email, saveAppState, profileAuth0, forPlaid, site }) => {
  const errorCode = error?.code || error?.errorCode
  const isKnownError = error && errorCode && error.message
  return (
    <div className="mt1" style={{ color: '#a94442' }}>
      {
        isKnownError ? (
          `Error: (${errorCode}) ${error.message}`
        ) : (
          'Unknown Error'
        )
      }
      <br />
      {
        (errorCode === 'E00039' && !profileAuth0) ? (
          <LoginButton
            saveAppState={saveAppState}
            email={email}
            className="btn-donate"
            text={`Try logging in as ${email} first`}
          />
        ) : (isKnownError ? null : <div>{siteDefaultErrMsg[forPlaid ? 'plaid' : 'general'][site] || ''}</div>)
      }
    </div>
  )
}

export default RequestError