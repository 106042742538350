// @ts-nocheck

// import { switchView, saveAppState, setShowDetails } from 'actions/rootActions'
import { setData, setValidationError } from 'actions/contactActions';
import { setBillingAddress, setBillingAddressSameAsContact } from 'actions/paymentActions';
import _ from 'lodash';
import { getCookie } from 'modules/cookie';
import { campaignIgnoreSites, campaignMenuVisibleSites } from 'modules/siteContents';
import { checkCampaignOption } from 'modules/utils';
import React from 'react';
import { Button, Col, Dropdown, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';
import { Dropdown as NestedDropdown } from 'react-nested-dropdown';
import { connect } from 'react-redux';
// import { Dropdown } from 'react-nested-dropdown'
import Address from 'sharedComponents/Address';
// import Address from 'sharedComponents/Address'
import LoginButton from 'sharedComponents/LoginButton';
import GiftMenu from '../../components/GiftMenu';
import { setContactData } from 'slices/accountSlice';
import { saveAppState, setShowDetails, switchView } from 'slices/rootSlice';

import type { AccountStateAddress } from 'slices/accountSlice'

@connect(store => ({
  contact: store.account.contact,
  contactErrors: store.account.contact.__errors,
  billingContact: store.account.billingContact,
  
  inspiration: store.contact.inspiration,
  campaignCategories: store.contact.campaignCategories,
  campaignId: store.contact.campaignId,
  selectedCampaignOption: store.contact.selectedCampaignOption,
  campaignOptions: (store.root.campaigns.find(c => c.id === store.contact.campaignId) || {}).options,

  customer: store.root.customer,
  campaigns: store.root.campaigns,
  showDetails: store.root.showDetails,
  profileAuth0: store.root.profileAuth0,
  subscription: store.root.subscription,
  crm: store.root.crm,
  mimicState: store.root.mimicState,

  displayValidationError: store.payment.displayValidationError,
  billingAddress: store.payment.billingAddress,
  billingAddressSameAsContact: store.payment.billingAddressSameAsContact,
  
  charityRun: {
    ready: store.charityRun.ready,
    campaignId: store.charityRun.campaignId,
  },
  
  donationType: store.options.donationType,
  donationAmount: store.options.donationAmount,
}), {
  switchView,
  saveAppState,
  setContactData,
  setData,
  setValidationError,
  setBillingAddress,
  setBillingAddressSameAsContact,
  setShowDetails,
})

export default class Contact extends React.Component {

  state = {
    tempCid: 'temp',
    showMessageBox: false,
  }

  enterInfo = (field, val) => {
    const contactFields = ['email', 'firstName', 'lastName', 'phone']
    const campaignFields = ['inspiration', 'selectedCampaignOption']
    const { contact, setData, setValidationError, setContactData } = this.props

    if (field === campaignFields[0]) {
      setData({ field, val: val.public_name__c || val.name })
      setData({ field: 'campaignId', val: val.id })
      setData({ field: 'campaignCategories', val: (val.website_category__c || '').split(';').filter(v => v)})
    } 
    else if (campaignFields.includes(field)) {
      setData({ field, val })
    } else if (contactFields.includes(field)) {
      setContactData({ [field]: val })
    } else {
      // setContactData({ [field]: val })
      setContactData({
        address: {
          // ...contact.address,
          [field]: val,
        }
      })
    }

    
    // if (['email', 'selectedCampaignOption'].includes(field)) {
    //   setData({ field, val })
    // }
    // else if (field === 'inspiration') {
    
    // }
    // else {
    //   setData({
    //     field: 'address',
    //     val: {
    //       ...address,
    //       [field]: val,
    //     }
    //   })
    // }
    // setValidationError({
    //   [field]: val ? '' : this.props.validationError[field]
    // })
  }

  getCampaignDropdownOptions = (inspirations) => {
    const { inspiration, campaignCategories } = this.props
    if (!inspirations[0]) {
      return [{
        label: '',
        className: 'fa fa-spinner fa-pulse',
        disabled: true,
      }]
    }
    const categoryMap = inspirations.filter(i => i.website_visible__c && i.website_category__c).reduce((acc, i) => {
      const categories = (i.website_category__c || '').split(';').filter(v => v)
      if (!categories.length) return acc
      return {
        ...acc,
        ...categories.reduce((acc1, c) => {
          return {
            ...acc1,
            [c]: [
              ...(acc[c] || []),
              i,
            ]
          }
        }, {}),
      }
    }, {})
    if (!Object.keys(categoryMap).length) {
      return inspirations.map(i => ({
        label: i.public_name__c || i.name,
        onSelect: () => this.enterInfo('inspiration', i),
        className: (i.public_name__c === inspiration || i.name === inspiration) ? 'active' : '',
      }))
    }

    return Object
      .keys(categoryMap)
      .sort((a, b) => {
        return a > b ? 1 : -1
      })
      .map(c => {
        if (categoryMap[c].length === 1) {
          const inspiration = categoryMap[c][0]
          return {
            label: inspiration.public_name__c || inspiration.name,
            onSelect: () => this.enterInfo('inspiration', inspiration),
            className: (inspiration.public_name__c === inspiration || inspiration.name === inspiration) ? 'active' : '',
          }
        }

        return {
          label: c,
          className: campaignCategories.includes(c) ? 'active' : '',
          items: categoryMap[c].map(i => ({
            label: i.public_name__c || i.name,
            onSelect: () => this.enterInfo('inspiration', i),
            className: (i.public_name__c === inspiration || i.name === inspiration) ? 'active' : '',
            itemsContainerWidth: '100%',
          }))
        }
      })
  }

  componentDidUpdate(prevProps) {
    const { setData, campaigns, profileAuth0, subscription, campaignId, campaignOptions, donationAmount, donationType, selectedCampaignOption, layoutConfig } = this.props
    const { tempCid } = this.state
    if (!prevProps.campaigns.length && campaigns.length && !prevProps.campaignId) {
      const campaign = campaigns.find(c => c.id === tempCid) || campaigns.find(c => (c.public_name__c || c.name) === _.get(subscription, 'donation_source__c'))
      const defaultCampaign = campaigns.find(c => c.options?.setAsDefault || ((c.public_name__c || c.name) === 'General Donations'))
      if (campaign) {
        setData({ field: 'inspiration', val: campaign.public_name__c || campaign.name })
        setData({ field: 'campaignId', val: campaign.id })
      }
      else if (defaultCampaign) {
        setData({ field: 'inspiration', val: defaultCampaign.public_name__c || defaultCampaign.name })
        setData({ field: 'campaignId', val: defaultCampaign.id })
      }
    }
    if (
        prevProps.campaignId !== campaignId || 
        prevProps.donationAmount !== donationAmount ||
        prevProps.donationType !== donationType
      ) {
      const nextSelectedCampaignOption = checkCampaignOption(campaignOptions, layoutConfig, donationAmount, donationType)
      if (
        (nextSelectedCampaignOption && !selectedCampaignOption) || 
        (!nextSelectedCampaignOption && selectedCampaignOption)
      ) {
        // Prevent resetting while switching donation amount/type
        this.enterInfo('selectedCampaignOption', nextSelectedCampaignOption)
      }
    }
  }

  UNSAFE_componentWillMount() {
    const { setData, campaigns, subscription, initialUrlParams, campaignOptions, donationAmount, donationType, layoutConfig } = this.props
    const cid = initialUrlParams.cid || getCookie('cid') 
    let campaign = campaigns ? campaigns.find(c => c.id === cid) || campaigns.find(c => (c.public_name__c || c.name) === _.get(subscription, 'donation_source__c')) : null
    const defaultCampaign = campaigns.find(c => c.options?.setAsDefault || ((c.public_name__c || c.name) === 'General Donations'))
    const useCampaign = campaign || defaultCampaign

    if (useCampaign) {
      setData({ field: 'inspiration', val: useCampaign.public_name__c || useCampaign.name })
      setData({ field: 'campaignId', val: cid || useCampaign.id })
    }
    this.setState({ tempCid: cid || '' })
    this.enterInfo('selectedCampaignOption', checkCampaignOption(campaignOptions, layoutConfig, donationAmount, donationType))
  }

  render() {
    let { 
      showDetails, 
      address, 
      email, 
      inspiration, 
      message,
      contact,
      // validationError = {}, 
      profileAuth0, 
      saveAppState, 
      campaigns = [], 
      displayValidationError,
      setData,
      guestMode,
      billingAddress,
      setBillingAddress,
      billingAddressSameAsContact,
      setBillingAddressSameAsContact,
      crm,
      site,
      customer,
      setShowDetails,
      charityRun,
      contactErrors,
      paymentErrors,
      campaignOptions,
      selectedCampaignOption,  
      layoutConfig,
      visibleInputs,
      title, // will override the other headline titles
      campaignMenuVisible = true,
      mimicState,
    } = this.props

    const { tempCid, showMessageBox } = this.state
    // const hasMissingFields = Object.values(validationError).reduce((acc, cur) => acc || !!cur, false)
    if (site === 'OPH') {
      crm = 'salesforce'
      campaigns = [
        'Where Most Needed',
        'In Memory of',
        'Cherry Hill Location',
        'Trenton Location',
        'Ocean City Location',
        'Mobile Unit',
      ].map(name => ({
        id: '',
        website_category__c: 'Fund',
        status: 'In Progress',
        name,
        website_visible__c: true,
        description: null,
        public_name_c: null,
      }))
      console.log('oph campaigns', campaigns)
    }
    
    const inspirations = campaigns.length ? (campaigns || []) : [null]
    const dropdownItems = this.getCampaignDropdownOptions(inspirations)
    const errors = contactErrors || []
    const campaignMenuPosition = layoutConfig?.campaignMenu?.position
    const campaignMenu = ((!tempCid || !campaigns.find(c => c.id === tempCid)) && 
      crm ==='salesforce' && 
      !campaignIgnoreSites.includes(site) &&
      !campaignMenuVisibleSites.includes(site)) && 
      campaignMenuVisible ? (
      <Form.Group>
        <Row className="sm-gutters" style={{marginBottom: '20px'}}>
          <Col xs={12} className={[errors.some(e => e.field === 'inspiration') ? 'has-error' : '', ''].join(' ')}>
            {dropdownItems.length > 1 && (
              <>
                <label className="pre-field required">What Inspired You to Give?</label>
                <NestedDropdown
                  items={dropdownItems} 
                  containerWidth="100%"
                  closeOnScroll={false}
                  className='d-grid gap-2'
                  
                >
                  {({ onClick }) => (
                    <Dropdown className='' onClick={(e) => {
                      onClick(e)
                      document.querySelector('.invalid-inspiration-feedback').style.display = 'none'
                    }}>
                      <Dropdown.Toggle id="inspiration-dropdown"
                        variant="primary"
                        style={{width: '100%'}}
                      >
                        {inspiration || "Please select"}
                        <span className='caret' />
                      </Dropdown.Toggle>
                    </Dropdown>
                  )}
                </NestedDropdown>
              </>
            )}
            {dropdownItems.length === 1 && (
              <>
              <FloatingLabel label="Fund">
                <Form.Select
                  id="inspiration-menu"
                  name={`inspiration`}
                  // value={state}
                  // onChange={e => onChangeHandle(e)}
                  // isInvalid={fieldInvalid('state')}
                >
                  {campaigns.map((i, index) => (
                    <option
                      key={i.name}
                      value={i.name}
                    >
                      { i.name }
                    </option>
                  ))}
                </Form.Select>
                {/* <Form.Control.Feedback type="invalid">{errorMessage('state')}</Form.Control.Feedback> */}
              </FloatingLabel>  
              </>
            )}

            
            <div className="invalid-feedback invalid-inspiration-feedback" style={{display: (displayValidationError && errors.some(e => e.field === 'inspiration')) ? 'block' : 'none'}}>{errors.find(e => e.field === 'inspiration')?.message[0]}</div>
          </Col>
        </Row>
      </Form.Group>
    ) : null
    const giftOptions = campaignOptions || layoutConfig?.giftOptions
    const campaignOptionMenu = (giftOptions && !layoutConfig && selectedCampaignOption) ? (
      <div className='mb3'>
        <GiftMenu
          giftOptions={giftOptions}
          selectedCampaignOption={selectedCampaignOption}
          enterInfo={(o) => this.enterInfo('selectedCampaignOption', { ...selectedCampaignOption, option: o })}
          />
      </div>
    ) : null
    const profileToggle = (customer && (layoutConfig?.contact?.profileToggle?.visible !== false)) ? (
      <div className="mt3">
        <Button className={showDetails ? 'btn-donate' : ''} onClick={() => setShowDetails(!showDetails)}>
          {`${showDetails ? 'Hide' : 'Show'} your profile details`}
        </Button>
      </div>
    ) : null

    /// Issue here
    ///
    if (!showDetails) {
      return (
        <section>
          { campaignMenu }
          { campaignOptionMenu }
          { profileToggle }
        </section>
      )
    }
    return (
      <section className='contact mt-40'>
        { (!campaignMenuPosition || (campaignMenuPosition === 'top')) && campaignMenu }
        { campaignOptionMenu }
        { profileToggle }
        <div style={{ display: 'inline-block', width: 'auto', marginRight: 10 }}>
          {
            layoutConfig?.contact?.title ? (
              <h3 className='donate-headline' style={layoutConfig?.contact?.titleStyle || null}>{title || layoutConfig.contact.title}</h3>
            ) : (
              guestMode ? (
                <h4>Add your information</h4>
              ) : (
                <legend>Contact Information</legend>
              )
            )
          }
        </div>
        {
          layoutConfig?.loginButton?.visible === false || (
            profileAuth0 || charityRun.ready
          ) ? null : (
            <LoginButton
              variant="link"
              size="small"
              saveAppState={saveAppState}
              text="Already have an account?"
              email={email}
              className="pl0"
            />
          )
        }
        <Address
          hasEmail
          // email={email}
          contactErrors={contactErrors}
          // address={address}
          contact={contact}
          enterInfo={this.enterInfo}
          displayValidationError={displayValidationError}
          id="contact-address"
          guestMode={guestMode}
          layoutConfig={layoutConfig}
          visibleInputs={visibleInputs}
          allowAutoCreatingEmail={!!mimicState}
        />
        {
          (guestMode && (layoutConfig?.contact?.billingAddressCheckbox?.visible !== false)) ? (
            <div>
              <Form.Check 
                id="billing-contact"
                className="pre-field flex-children"
                style={{marginTop: '0px'}}
              >
                <Form.Check.Input
                  type='checkbox'
                  defaultChecked={billingAddressSameAsContact} 
                  onChange={() => setBillingAddressSameAsContact(!billingAddressSameAsContact)}
                />
                <Form.Check.Label>
                  Billing address matches my Contact Address
                </Form.Check.Label>
              </Form.Check>
              {
                billingAddressSameAsContact ? null : (
                  <>
                  <h5>Billing Address</h5>
                  <Address
                    contactErrors={paymentErrors}
                    contact={{ ...billingAddress, address: billingAddress }}
                    // address={billingAddress}
                    enterInfo={(field, value) => {
                      setBillingAddress({ [field]: value })
                    }}
                    id="billing-address-edit-guest"
                    displayValidationError={displayValidationError}
                    layoutConfig={layoutConfig}
                    visibleInputs={visibleInputs}
                  />
                  </>
                )
              }
            </div>
          ) : null
        }
        {
          layoutConfig?.contact?.messageInput?.visible !== false ? (
            <Form.Group>
              <Button 
                variant="link"
                style={{ padding: 0 }}
                onClick={() => this.setState({ showMessageBox: !showMessageBox })}
              >
                <b>{showMessageBox ? '-' : '+' } Add a message</b>
              </Button>
              {
                showMessageBox ? (
                  <textarea
                    style={{ resize: 'none' }}
                    className="form-control mt1"
                    value={message}
                    onChange={e => setData({ field: 'message', val: e.target.value.slice(0, 255) })}
                    rows={3}
                  />
                ) : null
              }
            </Form.Group>
          ) : null
        }
        { campaignMenuPosition === 'bottom' && campaignMenu}
      </section>
    )
  }
}