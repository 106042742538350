import React from 'react';
import { merchantNames } from 'modules/siteContents';

const FundDisclaimer = ({ site, guestMode }) => (
  <legend 
    className="pre-field center" 
    style={{ fontSize: 12, paddingTop: guestMode ? 0 : 20, paddingBottom: 10, lineHeight: 1.2, fontWeight: 'normal', textAlign: 'justify' }}
  >
    {`Thank you for your support of the mission and programs of ${merchantNames[site]}. We will make every effort to ensure that gifts designated to support specific programs or activities will be used in accordance with your wishes. However, unless a gift is made expressly conditional, ${merchantNames[site]} and its board will determine how to use your gift in a way that furthers the mission of the organization.`}
  </legend>
)

export default FundDisclaimer