import { contactSchema, transformErrorIntoObject } from 'modules/validation'

export const setData = ({ field, val }) => {
  return {
    type: 'SET_DATA_CONTACT',
    payload: { field, val },
  }
}

export const setValidationError = error => {
  return {
    type: 'SET_VALIDATION_ERROR_CONTACT',
    payload: error,
  }
}

export const checkContactValidity = (site, skip = false) => {
  return async (dispatch, getState) => {
    const { 
      contact: {
        address,
        email,
        inspiration,
      },
      payment: {
        billingAddressSameAsContact,
        billingAddress: { 
          company: billingCompany, 
          ...billingAddress
        },
      }
    } = getState()

    if (skip) return

    try {
      await contactSchema.validate({
        site,
        address: {
          email,
          ...address,
        },
        inspiration,
      }, { abortEarly: false })
  
      await dispatch({
        type: 'SET_CONTACT_VALID',
        payload: true,
      })
    } catch(e) {
      await dispatch({
        type: 'SET_CONTACT_ERROR',
        payload: transformErrorIntoObject(e),
      })
    }
  }
}
