import React from 'react'
import { Alert, Button } from 'react-bootstrap'

const MimicState = ({
  state,
  isAdmin,
  unmimicAccount,
  getAccessTokenSilently,
  layoutConfig,
}) => (state && isAdmin && (layoutConfig?.mimicState?.visible !== false)) ? (
  <div className="system-message">
    <Alert variant="info">
      <span style={{ lineHeight: 2.5 }}>
        {
          state.mimicGateway === 'Salesforce' ? (
            `In mimic mode: Salesforce account ${state.mimicSfdcAccountId || 'N/A'}, contact ${state.mimicSfdcContactId || 'N/A'}`
          ) : (
            `In mimic mode: Auth0 account ${state.mimicAuth0Uid || 'N/A'}`
          )
        }
      </span>
      <Button
        variant="link"
        className="float-right"
        onClick={() => unmimicAccount(getAccessTokenSilently)}
      >
        Unmimic
      </Button>
    </Alert>
  </div>
) : null

export default MimicState