import { EventRegistrationType } from "../@types/eventRegistrationSlice"

/**
 * Determines whether current event is a sponsorship event workflow
 * 
 * @param state - event registration state
 * @returns 
 */
export const isSponsorship = (state) : boolean => state.campaignParams?.hasSponsorshipPackages && (
  (Array.isArray(state.type) && state.type.includes(EventRegistrationType.SPONSORSHIP)) ||
  state.type === EventRegistrationType.SPONSORSHIP
)

/**
 * Retrieve the sponsorship package index from the package list
 * 
 * @param state - event registration state
 * @returns 
 */
export const getSponsorshipPackageIndex = (name: string, state) : number => (state.sponsorshipParams?.packages || []).findIndex(sponsorshipPackage => sponsorshipPackage.id.toLowerCase() === name.toLowerCase())

/**
 * Use sponsorship parameters for sponsorship workflows 
 * or campaign parameters for regular workflow
 * 
 * @param state - event registration state
 * @returns number of max registrants
 */
export const useParams = (state) => isSponsorship(state) ? state.sponsorshipParams : state.campaignParams

/**
 * Use the Max registrations outlined by ether event
 * campaign
 * 
 * @param state - event registration state
 * @returns number of max registrants
 */
export const useMaxCampaignRegistrantLimit = (state) : number | undefined  => state.campaignParams?.exceptionsApply ? 
  state.campaignParams?.maxRegistrantsExcludingException : 
  state.campaignParams?.maxTotalRegistrants

/**
 * Use the Max registrations outlined by sponsorship
 * details
 * 
 * @param state - event registration state
 * @returns number of max registrants
 */
export const useMaxSponsorshipRegistrantLimit = (state, packageIndex = 0) : number | undefined => state.sponsorshipParams?.exceptionsApply? 
  state.sponsorshipParams?.packages[packageIndex].maxRegistrantsExcludingException :
  state.sponsorshipParams?.packages[packageIndex].maxTotalRegistrants

/**
 * Use the Max registrations outlined by ether event
 * campaign or sponsorship details
 * 
 * @param state - event registration state
 * @returns number of max registrants
 */
export const useMaxRegistrantLimit = (state, packageIndex = 0) : number | undefined => isSponsorship(state) ? 
  useMaxSponsorshipRegistrantLimit(state, packageIndex) :
  useMaxCampaignRegistrantLimit(state)

/**
 * Use Pricing options defined by ether sponsorship
 * or campaign parameters
 * 
 * @param state - event registration state
 * @returns pricing options configuration
 */
export const useOptions = (state) => state.ready && isSponsorship(state) ? state.sponsorshipParams?.packages[state.sponsorshipPackageIndex].options: state.options

/**
 * Update checkout total for checkout
 * 
 * @param state - event registration state
 */
export const updateTotals = (state) : void => {
  const params = useParams(state)
  const options = useOptions(state)

  const regularAttendees = state.attendees
      .filter(attendee => !attendee.exception)
      .length
    const exceptionAttendees = state.attendees
      .filter(attendee => attendee.exception)
      .length
  let discounts = 0;

  if (options) {
    
    let useAttendee = state.attendees
    if (params?.discountsApplyToAll || params?.discountsApply) {

      if (params?.discountsApply) {
        useAttendee = state.attendees.filter(attendee => !attendee.exception)
      }

      switch(options.discount?.type) {
        case 'fixed-volume':
          discounts = useAttendee.length ? (
            Math.floor(useAttendee.length / state.options.discount?.volumeOf) * state.options.discount.amount
          ): 0
          break;
        case 'percentage':
          discounts = (useAttendee.length / 100 * state.options.amount)
          break;
        default:
          discounts = 0
      }
    }

    state.checkout = {
      lineCount: regularAttendees,
      subtotalAmount: regularAttendees * options.amount,
      totalExceptionSubtotalAmount: exceptionAttendees * (options.exceptionAmount || 0),
      totalExceptionCount: exceptionAttendees,
      totalDiscountAmount: discounts,
    }
    state.checkout.totalAmount = state.checkout.subtotalAmount + state.checkout.totalExceptionSubtotalAmount - state.checkout.totalDiscountAmount
  }

}
