// @ts-nocheck
// cSpell:ignore Recaptcha, grecaptcha, autohide, hideicon //

// import { checkContactValidity } from 'actions/contactActions';
import { checkOptionValidity, setDonationAmount } from 'actions/optionsActions';
import {
    checkPaymentValidity, createStripeSetupIntent, enterBank, enterBankStripe, enterCard,
    makeDonation, setAjsLoaded, setConfirmedMonthly, setDisplayValidationError,
    setIncludeProcessingFee, setLoading, setPlaidLoaded, setRequestError, setStripeLoaded,
    setTypePayment, updatePaymentMethod
} from 'actions/paymentActions';
import _ from 'lodash';
import axiosServer from 'modules/axiosServer';
import { sendGtmPixel } from 'modules/gtm';
import { merchantNames, siteDisclaimerText, siteProcessingFeeText, donationTypeNames } from 'modules/siteContents';
import { plaidCreateLinkTokenUrl, plaidGetStripeTokenUrl } from 'modules/urls';
import {
    calculateProcessingFee, checkValidForPayment, loadScript, LoadScript, reCaptchaReady
} from 'modules/utils';
import { scrollToError, transformErrorIntoObject } from 'modules/validation';
import React from 'react';
import { Button, ButtonGroup, Col, FloatingLabel, Form, FormCheck, Modal, Row, Table, Toast, ToastContainer } from 'react-bootstrap';
import { connect } from 'react-redux';
import RequestError from 'sharedComponents/RequestError';
import Spinner from 'sharedComponents/Spinner';
import FundDisclaimer from 'sharedComponents/FundDisclaimer';
import InputWrapperByShowTitle from 'sharedComponents/InputWrapperByShowTitle'
import { checkContactValidity } from 'slices/accountSlice';
import { checkCharityRunValidity, syncCharityRunWithSalesforce } from 'slices/charityRunSlice';
import { getRecaptchaToken, loadRecaptcha } from 'slices/recaptchaSlice';
import { saveAppState, setShowDetails, updateMailChimpWithTag } from 'slices/rootSlice';
import { validateEventRegistrationState } from 'slices/eventRegistrationSlice';
import { withRouter } from 'src/routes/WithRouter';

import { CardNumberElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import { StripeProvider, StripeContext } from '../../providers/StripeProvider';
import StripeCardBody from './StripeCardBody';
import RecaptchaV2Wrapper from '../RecaptchaV2Wrapper';

import type { ConnectedProps } from 'react-redux'
import type { RootState } from 'src/store'
import type { AppRouteProps } from 'src/types/app'
import type { Stripe, CanMakePaymentResult, PaymentRequest } from '@stripe/stripe-js'

const methodNames = {
  card: 'Credit/Debit Card',
  bank: 'Bank Account',
  bank_ach: 'Bank Account (ACH)',
  bank_ach_stripe: 'Bank Account Log-in',
  applePay: 'Apple Pay',
  googlePay: 'Google Pay',
}
const paymentMethodImageUrls = {
  card: 'card_icon.svg',
  bank: 'bank_icon.svg',
  bank_ach: 'bank_icon.svg',
  bank_ach_stripe: 'bank_icon.svg',
  applePay: 'apple_pay_icon.svg',
  googlePay: 'google-pay-mark.png',
}
const bankAccountNames = {
  // Authorize.NET
  checking: 'Checking',
  saving: 'Saving',
  businessChecking: 'Business Checking',
  // Stripe
  individual: 'Individual',
  company: 'Company',
}
const plaidSupportedSites = [] // Plaid is disabled now
const anetSupportedSites = ['STS']
// const authData = {
//   clientKey: anetToken.clientKey,
//   apiLoginID: anetToken.loginId,
// }
const fourDigitSplit = val => {
  let result = ''
  for (var i = 0; i < Math.ceil(val.length / 4); i++) {
    result += (val.slice(i * 4, (i + 1) * 4) + ' ')
  }
  return result.trim()
}
let plaidLinkHandler
let clickTimestamp:number
const checkClickInterval = () => {
  const nextClickTimestamp = (new Date()).getTime()
  const allowClick = !clickTimestamp || (nextClickTimestamp - clickTimestamp > 500)
  clickTimestamp = nextClickTimestamp
  return allowClick
}

const mapState = (store: RootState, ownProps: AppRouteProps) => {
  const { services, sandbox } = ownProps
  if (!services || !sandbox) console.error('[shared][payment] service error: not available')
  const selectedPaymentProfile = (store.payment.useSaved && store.payment.selectedPaymentProfileId) ? (
    store.payment.paymentProfiles.find(p => (p.id || p.customerPaymentProfileId) === store.payment.selectedPaymentProfileId) || null
  ) : null
  const processingFee = calculateProcessingFee(store.options.donationAmount, selectedPaymentProfile, store.payment.type)

  return {
    // Loading and Errors
    loading: store.payment.loading || store.root.loadingCustomer,
    processingPayment: store.payment.loading,
    loadingCustomer: store.root.loadingCustomer,
    error: store.payment.requestError,
    validationError: store.payment.validationError,
    displayValidationError: store.payment.displayValidationError,
    ajsLoaded: store.payment.ajsLoaded,
    stripeLoaded: store.payment.stripeLoaded,
    recaptchaLoaded: store.root.recaptchaLoaded,
    plaidLoaded: store.payment.plaidLoaded,

    // Store.Root
    crm: store.root.crm,
    accountId: store.root.accountId,
    mailChimpUpdated: store.root.mailChimpUpdated,
    profileAuth0: store.root.profileAuth0,
    rootSubscription: store.options.donationType === 'monthly' ?  store.root.subscription : store.root.subscriptionYearly,
    showDetails: store.root.showDetails,
    subscription: store.root.subscription,
    subscriptionYearly: store.root.subscriptionYearly,
    
    // Store.Options
    donationAmount: store.options.donationAmount,
    donationType: store.options.donationType,
    monthlyOtherAmount: store.options.monthlyOtherAmount,
    plans: store.options.plans,
    ...checkValidForPayment(store, ownProps.site),

    // Store.Payment
    confirmedMonthly: store.payment.confirmedMonthly,
    type: store.payment.type,
    card: store.payment.card,
    bank: store.payment.bank,
    bankStripe: store.payment.bankStripe,
    useSaved: store.payment.useSaved,
    billingAddress: store.payment.billingAddress,
    billingAddressSameAsContact: store.payment.billingAddressSameAsContact,
    paymentProfileEditMode: store.payment.paymentProfileEditMode,
    successInfo: store.payment.successInfo,
    paymentGateway: store.payment.gateway,
    includeProcessingFee: store.payment.includeProcessingFee,
    processingFee,
    paymentRequestConfig: {
      // For Apple/Google pay
      currency: 'usd',
      country: 'US',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: `${merchantNames[ownProps.site]} - ${store.options.donationType === 'one-time' ? 'One Time' : 'Monthly'} Donation`,
        amount: store.options.donationAmount !== 'other' ? Math.round(store.options.donationAmount * 100) : 0,
      }
    },

    // extras
    contact: store.account.contact,
    // email: store.contact.email,
    email: store.account.email,
    recaptcha: store.recaptcha,
    charityRun: store.charityRun,
    eventRegistration: store.eventRegistration,
    services,
    sandbox,
  }
}

const actionCreators = {
  setShowDetails,
  saveAppState,
  setTypePayment,
  makeDonation,
  setConfirmedMonthly,
  enterBank,
  enterBankStripe,
  enterCard,
  setAjsLoaded,
  setStripeLoaded,
  setPlaidLoaded,
  setRequestError,
  setLoading,
  setDisplayValidationError,
  setIncludeProcessingFee,
  setDonationAmount,
  updatePaymentMethod,
  updateMailChimpWithTag,
  createStripeSetupIntent,
  syncCharityRunWithSalesforce,
  getRecaptchaToken,
  loadRecaptcha,
  checkValidity: (site, callback) => {
    return async (dispatch, getState) => {
      const { 
        account: {
          contact: {
            editMode
          }
        },
        payment: { 
          paymentProfileEditMode
        },
        charityRun: {
          ready: enableCharityRun
        },
        eventRegistration: {
          ready: enableEvents,
        }
      } = getState() 
      let toValidate = []
      const skip = editMode || paymentProfileEditMode

      if (enableCharityRun && !skip) toValidate.push(dispatch(checkCharityRunValidity(site, skip)))
      if (enableEvents && !skip) toValidate.push(dispatch(validateEventRegistrationState()))

      toValidate.push(dispatch(checkOptionValidity(site, skip)))
      toValidate.push(dispatch(checkContactValidity(site)))
      toValidate.push(dispatch(checkPaymentValidity(site)))
      await Promise.all(toValidate)

      const {
        account: {
          contact: { __valid: validContact, __errors: contactErrors },
          // billingContact: { __valid: validPayment, __errors: paymentErrors }
        },
        eventRegistration: { __valid: validEvents, __errors: eventErrors },
        payment: { valid: validPayment, errors: paymentErrors, },
        // contact: { valid: validContact, errors: contactErrors, },
        options: { valid: validOptions, errors: optionsErrors, },
        charityRun: { __valid: validCharityRun, __errors: charityRunErrors, },
      } = getState()

      let errors = [
        ...paymentErrors,
        ...contactErrors,
      ]
      let validForSubmission = validPayment && validContact

      if (!skip) {
        validForSubmission = validForSubmission && validOptions
        errors = [
          ...errors,
          ...optionsErrors,
        ]
      }
      if (enableCharityRun && !skip) {
        validForSubmission = validForSubmission && validCharityRun
        errors = [
          ...errors,
          ...charityRunErrors,
        ]
      }
      if (enableEvents && !skip) {
        validForSubmission = validForSubmission && validEvents
        errors = [
          ...errors,
          ...eventErrors,
        ]
      }
      

      const res = {
        valid:  validForSubmission,
        errors: errors.reverse() // reverse for scroll to field
      }
      console.log('res', res)

      if (callback) {
        callback(res)
      }
      return res
    }
  },
}

const connector = connect(mapState, actionCreators)
type PaymentProps = ConnectedProps<typeof connector> & AppRouteProps & { stripe: Stripe }
interface PaymentState {
  loadIndex: number,
  toast: {
    success?: boolean,
    error?: boolean,
    message: number,
  },
  loadingMessageInterval: number,
  loadingToast?: any,

  /** Stripe */
  // paymentRequest?: PaymentRequest,

  /** Plaid */
  selectedPlaidBankAccountId?: string,
  plaidBankAccounts: Array<any>,
  plaidBankAccountsModalOpen: boolean,
  plaidPublicToken?: string,

  /** Preferences */
  preferences: {
    sendEmailUpdates: boolean,
    sendSmsTextUpdates: boolean
  }
}

class Payment extends React.Component<PaymentProps, PaymentState> {
  static contextType = StripeContext;
  state: PaymentState 

  constructor(props) {
    super(props);
    this.state = {
      toast: { message: 0 },
      loadingMessageInterval: 1200,
      loadIndex: 0,
      plaidBankAccounts: [],
      plaidBankAccountsModalOpen: false,
      preferences: {
        sendEmailUpdates: true,
        sendSmsTextUpdates: false
      }
    }
  }

  submitStripeCcPayment = async (
    e,
    recaptchaResponseToken,
    responseHandler,
    stripeInstance,
    billingAddress,
    card,
    setLoading,
    loading,
  ) => {
    if (loading) return
    console.log('[shared][payment] submitStripeCcPayment', this.props)

    e.preventDefault()
    setLoading(true)
    const response = await this.props.stripe.createToken(this.props.elements.getElement(CardNumberElement), {
      name: card.fullName,
      address_line1: billingAddress.streetAddress,
      address_city: billingAddress.city,
      address_state: billingAddress.state,
      address_zip: billingAddress.zip,
      address_country: billingAddress.country,
     })
    setLoading(false)
    responseHandler(response, 'Stripe', recaptchaResponseToken)
  }

  submitPaymentDefault = (e, recaptchaResponseToken, responseHandler) => {
    const {
      type, 
      card, 
      bank, 
      setLoading, 
      loading,
      services,
    } = this.props
    if (loading) return
    console.log('[shared][payment] submitPaymentDefault', this.props)

    let secureData : {[key:string]:any} = {
      authData: {
        clientKey: services.anet.clientKey,
        apiLoginID: services.anet.loginId,
      }, 
    }

    if (type === 'card') {
      secureData.cardData = card
      secureData.cardData.year = secureData.cardData.year.slice(-2) // last 2 digits
    } else {
      secureData.bankData = bank
    }

    // set payment loading to true
    setLoading(true)
    // add a new event `add_card` here
    window.Accept.dispatchData(secureData, res => {
      setLoading(false)
      responseHandler(res, 'Authorize.NET', recaptchaResponseToken)
    })
  }

  ctaOnClick = async (
    e,
    {
      getAccessTokenSilently,
      validForPayment,
      donationAmount,
      processingFee,
      loading,
      ajsLoaded,
      stripeLoaded,
      recaptchaLoaded,
      makeDonation,
      editMode,
      responseHandler,
      submitPayment,
      setRequestError,
      useSaved,
      billingAddress,
      card,
      setShowDetails,
      setDisplayValidationError,
      stripe: stripeInstance,
      setLoading,
      createStripeSetupIntent,
      isAchStripe,
      services,
    }
  ) => {
    console.log('[shared][payment] ctaOnClick', this.props)
    if (!validForPayment) {
      setDisplayValidationError(true)
      setShowDetails(true)
      return
    }
    else {
      setDisplayValidationError(false)
    }
    e.preventDefault()
    if (loading || (!ajsLoaded && !stripeLoaded) || !recaptchaLoaded) {
      return setRequestError({
        code: 'RESOURCE NEEDED',
        message: 'Please wait a few seconds until the page is fully loaded',
      })
    }

    try {
      let recaptchaResponseToken = await reCaptchaReady(services.googleRecaptcha)
    
      if (useSaved && !editMode) {
        makeDonation(
          getAccessTokenSilently, 
          { 
            amount: donationAmount, 
            processingFee, 
            recaptchaResponseToken,
            preferences: this.props.guestMode ? this.state.preferences : null
          }
        )
      }
      else if (isAchStripe) {
        createStripeSetupIntent(getAccessTokenSilently, { recaptchaResponseToken }, async ({ client_secret: clientSecret }) => {
          const { 
            setupIntent: stripeSetupIntent,
            error: stripeError,
          } = await stripeInstance.collectBankAccountForSetup({
            clientSecret,
            params: {
              payment_method_type: 'us_bank_account',
              payment_method_data: {
                billing_details: {
                  name: `${this.props.contact.firstName} ${this.props.contact.lastName}`,
                  email: this.props.email,
                },
              },
            },
            expand: ['payment_method'],
          })

          if (stripeError) {
            console.error(stripeError.message)
            setLoading(false)
            return setRequestError({
              code: stripeError.code || 'STRIPE ERROR',
              message: stripeError.message,
            })
          }
          else if (stripeSetupIntent.status === 'requires_payment_method') {
            setLoading(false)
            return setRequestError({
              code: 'STRIPE ERROR',
              message: 'Confirmation failed. Please try again with a different payment method.',
            })
          }
          else if (stripeSetupIntent.status === 'requires_confirmation') {
            const {
              setupIntent: stripeConfirmSetupIntent,
              error: stripeConfirmError,
            } = await stripeInstance.confirmUsBankAccountSetup(clientSecret)
            if (stripeConfirmError) {
              console.error(stripeConfirmError)
              return setRequestError({
                code: setRequestError.code || 'STRIPE ERROR',
                message: setRequestError.message,
              })
            }
            else if (stripeConfirmSetupIntent.status === 'requires_payment_method') {
              setLoading(false)
              return setRequestError({
                code: 'STRIPE ERROR',
                message: 'Confirmation failed. Please try again with a different payment method.',
              })
            }
            else if ((stripeConfirmSetupIntent.next_action || {}).type === 'requires_payment_method') {
              setLoading(false)
              return setRequestError({
                code: 'VERIFICATION REQUIRED',
                message: 'This account needs to be verified via micro-deposits. Please try another one.',
              })
            }
            else {
              const { payment_method: paymentProfileId } = stripeConfirmSetupIntent
              // recaptchaResponseToken = await reCaptchaReady(services.googleRecaptcha)
              recaptchaResponseToken = await reCaptchaReady(services.googleRecaptcha)
              setLoading(false)
              makeDonation(
                getAccessTokenSilently, 
                { 
                  setupIntentPaymentMethodId: paymentProfileId,
                  amount: donationAmount, 
                  processingFee, 
                  recaptchaResponseToken,
                  preferences: this.props.guestMode ? this.state.preferences : null
                }
              )
            }
          }
        })
      }
      else {
        submitPayment(e, recaptchaResponseToken, responseHandler, stripeInstance, billingAddress, card, setLoading)
      }
    }
    catch(error) {
      console.error(error)
      setRequestError(error?.code ? error : {
        code: 'RECAPTCHA WARNING',
        message: 'Please wait a few minutes and try again.  Your activity has hit our speed limit rules.',
      })
    }
    
  }

  /**
   * On Component Mount [LCM]
   * 
   * 
   */
  componentDidMount(): void {
    if (this.props.loadingCustomer) {
      const loadingToast = setTimeout(() => {
        this.setState({ toast: { message: this.state.toast.message + 1 } });
      }, this.state.loadingMessageInterval);

      this.setState({
        loadingToast
      })
    }

    loadScript(LoadScript.ANET)
      .then((result) => {
        console.log('[shared][payment] loaded anet', result)
        this.props.setAjsLoaded(true)
      })
    
    if (!this.props.recaptcha.ready) loadRecaptcha()
    if (!this.props.recaptcha.token) this.props.getRecaptchaToken()

    // Plaid is disabled now
    // if (plaidSupportedSites.includes(this.props.site)) {
    //   loadScript(LoadScript.PLAID)
    //     .then(async (result) => {
    //       const plaidToken = (await axiosServer.post(plaidCreateLinkTokenUrl, {
    //         id: 'test-id',
    //         site: this.props.site,
    //       })).data.link_token
    //       if (plaidToken) {
    //         plaidLinkHandler = window.Plaid.create({
    //           token: plaidToken,
    //           onLoad: () => {
    //             this.props.setPlaidLoaded(true)
    //           },
    //           onEvent: this.plaidOnEvent,
    //           onSuccess: this.plaidOnSuccess,
    //           onExit: this.plaidOnExit,
    //         })
    //       }
    //     })
    // }
    
    // this.attachAcceptScript()
    // this.attachRecaptchaScript(this.props.services.googleRecaptcha)
    // if (plaidSupportedSites.includes(this.props.site)) {
    //   this.attachPlaidScript()
    // }
    if (this.props.editMode && !this.props.donationAmount) {
      const term = this.props.donationType || 'monthly', isMonthly = term === 'monthly'
      const amount = Number(this.props.plans[term][isMonthly ? 1 : 0])
      this.props.setDonationAmount(amount)
    }
    if (this.props.stripe) {
      // window.stripeInstance = this.props.stripe
      this.props.setStripeLoaded(true)
      if (!this.props.editMode) {
        this.updatePaymentRequest()
      }
    }

    // adding new payment method and payment type is not selected
    if (this.props.layoutConfig) {
      if (this.props.layoutConfig.initialPaymentType === undefined) {
        this.props.setTypePayment(null)
      }
    }
    else if (this.props.addingNew && !this.props.type) {
      this.props.setTypePayment('card')
    }

    if (['monthly', 'yearly'].includes(this.props.donationType)) {
      this.setRootSubscriptionPaymentType()
    }

    if (this.props.successInfo) {
      this.props.router.navigate('/success')
      return
    }
  }

  plaidOnEvent = e => {
    if (e === 'HANDOFF') {
      // Success and "Continue" btn is clicked
      this.setState({ plaidBankAccountsModalOpen: true })
    }
  }

  plaidOnSuccess = async (publicToken, metadata) => {
    const availableAccounts = (metadata.accounts || [metadata.account]).filter(a => a)
    const account_id = _.get(metadata, 'account_id') || _.get(metadata, ['accounts', 0, 'id'], null)
    // Throw error when no available accounts
    this.setState({
      plaidBankAccounts: availableAccounts,
      plaidBankAccountsModalOpen: true,
      plaidPublicToken: publicToken,
      selectedPlaidBankAccountId: account_id,
    })
    const { setLoading, onTogglePlaidAccountsModal } = this.props
    setLoading(false)
    if (onTogglePlaidAccountsModal) {
      onTogglePlaidAccountsModal(true)
    }
  }

  plaidOnExit = (err, metadata) => {
    // The user exited the Link flow.
    if (err != null) {
      console.error(err, metadata)
      // The user encountered a Plaid API error
      // prior to exiting.
      this.props.setRequestError({ code: 'ERR', message: 'Failed to retrieve bank account information'})
    }
    // metadata contains information about the institution
    // that the user selected and the most recent
    // API request IDs.
    // Storing this information can be helpful for support.
  }

  responseHandler = (response, paymentGateway, recaptchaResponseToken) => {
    const { 
      makeDonation, 
      getAccessTokenSilently, 
      donationAmount, 
      processingFee, 
      setRequestError, 
      setLoading, 
      editMode, 
      addingNew,
      updatePaymentMethod, 
      onClose,
      guestMode,
      accountId,
    } = this.props
    try {
      let errCode, errMsg, tokenCode, cardDetails = null

      if (paymentGateway === 'Authorize.NET') {
        if (response.messages.resultCode && (response.messages.resultCode.toLowerCase() !== 'ok')) {
          errCode = response.messages.message[0].code
          errMsg = response.messages.message[0].text
        }
        tokenCode = _.get(response, ['opaqueData', 'dataValue'], '')
        cardDetails = _.pick(response, ['encryptedCardData', 'customerInformation'])
      }
      else {
        errCode = _.get(response, ['error', 'code'], '').toUpperCase()
        errMsg = _.get(response, ['error', 'message'], '')
        tokenCode = _.get(response, ['token', 'id'], '')
        cardDetails = _.get(response, ['token', 'card'], null) || _.get(response, ['token', 'bank_account'], null)
      }


      if (errCode || errMsg) {
        throw new Error(`${errCode}:${errMsg}`)

      }
      else if (editMode) {
        updatePaymentMethod(getAccessTokenSilently, { 
          cardNumber: tokenCode,
          recaptchaResponseToken,
          cardDetails,
          addingNew,
          paymentGateway,
        }, onClose)
      }
      else {
        makeDonation(
          getAccessTokenSilently, 
          { 
            accountId,
            cardNumber: tokenCode, 
            amount: donationAmount, 
            processingFee, 
            recaptchaResponseToken, 
            cardDetails, 
            paymentGateway,
            preferences: guestMode ? this.state.preferences : null
          }
        )
      }
    } catch (e) {
      console.error('[payment] error:', e)

      if (e instanceof Error) {
        const [ code, message = '' ] = e.message.split(':')
        setRequestError({
          code,
          message,
        })
      }

      else if (typeof e === 'string') {
        setRequestError({
          code: 520,  // Catch all
          message: e,
        })
      }
      
      setLoading(false)
    }
    
  }

  renderBankAccountModal = () => {
    const { plaidBankAccounts, selectedPlaidBankAccountId, plaidPublicToken } = this.state
    const { 
      donationAmount, 
      processingFee, 
      setLoading, 
      setRequestError, 
      makeDonation, 
      getAccessTokenSilently, 
      loading, 
      editMode, 
      addingNew, 
      updatePaymentMethod, 
      onClose, 
      onTogglePlaidAccountsModal,
      guestMode,
      site,
      services,
    } = this.props

    const handleCancel = () => {
      this.setState({
        plaidBankAccountsModalOpen: false,
        plaidBankAccounts: [],
        selectedPlaidBankAccountId: null,
        plaidPublicToken: null,
      })
      if (onTogglePlaidAccountsModal) {
        onTogglePlaidAccountsModal(false)
      }
    }

    const handleContinue = async () => {
      try {
        setLoading(true)
        const stripeBankAccountToken = (await axiosServer.post(plaidGetStripeTokenUrl, {
          token: plaidPublicToken,
          id: selectedPlaidBankAccountId,
          site,
        })).data
        const selectedPlaidBankAccount = plaidBankAccounts.find(a => a.id === selectedPlaidBankAccountId)
        // const recaptchaResponseToken = await window.grecaptcha.execute(services.googleRecaptcha, { action: 'submit' })
        const recaptchaResponseToken = await reCaptchaReady(services.googleRecaptcha)
        if (onTogglePlaidAccountsModal) {
          onTogglePlaidAccountsModal(false)
        }
        setLoading(false)
        const cardDetails = {
          object: 'bank_account',
          last4: selectedPlaidBankAccount.mask,
        }
        if (editMode) {
          updatePaymentMethod(getAccessTokenSilently, {
            cardNumber: stripeBankAccountToken, 
            accountType: selectedPlaidBankAccount.name, 
            recaptchaResponseToken,
            addingNew,
            cardDetails,
            paymentGateway: 'Stripe',
          }, onClose)
        }
        else {
          makeDonation(getAccessTokenSilently, {
            cardNumber: stripeBankAccountToken,
            accountType: selectedPlaidBankAccount.name,
            amount: donationAmount,
            processingFee,
            recaptchaResponseToken,
            cardDetails,
            preferences: guestMode ? this.state.preferences : null
          })
        }
      }
      catch(error) {
        console.error(error)
        const errorMessage = error?.code ? error : _.get(error, ['response', 'data', 'error'], _.get(error, ['response', 'data'], error))
        setLoading(false)
        setRequestError(errorMessage)
      }
    }

    return (
      <Modal size="lg" show={true} className={`modal-${site}`} onHide={handleCancel}>
        <Modal.Header closeButton>Please Select Your Bank Account</Modal.Header>
        <Modal.Body>
          { loading ? <Spinner mask={true} /> : null }
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>Account Number</th>
                <th>Selected</th>
              </tr>
            </thead>
            <tbody>
              {
                plaidBankAccounts.map(a => (
                  <tr key={a.id}>
                    <td>{a.name}</td>
                    <td>{`XXXX ${a.mask}`}</td>
                    <td>
                      <Form.Check type="radio"
                        readOnly
                        checked={a.id === selectedPlaidBankAccountId}
                        onChange={() => {return}}
                        onClick={() => this.setState({ selectedPlaidBankAccountId: a.id })}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <div className="full-width left">
            <Button
              disabled={loading}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="btn-donate float-right"
              disabled={loading && !selectedPlaidBankAccountId}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

  getAmountsToUse = () => {
    const { includeProcessingFee, processingFee, eventRegistration, type } = this.props
    let donationAmount, processingFeeToUse
    if (eventRegistration.checkout) {
      donationAmount = eventRegistration.checkout.totalAmount
      processingFeeToUse = includeProcessingFee ? calculateProcessingFee(eventRegistration.checkout.totalAmount, null, type) : 0
    }
    else {
      donationAmount = Number(this.props.donationAmount)
      processingFeeToUse = includeProcessingFee ? processingFee : 0
    }
    const totalAmount = donationAmount + processingFeeToUse
    return { donationAmount, processingFeeToUse, totalAmount }
  }

  renderSummary = (withDisclaimerText) => {
    const { includeProcessingFee, processingFee, site, eventRegistration, type } = this.props
    let { donationAmount, processingFeeToUse } = this.getAmountsToUse()
    // if (eventRegistration.checkout) {
    //   donationAmount = eventRegistration.checkout.totalAmount
    //   processingFeeToUse = includeProcessingFee ? calculateProcessingFee(eventRegistration.checkout.totalAmount, null, type) : 0
    // }
    // else {
    //   donationAmount = Number(this.props.donationAmount)
    //   processingFeeToUse = includeProcessingFee ? processingFee : 0
    // }
    const processingFeePercentage = donationAmount ? processingFeeToUse / donationAmount * 100 : 3
    return (
      <div className="summary-box mt2">
        <h4>Your Donation Summary</h4>
        <div>
          <div><b>Donation:&nbsp;</b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( donationAmount )}</div>
          <div><b>Processing Fee:&nbsp;</b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( processingFeeToUse )}</div>
          {
            withDisclaimerText && (
              <div>
                <p className="disclaimer-text">
                  {`${siteProcessingFeeText[site]} Add ${processingFeePercentage.toFixed(1)}% to your donation and cover our credit card processing fees?`}
                </p>
              </div>
            )
          }
          <div>Total:&nbsp;{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format( donationAmount+processingFeeToUse )}</div>
        </div>
      </div>
    )
  }

  updatePaymentRequest = () => {
    const { paymentRequestConfig, setLoading, services } = this.props

    this.context.initPaymentRequest({
      paymentRequestOptions: paymentRequestConfig,

      onPaymentRequestSuccess: async (response) => {
        console.log('onPaymentRequestSuccess')
        console.log('Payment.updatePaymentRequest.stripeUpdatePaymentRequest:response', response)
        // this.setState({ paymentRequest: this.props.stripeContext.paymentRequest })

        const recaptchaResponseToken = await reCaptchaReady(services.googleRecaptcha)
        setLoading(false)
        this.responseHandler(response, 'Stripe', recaptchaResponseToken)
        // this.updatePaymentRequest()
      },

      onPaymentRequestCancel: () => {
        // this.updatePaymentRequest()
      },

      onCanMakePayment: (canMakePayment) => {
        if (this.props.updateStripeCanMakePayment) {
          this.props.updateStripeCanMakePayment(canMakePayment)
        }
      }
    })
  }

  setRootSubscriptionPaymentType = () => {
    const { type, setTypePayment, rootSubscription } = this.props
    if (rootSubscription) {
      const rootSubscriptionGateway = rootSubscription.paymentGateway
      if (rootSubscriptionGateway === 'Authorize.NET') {
        if (type !== 'bank_ach') setTypePayment('bank_ach')
      }
      else {
        if (type === 'bank_ach') setTypePayment('card')
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingCustomer && !this.props.loadingCustomer) {
      clearTimeout(this.state.loadingToast)
    }

    if (!prevProps.monthlyOtherAmount && this.props.monthlyOtherAmount) {
      this.props.setConfirmedMonthly(false)
    }
    if (prevProps.monthlyOtherAmount && !this.props.monthlyOtherAmount) {
      this.props.setConfirmedMonthly(true)
    }
    if (!prevProps.successInfo && this.props.successInfo) {
      this.props.router.navigate('/success')
      return
    }
    if (prevProps.loading && !this.props.loading) {
      this.setState({ plaidBankAccountsModalOpen: false })
    }

    /** 
     * Reload Stripe Payment Request workflow if there was
     * an error in post workflows
     */
    if (this.props.paymentGateway === 'Stripe' && 
      !prevProps.error && this.props.error && 
      !this.props.editMode
    ) {
      this.updatePaymentRequest()
    }

    if (!prevProps.stripe && this.props.stripe) {
      window.stripeInstance = this.props.stripe
      this.props.setStripeLoaded(true)
    }

    if (prevProps.type === 'bank_ach' && this.props.type !== 'bank_ach') {
      this.props.enterBank({ accountType: 'Individual' })
    }
    else if (prevProps.type !== 'bank_ach' && this.props.type === 'bank_ach') {
      this.props.enterBank({ accountType: 'checking' })
    }
    // if (this.props.donationType === 'monthly' && this.props.type === 'bank_ach') {
    //   this.props.setTypePayment('card')
    // }
    if (
      !this.props.mailChimpUpdated && location.pathname.startsWith('/donate') &&
      this.props.validForPayment && !prevProps.validForPayment && 
      this.props.recaptcha.ready && !this.props.profileAuth0
    ) {
      this.props.updateMailChimpWithTag('Cart_Abandonment')
      sendGtmPixel({
        event: 'ready_for_payment',
        email: this.props.email,
      })
    }
    if (
      (['monthly', 'yearly'].includes(this.props.donationType) && prevProps.donationType !== this.props.donationType) ||
      (this.props.rootSubscription && !prevProps.rootSubscription)
    ) {
      this.setRootSubscriptionPaymentType()
    }
    if (this.props.forceCheckValidity && !prevProps.forceCheckValidity) {
      this.props.checkValidity(this.props.site, this.props.checkValidityCallback)
    }
  }


  render() {
    const {
      validForPayment,
      loading,
      setLoading,
      ajsLoaded,
      stripeLoaded,
      plaidLoaded,
      paymentGateway,
      showDetails,
      type, card, bank, bankStripe,
      enterCard, enterBank, enterBankStripe,
      subscription,
      subscriptionYearly,
      contact,
      billingAddress,
      billingAddressSameAsContact,
      donationType, donationAmount, confirmedMonthly,
      setTypePayment,
      setConfirmedMonthly,
      useSaved,
      makeDonation,
      monthlyOtherAmount,
      profileAuth0,
      saveAppState,
      email,
      error,
      getAccessTokenSilently,
      validationErrorMessage,
      displayValidationError,
      setDisplayValidationError,
      setRequestError,
      setShowDetails,
      processingFee,
      includeProcessingFee,
      setIncludeProcessingFee,
      editMode,
      onClose,
      withTitle,
      titleText,
      guestMode,
      site,
      crm,
      createStripeSetupIntent,
      
      elements,
      services,
      accountId,
      charityRun,
      checkValidity,
      paymentErrors,
      eventRegistration,
      recaptcha,
      layoutConfig,
      hideCtaButton,
      showCtaButtonOnly,
      showFormOnly,
      sslVisible,
      errorVisible = true,
      toastVisible = true,
    } = this.props
    const {
      stripe,
      paymentRequest,
      canMakePayment: appleGoogleSupported = { applePay: false, googlePay: false, link: false },
    } = this.context
    const eventMinAmount = _.get(eventRegistration, ['campaignParams', 'minDonationAmount'])
    let { totalAmount } = this.getAmountsToUse()

    if (!services) return <Spinner />

    const { plaidBankAccountsModalOpen, preferences: { sendEmailUpdates, sendSmsTextUpdates } } = this.state
    const useAnet = paymentGateway === 'Authorize.NET' || type === 'bank_ach'
    // const useAnet = false
    const useStripeCardBody = paymentGateway === 'Stripe' && type === 'card' && ((showDetails && !useSaved) || editMode)
    const useAppleGooglePay = paymentGateway === 'Stripe' && ['applePay', 'googlePay'].includes(type) && showDetails && !useSaved && !editMode
    const recaptchaLoaded = !!window.grecaptcha
    const ctaText = (
      donationType === 'one-time' || 
      (!subscription && !subscriptionYearly) || 
      (!subscription && (donationType === 'monthly')) ||
      (!subscriptionYearly && (donationType === 'yearly')) 
    ) ? 'Donate Now' : 'Update Now'
    const ctaProps = {
      getAccessTokenSilently,
      validForPayment,
      donationAmount,
      processingFee,
      useSaved,
      loading,
      setLoading,
      ajsLoaded,
      stripe,
      stripeLoaded,
      recaptchaLoaded,
      elements,
      makeDonation,
      responseHandler: this.responseHandler,
      setRequestError,
      setDisplayValidationError,
      setShowDetails,
      billingAddress: billingAddressSameAsContact ? { ...contact ,...contact.address} : billingAddress,
      card,
      bankStripe,
      editMode,
      onClose,
      createStripeSetupIntent,
      services,
      recaptcha,
      accountId,
      charityRun,
      eventRegistration,
      checkValidity,
      paymentErrors,
    }
    const plaidSupported = plaidSupportedSites.includes(site)
    const disableAppleGoogle = !stripeLoaded || !recaptchaLoaded

    const loadingMessages = [
      "Hang tight, while we securely load...",
      "Loading your account...",
      "Loading available payment options...",
      "Finalizing...",
      "Finalizing...",
      "Finalizing..."
    ]

    let title
    if (layoutConfig?.paymentDetails?.titleStyle) {
      title = <h3 className='donate-headline' style={layoutConfig?.paymentDetails?.titleStyle}>
        {titleText || `Choose payment method`}
      </h3>
    }
    else {
      title = withTitle ? (
        guestMode ? (
          <legend>{titleText || `Choose payment method`}</legend>
        ) : <legend style={{ margin: 0 }}>Payment Information</legend>
      ) : null
    }
    const confirmMonthly = (donationType === 'monthly' && monthlyOtherAmount && !editMode) ? (
      <div>
        <Form.Check id="confirm-monthly-donation"
          className="mt2"
          style={{
            cursor: 'pointer',
            ...(displayValidationError && !confirmedMonthly ? {color: '#a94442', fontWeight: 'bold'} : null)
          }}
        >
          <Form.Check.Input
            type="checkbox"
            isInvalid={displayValidationError && !confirmedMonthly}
            checked={confirmedMonthly}
            onChange={() => {return}}
            onClick={() => setConfirmedMonthly(!confirmedMonthly)}
          />
          <Form.Check.Label>I confirm this is a&nbsp;<strong>Monthly</strong>&nbsp;donation</Form.Check.Label>
        </Form.Check>
      </div>
    ) : null
    const confirmProcessingFee = !editMode && !guestMode && (
      <div>
        <Form.Check id="transaction-fee-flag">
          <Form.Check.Input 
            type="checkbox"
            checked={includeProcessingFee}
            onChange={() => {return}}
            onClick={() => setIncludeProcessingFee(!includeProcessingFee)}
          />
          <Form.Check.Label>Cover transaction fee</Form.Check.Label>
        </Form.Check>
      </div>
    )

    const ctaButtonHandler = async (e) => {
      console.info('preventing submission')
      e.preventDefault()

      try {
        const res = await checkValidity(site)
        if (!res.valid) {
          this.setState({ 
            toast: {
              error: true,
              success: false,
            }
          })
          setTimeout(() => {
            this.setState({
              toast: { 
                error: false,
                success: false,
              }
            })
          }, 3000);
          console.error('Validation Errors', res.errors)
          
          setDisplayValidationError(true)
          setShowDetails(true)
          scrollToError(res.errors)
        }
        else if (!useStripeCardBody && (!useSaved || editMode) && type !== 'bank_ach_stripe' && !useAnet && window.Plaid) {
          plaidLinkHandler.open()
        }
        else {
          if (checkClickInterval()) {
            return await this.ctaOnClick(e, { 
              ...ctaProps,
              isAchStripe: type === 'bank_ach_stripe', 
              submitPayment: useStripeCardBody ? this.submitStripeCcPayment : this.submitPaymentDefault 
            })
          } else {
            console.error('click interval failed')
          }
        }

      } catch(e) {
        console.error(e)
      }
    }

    const ctaButton = useAppleGooglePay ? (
      paymentRequest && (
        <div 
          className="pr-btn-box" 
          style={{
            ...(loading ? {} : { cursor: 'pointer' }),
            ...(layoutConfig?.paymentDetails?.ctaButtonStyle),
            ...(hideCtaButton ? { display: 'none' } : null),
          }}
          onClick={async (e) => {
            let validCharityRun = true
            if (charityRun.ready) {
              await validateAttendees()
              validCharityRun = !charityRun.errors.length
            }

            if (!validForPayment || !validCharityRun) {
              setDisplayValidationError(true)
              setShowDetails(true)
            }
            else {
              setDisplayValidationError(false)
              if (!loading) {
                setLoading(true)
              }
            }
          }}
        >
          { 
            (loading || disableAppleGoogle || !validForPayment || (editMode && plaidBankAccountsModalOpen)) ? 
            <Spinner 
              mask
              transparent={!validForPayment}
            /> : 
            null 
          }
          <PaymentRequestButtonElement 
            onClick={(e) => {
              this.context.updatePaymentRequest(this.props.paymentRequestConfig, e)
            }}
            
            options={{
              paymentRequest, 

              style: {
                paymentRequestButton: {
                  type: 'donate'
                  }
                }
              }
            } 
          />
        </div>
      )
    ) : (
      <div 
        className={editMode ? 'modal-footer edit-mode' : `${guestMode ? '' : 'flex v-center'} mt2`}
        style={hideCtaButton ? { display: 'none' } : null}
      >
        {
          editMode ? (
            <Button 
              onClick={onClose}
            >
              Cancel
            </Button>
          ) : null
        }
        <Button
          type="button"
          style={{
            ...(editMode ? {} : { padding: '1rem 2rem'}),
            ...(guestMode ? { width: 200 } : {}),
            ...(layoutConfig?.paymentDetails?.ctaButtonStyle),
          }}
          disabled={loading || !ajsLoaded || !stripeLoaded || !recaptchaLoaded}
          className={`btn-donate ${editMode ? 'float-right' : ''}`}
          onClick={ctaButtonHandler}
        >
          {editMode ? 'Submit' : ctaText}
        </Button>
      </div>
    )
    const errMsg = (error && errorVisible) ? (
      <RequestError error={error} saveAppState={saveAppState} email={email} profileAuth0={profileAuth0} site={site} />
    ) : (
      (!validForPayment && displayValidationError) ? (
        <div className={`mt1 ${editMode ? 'mb2' : ''}`} style={{ color: '#a94442' }}>
          { validationErrorMessage }
        </div>
      ) : null
    )
    const donationText = !editMode && !guestMode && (
      <label className="msg">
        {
          donationAmount ?
            `You have selected a ${donationType.toUpperCase()} donation of $${donationAmount}` :
            'You haven\'t entered the amount of donation'
        }
      </label>
    )
    const hideDisclaimer = layoutConfig?.fundDisclaimer?.visible === false
    const spinner = (useAnet ? ajsLoaded : stripeLoaded) && !(editMode && plaidBankAccountsModalOpen) ? null : <Spinner mask={true} hideicon={true} />
    const fundDisclaimer = hideDisclaimer ? null : (
      <FundDisclaimer site={site} guestMode={guestMode}/>
    )
    const donationSummary = (includeProcessingFee && !guestMode) ? this.renderSummary(!hideDisclaimer && !fundDisclaimer) : null
    const disclaimer = !hideDisclaimer && !fundDisclaimer && !editMode && !guestMode && siteDisclaimerText[site] && (
      <div>
        <p className="disclaimer-text">
          { siteDisclaimerText[site] }
        </p>
        {/* <br /> */}
      </div>
    )

    /// Issue here
    ///
    if (!showDetails && !editMode) {
      console.group('[shared][payments] render scenario 1')
      console.info('stripeLoaded',stripeLoaded, 'recaptchaLoaded',recaptchaLoaded)

      console.groupEnd();
      return (
        <>
          { title }
          { spinner }
          { donationText }
          <br />
          { confirmMonthly }
          { errMsg }
          <RecaptchaV2Wrapper services={services} />
          { ctaButton }
          { fundDisclaimer }
        </>
      )
    }

    const anetInputConfigs = layoutConfig?.paymentDetails?.anet || {}
    const showInputTitles = layoutConfig?.paymentDetails?.showInputTitles
    const inputSize = layoutConfig?.paymentDetails?.inputSize
    const cardInputs = type === 'card' && (
      useAnet ? (
        // Authorize.net card inputs
        <Row className={editMode ? 'mb2' : ''}>
          <Col 
            xs={anetInputConfigs.cardName?.xs || 12} 
            sm={anetInputConfigs.cardName?.sm || 6} 
            className={(displayValidationError && !card.fullName) ? 'has-error' : ''}
          >
            <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Name on Card">
              <Form.Control
                type="text"
                className="mb1"
                title="Name on Card"
                value={card.fullName}
                onChange={e => enterCard({'fullName': e.target.value.trimStart()})}
                autoComplete="cc-name"
                maxLength={22}
                size={inputSize}
                placeholder={showInputTitles ? "Full Name" : "Name on Card"}
              />
            </InputWrapperByShowTitle>
          </Col>
          <Col
            xs={anetInputConfigs.cardNumber?.xs || 12} 
            sm={anetInputConfigs.cardNumber?.sm || 6} 
            className={(displayValidationError && !card.cardNumber) ? 'has-error' : ''}
          >
            <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Card Number">
              <Form.Control
                type="text"
                className="mb1"
                title="Card Number"
                value={fourDigitSplit(card.cardNumber)}
                onChange={e => enterCard({'cardNumber': e.target.value.trim().replace(/ /g, '')})}
                autoComplete="cc-number"
                size={inputSize}
                placeholder={showInputTitles ? "1234 1234 1234 1234" : "Card Number"}
              />
            </InputWrapperByShowTitle>
          </Col>
          <Col 
            xs={anetInputConfigs.month?.xs || 4} 
            sm={anetInputConfigs.month?.sm || 4} 
            className={(displayValidationError && !card.month) ? 'has-error' : ''}
          >
            <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Expiry Month">
              <Form.Control
                type="text"
                className="mb1"
                title="MM"
                value={card.month.slice(0,2)}
                onChange={e => enterCard({'month': e.target.value.trim().slice(0,2)})}
                autoComplete="cc-exp-month"
                size={inputSize}
                placeholder="MM"
              />
            </InputWrapperByShowTitle>
          </Col>
          <Col 
            xs={anetInputConfigs.year?.xs || 4} 
            sm={anetInputConfigs.year?.sm || 4} 
            className={(displayValidationError && !card.year) ? 'has-error' : ''}
          >
            <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Expiry Year">
              <Form.Control
                type="text"
                className="mb1"
                title="YY"
                value={card.year.slice(-2)}
                onChange={e => enterCard({'year': e.target.value.trim().slice(-2)})}
                autoComplete="cc-exp-year"
                size={inputSize}
                placeholder="YY"
              />
            </InputWrapperByShowTitle>
          </Col>
          <Col 
            xs={anetInputConfigs.cardCode?.xs || 4} 
            sm={anetInputConfigs.cardCode?.sm || 4} 
            className={(displayValidationError && !card.cardCode) ? 'has-error' : ''}
          >
            <InputWrapperByShowTitle showInputTitles={showInputTitles} title="CVC/CVV">
              <Form.Control
                type="text"
                className="mb1"
                title="Code"
                value={card.cardCode}
                onChange={e => enterCard({'cardCode': e.target.value.trim()})}
                autoComplete="cc-csc"
                size={inputSize}
                maxLength={4}
                placeholder={showInputTitles ? "123" : "CVC/CVV"}
              />
            </InputWrapperByShowTitle>
          </Col>
        </Row>
      ) : (
        // Stripe CC inputs
        <StripeCardBody
          displayValidationError={displayValidationError}
          enterCard={enterCard}
          ctaText={ctaText}
          validForPayment={validForPayment}
          setDisplayValidationError={setDisplayValidationError}
          {...ctaProps}
          ctaOnClick={this.ctaOnClick}
          submitPayment={this.submitStripeCcPayment}
          site={site}
          loading={loading}
          layoutConfig={layoutConfig}
        />
      )
    )

    // Not used yet
    // const currencyInputs = !useAnet && (
    //   <Col xs={12}>
    //     <label className="pre-field required">Account Currency</label>
    //     <br />
    //     <Row>
    //       <Col xs={12} sm={6}>
    //         <div className="full-width-dropdown mb1">
    //           <DropdownButton
    //             id="bank-account-country-menu"
    //             title={<span className="flex-1 left">{countryList.find(c => c.code === bank.country).name}</span>}
    //           >
    //             {
    //               countryList.map(c => (
    //                 <Dropdown.Item
    //                   key={c.code}
    //                   active={bank.country === c.code}
    //                   onClick={() => enterBank({ country: c.code })}
    //                 >
    //                   { c.name }
    //                 </Dropdown.Item>
    //               ))
    //             }
    //           </DropdownButton>
    //         </div>
    //       </Col>
    //       <Col xs={12} sm={6}>
    //         <div className="full-width-dropdown mb1">
    //           <DropdownButton
    //             id="bank-account-currency-menu"
    //             title={<span className="flex-1 left">{currencyList.find(c => c.code === bank.currency).name}</span>}
    //           >
    //             {
    //               currencyList.map(c => (
    //                 <Dropdown.Item
    //                   key={c.code}
    //                   active={bank.currency === c.code}
    //                   onClick={() => enterBank({ currency: c.code })}
    //                 >
    //                   { c.name }
    //                 </Dropdown.Item>
    //               ))
    //             }
    //           </DropdownButton>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Col>
    // )

    const bankAchAnetInputs = (type === 'bank_ach') && useAnet && (
      <Row className={`${editMode ? 'mb2' : ''} ${guestMode ? 'mt1' : ''}`}>
        <Col 
          xs={anetInputConfigs.nameOnAccount?.xs || 12} 
          sm={anetInputConfigs.nameOnAccount?.sm || 4} 
          className={(displayValidationError && !bank.nameOnAccount) ? 'has-error' : ''}
        >
          <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Name on Account">
            <Form.Control
              type="text"
              className="mb1"
              title="Name on Account"
              value={bank.nameOnAccount}
              onChange={e => enterBank({'nameOnAccount': e.target.value.trimStart()})}
              maxLength={22}
              placeholder={showInputTitles ? "Full Name" : "Name on Account"}
              size={inputSize}
            />
          </InputWrapperByShowTitle>
        </Col>
        <Col 
          xs={anetInputConfigs.routingNumber?.xs || 12} 
          sm={anetInputConfigs.routingNumber?.sm || 4} 
          className={(displayValidationError && !bank.routingNumber) ? 'has-error' : ''}
        >
          <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Routing Number">
            <Form.Control
              type="text"
              className="mb1"
              title="Bank Routing Number"
              value={bank.routingNumber}
              onChange={e => enterBank({'routingNumber': e.target.value.trim().replace(/ /g, '')})}
              placeholder={showInputTitles ? "123456789" : "Routing Number"}
              size={inputSize}
            />
          </InputWrapperByShowTitle>
        </Col>
        <Col 
          xs={anetInputConfigs.accountNumber?.xs || 12} 
          sm={anetInputConfigs.accountNumber?.sm || 4} 
          className={(displayValidationError && !bank.accountNumber) ? 'has-error' : ''}
        >
          <InputWrapperByShowTitle showInputTitles={showInputTitles} title="Account Number">
            <Form.Control
              type="text"
              className="mb1"
              title="Bank Account Number"
              value={bank.accountNumber}
              onChange={e => enterBank({'accountNumber': e.target.value.trim().replace(/ /g, '')})}
              placeholder={showInputTitles ? "123456789" : "Account Number"}
              size={inputSize}
            />
          </InputWrapperByShowTitle>
        </Col>
        <Col xs={12} className="account-type-menu">
          <label className="pre-field required">Account Type</label>
          <br />
          {
            [0, 1].map(mobile => (
              <ButtonGroup key={mobile} vertical={!!mobile} className={`${mobile ?  'd-block d-sm-none': 'd-none d-sm-block'} ${guestMode ? 'full-width' : ''}`}>
                {
                  ['checking', 'saving', 'businessChecking'].map(accountType => layoutConfig?.paymentDetails?.anet?.accountType?.style === 'checkbox' ? (
                    <Form.Check id={`anet-account-type-${accountType}`} key={accountType} className='inline-block mr2'>
                      <Form.Check.Input
                        type="checkbox"
                        inline={+true}
                        checked={bank.accountType === accountType}
                        onChange={() => {return}}
                        onClick={() => enterBank({'accountType': accountType})}
                      />
                      <Form.Check.Label>{bankAccountNames[accountType]}</Form.Check.Label>
                    </Form.Check>
                  ) : (
                    <Button
                      style={(guestMode && !mobile) ? {width: '33.33%'} : null}
                      key={accountType}
                      className={`btn-option ${bank.accountType === accountType ? 'selected' : ''}`}
                      onClick={() => enterBank({'accountType': accountType})}
                    >
                      {bankAccountNames[accountType]}
                    </Button>
                  ))
                }
              </ButtonGroup>
            ))
          }
        </Col>
        {/* { currencyInputs } */}
      </Row>
    )

    // const bankAchStripeInputs = (type === 'bank_ach_stripe') && (
    //   <Row className={`${editMode ? 'mb2' : ''} ${guestMode ? 'mt1' : ''}`}>
    //     <Col xs={12} sm={6} className={(displayValidationError && !bankStripe.accountName) ? 'has-error' : ''}>
    //       <Form.Control
    //         type="text"
    //         className="mb1"
    //         title="Account Name"
    //         value={bankStripe.accountName}
    //         onChange={e => enterBankStripe({'accountName': e.target.value.trimStart()})}
    //         maxLength={22}
    //         placeholder="Account Name"
    //       />
    //     </Col>
    //     <Col xs={12} sm={6} className={(displayValidationError && !bankStripe.accountEmail) ? 'has-error' : ''}>
    //       <Form.Control
    //         type="text"
    //         className="mb1"
    //         title="Account Email"
    //         value={bankStripe.accountEmail}
    //         onChange={e => enterBankStripe({'accountEmail': e.target.value.trim().replace(/ /g, '')})}
    //         placeholder="Account Email"
    //       />
    //     </Col>
    //   </Row>
    // )
    const bankAchStripeInputs = null

    const paymentBody = (
      <div className="mt2">
        {
          (type && (!useSaved || editMode)) ? (
            cardInputs || bankAchAnetInputs || bankAchStripeInputs
          ) : null
        }
        { 
          ['bank', 'bank_ach_stripe'].includes(type) && (
            <label className="pre-field">
              {`Please click the "${editMode ? 'Submit' : 'Donate Now'}" button to enter your bank account information after completing the required fields.`}
            </label>
          )
        }
        {
          ['applePay', 'googlePay'].includes(type) && (
            <label className="pre-field">
              {`Please click the ${methodNames[type]} button below to after completing the required fields.`}
            </label>
          )
        }
      </div>
    )

    let paymentMethodKeys = (useSaved && !editMode) ? null : [
      'card', 'bank_ach_stripe', 
      ...(anetSupportedSites.includes(site) ? ['bank_ach'] : []),
      // ...((paymentGateway === 'Stripe' && donationType === 'one-time') ? ['bank_ach'] : []),
      ...((appleGoogleSupported && !editMode) ? ['applePay', 'googlePay'].filter(v => appleGoogleSupported[v]) : [])
    ]

    if (ctaText === 'Update Now' && !editMode) {
      const subscriptionGateway = (donationType === 'monthly' ? subscription : subscriptionYearly).paymentGateway
      if (subscriptionGateway === 'Authorize.NET') {
        paymentMethodKeys = ['bank_ach']
      }
      else if (paymentMethodKeys) {
        paymentMethodKeys = paymentMethodKeys.filter(t => t !== 'bank_ach')
      } else {
        paymentMethodKeys = []
      }
    }

    const paymentMethodMenuEntries = paymentMethodKeys ? paymentMethodKeys.filter(
      t => !(layoutConfig?.paymentDetails?.excludedMethods || []).includes(t)
    ).map(t => (
      <div 
        key={t}
        className="payment-method-entry"
        style={layoutConfig?.paymentDetails?.menuEntryStyle || null}
        onClick={() => {
          if (t !== type) setTypePayment(t)
        }}
      >
        {
          showFormOnly ? null : (
            <div className="flex v-center full-width">
              <div className="flex-1 flex v-center">
                <img src={`https://donate.savethestorks.com/images/${paymentMethodImageUrls[t]}`} className="payment-method-icon"/>
                <span className="payment-method-text">
                  {editMode ? `${methodNames[t][0].toUpperCase()}${methodNames[t].slice(1)}` : `Pay with ${methodNames[t]}`}
                </span>
              </div>
              <div className={`fa fa-check-circle ${t === type ? 'checked' : ''}`}/>
            </div>
          )
        }
        { t === type ? paymentBody : null }
      </div>
    )) : null

    return (
      <>
      <section className={`payment ${showCtaButtonOnly ? 'hidden' : ''}`}>
        <ToastContainer position="top-center" className="position-fixed" style={toastVisible ? { zIndex: 100, margin: '40px auto', padding: 0} : {display: 'none'}}>
          <Toast show={this.props.loadingCustomer} style={{marginTop: 20}}>
            <Toast.Body><span className="fa fa-spinner fa-pulse" />&nbsp;&nbsp;{loadingMessages[this.state.toast.message]}</Toast.Body>
          </Toast>
          <Toast show={this.props.processingPayment} style={{marginTop: 20}}>
            <Toast.Body><span className="fa fa-spinner fa-pulse" />&nbsp;&nbsp;{this.props.paymentProfileEditMode ? `Please wait` : `Processing Donation`}...</Toast.Body>
          </Toast>
          <Toast show={this.state.toast.error === true} delay={3000} autohide style={{marginTop: 20}}>
            <Toast.Body>Please fill in the required fields.</Toast.Body>
          </Toast>
          <Toast bg="info" show={this.state.toast.success === true} delay={3000} autohide style={{marginTop: 20}}>
            <Toast.Body className='text-white'>Donation Successfully made.</Toast.Body>
          </Toast>
        </ToastContainer>

        { title }
        { plaidBankAccountsModalOpen ? this.renderBankAccountModal() : null }
        { spinner }
        { donationText }
        { (editMode || guestMode) ? null : <div><br /></div> }
        {
          (useSaved && !editMode) ? null : (
            <Form.Group className={(displayValidationError && !type) ? 'has-error' : ''}>
              <div 
                className="cursor-pointer payment-method-menu full-width"
                style={layoutConfig?.paymentDetails?.menuBoxStyle || null}
              >
                { paymentMethodMenuEntries }
              </div>
              <input type="hidden" name="dataValue" id="dataValue" />
              <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
            </Form.Group>
          )
        }
        {
          !editMode && (
            <div>
              { confirmProcessingFee }
              { confirmMonthly }
              { donationSummary }
              { disclaimer }
            </div>
          )
        }
        {
          (guestMode && crm ==='salesforce') ? (
            <Form.Check id='email-updates'>
              <Form.Check.Input 
                type='checkbox'
                checked={sendEmailUpdates}
                onChange={() => {
                  this.setState({ 
                    preferences: {
                      ...this.state.preferences,
                      sendEmailUpdates: !sendEmailUpdates,
                    }
                  })
                }}
              />
              <Form.Check.Label>Yes! Send me email updates.</Form.Check.Label>
            </Form.Check>
          ) : null
        }
        {
          (guestMode && crm === 'salesforce') ? (
            <Form.Check id='text-updates'>
              <Form.Check.Input 
                type='checkbox'
                checked={sendSmsTextUpdates}
                onChange={() => {
                  this.setState({ 
                    preferences: {
                      ...this.state.preferences,
                      sendSmsTextUpdates: !sendSmsTextUpdates,
                    }
                  })
                }}
              />
              <Form.Check.Label>Yes! <wbr />Send me text message updates.</Form.Check.Label>
            </Form.Check>
          ) : null
        }
        <div className={guestMode ? 'center mt3' : ''}>
          { 
            guestMode ? (
              <div className="mb2" style={{ fontSize: 16, letterSpacing: '.03em' }}>
                {`${donationTypeNames[donationType]} Gift Amount: ${ Number(totalAmount) ? (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount)) : 'N/A'}`}
              </div>
            ) : null 
          }
          <RecaptchaV2Wrapper services={services} />
        </div>
      </section>
      { errMsg }
      { 
        (!donationAmount && !isNaN(eventMinAmount) && (donationAmount >= eventMinAmount)) ? 
          <p>Credit card is required for verification of attendance.  There is no cost to attend.</p> :
          null
      }
      { ctaButton }
      { 
        guestMode && (sslVisible !== false) ? (
          <legend className="pre-field" style={{ fontSize: 12 }}>
            <span className="fa fa-lock"/>&nbsp;
            SSL encrypted and secure
          </legend>
        ) : null 
      }
       { fundDisclaimer }
      </>
    )
  }
}

export default connector(withRouter((props) => {
  return (
    <StripeProvider>
      <Payment {...props} />
    </StripeProvider>
  )
}))