import type { Extra, RootState } from "src/store";
import _ from "lodash";
import axiosServer from "modules/axiosServer";
import { getCookie, setCookie } from "modules/cookie";
import { sendGtmPixel } from "modules/gtm";
import {
  auditAccountUrl,
  getCampaignsUrl,
  getStatusReasonsUrl,
  getUtmVideosUrl,
  retrieveAccountUrl,
  retrieveRolesUrl,
  updateMailChimpUrl,
} from "modules/urls";
import { reformatApiAddress } from "modules/utils";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  setOtherTransactions,
  setSubscriptions,
  setTotalTransactionPages,
  setTotalTransactions,
  setTransactions,
} from "../actions/reportActions";
import {
  setAccountType,
  setContactEmail,
  setContactData,
  setCrmAccountId,
  setShippingAddressSameAsContact,
  setShippingContactData,
} from "./accountSlice";
import { getRecaptchaToken, setRecaptchaUsed } from "./recaptchaSlice";

import type {
  AccountStateAddress,
  AccountStateContact,
  AppState,
} from "./@types";

// const localStorageKey = import.meta.env.VITE_LOCALSTORAGE_KEY;
const localStorageKey = "storks_donor_state";

/**
 * Initial State
 */
const initialState: AppState = {
  recaptchaLoaded: false,
  userRoles: [],
  statusReasons: [],
  campaigns: [],
  utmVideos: [],
  loadingCustomer: false,
  loadingRoles: false,
  showDetails: true,
  mailChimpUpdated: false,
  recaptchaWhitelistKey: "",
  hasEventRegistrations: false,
};

// const extractCustomerAddress = (
//   customer: { [key: string]: any } = {},
//   paymentGateway: string
// ) => {
//   const customerAddress =
//     paymentGateway === "Authorize.NET"
//       ? _.get(customer, ["shipToList", 0], {})
//       : {
//           ...customer.address,
//           ..._.pick(customer, ["name", "phone"]),
//           ..._.pick(customer.metadata || {}, [
//             "firstName",
//             "lastName",
//             "company",
//           ]),
//         };
//   return {
//     address: reformatApiAddress(customerAddress, paymentGateway),
//     email: (customer.email || "").trim(),
//   };
// };

/**
 * Retrieve User Roles
 *
 * @return User Roles
 */
export const retrieveRoles = createAsyncThunk<
  any[],
  void,
  { state: RootState; extra: Extra }
>(
  "root/account/retrieveRoles",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        account: { contact },
        root: { profileAuth0 },
        access,
      } = getState();

      const headers = access.token
        ? {
            Authorization: `Bearer ${access.token}`,
          }
        : {};
      const res = await axiosServer.get(retrieveRolesUrl, { headers });

      const { email_verified: emailVerified, email } = profileAuth0;
      if (!contact.email && emailVerified) {
        dispatch(setContactEmail(email));
      }

      const { data } = res;
      if (data.length && data[0]?.name === "Admin Terminal") {
        dispatch(setAccountType("terminal"));
      }

      return data;
    } catch (e) {
      console.error("Error in userRoles/retrieveRoles", e);
      return rejectWithValue((e as Error).message);
    }
  },
  {
    condition: (_, { getState }) => !getState().root.loadingRoles,
  }
);

/**
 * Retrieve Customer
 */
export interface RetrieveCustomerRequest {
  accountId: string;
  contactId: string;
  layoutConfig: object;
  recaptchaResponseToken?: string;
}
export const retrieveCustomer = createAsyncThunk<
  any,
  RetrieveCustomerRequest,
  { state: RootState; extra: Extra }
>(
  "root/account/retrieveCustomer",
  async (
    { accountId, contactId, layoutConfig },
    { dispatch, rejectWithValue, getState, extra }
  ) => {
    // cookie values
    const guest_id = getCookie("guest_id");
    const needAudit = getCookie("audit") === "yes";

    // state values
    const {
      root: { profileAuth0, recaptchaWhitelistKey, campaigns },
      report: { transactionPerPage },
      access,
      recaptcha,
    } = getState();

    if (!profileAuth0 && !guest_id && !accountId && !contactId) {
      return rejectWithValue(new Error("login required"));
    }

    const recaptchaMode = recaptcha.mode
    let recaptchaResponseToken = recaptchaMode === 'v2' ? recaptcha.v2Token : recaptcha.token;
    if (!recaptcha.token || recaptcha.status !== "idle") {
      await dispatch(getRecaptchaToken())
      recaptchaResponseToken = getState().recaptcha[recaptchaMode === 'v2' ? 'v2Token' : 'token'];

      if (!recaptcha.token)
        return rejectWithValue(new Error("no recaptcha token"));
    }

    // set headers
    const headers: { [key: string]: string | undefined } = {
      recaptcharesponsetoken: recaptchaResponseToken,
    };
    if (access.token) {
      headers.Authorization = `Bearer ${access.token}`;
    }

    if (needAudit) {
      await dispatch(auditAccount());
    }

    let { email, sub } = profileAuth0 || {};
    email = email.trim();

    // make request
    const res = await axiosServer.get(retrieveAccountUrl, {
      params: {
        email,
        auth0_uid: sub,
        guest_id,
        aid: accountId,
        contact_id: contactId,
        key: recaptchaWhitelistKey,
      },
      headers,
    });
    await dispatch(setRecaptchaUsed());

    const {
      setToAddress,
      customerProfile,
      paymentGateway,
      subscription,
      subscriptionYearly,
      subscriptions,
      transactions,
      otherTransactions,
      totalNumInResultSet,
      hasMoreTransactions,
    } = res.data;

    ////
    // retrieveCustomer: Set Contact Address
    ////
    let contact: AccountStateContact;
    if (paymentGateway === "Authorize.NET") {
      let profile = customerProfile.shipToList[0];

      contact = {
        type: "default",
        email: (customerProfile.email || "").trim(),
        phone: (profile.phoneNumber || "").trim(),
        firstName: (profile.firstName || "").trim(),
        lastName: (profile.lastName || "").trim(),
        addressId: profile.customerAddressId,
        address: setToAddress || {
          streetAddress: (profile.address || "").trim(),
          company: (profile.company || "").trim(),
          city: (profile.city || "").trim(),
          state: (profile.state || "").trim(),
          zip: (profile.zip || "").trim(),
          country: (profile.country || "").trim(),
        },
      };
    } else {
      customerProfile.address = customerProfile.address || {};
      let { firstName, lastName } = customerProfile.address;
      if (!firstName || !lastName) {
        lastName = (
          (customerProfile.name || "").split(" ").slice(-1)[0] || ""
        ).trim();
        firstName = (customerProfile.name || "")
          .replace(lastName, "")
          .trim()
          .trim();
      }

      contact = {
        type: "default",
        email: (customerProfile.email || "").trim(),
        phone: (customerProfile.phone || "").trim(),
        firstName,
        lastName,
        address: setToAddress || {
          company: (customerProfile.metadata?.company || "").trim(),
          streetAddress: (
            (customerProfile.address?.line1 || "") +
            " " +
            (customerProfile.address?.line2 || "")
          ).trim(),
          city: customerProfile.address?.city || "".trim(),
          state: customerProfile.address?.state || "".trim(),
          country: customerProfile.address?.country || "".trim(),
          zip: (customerProfile.address?.postal_code || "").trim(),
        },
      };
    }

    console.log("setting contact", contact);
    dispatch(setContactData(contact));

    if (customerProfile) {
      ////
      // retrieveCustomer: Set Shipping Address
      ////
      if (customerProfile.shipping) {
        if (
          _.isEqual(customerProfile.address, customerProfile.shipping.address)
        ) {
          console.log("shipping address is same as default contact");
          dispatch(setShippingAddressSameAsContact(true));
        } else {
          const [firstName, lastName] = customerProfile.shipping.name.split(" ");
          const shippingContact: AccountStateContact = {
            type: "shipping",
            sameAsDefaultContact: false,
            firstName,
            lastName,
            email: customerProfile.shipping.email || email,
            address: {
              company: customerProfile.shipping.company || "",
              streetAddress:
                customerProfile.shipping.address.line1 +
                (customerProfile.shipping.address.line2 || ""),
              city: customerProfile.shipping.address.city,
              state: customerProfile.shipping.address.state,
              country: customerProfile.shipping.address.country,
              zip: customerProfile.shipping.address.postal_code,
            },
          };

          dispatch(setShippingContactData(shippingContact));
        }
      }

      ////
      // retrieveCustomer: Set Account Id
      ////
      if (customerProfile.metadata?.sfdc_account_id) {
        dispatch(setCrmAccountId(customerProfile.metadata.sfdc_account_id));
      }

      let paymentProfiles = customerProfile.paymentProfiles
        ? customerProfile.paymentProfiles.map((p) => ({
            ...p,
            gateway: "Authorize.NET",
          }))
        : _.get(customerProfile, ["sources", "data"], []).map((c) => ({
            ...c,
            gateway: c.gateway || "Stripe",
          }));
      dispatch({
        type: "SET_PAYMENT_PROFILES_PAYMENT",
        payload: paymentProfiles, // TODO: TBD: attach both gateways' cards
      });
    }

    if (subscription || subscriptionYearly) {
      const inspiration = _.get(
        subscription || subscriptionYearly,
        "donation_source__c"
      );
      const campaign = (campaigns || []).find(
        (c) => (c.public_name__c || c.name) === inspiration
      );

      if (campaign) {
        dispatch({
          type: "SET_INSPIRATION_CONTACT",
          payload: inspiration,
        });
        dispatch({
          type: "SET_DATA_CONTACT",
          payload: { field: "campaignId", val: campaign.id },
        });
      }
    }

    if (subscriptions) {
      dispatch(setSubscriptions(subscriptions));
    }

    dispatch({
      type: "SET_GATEWAY_PAYMENT",
      payload: paymentGateway,
    });
    dispatch({
      type: "ENTER_BANK_PAYMENT",
      payload: {
        accountType:
          paymentGateway === "Authorize.NET" ? "checking" : "Individual",
      },
    });
    if (transactions) {
      dispatch(setTransactions(transactions));
    }
    if (otherTransactions) {
      dispatch(setOtherTransactions(otherTransactions));
    }
    if (totalNumInResultSet) {
      dispatch(
        setTotalTransactionPages(
          Math.ceil(totalNumInResultSet / transactionPerPage)
        )
      );
      dispatch(setTotalTransactions(totalNumInResultSet));
    } else if (hasMoreTransactions) {
      // For stripe
      dispatch(setTotalTransactionPages(2));
      dispatch(setTotalTransactions(transactionPerPage * 2));
    }

    return { ...res.data, layoutConfig };
  },
  {
    condition: (_, { getState }) => !getState().root.loadingCustomer,
  }
);

export const auditAccount = createAsyncThunk<void, void, { state: RootState }>(
  "root/account/auditAccount",
  async (_, { getState, dispatch }) => {
    const {
      access,
      root: { profileAuth0 },
    } = getState();
    const headers: { [key: string]: string } = {};
    if (access.token) {
      headers.Authorization = `Bearer ${access.token}`;
    }
    const { data: primarySalesforceAccountId } = await axiosServer.post(
      auditAccountUrl,
      { profileAuth0 },
      { headers }
    );

    setCookie("audit", "");
    sendGtmPixel({
      event: "account_linked",
      email: profileAuth0?.email,
      id: primarySalesforceAccountId,
    });
  }
);

export const saveAppState = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("root/state/save", async (nextModal, { getState }) => {
  const {
    account,
    contact,
    options,
    payment,
    root: { utms },
  } = getState();
  localStorage.setItem(
    localStorageKey,
    JSON.stringify({ account, contact, options, payment, utms })
  );
  if (nextModal) {
    localStorage.setItem("storks_donor_modal", nextModal);
  }
});

export const loadAppState = createAsyncThunk<
  string,
  void,
  { state: RootState }
>("root/state/load", async (_, { dispatch }) => {
  const appState = localStorage.getItem(localStorageKey);
  if (appState) {
    const { contact, options, payment, utms } = JSON.parse(appState);
    dispatch({ type: "RESET_CONTACT", payload: contact });
    dispatch({ type: "RESET_OPTIONS", payload: options });
    dispatch({ type: "RESET_PAYMENT", payload: payment });

    return utms;
  }
});

export const getCampaigns = createAsyncThunk<
  { data: any }[],
  string,
  { state: RootState }
>("root/campaigns/get", async (campaignId) => {
  return Promise.all([
    axiosServer.get(
      getCampaignsUrl + (campaignId ? `/with/${campaignId}` : "")
    ),
    axiosServer.get(getUtmVideosUrl),
  ]);
});

export const getStatusReasons = createAsyncThunk<
  { data: any },
  boolean,
  { state: RootState }
>("root/statusReasons/get", async (admin) => {
  return axiosServer.get(`${getStatusReasonsUrl}${admin ? "/admin" : ""}`);
});

export const updateMailChimpWithTag = createAsyncThunk<
  { data: string },
  string,
  { state: RootState; extra: Extra }
>(
  "root/mailChimp/updateTag",
  async (tag, { dispatch, getState, rejectWithValue, extra }) => {
    const {
      root: { mailChimpListMemberId, recaptchaWhitelistKey },
      account: {
        contact: { email },
      },
      recaptcha,
    } = getState();

    try {
      const recaptchaMode = recaptcha.mode
      let token = recaptchaMode === 'v2' ? recaptcha.v2Token : recaptcha.token;
      if (!token || recaptcha.status !== "idle") {
        await dispatch(getRecaptchaToken());
        token = getState().recaptcha[recaptchaMode === 'v2' ? 'v2Token' : 'token'];
      }

      const res = await axiosServer.post(
        `${updateMailChimpUrl}/${tag}`,
        {
          email,
          memberId: mailChimpListMemberId,
        },
        {
          headers: {
            recaptchaResponseToken: token,
          },
          params: {
            key: recaptchaWhitelistKey,
          },
        }
      );
      await dispatch(setRecaptchaUsed());
      return res;
    } catch (e) {
      return rejectWithValue(new Error("reCaptcha not set"));
    }
  }
);

const rootSlices = createSlice({
  name: "root",
  initialState,
  reducers: {
    setAccountId(state, { payload: id }) {
      state.accountId = id;
    },
    setContactId(state, { payload: id }) {
      state.contactId = id;
    },
    setGuestId(state, { payload: id }) {
      state.guestId = id;
    },
    setProfileAuth0(state, { payload: profile }) {
      state.profileAuth0 = profile;
    },
    setReceiptUrl(state, { payload: url }) {
      state.url = url;
    },
    setSystemMessage(state, { payload: message }) {
      state.systemMessage = message;
    },

    // customer
    syncCustomerContact(state, { payload: customer }) {
      state.customer = customer;
      state.showDetails = true;
    },
    setCustomerFulfilled(state, { payload: customer }) {
      state.customer = customer;
      state.loadingCustomer = false;
      state.showDetails = !customer;
    },
    setCustomerDefaultSource(state, { payload: defaultSource }) {
      state.customer = state.customer || {};
      state.customer = {
        ...state.customer,
        default_source: defaultSource,
      };
    },

    setRootSubscription(state, { payload: subscription }) {
      state.subscription = subscription;
    },
    setRootSubscriptionYearly(state, { payload: subscriptionYearly }) {
      state.subscriptionYearly = subscriptionYearly;
    },

    setRecaptchaLoaded(state, { payload: loaded }) {
      state.recaptchaLoaded = loaded;
    },
    setCrm(state, { payload }) {
      state.crm = payload;
    },

    resetPage(state) {
      const {
        profileAuth0,
        recaptchaLoaded,
        userRoles,
        loadingRoles,
        campaigns,
        statusReasons,
        ...initialInfo
      } = initialState;

      return {
        ...initialInfo,
        profileAuth0: state.profileAuth0,
        campaigns: state.campaigns,
        campaignDefaultSettings: state.campaignDefaultSettings,
        statusReasons: state.statusReasons,
        recaptchaLoaded: state.recaptchaLoaded,
        userRoles: state.userRoles,
        loadingRoles: state.loadingRoles,
      };
    },
    setShowDetails(state, { payload: showDetails }) {
      state.showDetails = showDetails;
    },
    setUtms(state, { payload: utms }) {
      state.utms = utms;
    },
    setInfoModalType(state, { payload: infoModalType }) {
      state.infoModalType = infoModalType;
    },
    setRecaptchaWhitelistKey(state, { payload: recaptchaWhitelistKey }) {
      state.recaptchaWhitelistKey = recaptchaWhitelistKey;
    },
    setMailChimpListMemberId(state, { payload: mailChimpListMemberId }) {
      state.mailChimpListMemberId = mailChimpListMemberId;
    },
    // noop
    switchView() {},
  },

  extraReducers: (builder) => {
    builder.addCase(loadAppState.fulfilled, (state, { payload: utms }) => {
      if (utms) {
        state.utms = utms;
      }
    }),
      builder.addCase(retrieveRoles.pending, (state) => {
        state.loadingRoles = true;
      }),
      builder.addCase(retrieveRoles.rejected, (state, { payload: _error }) => {
        state.loadingRoles = false;
      }),
      builder.addCase(
        retrieveRoles.fulfilled,
        (state, { payload: userRoles }) => {
          state.userRoles = userRoles;
          state.loadingRoles = false;
        }
      ),
      builder.addCase(auditAccount.rejected, (state, { payload: _error }) => {
        state.loadingCustomer = false;
        state.showDetails = true;
      }),
      builder.addCase(retrieveCustomer.pending, (state) => {
        state.loadingCustomer = true;
      });
    builder.addCase(retrieveCustomer.rejected, (state, { payload: _error } ) => {
      // console.error(_error) // it would actually display undefined
      state.loadingCustomer = false;
      state.showDetails = true;
    }),
      builder.addCase(retrieveCustomer.fulfilled, (state, { payload }) => {
        console.log("retrieveCustomer----->>>>>>>", payload);
        const {
          userroles,
          mimicState,
          accountId,
          customerProfile,
          subscription,
          subscriptionYearly,
          receiptUrl,
          crm,
          layoutConfig,
          hasEventRegistrations,
        } = payload;

        state.showDetails = true;
        state.loadingCustomer = false;
        state.userRoles = userroles || [];
        state.mimicState = mimicState;
        state.crm = crm;
        state.accountId = accountId;
        state.customer = customerProfile;
        state.receiptUrl = receiptUrl;
        state.hasEventRegistrations = hasEventRegistrations;

        const guestId = getCookie("guest_id") as string;
        const linkGuestDone = getCookie("link_guest_done");

        state.guestId = guestId;
        if (linkGuestDone) {
          if (state.profileAuth0) {
            state.systemMessage = `Your previous donations have been saved to account ${state.profileAuth0.name}`;
            setCookie("guest_id", "");
          }
          setCookie("link_guest_done", "");
        }

        if (customerProfile) {
          state.customerProfileId =
            customerProfile.customerProfileId || customerProfile.id || "";
        }
        if (layoutConfig?.attachCurrentSubscription !== false) {
          if (subscription) {
            state.subscription = subscription;
          }
          if (subscriptionYearly) {
            state.subscriptionYearly = subscriptionYearly;
          }
        }

        // callback???
        localStorage.removeItem(localStorageKey);
      }),
      builder.addCase(getCampaigns.fulfilled, (state, { payload }) => {
        state.campaigns = payload[0].data.filter((c) => c.id !== "default");
        state.utmVideos = payload[1].data;
        state.campaignDefaultSettings =
          payload[0].data.find((c) => c.id === "default") || null;
      });
    builder.addCase(getStatusReasons.fulfilled, (state, { payload }) => {
      state.statusReasons = payload.data;
    });
    builder.addCase(updateMailChimpWithTag.fulfilled, (state, { payload }) => {
      state.mailChimpListMemberId = payload.data;
    });
  },
});

export const {
  setAccountId,
  setContactId,
  setGuestId,
  setProfileAuth0,
  setReceiptUrl,
  setSystemMessage,
  syncCustomerContact,
  setCustomerFulfilled,
  setCustomerDefaultSource,
  setRootSubscription,
  setRootSubscriptionYearly,

  setRecaptchaLoaded,
  setCrm,
  resetPage,
  setShowDetails,
  setUtms,
  setInfoModalType,
  setRecaptchaWhitelistKey,
  setMailChimpListMemberId,

  // to be deprecated
  switchView,
} = rootSlices.actions;
export default rootSlices.reducer;
