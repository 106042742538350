// import type { Reducer } from '@reduxjs/toolkit'
const initialState = {
  searchGateway: 'Salesforce',
  dataStatus: null,
  searchBy: {
    Salesforce: 'Email',
    Auth0: 'Email',
  },
  search: {
    email: '',
    name: '',
    auth0Uid: '',
    sfdcAccountId: '',
    sfdcContactId: '',
    sdfcRecurringDonationId: '',
    sfdcOpportunityId: '',
  },
  searchResult: {},
  mimicParams: {
    auth0Uid: '',
    sfdcAccountId: '',
    sfdcContactId: '',
  },
  attendees: [],
  newAttendees: [],
  pendingAttendeeChanges: [],
  attendeeErrors: [],
  attendeesValid: true,
  attendeeIdWithError: null,
  attendeeDataMessage: null,
  attendeePagination: {
    limit: 10,
    page: 1,
    count: null,
  },
  attendeeCustomCampaign: null,
  attendeeAdditionalConfigs: null,
  dedupAccountIds: [],
  accountMergingFields: {},
  dedupContactIds: [],
  contactMergingSets: [],
  mergableContactFields: [],
  retainDuplicates: true,
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_SEARCH_GATEWAY_ADMIN':
      return {
        ...state,
        searchGateway: action.payload,
      }
    case 'SET_DATA_STATUS_ADMIN':
      return {
        ...state,
        dataStatus: action.payload,
      }
    case 'SET_SEARCH_ADMIN':
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        }
      }
    case 'SET_SEARCH_BY_ADMIN':
      return {
        ...state,
        searchBy: {
          ...state.searchBy,
          ...action.payload,
        }
      }
    case 'SET_SEARCH_RESULT_ADMIN':
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          ...action.payload,
        },
      }
    case 'SET_ATTENDEES_ADMIN':
      return {
        ...state,
        attendees: action.payload,
      }
    case 'SET_ATTENDEE_PAGINATION_ADMIN':
      return {
        ...state,
        attendeePagination: {
          page: action.payload.page || state.attendeePagination.page,
          limit: action.payload.limit || state.attendeePagination.limit,
          count: [null, undefined].includes(action.payload.count) ? state.attendeePagination.count : action.payload.count,
        }
      }
    case 'SET_PENDING_ATTENDEE_CHANGES_ADMIN':
      return {
        ...state,
        pendingAttendeeChanges: action.payload,
      }
    case 'SET_ATTENDEE_ERRORS_ADMIN':
      return {
        ...state,
        attendeeErrors: action.payload,
      }
    case 'SET_ATTENDEES_VALID_ADMIN':
      return {
        ...state,
        attendeesValid: action.payload,
      }
    case 'SET_ATTENDEE_ID_WITH_ERROR_ADMIN':
      return {
        ...state,
        attendeeIdWithError: action.payload,
      }
    case 'SET_ATTENDEE_DATA_MESSAGE_ADMIN':
      return {
        ...state,
        attendeeDataMessage: action.payload,
      }
    case 'SET_ATTENDEE_CUSTOM_CAMPAIGN_ADMIN':
      return {
        ...state,
        attendeeCustomCampaign: action.payload,
      }
    case 'SET_ATTENDEE_ADDITIONAL_CONFIGS_ADMIN':
      return {
        ...state,
        attendeeAdditionalConfigs: action.payload,
      }
    case 'EDIT_ATTENDEE_ADMIN':
      // data param: all values of an attendee
      const [index, { name, value, data = null }, addAttendees = false] = action.payload
      const targetField = addAttendees ? 'newAttendees' : 'pendingAttendeeChanges'
      const targetAttendee = state[targetField][index] || null
      return {
        ...state,
        [targetField]: [
          ...state[targetField].slice(0, index),
          data || {
            ...targetAttendee,
            [name]: value,
          },
          ...state[targetField].slice(index + 1),
        ]
      }
    case 'REMOVE_ATTENDEE_ADMIN':
      const [removeIndex, reset = false] = action.payload
      if (reset) {
        return {
          ...state,
          newAttendees: []
        }
      }
      else {
        return {
          ...state,
          newAttendees: [
            ...state.newAttendees.slice(0, removeIndex),
            ...state.newAttendees.slice(removeIndex + 1),
          ]
        }
      }
    case 'SELECT_DEDUP_ACCOUNTS_ADMIN':
      return {
        ...state,
        dedupAccountIds: action.payload,
      }
    case 'SELECT_ACCOUNT_MERGING_FIELDS_ADMIN':
      return {
        ...state,
        accountMergingFields: action.payload,
      }
    case 'SELECT_CONTACT_MERGING_SETS_ADMIN':
      return {
        ...state,
        contactMergingSets: action.payload,
      }
    case 'SELECT_DEDUP_CONTACTS_ADMIN':
      return {
        ...state,
        dedupContactIds: action.payload,
      }
    case 'SET_MERGEABLE_CONTACT_FIELD_ADMIN':
      return {
        ...state,
        mergableContactFields: action.payload,
      }
    case 'SET_RETAIN_DUPLICATES_ADMIN':
      return {
        ...state,
        retainDuplicates: action.payload,
      }
    case 'SET_REQUEST_ERROR_ADMIN':
      return {
        ...state,
        requestError: action.payload,
      }
    case 'SET_MIMIC_PARAMS_ADMIN': {
      return {
        ...state,
        mimicParams: {
          ...state.mimicParams,
          ...action.payload,
        }
      }
    }
  }
  return state
}

export default reducer;