// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { setRecaptchaV2Token } from 'slices/recaptchaSlice';
import type { AppRouteProps } from 'src/types/app'

type RecaptchaV2WrapperProps = Partial <AppRouteProps> & { services: any };

@connect(({ recaptcha }) => ({
  recaptcha
}), {
  setRecaptchaV2Token,
})

export default class RecaptchaV2Wrapper extends React.Component<RecaptchaV2WrapperProps> {

  componentDidUpdate(prevProps: Readonly<RecaptchaV2WrapperProps>): void {
    const { setRecaptchaV2Token, recaptcha, services } = this.props
    if (prevProps.recaptcha.mode === 'v3' && recaptcha.mode === 'v2') {
      window.grecaptcha.render('recaptcha-wrapper', {
        sitekey : services.googleRecaptcha_v2,
        callback: () => {
          console.log('recaptcha successful')
          const res = window.grecaptcha.getResponse()
          setRecaptchaV2Token(res)
        },
        'expired-callback': () => {
          if (!recaptcha.v2TokenValidated) {
            setRecaptchaV2Token('')
          }
          console.log('recaptcha expired')
        },
        'error-callback':  () => {
          if (!recaptcha.v2TokenValidated) {
            setRecaptchaV2Token('')
          }
          console.log('recaptcha error')
        },
      })
    }
  }

  render() {
    const { recaptcha } = this.props
    return recaptcha ? (
      <div 
        id="recaptcha-wrapper"
        className='my1'
        style={(recaptcha.ready && (recaptcha.mode === 'v2') && !recaptcha.v2TokenValidated) ? {display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'} : {display: 'none'}}
      />
    ) : null
  }
}