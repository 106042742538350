import axiosServer from "modules/axiosServer";
import {
  donationUrls,
  updatePaymentMethodUrl,
  deletePaymentProfileUrl,
  createStripeSetupIntentUrl,
  fetchStripeTerminalsUrl,
  fetchStripeTerminalConfigUrl,
} from "modules/urls";
import { setCookie, getCookie } from "modules/cookie";
import { sendGtmPixel } from "modules/gtm";
import { paymentSchema, transformErrorIntoObject } from "modules/validation";
import { checkRecaptchaFromError, calculateProcessingFee } from "modules/utils";
import { setRecaptchaMode, setRecaptchaV2TokenValidated } from "../slices/recaptchaSlice";

import _ from "lodash";

import {
  updateMailChimpWithTag,
  setCustomerFulfilled,
  setCustomerDefaultSource,
  setAccountId,
  setContactId,
  setGuestId,
} from "../slices/rootSlice";
import { setCrmAccountId, setContactData } from "../slices/accountSlice";
import { getAccessToken } from "../slices/accessSlice"
import moment from "moment-timezone";

export const setTypePayment = (type) => {
  return {
    type: "SET_TYPE_PAYMENT",
    payload: type,
  };
};

export const setBankTransfer = ({ field, val }) => {
  return {
    type: "SET_BANK_TRANSFER_PAYMENT",
    payload: { [field]: val },
  };
};

export const setLoading = (flag) => {
  return {
    type: "SET_LOADING_PAYMENT",
    payload: flag,
  };
};

export const setValidationError = (error) => {
  return {
    type: "SET_VALIDATION_ERROR_PAYMENT",
    payload: error,
  };
};

export const setUseSaved = (flag) => {
  return {
    type: "SET_USE_SAVED_PAYMENT",
    payload: flag,
  };
};

export const setConfirmedMonthly = (flag) => {
  return {
    type: "SET_CONFIRMED_MONTHLY_PAYMENT",
    payload: flag,
  };
};

export const setPaymentProfiles = (paymentProfiles) => {
  return {
    type: "SET_PAYMENT_PROFILES_PAYMENT",
    payload: paymentProfiles,
  };
};

export const enterCard = (data) => {
  return {
    type: "ENTER_CARD_PAYMENT",
    payload: data,
  };
};

export const enterBank = (data) => {
  return {
    type: "ENTER_BANK_PAYMENT",
    payload: data,
  };
};

export const enterBankStripe = (data) => {
  return {
    type: "ENTER_BANK_STRIPE_PAYMENT",
    payload: data,
  };
};

export const setBillingAddress = (address) => {
  return {
    type: "SET_BILLING_ADDRESS_PAYMENT",
    payload: address,
  };
};

export const setBillingAddressSameAsContact = (flag) => {
  return {
    type: "SET_BILLING_ADDRESS_SAME_AS_CONTACT_PAYMENT",
    payload: flag,
  };
};

export const selectPaymentProfile = (id) => {
  return {
    type: "SELECT_PAYMENT_PROFILE_PAYMENT",
    payload: id,
  };
};

export const setAjsLoaded = (flag) => {
  return {
    type: "SET_AJS_LOADED_PAYMENT",
    payload: flag,
  };
};

export const setStripeLoaded = (flag) => {
  return {
    type: "SET_STRIPE_LOADED_PAYMENT",
    payload: flag,
  };
};

export const setPlaidLoaded = (flag) => {
  return {
    type: "SET_PLAID_LOADED_PAYMENT",
    payload: flag,
  };
};

export const setAsDefaultProfile = (flag) => {
  return {
    type: "SET_AS_DEFAULT_PROFILE_PAYMENT",
    payload: flag,
  };
};

export const setRequestError = (error) => {
  return {
    type: "SET_REQUEST_ERROR_PAYMENT",
    payload: error,
  };
};

export const setDisplayValidationError = (flag) => {
  return {
    type: "DISPLAY_VALIDATION_ERROR_PAYMENT",
    payload: flag,
  };
};

export const setIncludeProcessingFee = (flag) => {
  return {
    type: "SET_INCLUDE_PROCESSING_FEE_PAYMENT",
    payload: flag,
  };
};

export const setSuccessInfo = (data) => {
  return {
    type: "DONATION_SUCCESS_PAYMENT",
    payload: data,
  };
};

export const setPaymentProfileEditMode = (flag) => {
  return {
    type: "SET_PAYMENT_PROFILE_EDIT_MODE_PAYMENT",
    payload: flag,
  };
};

export const setPaymentMethodUpdateMessage = (message) => {
  return {
    type: "SET_PAYMENT_METHOD_UPDATE_MESSAGE_PAYMENT",
    payload: message,
  };
};

export const fetchStripeTerminalConfig = (
  getAccessTokenSilently,
  { recaptchaResponseToken },
  callback
) => {
  return async (dispatch, getState) => {
    try {
      const { profileAuth0, recaptchaWhitelistKey } = getState().root;
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const headers = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      if (recaptchaResponseToken) {
        headers.recaptchaResponseToken = recaptchaResponseToken;
      }
      const { data: stripeTerminalConfig } = await axiosServer.get(
        fetchStripeTerminalConfigUrl,
        { headers, params: { key: recaptchaWhitelistKey } }
      );
      dispatch({
        type: "SET_STRIPE_TERMINAL_CONFIG_PAYMENT",
        payload: stripeTerminalConfig,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const setStripeTerminal = (terminal) => {
  return {
    type: "SET_STRIPE_TERMINAL_PAYMENT",
    payload: terminal,
  };
};

export const fetchStripeTerminals = (
  getAccessTokenSilently,
  { recaptchaResponseToken },
  callback
) => {
  return async (dispatch, getState) => {
    try {
      const { profileAuth0, recaptchaWhitelistKey } = getState().root;
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const headers = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      if (recaptchaResponseToken) {
        headers.recaptchaResponseToken = recaptchaResponseToken;
      }
      const {
        data: {
          terminals: stripeTerminals,
          terminalLocations: stripeTerminalLocations,
        },
      } = await axiosServer.get(fetchStripeTerminalsUrl, {
        headers,
        params: { key: recaptchaWhitelistKey },
      });
      dispatch({
        type: "SET_STRIPE_TERMINALS_PAYMENT",
        payload: stripeTerminals,
      });
      dispatch({
        type: "SET_STRIPE_TERMINAL_LOCATIONS_PAYMENT",
        payload: stripeTerminalLocations,
      });
      if (callback) {
        callback(stripeTerminals);
      }
    } catch (error) {
      console.error("Error in fetchStripeTerminals", error);
      if (callback) {
        callback([], error);
      }
    }
  };
};

const constructDonationGtmEventData = (
  profileAuth0,
  email,
  amount,
  currentAmount,
  donationType,
  isMonthly,
  paymentDate,
  isYearly,
  paymentMonth
) => {
  // Event data
  let submitEventData = {
    event: "donate_submit",
    email: profileAuth0 ? profileAuth0.email : email,
    amount,
    type: donationType,
    payment_date: isMonthly || isYearly ? paymentDate : null,
    payment_month: isYearly ? paymentMonth : null,
  };
  let successEventData = {
    event: "donate_success",
    email: profileAuth0 ? profileAuth0.email : email,
    amount,
    type: donationType,
    payment_date: isMonthly || isYearly ? paymentDate : null,
    payment_month: isYearly ? paymentMonth : null,
  };
  let failEventData = {
    event: "donate_fail",
    email: profileAuth0 ? profileAuth0.email : email,
    amount,
    type: donationType,
    payment_date: isMonthly || isYearly ? paymentDate : null,
    payment_month: isYearly ? paymentMonth : null,
  };
  if ((isMonthly || isYearly) && currentAmount && currentAmount !== amount) {
    submitEventData.type = currentAmount > amount ? "downgrade" : "upgrade";
    submitEventData.old_amount = currentAmount;
    successEventData.event =
      currentAmount > amount ? "donate_downgrade" : "donate_upgrade";
    successEventData.old_amount = currentAmount;
    failEventData.type = currentAmount > amount ? "downgrade" : "upgrade";
    failEventData.old_amount = currentAmount;
  }
  console.log([submitEventData, successEventData, failEventData]);
  return [submitEventData, successEventData, failEventData];
};

export const makeDonation = (
  getAccessTokenSilently,
  {
    cardNumber,
    accountType,
    amount,
    processingFee,
    recaptchaResponseToken,
    cardDetails,
    paymentGateway,
    preferences,
    terminalCustomerId,
    terminalPaymentMethodId,
    terminalSetupIntentId,
    setupIntentPaymentMethodId,
  },
  force = false
) => {
  return async (dispatch, getState) => {
    const {
      //   addressId: customerAddressId,
      //   email,
      //   name: customerName,
      inspiration,
      campaignId,
      message,
      selectedCampaignOption,
    } = getState().contact;
    const { mode: recaptchaMode } = getState().recaptcha;

    const {
      contact: {
        addressId: customerAddressId,
        email,
        firstName,
        lastName,
        phone,
        address,
      },
    } = getState().account;
    const customerName = `${firstName} ${lastName}`;

    const charityRun = getState().charityRun;
    const eventRegistration = getState().eventRegistration;
    const { donationType, paymentDate, paymentMonth } = getState().options;
    const {
      accountId,
      subscription,
      subscriptionYearly,
      utms,
      profileAuth0,
      recaptchaWhitelistKey,
    } = getState().root;

    const customerProfileId =
      terminalCustomerId || getState().root.customerProfileId;

    const customerAddress = !!terminalCustomerId
      ? null
      : { ...address, phone, firstName, lastName, email };

    const {
      type: paymentType,
      selectedPaymentProfileId,
      billingAddress,
      billingAddressSameAsContact,
      asDefaultProfile,
      gateway: paymentGatewayDefault,
      includeProcessingFee,
      loading,
    } = getState().payment;
    console.log('billingAddressSameAsContact', billingAddressSameAsContact)
    if (loading && !force) return;
    // TBD: check if the same type of sub
    let currentAmount;
    const customerPaymentProfileId =
      terminalPaymentMethodId ||
      setupIntentPaymentMethodId ||
      selectedPaymentProfileId ||
      "";

    const isMonthly = donationType === "monthly";
    const isYearly = donationType === "yearly";
    if (isMonthly) {
      currentAmount = subscription
        ? _.get(subscription, "npe03__amount__c", null)
        : null;
    } else if (isYearly) {
      currentAmount = subscriptionYearly
        ? _.get(subscriptionYearly, "npe03__amount__c", null)
        : null;
    }

    const guestId = getCookie("guest_id");
    const [submitEventData, successEventData, failEventData] =
      constructDonationGtmEventData(
        profileAuth0,
        email,
        amount,
        currentAmount,
        donationType,
        isMonthly,
        paymentDate,
        isYearly,
        paymentMonth
      );
    dispatch(setLoading(true));
    dispatch(setSuccessInfo(null));
    dispatch(setRequestError(null));
    sendGtmPixel({
      ...submitEventData,
      id: accountId || guestId || "N/A",
    });
    if (sessionStorage.getItem('layout') === 'by_step') {
      sendGtmPixel({
        event: 'donate_by_step',
        step: 'donation_complete',
        prev_step: 'payment_details',
      })
    }
    let subscriptionId = null,
      subscriptionPaymentProfileId = null;
    const paymentGatewayToUse = paymentGateway || paymentGatewayDefault;
    if (isMonthly && paymentGatewayToUse === "Stripe") {
      subscriptionId = _.get(subscription, "stripe_subscription_id__c", null);
      subscriptionPaymentProfileId = _.get(
        subscription,
        "stripe_payment_source_id__c",
        null
      );
    } else if (isMonthly && paymentGatewayToUse === "Authorize.NET") {
      subscriptionId = _.get(
        subscription,
        "authorize_net_subscription_id__c",
        null
      );
      subscriptionPaymentProfileId = _.get(
        subscription,
        "authorize_net_payment_profile_id__c",
        null
      );
    }
    if (isYearly && paymentGatewayToUse === "Stripe") {
      subscriptionId = _.get(
        subscriptionYearly,
        "stripe_subscription_id__c",
        null
      );
      subscriptionPaymentProfileId = _.get(
        subscriptionYearly,
        "stripe_payment_source_id__c",
        null
      );
    } else if (isYearly && paymentGatewayToUse === "Authorize.NET") {
      subscriptionId = _.get(
        subscriptionYearly,
        "authorize_net_subscription_id__c",
        null
      );
      subscriptionPaymentProfileId = _.get(
        subscriptionYearly,
        "authorize_net_payment_profile_id__c",
        null
      );
    }
    try {
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      let sponsorship = null
      if (typeof eventRegistration.sponsorshipPackageIndex !== 'undefined') {
        sponsorship = eventRegistration.sponsorshipParams?.packages[eventRegistration.sponsorshipPackageIndex]?.id
      }
      const { data: response } = await axiosServer.post(
        `${donationUrls[donationType]}`,
        {
          recaptchaResponseToken,
          recaptchaMode,
          profileAuth0,
          paymentGateway: paymentGatewayToUse,
          paymentType,
          accountId,
          customerProfileId,
          customerPaymentProfileId,
          terminalSetupIntentId,
          subscriptionId,
          subscriptionPaymentProfileId,
          cardNumber,
          cardDetails,
          accountType,
          amount: Number(amount),
          currentAmount,
          paymentDate,
          paymentMonth: isYearly ? paymentMonth : null,
          interval: isMonthly ? "month" : isYearly ? "year" : null,
          startDate: subscription
            ? _.get(subscription, "npe03__date_established__c", null)
            : null,
          customerName: terminalCustomerId ? customerName : null,
          customerAddress: terminalCustomerId ? null : customerAddress,
          customerAddressId,
          billingAddress: billingAddressSameAsContact
            ? customerAddress
            : (customerPaymentProfileId ? null : billingAddress),
          setDefault: asDefaultProfile,
          email,
          inspiration,
          campaignId,
          campaignMessage: selectedCampaignOption
            ? [selectedCampaignOption.title, selectedCampaignOption.option].filter(v => v).join(': ')
            : null,
          message,
          utms,
          includeProcessingFee,
          feeAmount: includeProcessingFee ? processingFee : 0,
          preferences,
          charityRun,
          eventRegistrations: {
            sponsorship,
            ...eventRegistration,
            checkout: {
              ...eventRegistration?.checkout || null,
              feeAmount: includeProcessingFee ? calculateProcessingFee(eventRegistration?.checkout?.totalAmount || 0, null, paymentType) : 0,
            }
          },
          landingUrl: sessionStorage.getItem('landing_url'),
        },
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
          params: { key: recaptchaWhitelistKey },
        }
      );
      if (getState().root.mailChimpListMemberId && !profileAuth0) {
        dispatch(updateMailChimpWithTag("Donation_Complete"));
      }
      if (response && response.id) {
        sendGtmPixel({
          event: "account_created_success",
          email: profileAuth0 ? profileAuth0.email : email,
          id: response.id,
        });
        dispatch(setAccountId(response.id));
        dispatch(setCrmAccountId(response.id));
        if (!profileAuth0) {
          if (guestId) {
            dispatch(setGuestId(guestId));
          }
          if (response.contactId) {
            dispatch(setContactId(response.contactId));
            dispatch(setContactData({ crmContactId: response.contactId }));
          }
        }
      }
      let startDate = null,
        momentToday = moment().hour(0).minute(0).second(0).millisecond(0),
        momentStartDate;
      if (isMonthly) {
        momentStartDate = moment()
          .date(paymentDate)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0);
        if (momentStartDate.month() !== momentToday.month()) {
          // exceeds the max date of current month
          momentStartDate.date(1).subtract(1, "day");
        }
        if (momentStartDate.isBefore(momentToday)) {
          momentStartDate.month(momentStartDate.month() + 1);
        }
        startDate = momentStartDate.format("MMMM Do YYYY");
      } else if (isYearly) {
        momentStartDate = moment()
          .date(paymentDate)
          .month(paymentMonth)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0);
        if (momentStartDate.isBefore(momentToday)) {
          momentStartDate.year(momentStartDate.year() + 1);
        }
        startDate = momentStartDate.format("MMMM Do YYYY");
      }
      sendGtmPixel({
        ...successEventData,
        id: (response && response.id) || guestId || "N/A",
      });
      dispatch(setLoading(false));
      dispatch(
        setSuccessInfo(
          donationSuccessMessage(donationType, amount, subscription, startDate)
        )
      );
      if (recaptchaMode === 'v2') {
        dispatch(setRecaptchaV2TokenValidated(true))
      }
    } catch (error) {
      console.error(error);
      let errorMessage = _.get(
        error,
        ["response", "data", "error"],
        _.get(error, ["response", "data"], error)
      );
      if (errorMessage === "") {
        // maybe a 500 error
        // if (error.response?.status === 500) {
          errorMessage = "We're experiencing technical difficulties at the moment. Please try again later or contact our support team for assistance.";
        // } 
      }
      checkRecaptchaFromError(errorMessage, setRecaptchaMode, setRecaptchaV2TokenValidated, dispatch, getState) // If recaptcha v3 fails, switch to v2 mode
      sendGtmPixel({
        ...failEventData,
        message: errorMessage,
        id: accountId || guestId || "N/A",
      });
      dispatch(setLoading(false));
      if (paymentGatewayToUse === "Stripe" && paymentType === "bank") {
        errorMessage = {
          ...errorMessage,
          forPlaid: true,
        };
      }
      dispatch(setRequestError(errorMessage));
    }
  };
};

export const updatePaymentMethod = (
  getAccessTokenSilently,
  {
    paymentProfileId,
    updateData,
    cardNumber,
    accountType,
    recaptchaResponseToken,
    cardDetails,
    addingNew,
    paymentGateway,
  },
  callback
) => {
  return async (dispatch, getState) => {
    const {
      contact: { firstName, lastName, phone, email, address, addressId },
    } = getState().account;
    const customerAddress = {
      firstName,
      lastName,
      phone,
      email,
      ...address,
    };
    const {
      accountId,
      customerProfileId,
      profileAuth0,
      customer,
      recaptchaWhitelistKey,
    } = getState().root;
    const {
      type: paymentType,
      paymentProfiles,
      selectedPaymentProfileId,
      billingAddress,
      billingAddressSameAsContact,
      asDefaultProfile,
      gateway: paymentGatewayDefault,
      loading,
    } = getState().payment;
    const {
      mode: recaptchaMode,
    } = getState().recaptcha;
    const paymentGatewayToUse = paymentGateway || paymentGatewayDefault;
    if (loading) return;
    const customerPaymentProfileId = addingNew
      ? ""
      : paymentProfileId || selectedPaymentProfileId || "";
    dispatch(setLoading(true));
    dispatch(setRequestError(null));

    try {
      if (profileAuth0) await dispatch(getAccessToken())
      const accessToken = profileAuth0 ? getState().access.token : null;
    
      const { data: response = {} } = await axiosServer.post(
        updatePaymentMethodUrl,
        {
          recaptchaResponseToken,
          recaptchaMode,
          profileAuth0,
          paymentGateway: paymentGatewayToUse,
          accountId,
          customerProfileId,
          customerPaymentProfileId,
          updateData,
          cardNumber,
          cardDetails,
          accountType,
          customerAddress,
          customerAddressId: addressId,
          billingAddress: billingAddressSameAsContact
            ? customerAddress
            : billingAddress,
          setDefault: asDefaultProfile,
          email,
        },
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {},
          params: { key: recaptchaWhitelistKey },
        }
      );
      const { summary = null, paymentMethod: upsertedPaymentMethod } = response;
      if (upsertedPaymentMethod) {
        const nextPaymentProfiles = [
          upsertedPaymentMethod,
          ...(selectedPaymentProfileId && !addingNew
            ? paymentProfiles.filter(
                (p) =>
                  (p.id || p.customerPaymentProfileId) !==
                  selectedPaymentProfileId
              )
            : paymentProfiles
          ).filter(
            (p) =>
              (p.id || p.customerPaymentProfileId) !== upsertedPaymentMethod.id
          ),
        ];
        dispatch(setPaymentProfiles(nextPaymentProfiles));
        if (asDefaultProfile && upsertedPaymentMethod.id && customer) {
          dispatch(setCustomerFulfilled(upsertedPaymentMethod.id));
        }
      }
      dispatch(setLoading(false));
      dispatch(
        setPaymentMethodUpdateMessage({
          type: "success",
          text: summary
            ? `Payment method ${summary} has been ${
                selectedPaymentProfileId && !addingNew ? "updated" : "created."
              }`
            : null,
        })
      );
      if (callback) {
        callback();
      }
      if (recaptchaMode === 'v2') {
        dispatch(setRecaptchaV2TokenValidated(true))
      }
    } catch (error) {
      console.error('paymentActions.updatePaymentMethod ---');
      console.error(error);
      let errorMessage = _.get(
        error,
        ["response", "data", "error"],
        _.get(error, ["response", "data"], error)
      );
      checkRecaptchaFromError(errorMessage, setRecaptchaMode, setRecaptchaV2TokenValidated, dispatch, getState) // If recaptcha v3 fails, switch to v2 mode
      dispatch(setLoading(false));
      if (paymentGatewayToUse === "Stripe" && paymentType === "bank") {
        errorMessage = {
          ...errorMessage,
          forPlaid: true,
        };
      }
      dispatch(setRequestError(errorMessage));
    }
  };
};

export const removePaymentProfile = (
  getAccessTokenSilently,
  {
    customerProfileId,
    customerPaymentProfileId,
    paymentGateway,
    recaptchaResponseToken,
  },
  callback
) => {
  return async (dispatch, getState) => {
    const { paymentProfiles } = getState().payment;
    const { profileAuth0, recaptchaWhitelistKey } = getState().root;
    const { mode: recaptchaMode } = getState().recaptcha;
    try {
      dispatch(setLoading(true));
      dispatch(setRequestError(null));
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const headers = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      if (recaptchaResponseToken) {
        headers.recaptchaResponseToken = recaptchaResponseToken;
        headers.recaptchaMode = recaptchaMode;
      }
      const { data: nextDefaultPaymentProfileId } = await axiosServer.delete(
        `${deletePaymentProfileUrl}/${customerProfileId}/${customerPaymentProfileId}?gateway=${paymentGateway}&key=${recaptchaWhitelistKey}`,
        { headers }
      );
      const nextPaymentProfiles = paymentProfiles
        .filter(
          (p) =>
            (p.id || p.customerPaymentProfileId) !== customerPaymentProfileId
        )
        .map((p) =>
          p.customerPaymentProfileId === nextDefaultPaymentProfileId
            ? {
                ...p,
                defaultPaymentProfile: true,
              }
            : p
        );
      if (paymentGateway === "Stripe") {
        dispatch(setCustomerDefaultSource(nextDefaultPaymentProfileId));
      }
      dispatch(setPaymentProfiles(nextPaymentProfiles));
      // if we've removed all profiles
      if (!nextPaymentProfiles.length) {
        dispatch(setUseSaved(false))
      }
      dispatch(selectPaymentProfile((nextPaymentProfiles[0] || {}).id || null));
      dispatch(setLoading(false));
      if (callback) {
        callback();
      }
      if (recaptchaMode === 'v2') {
        dispatch(setRecaptchaV2TokenValidated(true))
      }
    } catch (error) {
      console.error(error);
      const errorMessage = _.get(
        error,
        ["response", "data", "error"],
        _.get(error, ["response", "data"], error)
      );
      checkRecaptchaFromError(errorMessage, setRecaptchaMode, setRecaptchaV2TokenValidated, dispatch, getState) // If recaptcha v3 fails, switch to v2 mode
      dispatch(setLoading(false));
      dispatch(setRequestError(errorMessage));
    }
  };
};

export const createStripeSetupIntent = (
  getAccessTokenSilently,
  { recaptchaResponseToken, setToastLoading },
  callback
) => {
  return async (dispatch, getState) => {
    const {
      accountId,
      customerProfileId,
      profileAuth0,
      recaptchaWhitelistKey,
    } = getState().root;
    const {
      contact: { firstName, lastName, email, phone, address },
    } = getState().account;
    const { mode: recaptchaMode } = getState().recaptcha;
    const name = `${firstName} ${lastName}`;
    const customerAddress = {
      firstName,
      lastName,
      email,
      phone,
      ...address,
    };
    const { billingAddress, billingAddressSameAsContact, loading } =
      getState().payment;
    if (loading) return;
    try {
      const accessToken = profileAuth0 ? await getAccessTokenSilently() : null;
      const headers = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      if (recaptchaResponseToken) {
        headers.recaptchaResponseToken = recaptchaResponseToken;
        headers.recaptchaMode = recaptchaMode;
      }
      dispatch(setLoading(true));
      dispatch(setRequestError(null));
      const { data: stripeSetupIntent } = await axiosServer.post(
        createStripeSetupIntentUrl,
        {
          customerName: name,
          email,
          customerAddress,
          billingAddress,
          billingAddressSameAsContact,
          accountId,
          customerProfileId,
        },
        { headers, params: { key: recaptchaWhitelistKey } }
      );
      // dispatch(setLoading(false))
      if (callback) {
        callback(stripeSetupIntent);
      }
      if (recaptchaMode === 'v2') {
        dispatch(setRecaptchaV2TokenValidated(true))
      }
    } catch (error) {
      console.error(error);
      const errorMessage = _.get(
        error,
        ["response", "data", "error"],
        _.get(error, ["response", "data"], error)
      );
      checkRecaptchaFromError(errorMessage, setRecaptchaMode, setRecaptchaV2TokenValidated, dispatch, getState) // If recaptcha v3 fails, switch to v2 mode
      dispatch(setLoading(false));
      dispatch(setRequestError(errorMessage));
      if (setToastLoading) {
        setToastLoading({ show: false });
      }
    }
  };
};

const donationSuccessMessage = (
  donationType,
  amount,
  subscription,
  startDate = null
) => {
  let message = "";
  if (donationType === "monthly") {
    if (subscription) {
      if (subscription.amount !== amount) {
        message = `Your MONTHLY donation has been changed from $${subscription.amount} to $${amount}.`;
      } else {
        message = `You have successfully updated your contact/billing information.`;
      }
    } else {
      message = `You have successfully set a MONTHLY donation of $${amount}.`;
    }
  } else if (donationType === "yearly") {
    if (subscription) {
      if (subscription.amount !== amount) {
        message = `Your ANNUAL donation has been changed from $${subscription.amount} to $${amount}.`;
      } else {
        message = `You have successfully updated your contact/billing information.`;
      }
    } else {
      message = `You have successfully set a ANNUAL donation of $${amount}.`;
    }
  } else {
    message = `You have made a ONE-TIME donation of $${amount}.`;
  }
  return { startDate, message };
};

export const checkPaymentValidity = (site, skip = false) => {
  return async (dispatch, getState) => {
    const {
      payment: {
        // confirmedMonthly,
        type,
        card,
        bank,
        // bankStripe,
        gateway,
        useSaved,
        paymentProfiles,
        paymentProfileEditMode,
        selectedPaymentProfileId,
        billingAddressSameAsContact,
        billingAddress: { company: billingCompany, ...billingAddress },
      },
    } = getState();

    if (skip) return;

    try {
      await paymentSchema.validate(
        {
          card,
          type,
          bank,
          gateway,
          useSaved: paymentProfiles.length && useSaved, // true = use saved payment method on file
          paymentProfileEditMode, // true = user is on payment_methods tab, false otherwise
          selectedPaymentProfileId, // selected payment method to edit/delete
          billingAddressSameAsContact,
          billingAddress,
        },
        { abortEarly: false }
      );

      await dispatch({
        type: "SET_PAYMENT_VALID",
        payload: true,
      });
    } catch (e) {
      await dispatch({
        type: "SET_PAYMENT_ERROR",
        payload: transformErrorIntoObject(e),
      });
    }
  };
};
