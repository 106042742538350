import React from 'react'
import { Container, Nav, NavItem, Navbar} from 'react-bootstrap'
import qs from 'query-string'
import { useAuth0 } from "@auth0/auth0-react"
import {
  siteLinkUrl,
  siteLogoStyles,
} from 'modules/siteContents'
import { constructRedirectUri } from 'modules/utils'

import { MainImage } from './MainImage'
import { HeaderNavigationDropDown } from './HeaderNavigationDropdown'

export const HeaderFEW = (props) => {
  const { saveAppState, email, isAdmin, search, successInfo, site, isAdminTerminal, utmCampaign, mainImage } = props
  const { loginWithRedirect, user } = useAuth0()
  const redirectUri = constructRedirectUri(qs.parse(search), location.pathname)

  return (
    <div id="header">
      <style type="text/css">
        {`.navbar-brand img {
            width: 350px;
            height: 42px;
          }
        @media only screen and (max-width: 991px) {
          .navbar-brand img {
            width: 180px;
            height: 21px;
          }
        }`}
      </style>
      <Navbar collapseOnSelect style={{ marginBottom: 0, padding: 0 }}>
        <Container fluid style={{ marginLeft: 34 }}>
          <div className="navbar-header inline-flex">
            <div className="flex v-center" style={{ height: 86 }}>
              <Navbar.Brand>
              <a href={siteLinkUrl[site] || null} style={{ paddingRight: 16 }}>
                <img 
                  width="350" height="42" 
                  style={siteLogoStyles[site]}
                  src="/server/assets/images/Horizontal-Green-Logo_comp-350x42.png" 
                  alt="" decoding="async" 
                  srcSet="/server/assets/images/Horizontal-Green-Logo_comp-350x42.png 350w, /server/assets/images/Horizontal-Green-Logo_comp-300x36.png 300w, /server/assets/images/Horizontal-Green-Logo_comp-1024x122.png 1024w, /server/assets/images/Horizontal-Green-Logo_comp-768x92.png 768w, /server/assets/images/Horizontal-Green-Logo_comp.png 1400w"
                  sizes="(max-width: 350px) 100vw, 350px"
                />
                {/* style={siteLogoStyles[site]} /> */}
              </a>
              </Navbar.Brand>
              <Navbar.Toggle />
            </div>
          </div>
          <Navbar.Collapse>
          <Nav className="flex v-center login-nav nav navbar-nav navbar-right">
              { 
                user ? 
                  <HeaderNavigationDropDown 
                    {...props}
                  /> : (
                  <NavItem 
                    className='flex v-center link-item cursor-pointer' 
                    style={{ height: 70, fontFamily: 'Futura Bold,Arial,sans-serif', letterSpacing: '.1em', display: 'flex' }}
                    onClick={async () => { await loginWithRedirect({ authorizationParams: { screen_hint: 'login', redirect_uri: redirectUri, login_hint: email || undefined }})}}
                  >
                    DONOR LOGIN
                  </NavItem>
                )
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <MainImage {...props}></MainImage>
    </div>
  )
}