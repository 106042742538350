import axiosServer from "modules/axiosServer";
import { charityRunHouseholdUrl } from "modules/urls";
import { loadScript, LoadScript, reCaptchaReady } from "modules/utils";
import {
  attendeeSchema,
  charityRunSchema,
  transformErrorIntoObject,
} from "modules/validation";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState, Extra } from "src/store";
import type { RecaptchaState } from "./@types/recaptchaSlice";

/**
 * Initial State of Recaptcha Slice
 *
 * Since it is a third party script, before it is loaded
 * ready will be false.
 */
const initialState: RecaptchaState = {
  status: "pending",
  ready: false,
  mode: 'v3',
  v2Token: '',
  v2TokenValidated: false,
};

/**
 * Load Recaptcha Script
 *
 * @returns void
 */
export const loadRecaptcha = createAsyncThunk<
  void,
  void,
  { state: RootState; extra: Extra }
>("recaptcha/load", async (_, { rejectWithValue, extra, dispatch }) => {
  try {
    await loadScript(
      LoadScript.RECAPTCHA,
      extra.site().services.googleRecaptcha
    );
    await dispatch(getRecaptchaToken());
  } catch (e) {
    return rejectWithValue(e);
  }
});

/**
 * Get Recaptcha Response Token
 *
 * @returns token
 */
export const getRecaptchaToken = createAsyncThunk<
  string,
  void,
  { state: RootState; extra: Extra }
>("recaptcha/get", async (_, { rejectWithValue, extra }) => {
  try {
    return await reCaptchaReady(extra.site().services.googleRecaptcha);
  } catch (e) {
    return rejectWithValue(e);
  }
});

const recaptchaSlices = createSlice({
  name: "recaptcha",
  initialState,
  reducers: {
    setRecaptchaUsed(state) {
      state.status = "used";
    },
    setRecaptchaMode(state, { payload: mode }) {
      state.mode = mode
    },
    setRecaptchaV2Token(state, { payload: token }) {
      state.v2Token = token
    },
    setRecaptchaV2TokenValidated(state, { payload: validated }) {
      state.v2TokenValidated = validated
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadRecaptcha.rejected, (state, { payload }) => {
      state.errors = payload;
      state.status = "error";
      state.token = undefined;
    }),
      builder.addCase(loadRecaptcha.fulfilled, (state, { payload }) => {
        state.ready = true;
      }),
      builder.addCase(getRecaptchaToken.rejected, (state, { payload }) => {
        state.errors = payload;
        state.status = "error";
        state.token = undefined;
      }),
      builder.addCase(
        getRecaptchaToken.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          state.token = payload;
          state.status = "idle";
        }
      );
  },
});

export const { setRecaptchaUsed, setRecaptchaMode, setRecaptchaV2Token, setRecaptchaV2TokenValidated } = recaptchaSlices.actions;
export default recaptchaSlices.reducer;
