// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { setStripeTerminal } from 'actions/paymentActions'
import { getCookie, setCookie } from 'modules/cookie'
import _ from 'lodash'

@connect(store => ({
  stripeTerminal: store.payment.stripeTerminal,
  stripeTerminals: store.payment.stripeTerminals,
  stripeTerminalLocations: store.payment.stripeTerminalLocations,
}), {
  setStripeTerminal
})

export default class TerminalMenuExpress extends React.Component {

  findAndSetStripeTerminal = () => {
    const { stripeTerminals, setStripeTerminal, initialUrlParams } = this.props
    const cookieTerminal = getCookie('terminal_reader')
    const presetTerminalId = initialUrlParams.terminal_reader || cookieTerminal
    const presetTerminal = presetTerminalId ? stripeTerminals.find(t => t.id === presetTerminalId) : null
    const nextTerminal = presetTerminal || stripeTerminals[0]
    if (nextTerminal) {
      setStripeTerminal(nextTerminal)
      setCookie('terminal_reader', nextTerminal.id, 1000 * 60 * 60 * 24 * 30)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.stripeTerminals.length && !prevProps.stripeTerminals.length) {
      this.findAndSetStripeTerminal()
    }
    else if (this.props.stripeTerminal && (this.props.stripeTerminal.id !== _.get(prevProps.stripeTerminal, 'id'))) {
      setCookie('terminal_reader', this.props.stripeTerminal.id, 1000 * 60 * 60 * 24 * 30)
    }
  }

  componentDidMount() {
    const { stripeTerminals } = this.props
    if (stripeTerminals.length) {
      this.findAndSetStripeTerminal()
    }
  }

  render() {
    const { stripeTerminal, stripeTerminals, stripeTerminalLocations, setStripeTerminal, dropup, className } = this.props
    if (!stripeTerminals.length || !stripeTerminal) {
      return null
    }
    const stripeTerminalLocationDict = stripeTerminalLocations.reduce((acc, cur) => ({
      ...acc,
      [cur.id]: cur.display_name,
    }), {})
    return (
      <div className={className}>
        <Dropdown drop="up">
          <Dropdown.Toggle
            id="terminal-menu-express"
          >
            <div style={{ border: 'none', fontWeight: 'bold', padding: 0 }}>
              {`Terminal: ${stripeTerminal.label || stripeTerminal.id}`}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              stripeTerminals.map(t => (
                <Dropdown.Item
                  key={t.id}
                  onClick={() => {
                    setStripeTerminal(t)
                  }}
                  active={t.id === stripeTerminal.id}
                >
                  { `${t.label || t.id} (${stripeTerminalLocationDict[t.location] || t.location || 'Unknown location'})` }
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
        {/* <DropdownButton
          id="terminal-menu-express"
          title={`Terminal: ${stripeTerminal.label || stripeTerminal.id}`}
          variant="link"
          style={{ border: 'none', fontWeight: 'bold', padding: 0 }}
          dropup={dropup}
        >
          {
            stripeTerminals.map(t => (
              <Dropdown.Item
                key={t.id}
                onClick={() => {
                  setStripeTerminal(t)
                }}
                active={t.id === stripeTerminal.id}
              >
                { `${t.label || t.id} (${stripeTerminalLocationDict[t.location] || t.location || 'Unknown location'})` }
              </Dropdown.Item>
            ))
          }
        </DropdownButton> */}
      </div>
    )
  }
}