import React from 'react'

import type { StripeContext } from './context'
import type { PaymentRequestSourceEvent, PaymentRequestTokenEvent, PaymentRequestCompleteStatus, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'

/**
 * PaymentMethod Creation event type payment request handler
 * 
 * @param stripeContext - Context State
 * @param setStripeContext - useState set function for context
 */
export const paymentMethodEventHandler = (stripeContext: StripeContext, setStripeContext) => (paymentMethod: PaymentRequestPaymentMethodEvent) => {
  console.debug('[providers][stripe] paymentMethodEventHandler', paymentMethod)
  setStripeContext({
    ...stripeContext,
    paymentRequestEvents: {
      ...stripeContext.paymentRequestEvents,
      paymentMethod,
    }
  })

  // potentially other resolutions we can send here
  // -- fail | invalid_(field) | complete
  const eventResolution: PaymentRequestCompleteStatus = 'success' 

  // IMPORTANT: has to be called within 30s
  paymentMethod.complete(eventResolution)
}

/**
 * Token event type payment request handler
 * 
 * @param token - The token event response
 */
export const tokenEventHandler = (token: PaymentRequestTokenEvent) => {
  return new Promise((resolve, reject) => {
    resolve(token)
  })
}

/**
 * Source event type payment request handler
 * 
 * @param stripeContext - Context State
 * @param setStripeContext - useState set function for context
 */
export const sourceEventHandler = (stripeContext: StripeContext, setStripeContext) => (source: PaymentRequestSourceEvent) => {
  console.debug('[providers][stripe] sourceEventHandler', source)
  setStripeContext({
    ...stripeContext,
    paymentRequestEvents: {
      ...stripeContext.paymentRequestEvents,
      source,
    }
  })

  const eventResolution: PaymentRequestCompleteStatus = 'success' 

  // IMPORTANT: has to be called within 30s
  source.complete(eventResolution)
}

/**
 * Cancel event type payment request handler
 */
export const cancelEventHandler = () => {
  return Promise.resolve()
}
