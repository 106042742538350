// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { setData } from 'actions/contactActions'
import { getCookie, setCookie } from 'modules/cookie'

@connect(store => ({
  campaigns: store.root.campaigns,
  inspiration: store.contact.inspiration,
  campaignId: store.contact.campaignId
}), {
  setData
})

export default class CampaignMenuExpress extends React.Component {

  findAndSetStripeCampaign = () => {
    const { campaigns, setData, initialUrlParams } = this.props
    const cookieCid = getCookie('cid')
    const presetCid = initialUrlParams.cid || cookieCid
    const presetCampaign = (presetCid && campaigns) ? campaigns.find(c => c.id === presetCid) : null
    const nextCampaign = presetCampaign || campaigns[0]
    if (nextCampaign) {
      setData({ field: 'inspiration', val: nextCampaign.public_name__c || nextCampaign.name })
      setData({ field: 'campaignId', val: nextCampaign.id })
      setCookie('cid', nextCampaign.id, 1000 * 60 * 60 * 24 * 30)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.campaigns.length && !prevProps.campaigns.length) {
      this.findAndSetStripeCampaign()
    }
    else if (this.props.campaignId !== prevProps.campaignId) {
      setCookie('cid', this.props.campaignId, 1000 * 60 * 60 * 24 * 30)
    }
  }

  componentDidMount() {
    const { campaigns } = this.props
    if (campaigns.length) {
      this.findAndSetStripeCampaign()
    }
  }

  render() {
    const { campaigns, campaignId, inspiration, setData, dropup, className } = this.props
    if (!campaigns.length) {
      return null
    }
    return (
      <div className={className}>
        <Dropdown drop="up">
          <Dropdown.Toggle
            id="campaign-menu-express"
            // variant='link'
          >
            {`Campaign: ${inspiration}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              campaigns.map(c => (
                <Dropdown.Item
                  key={c.id}
                  onClick={() => {
                    setData({ field: 'inspiration', val: c.public_name__c || c.name })
                    setData({ field: 'campaignId', val: c.id })
                  }}
                  active={c.id === campaignId}
                >
                  { c.public_name__c || c.name }
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
        {/* <DropdownButton
          title={`Campaign: ${inspiration}`}
          id="campaign-menu-express"
          className='d-inline-flex'
        >
          {
            dates.map((d, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => setPaymentDate(d)}
                active={d === paymentDate}
              >
                {dateTitles[i]}
              </Dropdown.Item>
            ))
          }
        </DropdownButton>
        <DropdownButton
          id="campaign-menu-express"
          title={`Campaign: ${inspiration}`}
          variant="link"
          style={{ border: 'none', fontWeight: 'bold', padding: 0 }}
          dropup={dropup}
        >
          
        </DropdownButton> */}
      </div>
    )
  }
}